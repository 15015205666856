import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';

import { EStates } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useGetRecurringMessageDetail = () => {
  const { campaignId } = useParams();
  const service = useCampaignsService();
  const [name, setName] = useState<string>('');
  const [configId, setConfigId] = useState<number>(0);
  const [state, setState] = useState<EStates>(EStates.ACTIVE);
  const [getInfoIsLoading, setGetInfoIsLoading] = useState<boolean>(false);

  const getRecurringMessageDetail = useCallback(async () => {
    if (!campaignId) return;
    setGetInfoIsLoading(true);
    const [dataResult, actionResult] = await Promise.all([
      service.getCampaignByID(Number(campaignId)),
      service.getActionsByCampaign({ campaignId: Number(campaignId), channel: [7] }),
    ]);

    if (dataResult.campaign && dataResult.campaign.name && actionResult.actions) {
      setName(dataResult.campaign.name);
      setConfigId(actionResult.actions[0].action.actionId ?? 0);
      setState(dataResult.campaign.state as EStates);
    }
    setGetInfoIsLoading(false);
  }, [campaignId, service]);

  useEffect(() => {
    void getRecurringMessageDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    name,
    configId,
    state,
    getInfoIsLoading,
    recurringMessageId: Number(campaignId),
    getRecurringMessageDetail,
  };
};
