import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Heading } from '@/lib/components';
import { Modal } from '@/lib/components/Modal';
import { closeModal } from '@/lib/components/Modal/Modal';
import { Button, Input, toast } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

const ButtonsSection = ({
  onAction,
  onClose,
}: {
  onAction?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  return (
    <div className="pt-4">
      <Flex withGap alignment="end" gapSize="medium">
        <Button outline onClick={onClose}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_GROUP_MODAL.close')}
          </div>
        </Button>
        <Button typeButton="submit" onClick={onAction}>
          <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
            {t('MANAGE_GROUP_MODAL.confirm')}
          </div>
        </Button>
      </Flex>
    </div>
  );
};

type FormInput = {
  group: string;
};

interface ICreateAndEditGroupModalProps {
  lastRefreshIncrement?: Function;
  editProps?: IEditProps;
  setLastRefreshParent?: Dispatch<SetStateAction<number>>;
}
interface IEditProps {
  groupId: number;
  groupName: string;
}

export const CreateAndEditGroupModal = ({
  lastRefreshIncrement,
  editProps,
  setLastRefreshParent,
}: ICreateAndEditGroupModalProps) => {
  const { handleSubmit, control } = useForm<FormInput>({
    defaultValues: {
      group: editProps?.groupName ?? '',
    },
  });

  const { t } = useTranslation();
  const service = useService();

  const RULES = {
    group: {
      required: t('MANAGE_GROUP_MODAL.emptyField'),
    },
    onActionCreate: async (value: string) => {
      const responseCreate = await service?.createGroup(value);
      const OkBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.OkBody');
      let alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.KoBody');
      let result = false;

      if (responseCreate.groupId) {
        result = true;
      } else if (responseCreate.errorByName) {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.ExistingBody');
      } else if (responseCreate.errorByLimit) {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.LimitBody');
      } else {
        alertBody = t('MANAGE_GROUP_MODAL.NOTIFICATION.KoBody');
      }
      result && lastRefreshIncrement && lastRefreshIncrement();
      setLastRefreshParent?.((prevLastRefresh) => prevLastRefresh + 1);

      closeModal();
      toast({
        title: result
          ? t('MANAGE_GROUP_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_GROUP_MODAL.NOTIFICATION.AlertTitle'),
        body: result ? OkBody : alertBody,
        variant: result ? 'success' : 'error',
      });
    },
    onActionEdit: async (newName: string) => {
      if (editProps === undefined) {
        return;
      }
      const result = await service?.editGroup({ groupId: editProps.groupId, groupName: newName });
      result && lastRefreshIncrement && lastRefreshIncrement();

      toast({
        title: result
          ? t('MANAGE_GROUP_MODAL.NOTIFICATION.OkTitle')
          : t('MANAGE_GROUP_MODAL.NOTIFICATION.AlertTitle'),
        body: result
          ? t('MANAGE_GROUP_MODAL.edit.NOTIFICATION.OkBody')
          : t('MANAGE_GROUP_MODAL.edit.NOTIFICATION.KoBody'),
        variant: result ? 'success' : 'error',
      });
      closeModal();
    },
  };

  const onAction = handleSubmit((value: FormInput) => {
    if (editProps !== undefined) {
      RULES.onActionEdit(value.group).catch(console.error);
    } else {
      RULES.onActionCreate(value.group).catch(console.error);
    }
  });

  return (
    <Modal isFloat={true} show={true}>
      <div className="h-full p-6">
        <Divide>
          <Flex column gapSize="small" itemAlignment="stretch">
            <Modal.Title buttonClose={false} onAction={closeModal}>
              <Heading>
                {editProps ? t('MANAGE_GROUP_MODAL.edit.title') : t('MANAGE_GROUP_MODAL.title')}
              </Heading>
            </Modal.Title>
            <div className="h-[86%]">
              <Modal.Body>
                <div className="w-[30vw] p-[1%] py-4">
                  <div className="w-full">
                    <section className="mb-3">
                      <div className="pt-4">
                        <Flex column itemAlignment={'start'}>
                          <label className="xl:text-15 text-[10px] font-bold text-[#63799b] lg:text-14 2xl:text-16">
                            {t('MANAGE_TAG_MODAL.fieldName')}
                          </label>
                        </Flex>
                        <Input control={control} name="group" rules={RULES.group} />
                      </div>
                    </section>
                  </div>
                </div>
              </Modal.Body>
            </div>
            <Modal.Footer>
              <ButtonsSection onAction={onAction} onClose={closeModal} />
            </Modal.Footer>
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};
