import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { SummaryMetricsSkeleton } from '@/lib/components/Skeletons';
import { Card, IconSvg, If, Text, Tooltip } from '@/lib/v2/components';
import { InfoIcon } from '@/lib/v2/icons/outline';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import {
  atomChannelTotals,
  atomChannelTracking,
  atomMetricType,
} from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { COLOR_METRICS, METRICS_HISTORY } from '@/src/modules/InsightsModule/constants';
import { useShippingMetric } from '@/src/modules/InsightsModule/hooks/useShippingMetrics';
import { IChannelTotals } from '@/src/modules/InsightsModule/interfaces/ContactsSummary';
import { transformBouncesToFailed } from '@/src/modules/InsightsModule/utils';

import {
  ChannelTotalType,
  ChannelTrackingType,
  ISectionMetrics,
} from '@/modules/InsightsModule/interfaces/Channel';

const ShippingMetrics = () => {
  const { t } = useTranslation(['insights']);

  const channelTotals = useAtomValue(atomChannelTotals);
  const metricType = useAtomValue(atomMetricType);
  const channelTracking = useAtomValue(atomChannelTracking);

  const {
    state: { loading },
  } = useShippingMetric();

  const sectionMetrics: ISectionMetrics = useMemo(
    () => ({
      [ChannelTotalType.ALL]: {
        title: t('shipping.actions.all'),
        metrics: METRICS_HISTORY.all,
        colors: COLOR_METRICS.all,
      },
      [ChannelTotalType.EMAIL]: {
        title: t('shipping.actions.email'),
        metrics: METRICS_HISTORY.email,
        colors: COLOR_METRICS.email,
      },
      [ChannelTotalType.SMS]: {
        title: t('shipping.actions.sms'),
        metrics: METRICS_HISTORY.sms,
        colors: COLOR_METRICS.sms,
      },
      [ChannelTotalType.INBOX]: {
        title: t('shipping.actions.inbox'),
        metrics: METRICS_HISTORY.inbox,
        colors: COLOR_METRICS.inbox,
      },
      [ChannelTotalType.ONSITE]: {
        title: t('shipping.actions.onsite'),
        metrics: METRICS_HISTORY.onsite,
        colors: COLOR_METRICS.onsite,
      },
    }),
    [t]
  );

  const metrics = useMemo(
    () =>
      channelTracking
        ? Object.entries(channelTracking)
            .map(([key, value]) => ({ key, value }))
            .filter((item) =>
              sectionMetrics[metricType].metrics.includes(item.key as ChannelTrackingType)
            )
            .map((item, metricKey) => {
              const color = sectionMetrics[metricType].colors[metricKey];
              return { ...item, color };
            })
        : [],
    [sectionMetrics, channelTracking, metricType]
  );

  return (
    <Card noPadding noShadow className="charles flex-1 p-4">
      <Text variant="sub-headline-2">{sectionMetrics[metricType].title}</Text>
      <div className="flex items-end justify-start ">
        <Text className="h-10 leading-tight" variant="title-3">
          <If condition={!loading || !channelTotals} fallback={<TextSkeleton size="xxs" />}>
            {metricType === ChannelTotalType.ALL
              ? quantifier(channelTotals?.all ?? 0)
              : quantifier(channelTotals?.[metricType.toLowerCase() as keyof IChannelTotals] ?? 0)}
          </If>
        </Text>
        <div className="mt-1 flex self-start">
          <Tooltip
            isDark
            alignment="center"
            content={
              <Text alignment="center" className="w-40 text-white" variant="text-sm">
                <If condition={metricType === ChannelTotalType.ALL}>
                  {t('shipping.tooltipChannels')}
                </If>
                <If condition={metricType !== ChannelTotalType.ALL}>
                  {t('shipping.tooltipByChannels', { channel: sectionMetrics[metricType].title })}
                </If>
              </Text>
            }
            position="top"
          >
            <IconSvg height="24" strokeColor="secondary" svgComponent={<InfoIcon />} width="24" />
          </Tooltip>
        </div>
      </div>

      <If condition={!loading} fallback={<SummaryMetricsSkeleton columns={2} />}>
        <div className="visible-scrollbar flex max-h-96 flex-col overflow-y-auto px-2">
          {metrics?.map((item, key) => {
            const labelKey = item.key.replaceAll(' ', '_');
            const cleanLabel = transformBouncesToFailed(labelKey, metricType);
            const keyId = `${cleanLabel}_${key}`;
            const itemClassName = classNames(
              'flex items-center p-4 border-solid border-emblue-secondary',
              { 'border-b': key !== metrics.length - 1 }
            );

            return (
              <div key={keyId} className={itemClassName}>
                <div className="flex w-3/4 items-center gap-2">
                  <div className={`size-4 ${item.color} rounded-sm`}></div>
                  {t(`shipping.metrics.${cleanLabel.toUpperCase()}`)}
                </div>
                <div className="flex w-1/4 justify-end">
                  <Text fontWeight="medium" variant="sub-headline-2">
                    {quantifier(item.value)}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </If>
    </Card>
  );
};

export default ShippingMetrics;
