import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, IconSvg } from '@/lib/v2/components';
import { DeleteIconNew, PreviewIcon } from '@/lib/v2/icons/outline';
import { EditPencilIconNew } from '@/lib/v2/icons/solid';
import { ModalConfirmation } from '@/lib/v2/organisms';

import { atomOpenMessageModal } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { ButtonPreviewCopyLink } from '@/src/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail/components/ButtonPreviewCopyLink';

import { useEmailPreviewButtons } from './useEmailPreviewButtons.hooks';

interface IEmailPreviewButtons {
  loading?: boolean;
  disabled?: boolean;
  sourceEditor?: string;
  hasPreview?: boolean;
  onDelete?: () => void;
  onPreview?: () => void;
  onEdit?: () => void;
}

const EmailPreviewButtons = ({
  loading,
  onDelete,
  disabled,
  sourceEditor,
  hasPreview = true,
  onPreview,
  onEdit,
}: IEmailPreviewButtons) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useAtom(atomOpenMessageModal);

  const {
    state: { redirectLocation, editorRoute },
  } = useEmailPreviewButtons({ sourceEditor });

  const handlePreview = useCallback(() => {
    if (onPreview) {
      onPreview();
      return;
    }
    navigate(`${redirectLocation}/preview`);
  }, [onPreview, navigate, redirectLocation]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit();
      return;
    }
    navigate(`${redirectLocation}/${editorRoute}`);
  }, [onEdit, navigate, redirectLocation, editorRoute]);

  return (
    <>
      <div className="flex justify-end gap-2">
        <Button
          gray
          outline
          className="!size-[38px]"
          disabled={!hasPreview}
          iconLeft={<IconSvg height="18px" svgComponent={<PreviewIcon />} width="18px" />}
          id="email-preview"
          size="small"
          onClick={handlePreview}
        />
        <Button
          gray
          outline
          className="!size-[38px]"
          disabled={disabled}
          iconLeft={<IconSvg svgComponent={<EditPencilIconNew />} />}
          id="email-edit"
          size="small"
          onClick={handleEdit}
        />
        <ButtonPreviewCopyLink />
        <Button
          gray
          outline
          className="!size-[38px]"
          disabled={disabled}
          iconLeft={<IconSvg height="20px" svgComponent={<DeleteIconNew />} width="20px" />}
          id="email-delete"
          size="small"
          onClick={() => setOpenModal(true)}
        />
      </div>
      {openModal && (
        <ModalConfirmation
          isLoading={loading}
          isOpen={openModal}
          textCancelButton={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.cancel')}
          textConfirmButton={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.delete`)}
          title={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.modalConfirmation')}
          onClose={() => setOpenModal(false)}
          onConfirmButton={onDelete}
        />
      )}
    </>
  );
};

export default memo(EmailPreviewButtons);
