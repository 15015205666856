import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useDropdownFilters } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer/components/ActionsFilterBar/hooks/useDropdownFilters';

import { DROPDOWN_CHANNELS } from '@/modules/CampaignsModule/constants';
import { EChannelsID } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const FilterActionChannel = () => {
  const { t } = useTranslation();

  const {
    state: { filtersDropdownAction },
    events: { handleCheckboxChange },
  } = useDropdownFilters();

  const showNewAutomationModule = useFeatureFlag('showNewAutomationModule');

  const handleCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      handleCheckboxChange('channel', Number(value), checked);
    },
    [handleCheckboxChange]
  );

  const channelItems = useMemo(() => {
    return DROPDOWN_CHANNELS.map((channel) => ({
      ...channel,
      label: t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${channel.label}`),
      value: channel.value,
      checked: filtersDropdownAction?.channel?.includes(Number(channel.value)),
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [filtersDropdownAction, t]);

  const classNameScrollable = 'flex flex-col gap-2 max-h-[180px]';

  const filteredChannels = channelItems.filter(
    (channel) => !(showNewAutomationModule && channel.value === EChannelsID.EMAIL_TRIGGER)
  );

  return (
    <div className="mb-4">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGN_ACTIONS_MAIN.FILTERS.channel')}
      </Text>
      <div className={classNameScrollable}>
        {filteredChannels.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`channel-${item.id}`}
            label={item.label}
            value={item.value}
            onChange={handleCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterActionChannel);
