//cspell:ignore mensaje
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, PhonePreview, PhonePreviewWhatsApp, Spinner, Text } from '@/lib/v2/components';

import { useContentVariables } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/hooks/useContentVariables';

import { atomCurrentActionId } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { useExtractBodyFromJson } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction/hooks/useExtractBodyFromJson';
import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';

const PhonePreviewModal = () => {
  const { t } = useTranslation();

  const actionInfoSms = useAtomValue(atomActionInfoSms);
  const [currentActionId, setCurrentActionId] = useAtom(atomCurrentActionId);

  const { getSmsInfo, loading } = useActionSmsData(Number(currentActionId));
  const bodyMessage = useExtractBodyFromJson(actionInfoSms?.mensaje ?? '');

  const { parseAndReplaceContentVariables } = useContentVariables();

  useEffect(() => {
    void getSmsInfo();
  }, [getSmsInfo]);

  const closeModal = useCallback(() => {
    setCurrentActionId(undefined);
  }, [setCurrentActionId]);

  //cspell:disable
  const renderContent = () => {
    if (loading) {
      return <Spinner withoutOverlay />;
    }

    if (typeof actionInfoSms?.mensaje === 'string' && actionInfoSms.mensaje.trim() !== '') {
      const result = parseAndReplaceContentVariables(actionInfoSms.mensaje);

      if (result.isValid && result.contentSid) {
        return <PhonePreviewWhatsApp shortPhone mediaUrl={result.mediaUrl} message={result.body} />;
      }
      return <PhonePreview shortPhone message={bodyMessage} />;
    }

    return (
      <div className="flex h-300 w-430 items-center justify-center p-12">
        <span className="block py-2 text-center text-16 text-emblue-disabled">
          {t('CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.noMessage')}
        </span>
      </div>
    );
  };
  //cspell:enable

  return (
    <Modal open={!!currentActionId} onClose={closeModal}>
      <div className="flex flex-col gap-2 divide-y">
        <header>
          <Text as="h3" fontWeight="medium" variant="sub-headline">
            {t('CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.preview')}
          </Text>
        </header>
        <section className="flex w-full justify-center pt-6 text-16">{renderContent()}</section>
      </div>
    </Modal>
  );
};

export default PhonePreviewModal;
