import { format } from '@formkit/tempo';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/BadgeText';
import { ColorsBadge } from '@/lib/v2/components/BadgeText/types';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import {
  DROPDOWN_ACTIONS_STATE,
  DROPDOWN_CHANNELS,
  LIST_ACTIONS_STATE,
  WHATSAPP_PREFIX,
} from '@/modules/CampaignsModule/constants';
import {
  ICalendarCampaignActions,
  ICalendarCampaignList,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface SidebarListProps {
  list: ICalendarCampaignList | undefined;
}

const SidebarList = ({ list }: SidebarListProps) => {
  const { t } = useTranslation();

  const enableWhatsAppModule = useFeatureFlag('enableWhatsAppModule');

  const getChannelLabel = useCallback(
    (channelId: number): string => {
      const labelChannel =
        DROPDOWN_CHANNELS.find((channel) => channel.value === channelId)?.label ?? 'email';
      return t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${labelChannel}`);
    },
    [t]
  );

  const getCurrentState = useCallback(
    (status: number) => {
      const [filter] = DROPDOWN_ACTIONS_STATE.filter((item) => Number(item.value) === status);
      return t(`CAMPAIGN_ACTIONS_MAIN.STATES.${filter.label}`) ?? '-';
    },
    [t]
  );

  const getStatusColor = useCallback(
    (statusId: number): { badge: string; background: string } | null => {
      const group = LIST_ACTIONS_STATE.find((group) => group.values.includes(statusId));
      return group ? { badge: group.badge, background: group.background } : null;
    },
    []
  );

  const isWhatsAppAction = useCallback(
    (name: string) => enableWhatsAppModule && name.startsWith(WHATSAPP_PREFIX),
    [enableWhatsAppModule]
  );

  const formatActionName = useCallback(
    (name: string) => {
      const formattedName = enableWhatsAppModule ? name.replace(WHATSAPP_PREFIX, '') : name;
      return formattedName.charAt(0).toLocaleUpperCase() + formattedName.slice(1);
    },
    [enableWhatsAppModule]
  );

  if (!list) return null;

  return (
    <div className="visible-scrollbar h-[calc(100vh_-_186px)] max-h-[720px] overflow-y-auto overflow-x-hidden">
      {list &&
        Object.entries(list).map(
          ([campaignId, campaigns]: [string, ICalendarCampaignActions[]], index) => (
            <Disclosure key={campaignId} as="div" className="mt-2" defaultOpen={index === 0}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className="text-sm mb-3 flex w-full items-center justify-between gap-1 text-left font-medium"
                    id={`calendar-toggle__button-${index}`}
                  >
                    <Text truncate color="black" fontWeight="medium" variant="sub-headline">
                      {campaigns[0].campaignName.charAt(0).toUpperCase() +
                        campaigns[0].campaignName.slice(1)}
                    </Text>
                    <span className="min-h-5">
                      <IconSvg
                        height="20px"
                        svgComponent={<ChevronDownIcon className={`${open ? 'rotate-180' : ''}`} />}
                        width="20px"
                      />
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {campaigns?.map((action: ICalendarCampaignActions) => (
                      <div key={action.id} className="relative mb-4 flex flex-col gap-1 pl-2">
                        <div className=" max-w-[80%]">
                          <Text truncate color="black" fontWeight="medium" variant="text">
                            {formatActionName(action.name)}
                          </Text>
                        </div>
                        <div className="flex items-center gap-x-2">
                          <Text color="black" variant="text">
                            {isWhatsAppAction(action.name)
                              ? 'WhatsApp'
                              : getChannelLabel(action.type)}
                          </Text>
                          <BadgeText
                            text={getCurrentState(action.status)}
                            variantColor={
                              (getStatusColor(action.status)?.badge as ColorsBadge) ?? null
                            }
                          />
                        </div>
                        <Text className="absolute right-1 top-0" color="black" variant="text">
                          {format(new Date(action.sendDate), 'HH:mm')}
                        </Text>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )
        )}
    </div>
  );
};

export default SidebarList;
