import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAutomationService } from '@/src/infrastructure/Protocol/Automation/useAutomationService';
import { atomCreateEventForm } from '@/src/modules/AutomationModule/atoms/createEventAtoms';

export const useStructureDataEvent = () => {
  const service = useAutomationService();
  const createEventForm = useAtomValue(atomCreateEventForm);

  const [apiKey, setApiKey] = useState<string>('');

  const getApiKey = useCallback(async () => {
    const response = await service.getApiKey();
    return response.data;
  }, [service]);

  useEffect(() => {
    const fetchApiKey = async () => {
      const token = await getApiKey();
      setApiKey(token.apiKeyBase64);
    };

    void fetchApiKey();
  }, [getApiKey]);

  const jsonEvent = useMemo(() => {
    const attributesJson = createEventForm.attributes.reduce<Record<string, string>>(
      (acc, attr) => {
        acc[attr.value] = '';
        return acc;
      },
      {}
    );

    const eventItemsJson = createEventForm.eventItems.reduce<Record<string, string>>(
      (acc, attr) => {
        acc[attr.value] = '';
        return acc;
      },
      {}
    );

    return {
      email: '',
      eventName: createEventForm.name ?? '',
      attributes: { ...attributesJson, event_items: [{ ...eventItemsJson }] },
    };
  }, [createEventForm]);

  const handleEditorWillMount = (monaco: {
    editor: { defineTheme: (name: string, config: object) => void };
  }) => {
    monaco.editor.defineTheme('my-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: 'delimiter', foreground: '#ffffff' },
        { token: 'string.key.json', foreground: '#FC3293' },
        { token: 'string.value.json', foreground: '#8543D3' },
      ],
      colors: {
        'editor.background': '#002646',
      },
    });
  };

  return { jsonEvent, handleEditorWillMount, apiKey, getApiKey };
};
