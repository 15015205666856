import { useMemo } from 'react';

import { ErrorNewIcon, InfoNewIcon, SuccessNewIcon, WarningNewIcon } from '@/lib/v2/icons/outline';
import { AlertType } from '@/lib/v3/components/Alert/types';

export const useAlertIcon = (type: AlertType) => {
  const alertIcon = useMemo(() => {
    const ALERT_ICONS: Record<AlertType, JSX.Element> = {
      success: <SuccessNewIcon />,
      warning: <WarningNewIcon />,
      info: <InfoNewIcon />,
      error: <ErrorNewIcon />,
    };
    return ALERT_ICONS[type];
  }, [type]);

  return alertIcon;
};
