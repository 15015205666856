//cspell:disable
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { useCustomField } from '@/src/application/hooks/useCustomField';
import { IAddresseesList } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  TAG_FREQUENCY_MAP,
  TAG_TYPE_FIELD_OPTIONS,
  TRANSLATE_RULES_EXECUTION_OPTIONS,
  TRANSLATE_RULES_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_PERIOD_OPTIONS,
  TRANSLATE_RULES_PRESENCE_OPTIONS,
  TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS,
  TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS,
  TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_TAG_TYPE_OPTIONS,
  TRANSLATE_RULES_TRIGGER_OPTIONS,
} from '@/src/modules/RulesModule/constants';
import {
  IRulesV1Response,
  ITagListRules,
  RulesOptions,
  RuleTypes,
} from '@/src/modules/RulesModule/interfaces';

type Language = 'es' | 'en' | 'pt';
type ActionLabel = 'sameDay' | 'before' | 'after' | 'immediate';

interface ExecutionGroup {
  id: number;
  label: ActionLabel;
  values: Language[];
}

interface ExecutionTranslations {
  [key: string]: Record<Language, string>;
}

const EXECUTION_STATE_GROUPED: ExecutionGroup[] = [
  { id: 1, label: 'sameDay', values: ['es', 'en', 'pt'] },
  { id: 2, label: 'before', values: ['es', 'en', 'pt'] },
  { id: 3, label: 'after', values: ['es', 'en', 'pt'] },
  { id: 4, label: 'immediate', values: ['es', 'en', 'pt'] },
];

const executionTranslations: ExecutionTranslations = {
  sameDay: { es: 'mismo', en: 'on the same', pt: 'mismo' },
  before: { es: 'antes', en: 'before the', pt: 'antes' },
  after: { es: 'despues', en: 'after the', pt: 'despois' },
  immediate: { es: 'de inmediato', en: 'immediate', pt: 'imediato' },
};

const normalizeString = (str: string): string => str.trim().toLowerCase().replace(/\s+/g, '');

const findMatchingGroup = (normalizedValue: string): ExecutionGroup | undefined =>
  EXECUTION_STATE_GROUPED.find((group) =>
    group.values.some(
      (lang) => normalizeString(executionTranslations[group.label][lang]) === normalizedValue
    )
  );

const transformToOption = ({
  id,
  name,
  value,
}: {
  id?: string | number | null;
  name: string;
  value?: string | number | boolean;
}): Option => ({
  id: id ?? name,
  name,
  value: value,
  disabled: false,
});

const transformToExternalOption = ({
  id,
  name,
  value,
}: {
  id: string | number;
  name: string;
  value: string | number;
}): OptionExternal => ({
  id,
  name,
  value,
});

const tagsSelectOptions = (tagList: ITagListRules | undefined, selectedTags: number[]) => {
  if (!tagList?.list?.length) return [];

  const selectedTagsSet = new Set(selectedTags);
  return tagList.list
    .filter(({ tag }) => selectedTagsSet.has(tag.id))
    .map(({ tag }) => ({
      id: Number(tag.id),
      name: tag.name,
      value: tag.id,
    }));
};

const useRuleDataHelper = () => {
  const { t } = useTranslation('rules');
  const {
    events: { translateCustomField },
  } = useCustomField();

  const getExecutionOption = useMemo(
    () =>
      (value: string): Option | undefined => {
        const normalizedValue = normalizeString(value);
        const matchingGroup = findMatchingGroup(normalizedValue);

        if (matchingGroup) {
          return {
            id: matchingGroup.id,
            name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.${matchingGroup.label}`),
            value: value,
          };
        }

        return undefined;
      },
    [t]
  );

  const getRuleFrequency = useCallback(
    (trigger: string, sendDate: string) => {
      if (trigger === RuleTypes.TAG) {
        const period = TAG_FREQUENCY_MAP[sendDate];
        if (!period) return undefined;

        return {
          id: period,
          name: `${t(`${TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS}.${period}`)}`,
          value: sendDate,
        };
      }

      const [, sendFrequency] =
        sendDate === 'de inmediato' ? [null, null, null, null] : sendDate?.split('|') ?? [];

      return sendFrequency
        ? transformToOption({
            id: sendFrequency,
            name: t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.${sendFrequency}`),
            value: sendFrequency,
          })
        : undefined;
    },
    [t]
  );

  const parseRuleData = useMemo(
    () =>
      (
        data: IRulesV1Response | null,
        addresseesResponse?: IAddresseesList[],
        tagList?: ITagListRules
      ): RulesOptions => {
        const safeData = data ?? ({} as IRulesV1Response);

        const [hours, minutes] = safeData.horaEnvio?.split(':') ?? [];
        const [days, , sendExecution, sendPeriod] =
          safeData.fechaEnvio === 'de inmediato'
            ? [null, null, null, null]
            : safeData.fechaEnvio?.split('|') ?? [];

        let selectedGroupOrSegment: OptionExternal | undefined;

        if (addresseesResponse && addresseesResponse.length > 0) {
          if (safeData.group_rules) {
            const groupRule = addresseesResponse[0];

            selectedGroupOrSegment = transformToExternalOption({
              id: groupRule.groupId,
              name: groupRule.name,
              value: groupRule.type,
            });
          }
        }

        let presenceCondition: Option | undefined;

        if (safeData.group_rules_in === null) {
          presenceCondition = undefined;
        } else if (safeData.group_rules_in) {
          presenceCondition = transformToOption({
            id: 1,
            name: t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.present`),
            value: 1,
          });
        } else {
          presenceCondition = transformToOption({
            id: 0,
            name: t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.notPresent`),
            value: 0,
          });
        }

        return {
          trigger: safeData.desencadenante
            ? transformToOption({
                id: normalizeString(safeData.desencadenante),
                name: t(
                  `${TRANSLATE_RULES_TRIGGER_OPTIONS}.${normalizeString(safeData.desencadenante)}`
                ),
                value: safeData.desencadenante,
              })
            : undefined,
          customField: safeData.campoDTO
            ? transformToOption({
                id: safeData.campoDTO.id,
                name: translateCustomField(safeData.campoDTO.nombre).toLowerCase(),
                value: safeData.campoDTO.id,
              })
            : undefined,
          days:
            days && days !== 'de inmediato'
              ? transformToOption({ name: days, value: days })
              : undefined,
          uniqueExecution: days !== 'de inmediato' ? (days as string) : undefined,
          frequency: getRuleFrequency(safeData.desencadenante, safeData.fechaEnvio),
          execution: sendExecution ? getExecutionOption(sendExecution) : undefined,
          period:
            sendPeriod && sendPeriod !== 'de inmediato'
              ? transformToOption({
                  id: sendPeriod,
                  name: t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.${sendPeriod}`),
                  value: sendPeriod,
                })
              : undefined,
          hours: hours ? transformToOption({ name: hours, value: hours }) : undefined,
          minutes: minutes ? transformToOption({ name: minutes, value: minutes }) : undefined,
          selectedGroupOrSegment,
          presenceCondition,
          repeatEmails: transformToOption({
            id: safeData.envioRecurrente ? 1 : 0,
            name: t(
              `${TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS}.${
                safeData.envioRecurrente ? 'always' : 'once'
              }`
            ),
            value: safeData.envioRecurrente ? 1 : 0,
          }),
          actionId: safeData.elementoId,
          groupContacts: safeData.grupoDTO
            ? transformToExternalOption({
                id: safeData.grupoDTO.id,
                name: safeData.grupoDTO.nombre.toString(),
                value: Number(safeData.grupoDTO.id),
              })
            : undefined,
          redirectSuccess: safeData.redireccionamientoFinal
            ? transformToOption({
                id: normalizeString(safeData.redireccionamientoFinal),
                name: t(
                  `${TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS}.${normalizeString(
                    safeData.redireccionamientoFinal
                  )}`
                ),
                value: safeData.redireccionamientoFinal,
              })
            : undefined,
          destinationSuccessUrl: safeData.urlDestino || '',
          redirectError: safeData.redireccionamientoFinalError
            ? transformToOption({
                id: normalizeString(safeData.redireccionamientoFinalError),
                name: t(
                  `${TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS}.${normalizeString(
                    safeData.redireccionamientoFinalError
                  )}`
                ),
                value: safeData.redireccionamientoFinalError,
              })
            : undefined,
          destinationErrorUrl: safeData.urlDestinoError || '',
          externalUrl: safeData.urlExterna,
          tagTypeField:
            safeData.tag_id.length === 0
              ? {
                  id: '1',
                  name: t(`${TRANSLATE_RULES_TAG_TYPE_OPTIONS}.anyTag`),
                  value: TAG_TYPE_FIELD_OPTIONS.ANY_TAG,
                }
              : {
                  id: '2',
                  name: t(`${TRANSLATE_RULES_TAG_TYPE_OPTIONS}.groupTags`),
                  value: TAG_TYPE_FIELD_OPTIONS.GROUP_TAGS,
                },
          tagsGroupField: tagsSelectOptions(tagList, safeData.tag_id),
          customFieldsDTO: safeData.camposPersonalizadosDTO,
        };
      },
    [t, translateCustomField, getRuleFrequency, getExecutionOption]
  );

  return {
    parseRuleData,
    getExecutionOption,
  };
};

export default useRuleDataHelper;
//cspell:enable
