import { useAtomValue } from 'jotai';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { atomProductTourToggle } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { atomBlockAndSuspendModal } from '@/src/presentation/components/BlockAndSuspendModal';

import CustomTooltip from './components/CustomTooltip';
import { useInsightsProductTour } from './hooks/useInsightsProductTour';

export const InsightsProductTour = () => {
  const { t } = useTranslation(['insights']);

  const runTour = useAtomValue(atomProductTourToggle);
  const blockAndSuspendModal = useAtomValue(atomBlockAndSuspendModal);
  const { paymentInfo } = useStateUserContext();

  const {
    events: { handleJoyrideCallback, startTour },
    state: { steps },
  } = useInsightsProductTour();

  useEffect(() => {
    if (!paymentInfo) return;

    const { isBlocked, isSuspended } = paymentInfo;

    if (isBlocked || isSuspended) {
      if (!blockAndSuspendModal) {
        void startTour(0);
      }
    } else {
      void startTour();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTour, blockAndSuspendModal, paymentInfo?.isBlocked, paymentInfo?.isSuspended]);

  return (
    <Joyride
      continuous
      disableOverlayClose
      scrollToFirstStep
      callback={handleJoyrideCallback}
      locale={{
        back: t('INSIGHTS_PRODUCT_TOUR.BUTTON.previous'),
        close: t('INSIGHTS_PRODUCT_TOUR.BUTTON.close'),
        next: t('INSIGHTS_PRODUCT_TOUR.BUTTON.next'),
      }}
      run={runTour}
      scrollOffset={250}
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
      tooltipComponent={CustomTooltip}
    />
  );
};

export default memo(InsightsProductTour);
