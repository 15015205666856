import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { usePagination } from '@/lib/v3/components/Table';
import { OrderColumnState } from '@/lib/v3/components/Table/Table';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { CheckboxItems } from '@/src/modules/AutomationModuleNew/components/DropdownFilterSection/DropdownFilterSection';
import {
  RECURRING_MESSAGE_DETAIL_FILTERS,
  RecurringMessageDetailFilters,
} from '@/src/modules/AutomationModuleNew/constants';
import { CampaignActionsListFilters } from '@/src/modules/AutomationModuleNew/interfaces/automation';

import {
  ICampaignActions,
  ICampaignActionsListPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const defaultFilters: CampaignActionsListFilters = {
  search: '',
  status: [],
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
  sortField: 'creationDate',
  sortOrder: 'desc',
};

export const useRecurringMessagesDetailFilters = () => {
  const service = useCampaignsService();
  const { campaignId } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [countDataList, setCountDataList] = useState<{ total: number; partial: number }>({
    total: 0,
    partial: 0,
  });
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const defaultDropDownItems = useCallback(
    (stateUrl?: string | null): CheckboxItems => ({
      status: {
        label: t('RecurringMessagesFilters.filter.status'),
        items: [
          {
            id: RECURRING_MESSAGE_DETAIL_FILTERS.completed.label,
            label: t('useRecurringMessagesDetailFilters.filter.completed'),
            value: RECURRING_MESSAGE_DETAIL_FILTERS.completed.label,
            checked: stateUrl?.includes(RECURRING_MESSAGE_DETAIL_FILTERS.completed.label) || false,
          },
          {
            id: RECURRING_MESSAGE_DETAIL_FILTERS.draft.label,
            label: t('useRecurringMessagesDetailFilters.filter.draft'),
            value: RECURRING_MESSAGE_DETAIL_FILTERS.draft.label,
            checked: stateUrl?.includes(RECURRING_MESSAGE_DETAIL_FILTERS.draft.label) || false,
          },
          {
            id: RECURRING_MESSAGE_DETAIL_FILTERS.inProgress.label,
            label: t('useRecurringMessagesDetailFilters.filter.inProgress'),
            value: RECURRING_MESSAGE_DETAIL_FILTERS.inProgress.label,
            checked: stateUrl?.includes(RECURRING_MESSAGE_DETAIL_FILTERS.inProgress.label) || false,
          },
        ],
      },
    }),
    [t]
  );

  const [dropDownItems, setDropDownItems] = useState<CheckboxItems>(
    defaultDropDownItems(searchParams.get('status'))
  );

  const {
    data,
    setData,
    isLoading,
    valuePagination,
    setRowCount,
    rowCount,
    paginationChange,
    orderColumn,
    orderColumnChange,
    rowSelection,
    rowSelectionChange,
  } = usePagination<ICampaignActions>({
    initialPaginationState: {
      currentPage: searchParams.get('page')
        ? Number(searchParams.get('page'))
        : defaultFilters.page,
      pageSize: searchParams.get('limit')
        ? Number(searchParams.get('limit'))
        : (defaultFilters.limit as number),
    },
    initialOrderColumn: {
      orderByColumn: searchParams.get('sortField') || (defaultFilters.sortField as string),
      orderByDirection:
        (searchParams.get('sortOrder') as 'asc' | 'desc') ||
        (defaultFilters.sortOrder as 'asc' | 'desc'),
    },
  });

  const [filters, setFilters] = useState<CampaignActionsListFilters>({
    campaignId: Number(campaignId),
    channel: [1],
    search: searchParams.get('search') || '',
    status: searchParams.get('status')?.split(',') || [],
    ...(searchParams.get('startDate') && {
      startDate: new Date(searchParams.get('startDate') as string),
    }),
    ...(searchParams.get('endDate') && {
      endDate: new Date(searchParams.get('endDate') as string),
    }),
    page: valuePagination.currentPage,
    limit: valuePagination.pageSize,
    sortField: searchParams.get('sortField') || 'creationDate',
    sortOrder: searchParams.get('sortOrder') || 'desc',
  });

  const getHasFiltersApplied = useCallback((filters: CampaignActionsListFilters) => {
    return (
      filters.search !== '' ||
      (filters.status && filters.status.length > 0) ||
      filters.startDate instanceof Date ||
      filters.endDate instanceof Date
    );
  }, []);

  const getFetchRecurrentMessage = useCallback(
    async (filters: CampaignActionsListFilters) => {
      if (!filters.campaignId) return;
      const response = await service.getActionsByCampaign(filters as ICampaignActionsListPayload);
      if (response.actions) {
        const dataMap = response.actions.map((item) => item.action);
        const partial = response.partial ?? 0;
        // TODO: Patch due to error in the API that counts the master action as 1
        const total = response.total ? response.total - 1 : 0;
        const hasFiltersApplied = getHasFiltersApplied(filters);
        setHasFiltersApplied(hasFiltersApplied);
        setCountDataList({ total, partial });
        setData(dataMap);
        setRowCount(hasFiltersApplied ? partial : total);
      }
    },
    [getHasFiltersApplied, service, setData, setRowCount]
  );

  const handlePaginationChange = (currentPage: number, pageSize: number) => {
    void paginationChange(currentPage, pageSize, async () => {
      const newFilters = { ...filters, page: currentPage, limit: pageSize };
      updateFiltersUrl(newFilters);
      await getFetchRecurrentMessage(newFilters);
    });
  };

  const updateFiltersUrl = useCallback(
    (newFilters: CampaignActionsListFilters) => {
      const updatedFilters: CampaignActionsListFilters = { ...filters, ...newFilters };
      setFilters(updatedFilters);

      const mapDates = {
        ...updatedFilters,
        startDate: updatedFilters.startDate?.toISOString(),
        endDate: updatedFilters.endDate?.toISOString(),
      };
      const params = new URLSearchParams();
      Object.entries(mapDates).forEach(([key, value]) => {
        if (key !== 'campaignId') {
          if (Array.isArray(value)) {
            if (value.length > 0) params.set(key, value.join(','));
          } else if (value) {
            params.set(key, String(value));
          }
        }
      });

      setSearchParams(params);
    },
    [filters, setSearchParams]
  );

  const updateUrlAndFetchDataWithResetPagination = useCallback(
    async (updateFilters: CampaignActionsListFilters, noResetPageAndLimit: boolean = false) => {
      const newFilters = {
        ...filters,
        ...updateFilters,
        ...(!noResetPageAndLimit && { page: defaultFilters.page }),
        ...(!noResetPageAndLimit && { limit: defaultFilters.limit }),
      };

      updateFiltersUrl(newFilters);

      newFilters.status = newFilters.status?.flatMap(
        (item) =>
          RECURRING_MESSAGE_DETAIL_FILTERS[item as keyof RecurringMessageDetailFilters].values
      );

      await paginationChange(newFilters.page as number, newFilters.limit as number, async () => {
        await getFetchRecurrentMessage(newFilters);
      });
    },
    [filters, getFetchRecurrentMessage, paginationChange, updateFiltersUrl]
  );

  const getInitialData = useCallback(async () => {
    await updateUrlAndFetchDataWithResetPagination(filters);
  }, [filters, updateUrlAndFetchDataWithResetPagination]);

  const resetFilters = useCallback(async () => {
    await updateUrlAndFetchDataWithResetPagination(defaultFilters);
    setDropDownItems(defaultDropDownItems());
  }, [defaultDropDownItems, updateUrlAndFetchDataWithResetPagination]);

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilters((prevState) => ({ ...prevState, search: value }));
  }, []);

  const handleSearchApply = useCallback(
    (value: string) => {
      const newFilters = {
        ...filters,
        search: value,
      };
      void updateUrlAndFetchDataWithResetPagination(newFilters);
    },
    [filters, updateUrlAndFetchDataWithResetPagination]
  );

  const handleStatusFilterApply = useCallback(
    (checkedItems?: CheckboxItems) => {
      const newFilters: CampaignActionsListFilters = {
        ...filters,
        page: defaultFilters.page,
        limit: defaultFilters.limit,
        status: checkedItems?.status?.items.map((item) => item.value) || [],
      };
      void updateUrlAndFetchDataWithResetPagination(newFilters);
    },
    [filters, updateUrlAndFetchDataWithResetPagination]
  );

  const handleStatusFilterChange = useCallback(
    (isChecked: boolean, section: string, index: number) => {
      setDropDownItems((prevState) => {
        const newItems = { ...prevState };
        newItems[section].items[index].checked = isChecked;
        return newItems;
      });
    },
    []
  );

  const handleDateChange = useCallback(
    (date: Date | [Date | null, Date | null] | null) => {
      if (Array.isArray(date)) {
        setFilters((prevState) => ({
          ...prevState,
          startDate: date[0],
          endDate: date[1],
        }));
        if (date[1]) {
          void updateUrlAndFetchDataWithResetPagination({
            ...filters,
            startDate: date[0],
            endDate: date[1],
          });
        }
      }
    },
    [filters, updateUrlAndFetchDataWithResetPagination]
  );

  const handleOrderColumnChange = useCallback(
    (value: OrderColumnState) => {
      const newFilters = {
        ...filters,
        sortField: value.orderByColumn,
        sortOrder: value.orderByDirection,
      };

      void orderColumnChange(value, async () => {
        await updateUrlAndFetchDataWithResetPagination(newFilters, true);
      });
    },
    [filters, orderColumnChange, updateUrlAndFetchDataWithResetPagination]
  );

  const totalRowSelected = Object.keys(rowSelection).length || 0;
  const dataSelected = Object.keys(rowSelection).map((index) => data[Number(index)]);

  return {
    dropDownItems,
    data,
    countDataList,
    getInitialData,
    valuePagination,
    rowCount,
    handlePaginationChange,
    isLoading,
    hasFiltersApplied,
    resetFilters,
    filters,
    handleSearchChange,
    handleSearchApply,
    handleStatusFilterApply,
    handleStatusFilterChange,
    handleDateChange,
    orderColumn,
    handleOrderColumnChange,
    rowSelection,
    rowSelectionChange,
    totalRowSelected,
    dataSelected,
  };
};
