/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import {
  INotificationBase,
  INotificationPayload,
} from '@/src/infrastructure/Protocol/Notifications/INotificationsService';
import { useNotificationsService } from '@/src/infrastructure/Protocol/Notifications/useNotificationsService';

type NotificationPayload = {
  type: string;
  timestamp: string;
};

export const useUserNotifications = () => {
  const notificationsService = useNotificationsService();

  const getNotification = useCallback(
    async (params: INotificationBase) => {
      const notification = await notificationsService.getNotification<NotificationPayload>(params);
      return !!notification;
    },
    [notificationsService]
  );

  const saveNotification = useCallback(
    async (params: INotificationPayload) => {
      return await notificationsService.saveNotification(params);
    },
    [notificationsService]
  );

  return { getNotification, saveNotification };
};
