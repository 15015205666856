import { atom, WritableAtom } from 'jotai';

import { calculateSMSMetrics } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/utils';
import { ISmsInfoPayload } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ISmsInfoResponseV1,
  ISmsRecipientsResponseV1,
  ISmsReportActionResponseV1,
  MessageStateSMS,
  UpdateTypeStateSMS,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { getCustomFields } from '@/src/modules/CampaignsModule/utils';

export const atomActionInfoSms = atom<ISmsInfoResponseV1>({} as ISmsInfoResponseV1);
atomActionInfoSms.debugLabel = 'atomActionInfoSms';

export const atomActionReportSms = atom<ISmsReportActionResponseV1>(
  {} as ISmsReportActionResponseV1
);
atomActionReportSms.debugLabel = 'atomActionReportSms';

export const atomActionRecipients = atom<ISmsRecipientsResponseV1 | undefined>(undefined);
atomActionRecipients.debugLabel = 'atomActionRecipients';

export const DEFAULT_MESSAGE_SMS = {
  original: '',
  shortened: '',
  track: false,
  length: 0,
  count: 0,
  customFields: 0,
};
export const atomMessageSMS: WritableAtom<MessageStateSMS, [UpdateTypeStateSMS], void> = atom(
  DEFAULT_MESSAGE_SMS,
  (get, set, update) => {
    const currentState = get(atomMessageSMS);
    const newState = { ...currentState, ...update };

    if (JSON.stringify(newState) === JSON.stringify(currentState)) return;

    const message = newState.track ? newState.shortened : newState.original;
    const { length, count } = calculateSMSMetrics(message);
    const customFields = getCustomFields(message).length;

    set(atomMessageSMS, {
      ...newState,
      length,
      count,
      customFields,
    });
  }
);
atomMessageSMS.debugLabel = 'atomMessageSMS';

export const atomInfoSmsPayload = atom<ISmsInfoPayload>({} as ISmsInfoPayload);
atomInfoSmsPayload.debugLabel = 'atomInfoSmsPayload';
