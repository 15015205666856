import { Editor } from '@monaco-editor/react';

import { CopyToClipboard, Spinner } from '@/lib/v2/components';

import { useStructureDataForm } from '@/src/modules/RulesModule/components/FormRule/components/forms/FormWebForm/hooks/useStructureDataForm';

const SourceCode = ({ contentHtml }: { contentHtml: string }) => {
  const { handleEditorWillMount } = useStructureDataForm();

  return (
    <div className="flex w-full flex-col rounded bg-emblue-tiles pb-2">
      <div className="mx-6 mt-6 flex justify-end">
        <CopyToClipboard color="light" id="copyCurl" value={contentHtml} />
      </div>
      <Editor
        beforeMount={handleEditorWillMount}
        defaultLanguage="html"
        defaultValue={contentHtml}
        height={'400px'}
        loading={<Spinner />}
        options={{
          readOnly: true,
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          lineNumbers: 'off',
          contextmenu: false,
          wordWrap: 'on',
        }}
        theme="my-theme"
        value={contentHtml}
        width="100%"
      />
    </div>
  );
};

export default SourceCode;
