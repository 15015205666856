import {
  ChannelTotalType,
  ChannelTrackingType,
} from '@/src/modules/InsightsModule/interfaces/Channel';

/**
 * Transforms the 'BOUNCES' label to 'FAILED' specifically for SMS and INBOX channels.
 * This transformation is necessary because these channels handle bounces as failures.
 * @param label - Original tracking label
 * @param metricType - Channel type (SMS, INBOX, EMAIL, etc.)
 * @returns - Transformed label or the original if transformation doesn't apply
 */
export const transformBouncesToFailed = (label: string, metricType: ChannelTotalType): string => {
  const cleanLabel = label;
  const shouldTransformToFailed =
    cleanLabel === ChannelTrackingType.BOUNCES &&
    [ChannelTotalType.SMS, ChannelTotalType.INBOX].includes(metricType);

  return shouldTransformToFailed ? ChannelTrackingType.FAILED : cleanLabel;
};
