import { ComponentType, PropsWithChildren, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Container, If } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { DeprecatedBanner } from '@/src/modules/CampaignsModule/components/DeprecatedBanner';
import { DeprecationOldEditors } from '@/src/modules/CampaignsModule/components/notifications/DeprecationOldEditors';
import { useDeprecatedBanner } from '@/src/modules/CampaignsModule/hooks/useDeprecatedBanner';

const CampaignWithWrapperLayout = (WrappedComponent?: ComponentType<PropsWithChildren<object>>) => {
  const WrapperComponent = (props: PropsWithChildren<object>) => {
    const { pathname } = useLocation();
    const { actionType } = useParams();

    const showRulesModuleV2 = useFeatureFlag('showRulesModuleV2');
    const createSimpleEmail = useFeatureFlag('createSimpleEmail');

    //TODO: remove this when the feature flag showDragDropRemovalNotice is removed
    const { showDeprecatedDragDropForPath } = useDeprecatedBanner();

    const isOldViewEditors = useMemo(() => {
      return (
        actionType?.includes('email') &&
        (!showRulesModuleV2 || !createSimpleEmail) &&
        !pathname?.includes('editor')
      );
    }, [actionType, showRulesModuleV2, createSimpleEmail, pathname]);

    return (
      <>
        <If condition={showDeprecatedDragDropForPath}>
          <DeprecatedBanner />
        </If>
        {WrappedComponent ? (
          <Container fullHeight>
            <WrappedComponent {...props} />
          </Container>
        ) : (
          <Outlet />
        )}
        <If condition={isOldViewEditors}>
          <DeprecationOldEditors />
        </If>
      </>
    );
  };

  return WrapperComponent;
};

export default CampaignWithWrapperLayout;
