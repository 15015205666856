import { memo } from 'react';
import { Link } from 'react-router-dom';

import { If, Text, Tooltip } from '@/lib/v2/components';

import { CardContent } from '@/modules/InsightsModule/components/CardContacts/components/CardContent';
import { ICardContacts } from '@/modules/InsightsModule/interfaces/ContactsSummary';

interface ICardContactsProps extends ICardContacts {
  className: string;
  truncate?: boolean;
}

const CardContacts = (props: ICardContactsProps) => {
  const { wordings, linkTo, id, truncate, datadogKey, ...cardContentProps } = props;

  return (
    <If
      condition={!!wordings?.tooltip}
      fallback={
        <Link className="text-emblue-primary" data-dd-action-name={datadogKey} id={id} to={linkTo}>
          <CardContent {...cardContentProps} wordings={wordings} />
        </Link>
      }
    >
      <Tooltip
        isDark
        content={
          <Text className="w-[170px] leading-tight text-white" variant="text-sm">
            {wordings?.tooltip}
          </Text>
        }
        position="top"
      >
        <Link className="text-emblue-primary" data-dd-action-name={datadogKey} id={id} to={linkTo}>
          <CardContent {...cardContentProps} truncate={truncate} wordings={wordings} />
        </Link>
      </Tooltip>
    </If>
  );
};

export default memo(CardContacts);
