import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';
import { GroupsIcon, SegmentsIcon, TagsIcon } from '@/lib/v2/icons/solid';

import { useDebounce } from '@/src/application/hooks/useDebounce';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomRecipientOptions } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  EGroupAndListsTypeV1MapString,
  IAddresseesList,
  IAddresseesPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { EcommerceIcon } from '@/modules/OnboardingModule/images/icons';
import { TRANSLATE_RULES_PLACEHOLDERS } from '@/modules/RulesModule/constants';
import { IRuleForm } from '@/modules/RulesModule/interfaces';

interface IRecipientsRulesProps {
  setValue?: UseFormSetValue<IRuleForm>;
}

const ICONS_BY_TYPE: { [key: string]: JSX.Element } = {
  user: <GroupsIcon />,
  interest: <TagsIcon />,
  integration: <EcommerceIcon />,
  profile: <SegmentsIcon />,
};

const PAYLOAD_RECIPIENTS: IAddresseesPayload = {
  offset: 0,
  groupQuantity: 20,
  filterType: {
    user: true,
    profile: false,
    integration: false,
    interest: false,
  },
};

export const useGroupContactsDropdown = ({ setValue }: IRecipientsRulesProps = {}) => {
  const { t } = useTranslation('rules');

  const [loading, setLoading] = useState(false);
  const [recipientsSearch, setRecipientsSearch] = useState<string>('');
  const [hasMoreRecipient, setHasMoreRecipient] = useState<boolean>(false);
  const [recipientListPayload, setRecipientListPayload] =
    useState<IAddresseesPayload>(PAYLOAD_RECIPIENTS);

  const campaignService = useCampaignsService();
  const recipientsSearchDebounced = useDebounce(recipientsSearch, 400);

  const [recipientsOptions, setRecipientOptions] = useAtom(atomRecipientOptions);

  const NO_GROUP_OPTION = useMemo<OptionExternal>(
    () => ({
      id: 0,
      name: t(`${TRANSLATE_RULES_PLACEHOLDERS}.group`),
      value: false,
      metadata: {
        icon: null,
        alt: '',
      },
    }),
    [t]
  );

  useEffect(() => {
    if (recipientsSearchDebounced.length === 0) return;

    const payloadOffset = {
      ...recipientListPayload,
      search: recipientsSearchDebounced,
      offset: 0,
    };

    void getRecipients(payloadOffset);

    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientsSearchDebounced]);

  const getRecipientsOptions = useCallback(
    (addresseesList: IAddresseesList[], includeNoGroupOption = false): OptionExternal[] => {
      const addresseesOptions = addresseesList
        //cspell:disable
        .filter((address) => address.type === EGroupAndListsTypeV1MapString.USUARIO)
        //cspell:enable
        .map((address) => ({
          id: Number(address.groupId),
          name:
            String(address.name).trim().length > 47
              ? String(address.name).trim().slice(0, 47) + '...'
              : String(address.name).trim() || '-',
          value: address.type,
          metadata: {
            icon: ICONS_BY_TYPE[address.type],
            alt: `(${address.contactCount})`,
          },
        }));

      if (includeNoGroupOption) {
        return [NO_GROUP_OPTION, ...addresseesOptions];
      }

      return addresseesOptions;
    },
    [NO_GROUP_OPTION]
  );

  const getRecipients = useCallback(
    async (payload: IAddresseesPayload) => {
      if (!payload) return;

      setLoading(true);

      const response = await campaignService.getAddressees(payload);
      const newOptions = getRecipientsOptions(response, payload.offset === 0);

      setRecipientOptions((prevOptions) => {
        if (payload.offset === 0) {
          return newOptions;
        }

        const updatedOptions = newOptions.filter(
          (option) => !prevOptions.some((prev) => prev.id === option.id)
        );
        return [...prevOptions, ...updatedOptions];
      });

      setHasMoreRecipient(response.length === payload.groupQuantity);
      setLoading(false);
    },
    [campaignService, getRecipientsOptions, setRecipientOptions]
  );

  const handleChangeRecipients = useCallback(
    (value: OptionExternal | OptionExternal[]) => {
      const selectedValue = Array.isArray(value) ? value[0] : value;
      setValue?.('groupContacts', selectedValue);
    },
    [setValue]
  );

  const handleLoadLastRecipients = useCallback(
    async (option: OptionExternal) => {
      if (!hasMoreRecipient) return;

      if (
        recipientsOptions.length > 0 &&
        option.id === recipientsOptions[recipientsOptions.length - 1].id
      ) {
        const payloadOffset = {
          ...recipientListPayload,
          offset: recipientsOptions.length,
        };

        await getRecipients(payloadOffset);
        setRecipientListPayload(payloadOffset);
      }
    },
    [
      getRecipients,
      hasMoreRecipient,
      recipientListPayload,
      recipientsOptions,
      setRecipientListPayload,
    ]
  );

  const handleCloseRecipients = useCallback(() => {
    if (recipientsSearch.length === 0) return;

    const payloadOffset = { ...recipientListPayload, search: '', offset: 0 };

    void getRecipients(payloadOffset);
    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
  }, [
    getRecipients,
    recipientListPayload,
    recipientsSearch.length,
    setHasMoreRecipient,
    setRecipientListPayload,
  ]);

  return {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: {
      setRecipientsSearch,
    },
    state: {
      loading,
      recipientsOptions,
      recipientListPayload,
    },
  };
};
