/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAtom } from 'jotai';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { velocityBar } from '@/src/ContactsModule/components/ContactsViewInfo/ProgressVelocityEnum';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useResetImport } from '@/src/modules/ContactsModule/hooks/useResetImport';
import { importStatus, loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';
import { IAnalysisResult } from '@/src/presentation/types/interfaces/IAnalysisResult.interface';
import { FileStateProps } from '@/src/presentation/types/interfaces/IImport.interface';

import { getDataCardCleaning, getDataCardGroups } from './DataCard';

import { atomModalMappedFields } from '@/modules/ContactsModule/atoms/Step4';
import {
  atomFiles,
  atomIdProcess,
  atomLoadingBar,
  atomShowProgressNotification,
} from '@/modules/ContactsModule/atoms/Steps';
import { ImportSteps } from '@/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';
import { useUpdateContactsStatus } from '@/modules/InsightsModule/hooks/useUpdateContactsStatus';

interface IVerifyState {
  verifyProcessedImport: () => void;
}

interface ContactsProviderProps {
  fileStatesProps?: Partial<FileStateProps>;
  children: ReactNode;
}

interface IFileStateContext {
  files: FileStateProps | undefined;
  fileName: string;
}

export const MutationVerifyState = createContext<IVerifyState | undefined>(undefined);
export const StateFileContext = createContext<IFileStateContext | undefined>(undefined);

const FileProvider: FC<ContactsProviderProps> = ({ children }): JSX.Element => {
  const showInsights = useFeatureFlag('showInsights');
  const [loadingBar, setLoadingBar] = useAtom(atomLoadingBar);
  const [watchProcessedFile, setWatchProcessedFile] = useState<boolean>(false);
  const { t } = useTranslation();
  const [idProcess] = useAtom(atomIdProcess);
  const { resetImport } = useResetImport();
  const [, setIsOpenModalMappedFields] = useAtom(atomModalMappedFields);

  const [showProgressNotification, setShowProgressNotification] = useAtom(
    atomShowProgressNotification
  );

  const [files, setFiles] = useAtom(atomFiles);
  const service = useService();
  const timer = useRef<NodeJS.Timer>();
  const {
    events: { updateStatus },
  } = useUpdateContactsStatus();

  const dataCardStep3 = useMemo(() => getDataCardCleaning(t), [t]);
  const dataCardStep4 = useMemo(() => getDataCardGroups(t), [t]);

  const checkImportStatus = useCallback(async (): Promise<{
    analysis: IAnalysisResult | undefined;
    status: string;
    percentage: number;
  }> => {
    try {
      const response = await service?.importStatus({ idProcess });
      const status: string = response?.status ?? '';

      if (
        status === importStatus.preImportDataAvailable ||
        status === importStatus.endConcatenation
      ) {
        const analysis: IAnalysisResult | undefined = await service?.analysisResult({
          idProcess,
        });
        if (analysis) {
          return { analysis, status: status, percentage: 100 };
        }
      } else if (status === importStatus.preImportError) {
        toast({
          title: t('IMPORT_MAIN.errorLoadingTitle'),
          variant: 'error',
          body: t('IMPORT_MAIN.errorLoadingBody'),
        });
        return { analysis: undefined, status, percentage: 100 };
      }

      let percentage = showProgressNotification.percentage;
      const { Min = 0, Max = 1 } = response?.ProgressRange ?? {};

      if (percentage < Min) {
        percentage = Min;
      } else if (percentage >= Min && percentage < Max) {
        percentage = percentage + 1;
      }

      return { analysis: undefined, status, percentage: percentage };
    } catch (e) {
      console.error(e);
    }

    return { analysis: undefined, status: '', percentage: 0 };
  }, [service, idProcess, showProgressNotification.percentage, t]);

  const getImportStatus = useCallback(async () => {
    const done = await checkImportStatus();

    if (
      (done && done.status === importStatus.preImportDataAvailable) ||
      done.status === importStatus.endConcatenation ||
      done.status === importStatus.preImportError
    ) {
      done.status === importStatus.preImportError && setLoadingBar(false);
      const stepIndex = (dataCardStep3.length ?? 5) - 1;
      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          analysisResult: done.analysis,
          groups: files?.groups,
          process: idProcess,
          showProgressCard: false,
          state: done.analysis ? loadingStatus.ready : loadingStatus.error,
          step: ImportSteps.dataCleaning,
        };
      });
      setShowProgressNotification((prevProgressNotification) => ({
        ...prevProgressNotification,
        percentage: 100,
        status: done.analysis ? loadingStatus.ready : loadingStatus.error,
        velocity: velocityBar.medium,
        show: localStorage.getItem('@import/outside') === 'true',
        step: stepIndex,
        description: dataCardStep3[stepIndex].description,
      }));
    } else {
      const percentage = done?.percentage ?? 1;
      const countStep = dataCardStep3.length ?? 0;
      const index = Math.floor((percentage / 100) * countStep);
      const step = Math.max(0, Math.min(index, countStep - 1));
      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          state: loadingStatus.loading,
          showStateCard: true,
        };
      });
      setShowProgressNotification((prevProgressNotification) => ({
        ...prevProgressNotification,
        show: localStorage.getItem('@import/outside') === 'true',
        percentage: done.percentage,
        status: done.percentage === 100 ? loadingStatus.ready : loadingStatus.loading,
        velocity: velocityBar.slow,
        step: step,
        description: dataCardStep3[step].description,
      }));
    }
  }, [
    checkImportStatus,
    setLoadingBar,
    dataCardStep3,
    setFiles,
    setShowProgressNotification,
    files?.groups,
    idProcess,
  ]);

  const getProcessedFile = useCallback(async () => {
    const done = await checkImportStatus();

    if (done?.status === importStatus.importFinish || done?.status === importStatus.importError) {
      const isError = done.status === importStatus.importError;
      isError && setWatchProcessedFile(false);
      const step = dataCardStep4.length - 1;

      setFiles((prevFiles) => {
        return {
          ...prevFiles,
          state: isError ? loadingStatus.error : loadingStatus.ready,
          step: ImportSteps.finish,
        };
      });
      setShowProgressNotification((prevProgressNotification) => ({
        ...prevProgressNotification,
        velocity: velocityBar.medium,
        percentage: 100,
        show: localStorage.getItem('@import/outside') === 'true',
        status: isError ? loadingStatus.error : loadingStatus.ready,
        step: step,
        description: dataCardStep4[step].description,
      }));

      if (showInsights && !isError) {
        await updateStatus();
      }

      toast({
        title: t(
          isError ? 'IMPORT_MAIN.Notification.ERROR.title' : 'IMPORT_MAIN.Notification.OK.title'
        ),
        body: t(
          isError ? 'IMPORT_MAIN.Notification.ERROR.body' : 'IMPORT_MAIN.Notification.OK.body'
        ),
        variant: isError ? 'error' : 'success'
      });

      setIsOpenModalMappedFields(false);

      service?.incrementLastRefreshContactsValue();
    } else {
      const percentage = done?.percentage ?? 1;
      const countStep = dataCardStep4.length ?? 0;
      const index = Math.floor((percentage / 100) * countStep);
      const step = Math.max(0, Math.min(index, countStep - 1));
      setShowProgressNotification((prevProgressNotification) => ({
        ...prevProgressNotification,
        status: loadingStatus.loading,
        velocity: velocityBar.slow,
        show: localStorage.getItem('@import/outside') === 'true',
        percentage: done.percentage,
        step: step,
        description: dataCardStep4[step].description,
      }));
    }
  }, [
    checkImportStatus,
    dataCardStep4,
    setFiles,
    setShowProgressNotification,
    t,
    setIsOpenModalMappedFields,
    service,
    updateStatus,
  ]);

  useEffect(() => {
    if (!files?.analysisResult && idProcess && loadingBar) {
      timer.current = setTimeout(getImportStatus, 2000);
    }

    if (files?.analysisResult && timer.current && loadingBar) {
      clearTimeout(timer.current);
      setLoadingBar(false);
    }
  }, [
    files,
    loadingBar,
    setLoadingBar,
    getImportStatus,
    idProcess,
    watchProcessedFile,
    setFiles,
    showProgressNotification,
  ]);

  useEffect(() => {
    const percentage = showProgressNotification.percentage;
    if (files?.analysisResult && idProcess && watchProcessedFile && percentage < 100) {
      clearTimeout(timer.current);
      timer.current = setTimeout(getProcessedFile, 2000);
    }

    if (files?.step === ImportSteps.finish && timer.current && watchProcessedFile)
      setWatchProcessedFile(false);
  }, [
    files,
    loadingBar,
    setLoadingBar,
    watchProcessedFile,
    idProcess,
    getProcessedFile,
    setFiles,
    setShowProgressNotification,
    showProgressNotification,
    resetImport,
  ]);

  const verifyProcessedImport = useCallback(() => {
    const outsidePage = localStorage.getItem('@import/outside') === 'true';

    setWatchProcessedFile(true);
    setFiles((prevFiles) => ({
      ...prevFiles,
      showStateCard: true,
    }));

    setShowProgressNotification((prevProgressNotification) => ({
      ...prevProgressNotification,
      percentage: 0,
      show: outsidePage,
      status: loadingStatus.loading,
      step: 0,
    }));
  }, [setFiles, setShowProgressNotification]);

  const memoizedVerifyState = useMemo<IVerifyState>(
    () => ({
      verifyProcessedImport,
    }),
    [verifyProcessedImport]
  );

  return (
    <MutationVerifyState.Provider value={memoizedVerifyState}>
      {children}
    </MutationVerifyState.Provider>
  );
};

export const useMutationVerifyState = (): IVerifyState =>
  useGetContext<IVerifyState>(MutationVerifyState);
export const useStateFileContext = (): IFileStateContext =>
  useGetContext<IFileStateContext>(StateFileContext);

const useGetContext = <T,>(contextName: React.Context<T | undefined>): T => {
  const context = useContext<T | undefined>(contextName);
  if (!context) {
    throw new Error('FileProvider is required');
  }
  return context;
};

export default FileProvider;
