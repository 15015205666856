import { useAtomValue } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GraphSkeleton } from '@/lib/components/Skeletons/GraphSkeleton';
import { If } from '@/lib/v2/components';
import { Tab } from '@/lib/v3/components';

import { atomChannelTotals, atomMetricType } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import QuantifierText from '@/src/modules/InsightsModule/components/QuantifierText/QuantifierText';
import ShippingGraph from '@/src/modules/InsightsModule/components/ShippingGraph';
import { useShippingMetric } from '@/src/modules/InsightsModule/hooks/useShippingMetrics';

import { ChannelTotalType } from '@/modules/InsightsModule/interfaces/Channel';

const ShippingTab = () => {
  const { t } = useTranslation(['insights']);

  const metricType = useAtomValue(atomMetricType);
  const channelTotals = useAtomValue(atomChannelTotals);

  const {
    state: { loading },
    events: { changeMetricChannel },
  } = useShippingMetric();

  const handleChangeMetric = useCallback(
    async (type: ChannelTotalType, value: number = 0) => {
      if (loading || value === 0) return;
      await changeMetricChannel(type);
    },
    [changeMetricChannel, loading]
  );

  return (
    <Tab currentTab={metricType}>
      <Tab.TabNav>
        <Tab.TabNavItem
          id="insights-channel-tab-all"
          isDisabled={(channelTotals?.all ?? 0) === 0}
          tabId={ChannelTotalType.ALL}
          onClick={() => void handleChangeMetric(ChannelTotalType.ALL, channelTotals?.all)}
        >
          <QuantifierText
            loading={loading}
            text={t('shipping.actions.all')}
            value={channelTotals?.all ?? 0}
          />
        </Tab.TabNavItem>
        <Tab.TabNavItem
          id="insights-channel-tab-email"
          isDisabled={(channelTotals?.email ?? 0) === 0}
          tabId={ChannelTotalType.EMAIL}
          onClick={() => void handleChangeMetric(ChannelTotalType.EMAIL, channelTotals?.email)}
        >
          <QuantifierText
            loading={loading}
            text={t('shipping.actions.email')}
            value={channelTotals?.email ?? 0}
          />
        </Tab.TabNavItem>
        <Tab.TabNavItem
          id="insights-channel-tab-sms"
          isDisabled={(channelTotals?.sms ?? 0) === 0}
          tabId={ChannelTotalType.SMS}
          onClick={() => void handleChangeMetric(ChannelTotalType.SMS, channelTotals?.sms)}
        >
          <QuantifierText
            loading={loading}
            text={t('shipping.actions.sms')}
            value={channelTotals?.sms ?? 0}
          />
        </Tab.TabNavItem>
        <Tab.TabNavItem
          id="insights-channel-tab-inbox"
          isDisabled={(channelTotals?.inbox ?? 0) === 0}
          tabId={ChannelTotalType.INBOX}
          onClick={() => void handleChangeMetric(ChannelTotalType.INBOX, channelTotals?.inbox)}
        >
          <QuantifierText
            loading={loading}
            text={t('shipping.actions.inbox')}
            value={channelTotals?.inbox ?? 0}
          />
        </Tab.TabNavItem>
        <Tab.TabNavItem
          id="insights-channel-tab-onsite"
          isDisabled={(channelTotals?.onsite ?? 0) === 0}
          tabId={ChannelTotalType.ONSITE}
          onClick={() => void handleChangeMetric(ChannelTotalType.ONSITE, channelTotals?.onsite)}
        >
          <QuantifierText
            loading={loading}
            text={t('shipping.actions.onsite')}
            value={channelTotals?.onsite ?? 0}
          />
        </Tab.TabNavItem>
      </Tab.TabNav>
      <Tab.TabContent>
        <Tab.TabContentPane tabId={ChannelTotalType.ALL}>
          <If condition={!loading} fallback={<GraphSkeleton className="h-96" />}>
            <ShippingGraph />
          </If>
        </Tab.TabContentPane>
        <Tab.TabContentPane tabId={ChannelTotalType.EMAIL}>
          <If condition={!loading} fallback={<GraphSkeleton className="h-96" />}>
            <ShippingGraph />
          </If>
        </Tab.TabContentPane>
        <Tab.TabContentPane tabId={ChannelTotalType.SMS}>
          <If condition={!loading} fallback={<GraphSkeleton className="h-96" />}>
            <ShippingGraph />
          </If>
        </Tab.TabContentPane>
        <Tab.TabContentPane tabId={ChannelTotalType.INBOX}>
          <If condition={!loading} fallback={<GraphSkeleton className="h-96" />}>
            <ShippingGraph />
          </If>
        </Tab.TabContentPane>
      </Tab.TabContent>
    </Tab>
  );
};

export default memo(ShippingTab);
