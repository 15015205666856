import BeePlugin from '@mailupinc/bee-plugin';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { usePreventExit } from '@/src/modules/CampaignsModule/hooks/usePreventExit';

import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

interface IProps {
  beefree?: BeePlugin;
}
const ModalPrevent = (props: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { beefree } = props;
  const newRulePath = useAtomValue(atomNewRulePath);
  const isAutomation = pathname.includes('automation');

  const { prevent, handleClosePrevent, getRedirectPrevent, removePreventRedirect } =
    usePreventExit();

  const wordings = useMemo(
    () => ({
      title: t('EDITOR_CONTENT.MODAL_PREVENT_BACK.title'),
      description: t('EDITOR_CONTENT.MODAL_PREVENT_BACK.description'),
      close: t('EDITOR_CONTENT.MODAL_PREVENT_BACK.close'),
      save: t('EDITOR_CONTENT.MODAL_PREVENT_BACK.save'),
    }),
    [t]
  );

  const handleSave = useCallback(() => {
    beefree?.save();
    handleClosePrevent();
  }, [beefree, handleClosePrevent]);

  const handleExit = useCallback(() => {
    const redirect = getRedirectPrevent();
    if (!redirect) return;

    handleClosePrevent();
    removePreventRedirect();

    if (newRulePath && isAutomation) navigate(-1);
    else newRulePath ? navigate(`${redirect}/email`) : navigate(redirect);
  }, [
    getRedirectPrevent,
    handleClosePrevent,
    navigate,
    newRulePath,
    isAutomation,
    removePreventRedirect,
  ]);

  const handleClose = useCallback(() => {
    removePreventRedirect();
    handleClosePrevent();
  }, [handleClosePrevent, removePreventRedirect]);

  return (
    <ModalConfirmation
      description={wordings.description}
      isOpen={prevent}
      textConfirmButton={wordings.save}
      textSecondaryButton={wordings.close}
      title={wordings.title}
      onClickSecondaryButton={handleExit}
      onClose={handleClose}
      onConfirmButton={handleSave}
    />
  );
};

export default memo(ModalPrevent);
