import { Option } from '@/lib/v2/components/Select/Select';

/* eslint-disable pii/no-email */
export const CONTACTS_organisms = 'Apps/ContactsModule/organisms';

export const CONTACTS_SCREENS = 'Apps/ContactsModule/Screens';

export const AUTH_SCREENS = 'Apps/AuthModule/Screens';

export const AUTH_COMPONENTS = 'Apps/AuthModule/Components';

export const ONBOARDING_SCREENS = 'Apps/OnboardingModule/Screens';

export const CONTACTS_COMPONENTS = 'Apps/ContactsModule/Components';

export const SEGMENT_FILTER_GROUP = {
  contacts: 'Contacts',
  cpSystem: 'CP SISTEMA',
};

export const SEGMENT_PATH = '/v2/contacts/segments';
export const GROUP_PATH = '/v2/contacts/groups';
export const CONTACT_PATH = '/v2/contacts';

export const RADIO_BUTTON_NAME = 'senderRadioButtons';

export const DEFAULT_EMAIL_ADDRESS = 'defaultsender@mailingmktg.com';

export const DELIMITER_LIST: Option[] = [
  {
    id: 0,
    name: 'Comma',
    value: ',',
  },
  {
    id: 1,
    name: 'Semicolon',
    value: ';',
  },
  {
    id: 2,
    name: 'Tab Space',
    value: 't',
  },
  {
    id: 3,
    name: 'Vertical Bar (|)',
    value: '|',
  },
];

export const IMPORT_DB = 'import DB';
export const ACTION_CREATED = 'action created';
export const ACTION_SENT = 'action sent';

export const LIMIT_SELECTION = 20;
export const DOWNLOAD_LIMIT_RANGE = 5;
export const CSV_TEMPLATE_MODEL_CONTACTS = '/app/templates/contacts-model.csv';

export const VIDEO_IMPORT_CONTACTS =
  'https://www.youtube.com/embed/koZooyR2XZw?modestbranding=1&rel=0&iv_load_policy=3&disablekb=1';

export enum WhatsAppTemplateRejectionErrorCode {
  INVALID_FORMAT = 'INVALID_FORMAT',
  TAG_CONTENT_MISMATCH = 'TAG_CONTENT_MISMATCH',
}

export const WHATSAPP_TEMPLATE_ERROR_TRANSLATION_PATH =
  'INBOX.SEND_WHATSAPP_MODAL.TEMPLATES_CONTAINER.ERROR_CODES';
