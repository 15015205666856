export const ActionDateNotValid = () => {
  return (
    <svg
      fill="none"
      height="160"
      viewBox="0 0 160 160"
      width="160"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.4737 150.758C119.878 150.758 152.632 118.004 152.632 77.5997C152.632 37.1957 119.878 4.44182 79.4737 4.44182C39.0697 4.44182 6.31579 37.1957 6.31579 77.5997C6.31579 118.004 39.0697 150.758 79.4737 150.758Z"
        fill="#DCE2F7"
      />
      <path
        d="M132.905 101.348C132.905 112.253 123.979 121.179 113.074 121.179H48.5684C37.6631 121.179 28.7368 112.253 28.7368 101.348V56.8633C28.7368 45.9581 37.6631 49.3265 48.5684 49.3265H113.074C123.979 49.3265 132.905 45.9581 132.905 56.8633V101.348Z"
        fill="white"
      />
      <path
        d="M152.884 110.274C152.821 127.537 138.716 141.6 121.6 141.453C104.211 141.305 90.3579 127.326 90.4421 110.021C90.5263 92.7999 104.611 78.9262 121.895 79.0105C139.011 79.1157 152.947 93.1578 152.884 110.253V110.274Z"
        fill="#FF4242"
      />
      <path
        d="M122.244 115.426L111.313 126.357C110.622 127.048 109.743 127.394 108.675 127.394C107.607 127.394 106.728 127.048 106.037 126.357C105.346 125.666 105 124.787 105 123.719C105 122.651 105.346 121.771 106.037 121.08L116.967 110.15L106.037 99.3133C105.346 98.6223 105 97.7429 105 96.6749C105 95.607 105.346 94.7275 106.037 94.0365C106.728 93.3455 107.607 93 108.675 93C109.743 93 110.622 93.3455 111.313 94.0365L122.244 104.967L133.08 94.0365C133.771 93.3455 134.651 93 135.719 93C136.787 93 137.666 93.3455 138.357 94.0365C139.111 94.7903 139.488 95.6855 139.488 96.722C139.488 97.7586 139.111 98.6223 138.357 99.3133L127.426 110.15L138.357 121.08C139.048 121.771 139.394 122.651 139.394 123.719C139.394 124.787 139.048 125.666 138.357 126.357C137.603 127.111 136.708 127.488 135.672 127.488C134.635 127.488 133.771 127.111 133.08 126.357L122.244 115.426Z"
        fill="white"
      />
      <path
        d="M35.7263 57.3265C65.8737 57.3055 95.8737 57.3055 125.937 57.3055C125.937 57.3055 125.937 57.3055 125.937 57.3265H132.905C132.905 53.8739 132.905 50.4213 132.884 46.9686C132.821 40.9265 128.463 36.5476 122.442 36.4423C119.39 36.3792 116.337 36.4423 113.284 36.4423C112.905 36.4423 112.505 36.4002 112.042 36.3792C112.042 33.9792 112.084 31.7055 112.042 29.4318C112 27.3476 110.274 25.8528 108.211 26.0634C106.442 26.2318 105.158 27.7055 105.137 29.6844C105.095 31.8949 105.137 34.1055 105.137 36.3581H84.3369C84.3369 34.1686 84.3369 32.0634 84.3369 29.9581C84.3369 27.6213 82.9474 26.0634 80.8842 26.0423C78.8211 26.0423 77.3895 27.6002 77.3895 29.916C77.3895 32.0423 77.3895 34.1897 77.3895 36.337C77.1579 36.3792 77.0316 36.4423 76.8842 36.4423C70.379 36.4423 63.8948 36.4423 57.3895 36.4634C56.6526 36.4634 56.5684 36.1055 56.5684 35.516C56.5684 33.4107 56.6105 31.3055 56.5263 29.2002C56.4632 27.5792 55.2 26.2949 53.6842 26.0634C52.1053 25.8318 50.5684 26.6318 49.979 28.1055C49.7474 28.6949 49.7053 29.3686 49.6842 30.0213C49.6421 32.1055 49.6842 34.2107 49.6842 36.4423C49.1579 36.4423 48.779 36.4423 48.379 36.4423C45.179 36.4423 41.979 36.3792 38.8 36.4844C33.2842 36.6739 28.9895 40.9897 28.8632 46.4844C28.779 50.1055 28.8 53.7055 28.8211 57.3265H35.7895H35.7263Z"
        fill="#004BC2"
      />
    </svg>
  );
};
