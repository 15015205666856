import { timeout } from '@/src/compat/util';

import { IInsightsService } from './IInsightsService';

import { IGraphEvolutionMonths } from '@/modules/InsightsModule/interfaces/ContactsEvolution';
import {
  InsightsContactsMetrics,
  InsightsFiltersActionsParam,
  InsightsMetricsActions,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

export class StubInsightsService implements IInsightsService {
  static create(): IInsightsService {
    return new StubInsightsService();
  }

  async getContactsMetrics(): Promise<InsightsContactsMetrics | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      console.error('Error en StubInsightsService.getContactsMetrics:', error);
      return null;
    }
  }

  async getContactsGraphEvolution(
    startDate: string,
    endDate: string
  ): Promise<IGraphEvolutionMonths[] | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      console.error('Error en StubInsightsService.getContactsGraphEvolution:', error);
      return null;
    }
  }

  async getActionsMetrics(
    filters: InsightsFiltersActionsParam
  ): Promise<InsightsMetricsActions | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      console.error('Error en StubInsightsService.getContactsGraphEvolution:', error);
      return null;
    }
  }

  async updateContactsStatus(accountIds: number[]): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error('Error en StubInsightsService.updateContactsStatus:', error);
      return false;
    }
  }
}
