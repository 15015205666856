import { useCallback, useState } from 'react';

import { OrderColumnState } from './Table';
export type ValuePagination = {
  currentPage?: number;
  pageSize: number;
};

export interface UsePaginationProps {
  initialPaginationState?: ValuePagination;
  initialOrderColumn?: OrderColumnState;
}

const usePagination = <T,>({
  initialPaginationState = {
    currentPage: 1,
    pageSize: 10,
  },
  initialOrderColumn = { orderByColumn: '', orderByDirection: 'desc' },
}: UsePaginationProps = {}) => {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<ValuePagination>(initialPaginationState);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [orderColumn, setOrderColumn] = useState<OrderColumnState>(initialOrderColumn);

  const paginationChange = useCallback(
    async (currentPage: number, pageSize: number, callBackFn?: () => Promise<void> | void) => {
      setPagination({ currentPage, pageSize });
      setRowSelection({});
      if (callBackFn) {
        setIsLoading(true);
        await callBackFn();
        setIsLoading(false);
      }
    },
    []
  );

  const orderColumnChange = useCallback(
    async (value: OrderColumnState, callBackFn?: () => Promise<void> | void) => {
      setOrderColumn(value);
      if (callBackFn) {
        setIsLoading(true);
        await callBackFn();
        setIsLoading(false);
      }
    },
    []
  );

  const resetPagination = useCallback(() => {
    setPagination({ currentPage: 1, pageSize: 10 });
    setRowSelection({});
  }, []);

  return {
    isLoading,
    setIsLoading,
    paginationChange,
    resetPagination,
    rowCount,
    setRowCount,
    data,
    setData,
    rowSelection,
    setRowSelection,
    valuePagination: pagination,
    setPagination,
    orderColumn,
    orderColumnChange,
    rowSelectionChange: setRowSelection,
    setOrderColumn,
  };
};

export default usePagination;
