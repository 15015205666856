export const InfoNewIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M16 22C16.34 22 16.625 21.885 16.855 21.655C17.085 21.425 17.2 21.14 17.2 20.8V16C17.2 15.66 17.085 15.375 16.855 15.145C16.625 14.915 16.34 14.8 16 14.8C15.66 14.8 15.375 14.915 15.145 15.145C14.915 15.375 14.8 15.66 14.8 16V20.8C14.8 21.14 14.915 21.425 15.145 21.655C15.375 21.885 15.66 22 16 22ZM16 12.4C16.34 12.4 16.625 12.285 16.855 12.055C17.085 11.825 17.2 11.54 17.2 11.2C17.2 10.86 17.085 10.575 16.855 10.345C16.625 10.115 16.34 10 16 10C15.66 10 15.375 10.115 15.145 10.345C14.915 10.575 14.8 10.86 14.8 11.2C14.8 11.54 14.915 11.825 15.145 12.055C15.375 12.285 15.66 12.4 16 12.4ZM16 28C14.34 28 12.78 27.685 11.32 27.055C9.86 26.425 8.59 25.57 7.51 24.49C6.43 23.41 5.575 22.14 4.945 20.68C4.315 19.22 4 17.66 4 16C4 14.34 4.315 12.78 4.945 11.32C5.575 9.86 6.43 8.59 7.51 7.51C8.59 6.43 9.86 5.575 11.32 4.945C12.78 4.315 14.34 4 16 4C17.66 4 19.22 4.315 20.68 4.945C22.14 5.575 23.41 6.43 24.49 7.51C25.57 8.59 26.425 9.86 27.055 11.32C27.685 12.78 28 14.34 28 16C28 17.66 27.685 19.22 27.055 20.68C26.425 22.14 25.57 23.41 24.49 24.49C23.41 25.57 22.14 26.425 20.68 27.055C19.22 27.685 17.66 28 16 28ZM16 25.6C18.68 25.6 20.95 24.67 22.81 22.81C24.67 20.95 25.6 18.68 25.6 16C25.6 13.32 24.67 11.05 22.81 9.19C20.95 7.33 18.68 6.4 16 6.4C13.32 6.4 11.05 7.33 9.19 9.19C7.33 11.05 6.4 13.32 6.4 16C6.4 18.68 7.33 20.95 9.19 22.81C11.05 24.67 13.32 25.6 16 25.6Z"
          fill="#004DBC"
        />
      </g>
    </svg>
  );
};
