import { useAtomValue } from 'jotai';
import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEmailFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailFormEvents';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { MAX_SIZE_SUBJECT } from '@/modules/CampaignsModule/constants';
import { IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { IRulesEmailFormInfo } from '@/modules/RulesModule/interfaces';

export const useRulesEmailSubject = ({
  setValue,
  getValues,
  trigger,
}: IFormProps<IRulesEmailFormInfo>) => {
  const { actionId } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const [showFirstLineContent, setShowFirstLineContent] = useState(false);

  const { setInfoByFieldOnChange } = useEmailFormEvents({ actionId: Number(actionId) });

  const { emailInfo } = actionInfoEmail ?? {};

  useEffect(() => {
    if (!emailInfo?.subject) return;

    setValue?.('subject', emailInfo?.subject);
    emailInfo?.firstLine && setValue?.('firstLine', emailInfo?.firstLine);

    if (emailInfo?.firstLine) setShowFirstLineContent(true);
  }, [emailInfo?.firstLine, emailInfo?.subject, setValue]);

  const handleBlurInput = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (value === '' || value.length >= MAX_SIZE_SUBJECT) {
        await trigger?.('subject');
        return;
      }

      await setInfoByFieldOnChange({ [name]: value });
    },
    [setInfoByFieldOnChange, trigger]
  );

  const handleChangeSubjectButton = useCallback(
    async ({ item }: ISubjectChangeButtonsProps) => {
      const currentSubject = getValues('subject');
      await setInfoByFieldOnChange({ subject: `${currentSubject} ${item}` });
      setValue('subject', `${currentSubject} ${item}`, { shouldValidate: true });
    },
    [getValues, setInfoByFieldOnChange, setValue]
  );

  return {
    events: {
      handleBlurInput,
      handleChangeSubjectButton,
    },
    setter: {
      setShowFirstLineContent,
    },
    state: {
      firstLine: emailInfo?.firstLine,
      showFirstLineContent,
    },
  };
};
