export const PendingIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="16"
        id="mask0_1906_2187"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="16"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="16" width="16" />
      </mask>
      <g mask="url(#mask0_1906_2187)">
        <path
          d="M4.5 9.09375C4.80382 9.09375 5.06207 8.98741 5.27474 8.77474C5.48741 8.56207 5.59375 8.30382 5.59375 8C5.59375 7.69618 5.48741 7.43793 5.27474 7.22526C5.06207 7.01259 4.80382 6.90625 4.5 6.90625C4.19618 6.90625 3.93793 7.01259 3.72526 7.22526C3.51259 7.43793 3.40625 7.69618 3.40625 8C3.40625 8.30382 3.51259 8.56207 3.72526 8.77474C3.93793 8.98741 4.19618 9.09375 4.5 9.09375ZM8 9.09375C8.30382 9.09375 8.56207 8.98741 8.77474 8.77474C8.98741 8.56207 9.09375 8.30382 9.09375 8C9.09375 7.69618 8.98741 7.43793 8.77474 7.22526C8.56207 7.01259 8.30382 6.90625 8 6.90625C7.69618 6.90625 7.43793 7.01259 7.22526 7.22526C7.01259 7.43793 6.90625 7.69618 6.90625 8C6.90625 8.30382 7.01259 8.56207 7.22526 8.77474C7.43793 8.98741 7.69618 9.09375 8 9.09375ZM11.5 9.09375C11.8038 9.09375 12.0621 8.98741 12.2747 8.77474C12.4874 8.56207 12.5938 8.30382 12.5938 8C12.5938 7.69618 12.4874 7.43793 12.2747 7.22526C12.0621 7.01259 11.8038 6.90625 11.5 6.90625C11.1962 6.90625 10.9379 7.01259 10.7253 7.22526C10.5126 7.43793 10.4062 7.69618 10.4062 8C10.4062 8.30382 10.5126 8.56207 10.7253 8.77474C10.9379 8.98741 11.1962 9.09375 11.5 9.09375ZM8.0051 15C7.04163 15 6.13455 14.8177 5.28385 14.4531C4.43316 14.0885 3.6888 13.5872 3.05078 12.9492C2.41276 12.3112 1.91146 11.5671 1.54688 10.7169C1.18229 9.86667 1 8.95825 1 7.99161C1 7.02498 1.18229 6.11936 1.54688 5.27474C1.91146 4.43012 2.41276 3.6888 3.05078 3.05078C3.6888 2.41276 4.43292 1.91146 5.28312 1.54688C6.13333 1.18229 7.04175 1 8.00839 1C8.97502 1 9.88064 1.18229 10.7253 1.54688C11.5699 1.91146 12.3112 2.41276 12.9492 3.05078C13.5872 3.6888 14.0885 4.43146 14.4531 5.27875C14.8177 6.12616 15 7.03155 15 7.9949C15 8.95837 14.8177 9.86545 14.4531 10.7161C14.0885 11.5668 13.5872 12.3112 12.9492 12.9492C12.3112 13.5872 11.5685 14.0885 10.7213 14.4531C9.87384 14.8177 8.96846 15 8.0051 15Z"
          fill="#FDCA46"
        />
      </g>
    </svg>
  );
};
