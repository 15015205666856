//cspell:words activoIndefinido elementoId minutos milis
import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomIsLoadingRuleData,
  atomRuleData,
  atomRuleDataRaw,
  atomRuleTypeSelected,
  atomTagList,
} from '@/src/modules/RulesModule/atoms/rules';
import { parseRuleDataRaw } from '@/src/modules/RulesModule/utils/parseRuleDataRaw';

import useRuleDataHelper from './useRuleDataHelper';

import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { IEmailInfoResponse } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const useRuleData = (actionId: number) => {
  const [isLoadingRuleData, setIsLoadingRuleData] = useAtom(atomIsLoadingRuleData);
  const [rulesData, setRulesData] = useAtom(atomRuleData);
  const setRulesDataRaw = useSetAtom(atomRuleDataRaw);
  const setActionInfoEmail = useSetAtom(atomActionInfoEmail);
  const setRuleTypeSelected = useSetAtom(atomRuleTypeSelected);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const setTagList = useSetAtom(atomTagList);

  const service = useService();
  const rulesService = useRulesService();
  const campaignsService = useCampaignsService();
  const { parseRuleData } = useRuleDataHelper();

  const getRuleData = useCallback(async () => {
    setIsLoadingRuleData(true);
    const ruleData = await rulesService.getRuleById(actionId);

    const parsedDataRaw = parseRuleDataRaw(ruleData);
    const recipientsResponse = await campaignsService.getAddressees({
      searchById: Number(ruleData.group_rules),
      offset: 0,
      groupQuantity: 1,
      filterType: {
        user: true,
        profile: true,
        integration: false,
        interest: false,
      },
    });

    const tagList = await service.getTagCloudInfo({
      identifier: 'TAG_CLOUD',
      search: '',
      tagsCategoriesFilter: [],
      tagsDataSourceFilter: [],
      lastRefresh: 0,
    });

    const parsedData = parseRuleData(ruleData, recipientsResponse, tagList);

    setRulesDataRaw(parsedDataRaw);
    setRulesData(parsedData);
    setRuleTypeSelected(parsedData.trigger);
    setTagList(tagList);

    setIsLoadingRuleData(false);
  }, [
    actionId,
    campaignsService,
    rulesService,
    service,
    parseRuleData,
    setIsLoadingRuleData,
    setRuleTypeSelected,
    setRulesData,
    setRulesDataRaw,
    setTagList,
  ]);

  const getEmailInfo = useCallback(async () => {
    setIsLoadingRuleData(true);
    const emailInfo = await campaignsService.getEmailInfo(actionId);
    setActionInfoEmail(emailInfo as IEmailInfoResponse);
    setIsLoadingRuleData(false);
  }, [actionId, campaignsService, setActionInfoEmail, setIsLoadingRuleData]);

  const refetch = useCallback(() => {
    void getRuleData();
    void getEmailInfo();
  }, [getRuleData, getEmailInfo]);

  const activateRule = useCallback(async () => {
    setProcessingAction(true);
    const payloadSaveCloseDate = {
      activoIndefinido: true,
      elementoId: actionId,
      hora: null,
      milis: null,
      minutos: null,
    };
    await rulesService.saveCloseDate(payloadSaveCloseDate);
    await rulesService.activateRule(Number(actionId));
    setProcessingAction(false);
  }, [rulesService, actionId, setProcessingAction]);

  const pauseRule = useCallback(async () => {
    setProcessingAction(true);
    await rulesService.inactivateRule(Number(actionId));
    setProcessingAction(false);
  }, [rulesService, actionId, setProcessingAction]);

  return {
    rule: rulesData,
    refetch,
    getRuleData,
    getEmailInfo,
    isLoadingRuleData,
    activateRule,
    pauseRule,
  };
};

export default useRuleData;
