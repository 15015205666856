import { useAtom, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useTransition } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, PhonePreview } from '@/lib/v2/components';

import { SmsForm } from './components/SmsForm';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomMessageSMS, DEFAULT_MESSAGE_SMS } from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomActionData, atomActionInitialize } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';
import {
  EActionTypesPath,
  EStepHeaderAction,
  ISmsInfoPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const SmsAction = () => {
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const [messageSMS, setMessageSMS] = useAtom(atomMessageSMS);
  const setActionTypePath = useSetAtom(atomActionTypePath);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const [isInitialized, setIsInitialized] = useAtom(atomActionInitialize);
  const [, startTransition] = useTransition();
  const [, setActionData] = useAtom(atomActionData);

  const { getSummaryInfoSms, setInfoSmsPayload } = useActionSmsData(Number(actionId));

  const getDetailSMS = useCallback(async () => {
    await getSummaryInfoSms();
  }, [getSummaryInfoSms]);

  useEffect(() => {
    if (!actionId) return;

    setInfoSmsPayload({} as ISmsInfoPayload);
    setMessageSMS(DEFAULT_MESSAGE_SMS);
    setIsInitialized(false);
    setActionData(null);

    startTransition(() => {
      setGlobalLoadingAction(true);
      if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
      setActionTypePath(EActionTypesPath.SMS);
      void getDetailSMS();
    });
  }, [actionId]);

  useEffect(() => {
    return () => {
      setMessageSMS(DEFAULT_MESSAGE_SMS);
      setGlobalLoadingAction(true);
      setIsInitialized(false);
      setActionData(null);
    };
  }, []);

  return (
    <Flex itemAlignment="start">
      <div className="my-12 w-1/2 bg-white px-10">
        <SmsForm />
      </div>
      <div className="grid min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-emblue-secondary-hover py-4 ">
        <PhonePreview message={messageSMS.track ? messageSMS.shortened : messageSMS.original} />
      </div>
    </Flex>
  );
};

export default memo(SmsAction);
