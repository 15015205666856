import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { Option } from '@/lib/v2/components';

import {
  FormTypes,
  IActionNodeInfo,
  IFieldStatus,
  IRulesV1Response,
  ITagListRules,
  RulesOptions,
} from '@/src/modules/RulesModule/interfaces';

export const atomToggleSidePanel = atom<boolean>(false);
atomToggleSidePanel.debugLabel = 'atomToggleSidePanel';

export const atomCurrentFormType = atomWithReset<FormTypes>(FormTypes.RULE);
atomCurrentFormType.debugLabel = 'atomCurrentFormType';

export const atomRuleData = atomWithReset<RulesOptions>({} as RulesOptions);
atomRuleData.debugLabel = 'atomRuleData';

export const atomRuleDataRaw = atomWithReset<IRulesV1Response | null>(null);
atomRuleDataRaw.debugLabel = 'atomRuleDataRaw';

export const atomRuleTypeSelected = atomWithReset<Option | undefined>(undefined);
atomRuleTypeSelected.debugLabel = 'atomRuleTypeSelected';

export const atomTouchRules = atomWithReset<boolean | undefined>(undefined);
atomTouchRules.debugLabel = 'atomTouchRules';

export const atomIsTriggerFormComplete = atomWithReset<boolean>(false);
atomIsTriggerFormComplete.debugLabel = 'atomIsTriggerFormComplete';

export const atomIsConditionFormComplete = atomWithReset<boolean>(false);
atomIsConditionFormComplete.debugLabel = 'atomIsConditionFormComplete';

export const atomActionNodeInfo = atomWithReset<IActionNodeInfo>({
  partial: false,
  complete: false,
  empty: true,
});
atomActionNodeInfo.debugLabel = 'atomActionNodeInfo';

export const atomIsLoadingRuleData = atomWithReset<boolean>(true);
atomIsLoadingRuleData.debugLabel = 'atomIsLoadingRuleData';

export const atomToggleActivateRuleModal = atomWithReset<boolean>(false);
atomToggleActivateRuleModal.debugLabel = 'atomToggleActivateRuleModal';

type CallbackStep = (() => Promise<void>) | (() => void);

export const atomCallbackSaveRule = atom<CallbackStep | null>(null);
atomCallbackSaveRule.debugLabel = 'atomCallbackSaveRule';

export const atomCallbackActivateRule = atom<CallbackStep | null>(null);
atomCallbackActivateRule.debugLabel = 'atomCallbackActivateRule';

export const atomCallbackPauseRule = atom<CallbackStep | null>(null);
atomCallbackPauseRule.debugLabel = 'atomCallbackPauseRule';

export const atomProcessingRule = atom<boolean>(false);
atomProcessingRule.debugLabel = 'atomProcessingRule';

export const atomNewRulePath = atom<boolean>(false);
atomNewRulePath.debugLabel = 'atomNewRulePath';

export const atomInfoModalPreviewRulePage = atom({
  isOpen: false,
  type: 'success | error',
});
atomInfoModalPreviewRulePage.debugLabel = 'atomInfoModalPreviewRulePage';

export const atomDisableEditRule = atom<boolean>(false);
atomDisableEditRule.debugLabel = 'atomDisableEditRule';

export const atomTagList = atom<ITagListRules>({ list: [] });
atomTagList.debugLabel = 'atomTagList';

export const atomActiveFormTab = atom<number>(1);
atomActiveFormTab.debugLabel = 'atomActiveFormTab';

export const atomFormFieldsStatusAtom = atomWithReset<IFieldStatus[]>([]);
atomFormFieldsStatusAtom.debugLabel = 'atomFormFieldsStatusAtom';

export const atomSearchFormField = atom('');
atomSearchFormField.debugLabel = 'atomSearchFormField';

export const atomDateFrequencyValue = atomWithReset<string | null>(null);
atomDateFrequencyValue.debugLabel = 'atomDateFrequencyValue';

export const atomDateExecutionValue = atomWithReset<string | null>(null);
atomDateExecutionValue.debugLabel = 'atomDateExecutionValue';

//TODO: New refactor
export const atomToggleCreateRuleModal = atom<boolean>(false);
atomToggleCreateRuleModal.debugLabel = 'atomToggleCreateRuleModal';
