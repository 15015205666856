import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';

import { Table, TableSkeleton } from '@/lib/components';
import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { ChangeTablePage } from '@/lib/components/Table/useTablePagination';
import { Flex, Text } from '@/lib/v2/components';
import { EmptyActivityIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { Consumption } from '@/src/modules/MyPlanModule/types';
import { convertDate } from '@/src/utils/Date/dateUtils';

interface ConsumptionTableProps {
  consumptionList?: Consumption[];
  isLoadingConsumptionList: boolean;
  totalConsumptionList: number;
  totalPages: number;
  changeTablePage: ChangeTablePage;
  changeTableOrder: (element: { id: string; isAsc: boolean | undefined }) => void;
}

const ConsumptionTable = ({
  consumptionList,
  isLoadingConsumptionList,
  totalConsumptionList,
  totalPages,
  changeTablePage,
  changeTableOrder,
}: ConsumptionTableProps) => {
  const { t } = useTranslation();
  const enableWhatsAppColumnConsumption = useFeatureFlag('enableWhatsAppColumnConsumption');
  const initialStateOrderByList = [
    { id: 'date', isAsc: true },
    { id: 'sent', isAsc: true },
    { id: 'attachments', isAsc: true },
    { id: 'sentSms', isAsc: true },
    { id: 'sentWhatsapp', isAsc: true },
    { id: 'smtp', isAsc: true },
    { id: 'onSiteImpressions', isAsc: true },
    { id: 'sentPush', isAsc: true },
    { id: 'sentWebhooksFlow', isAsc: true },
  ];

  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const addThousandsToNumber = (num: number | string) => {
    return Number(num).toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const dateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.date')}
        </span>
        <SortTableArrow
          elementName={'date'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'date',
    id: 'date',
    width: '12.5%',
    Cell: ({ cell }: { cell: Cell }) => {
      const value = cell.value as string;
      const row = cell.row;
      const canExpand = (row as unknown as { canExpand: boolean }).canExpand;

      return (
        <Flex withGap alignment="start" textAlignment="left">
          <span className="self-start text-14 text-emblue-subheadings">
            {canExpand ? convertDate(value) : value}
          </span>
        </Flex>
      );
    },
    SubRow: () => {
      return <h1>hello</h1>;
    },
  };

  const simpleEmailColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.simpleEmail')}
        </span>
        <SortTableArrow
          elementName={'sent'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'sent',
    id: 'sent',
    width: '12.5%',
    Cell: ({ cell }: { cell: Cell }) => {
      const { value } = cell;
      return (
        <Flex withGap alignment="start">
          <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
        </Flex>
      );
    },
  };

  const attachmentsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.attachments')}
        </span>
        <SortTableArrow
          elementName={'attachments'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'attachments',
    id: 'attachments',
    width: '18%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const smsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.sms')}
        </span>
        <SortTableArrow
          elementName={'sentSms'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'sentSms',
    id: 'sentSms',
    width: '12.5%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const whatsappColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.whatsapp')}
        </span>
        <SortTableArrow
          elementName={'sentWhatsapp'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'sentWhatsapp',
    id: 'sentWhatsapp',
    width: '12.5%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const webHookColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.api')}
        </span>
        <SortTableArrow
          elementName={'sentWebhooksFlow'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'sentWebhooksFlow',
    id: 'sentWebhooksFlow',
    width: '12.5%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const pushColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.sentPush')}
        </span>
        <SortTableArrow
          elementName={'sentPush'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'sentPush',
    id: 'sentPush',
    width: '13%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const onsiteColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.onsite')}
        </span>
        <SortTableArrow
          elementName={'onSiteImpressions'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'onSiteImpressions',
    id: 'onSiteImpressions',
    width: '12.5%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const smtpColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <span className="font-medium text-emblue-primary">
          {t('MY_PLAN.CONSUMPTION_TABLE.smtp')}
        </span>
        <SortTableArrow
          elementName={'smtp'}
          getTableOrderByList={changeTableOrder}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'smtp',
    id: 'smtp',
    width: '12.5%',
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Flex withGap alignment="start">
        <span className="self-start text-14 text-[#364365]">{addThousandsToNumber(value)}</span>
      </Flex>
    ),
  };

  const consumptionTableColumns: Column<object>[] = [
    dateColumn,
    simpleEmailColumn,
    attachmentsColumn,
    smsColumn,
    enableWhatsAppColumnConsumption ? whatsappColumn : null,
    webHookColumn,
    pushColumn,
    onsiteColumn,
    smtpColumn,
  ].filter((x) => x) as Column<object>[];

  if (!consumptionList) {
    return (
      <div className="size-full py-10">
        <TableSkeleton />
      </div>
    );
  }

  return (
    <>
      <Text isItalic className="absolute top-3" variant="text">
        {t('MY_PLAN.BILLING.updates')}
      </Text>
      <Table
        isPaginateOnOverflow
        withSubRows
        classNamePagination="pr-4"
        columns={consumptionTableColumns}
        data={consumptionList}
        emptyScreen={{
          totalCount: totalConsumptionList || 0,
          noResult: (
            <EmptyScreenTable
              actionText={t('EMPTY_SCREEN_TABLE.emptyActivityNoteAction')}
              description={t('EMPTY_SCREEN_TABLE.emptyActivityNoteDescription')}
              image={<EmptyActivityIcon />}
              title={t('EMPTY_SCREEN_TABLE.emptyNoteTitle')}
            />
          ),
          noData: (
            <EmptyScreenTable
              image={<EmptyActivityIcon />}
              title={t('MY_PLAN.BILLING_TABLE.noConsumption')}
            />
          ),
        }}
        expandSubRowsOnClick="row"
        isLoading={isLoadingConsumptionList}
        withCheckbox={false}
        withPagination={{
          totalPages,
          fetchData: changeTablePage,
        }}
      />
    </>
  );
};

export default ConsumptionTable;
