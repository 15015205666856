import { ChannelTrackingType, ShippingRange } from '@/modules/InsightsModule/interfaces/Channel';

export const TRANSLATE_INSIGHTS_CONTACTS = 'CONTACTS_GRAPH';
export const TRANSLATE_INSIGHTS_ACTIONS_SUMMARY = 'ACTIONS_SUMMARY';
export const TRANSLATE_INSIGHTS_NS = 'insights';
export const GROUPS_LIMIT = 200;

export const TOUR_STORAGE_KEY_PREFIX = 'insights-tour-completed';

export const SHIPPING_RANGE_TOTAL_DAYS: Record<ShippingRange, number> = {
  [ShippingRange.WEEKLY]: 7,
  [ShippingRange.BIWEEKLY]: 15,
  [ShippingRange.MONTHLY]: 30,
  [ShippingRange.QUARTERLY]: 90,
} as const;

export const COLOR_METRICS = {
  all: [
    'channel-metric__open',
    'channel-metric__click',
    'channel-metric__bounce',
    'channel-metric__unsubscribe',
  ],
  email: [
    'channel-metric__delivered',
    'channel-metric__open',
    'channel-metric__click',
    'channel-metric__bounce',
  ],
  sms: ['channel-metric__delivered', 'channel-metric__click', 'channel-metric__bounce'],
  inbox: ['channel-metric__delivered', 'channel-metric__click', 'channel-metric__bounce'],
  onsite: [],
};

export const COLOR_METRICS_HISTORY = {
  all: ['#18c678', '#9c59df', '#ffcfd7', '#ff4261'],
  email: ['#11cffa', '#18c678', '#9c59df', '#ffcfd7'],
  sms: ['#11cffa', '#9c59df', '#ffcfd7'],
  inbox: ['#11cffa', '#9c59df', '#ffcfd7'],
  onsite: [],
};

export const METRICS_HISTORY = {
  all: [
    ChannelTrackingType.OPENS,
    ChannelTrackingType.CLICKS,
    ChannelTrackingType.BOUNCES,
    ChannelTrackingType.UNSUBSCRIBES,
  ],
  email: [
    ChannelTrackingType.DELIVERED,
    ChannelTrackingType.OPENS,
    ChannelTrackingType.CLICKS,
    ChannelTrackingType.BOUNCES,
  ],
  sms: [ChannelTrackingType.DELIVERED, ChannelTrackingType.CLICKS, ChannelTrackingType.BOUNCES],
  inbox: [ChannelTrackingType.DELIVERED, ChannelTrackingType.CLICKS, ChannelTrackingType.BOUNCES],
  onsite: [],
};

export const BAR_GRAPH_CONFIG = {
  labels: {
    fontSize: '12px',
    fontWeight: 400,
  },
  bar: {
    barSize: 40,
    radius: [4, 4, 0, 0],
    fill: {
      contacts: '#5594FA',
      contacted: '#81CCF7',
    },
  },
};

export enum GRAPH_EVOLUTION_MONTHS {
  THREE = 3,
  SIX = 6,
  TWELVE = 12,
}

export type TBarRadius = [number, number, number, number];
