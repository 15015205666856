// cSpell:words requerido inválido Formato pais colspan urlExterna novalidate

import { TFunction } from 'i18next';

import { DEFAULT_HTML_FORM, TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import {
  IFieldStatus,
  IIncomingField,
  IRulesV1Response,
  ISelectCountryOption,
} from '@/modules/RulesModule/interfaces';

export const generateHtmlContent = (
  formData: IIncomingField[],
  fieldsState: IFieldStatus[],
  countriesOptions: ISelectCountryOption[],
  t: TFunction,
  rulesData: IRulesV1Response,
  URL_ONLINE_EXTERNAL_URL: string
): string => {
  const getKeyFromUrl = (url: string): string => {
    const match = url.match(/[?&]key=([^&]*)/);
    return match ? match[1] : '';
  };

  const key = getKeyFromUrl(rulesData?.urlExterna ?? '');

  const generateInputHtml = (
    id: string,
    inputType: string,
    requiredAttr: string,
    dataErrorAttr: string
  ): string => {
    return `
    <input
      id="${id}"
      type="${inputType}"
      name="${id}"
      ${requiredAttr}
      ${dataErrorAttr}
      class="eb-input eb-input--input w-full"
      style="border: 1px solid #cbd5e1; border-radius: 0.375rem; padding: 0.5rem 0.75rem; box-sizing: border-box; width: 90%;"
    />
  `;
  };

  const generateSelectHtml = (
    id: string,
    required: boolean,
    options: ISelectCountryOption[] | undefined
  ): string => {
    const requiredAttr = required ? 'required' : '';
    const dataErrorAttr = 'data-error="Campo requerido|Formato inválido"';

    const optionsHtml = ['<option value=""></option>']
      .concat(
        (options || []).map((option) => `<option value="${option.value}">${option.label}</option>`)
      )
      .join('');

    return `
    <select
      id="${id}"
      name="${id}"
      ${requiredAttr}
      ${dataErrorAttr}
      class="eb-input eb-input--input w-full"
      style="border: 1px solid #cbd5e1; border-radius: 0.375rem; padding: 0.5rem 0.75rem; box-sizing: border-box; width: 90%;"
    >
      ${optionsHtml}
    </select>
  `;
  };

  const generateMultiSelectHtml = (id: string, options: string[] | undefined): string => {
    const dataErrorAttr = 'data-error="Campo requerido|Formato inválido"';

    const optionsHtml = (options || [])
      .map((option) => `<option value="${option}">${option}</option>`)
      .join('');

    return `
    <select
      id="${id}"
      name="${id}"
      multiple
      ${dataErrorAttr}
      class="eb-input eb-input--input w-full"
      style="border: 1px solid #cbd5e1; border-radius: 0.375rem; padding: 0.5rem 0.75rem; box-sizing: border-box; width: 90%;"
    >
      ${optionsHtml}
    </select>
  `;
  };

  const generateFieldHtml = (field: IIncomingField, fieldState: IFieldStatus): string => {
    const { id, label, type, data } = field;
    const { requiredStatus } = fieldState;
    const requiredMark = requiredStatus ? '*' : '&nbsp;';

    let inputType = 'text';
    switch (type) {
      case 'email':
        inputType = 'email';
        break;
      case 'number':
        inputType = 'number';
        break;
      case 'date':
        inputType = 'date';
        break;
      default:
        inputType = 'text';
    }

    const requiredAttr = requiredStatus ? 'required' : '';
    const dataErrorAttr = 'data-error="Campo requerido|Formato inválido"';

    const requiredAsterisk = requiredMark === '*' ? '*' : '';
    const labelHtml = `<label class="block text-sm font-medium text-gray-700" style="margin-left: 8px; margin-bottom: 4px;">
      ${label} ${requiredAsterisk}
    </label>`;

    let inputHtml = '';

    switch (data.optionType) {
      case null:
        inputHtml = generateInputHtml(String(id), inputType, requiredAttr, dataErrorAttr);
        break;

      case 'radio':
        inputHtml = generateSelectHtml(
          String(id),
          requiredStatus,
          data.internalColumn === 'pais'
            ? countriesOptions
            : data.defaultValues?.map((value) => ({ value, label: value }))
        );
        break;

      case 'combo':
        inputHtml = generateMultiSelectHtml(String(id), data.defaultValues);
        break;

      default:
        inputHtml = generateInputHtml(String(id), inputType, requiredAttr, dataErrorAttr);
    }

    const fieldRowHtml = `
      <tr class="campo">
        <td colspan="2">
          ${labelHtml}
          ${inputHtml}
        </td>
      </tr>
    `;

    const errorRowHtml = `
      <tr class="error-row error-${id}">
        <td colspan="2" style="text-align: center;">
          <span class="error"></span>
        </td>
      </tr>
    `;

    return fieldRowHtml + errorRowHtml;
  };

  const getActiveFieldsHtml = (formData: IIncomingField[], fieldsState: IFieldStatus[]): string => {
    return formData
      .map((field) => ({
        field,
        fieldState: fieldsState.find((state) => state.id === field.id),
      }))
      .filter(({ fieldState }) => fieldState?.switchStatus)
      .map(({ field, fieldState }) => generateFieldHtml(field, fieldState as IFieldStatus))
      .join('');
  };

  const activeFieldsHtml = getActiveFieldsHtml(formData, fieldsState);

  const formWithKey = DEFAULT_HTML_FORM.replace(
    '<!-- Config here -->',
    `<form method="GET" action="${String(
      URL_ONLINE_EXTERNAL_URL
    )}" onsubmit="return validateForm();" novalidate>
          <input type="hidden" name="key" value="${key}">`
  )
    .replace('{{FORM_TITLE}}', t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.titleForm`))
    .replace('{{SUBMIT_BUTTON_TEXT}}', t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.submitForm`))
    .replace('{{FOOTER_TEXT}}', t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.footerForm`));

  const htmlContent = formWithKey.replace('<!-- FormFields here -->', activeFieldsHtml);

  return htmlContent;
};
