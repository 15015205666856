import { useCallback, useEffect, useState } from 'react';

import { ICheckNameResource } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { useCampaign } from './useCampaigns';

export interface IValidationResource {
  isValidInput: boolean;
  message: string;
}

export function useValidNameResource(data?: ICheckNameResource) {
  const { isValidNameResource, loading } = useCampaign();
  //TODO: [rules] Remove this state
  const [isValid, setIsValid] = useState<boolean>(false);

  //TODO: [rules] Remove this callback
  const fetchServiceValidName = useCallback(
    async (data: ICheckNameResource) => {
      if (!data?.name) return;
      return await isValidNameResource(data);
    },
    [isValidNameResource]
  );

  const validateNameResource = useCallback(
    async (data: ICheckNameResource) => {
      if (!data?.name) return false;
      return await isValidNameResource(data);
    },
    [isValidNameResource]
  );

  //TODO: [rules] Remove this effect
  useEffect(() => {
    return () => {
      setIsValid(false);
    };
  }, [data?.name, isValidNameResource]);

  return { fetchServiceValidName, isValid, loadingValidation: loading, validateNameResource };
}
