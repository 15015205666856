import { useCallback, useEffect, useState } from 'react';

import { useInsightsService } from '@/src/infrastructure/Protocol/Insights/useInsightsService';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import {
  ContactsMetricsType,
  IContactsMetric,
  InsightsContacts,
} from '@/src/modules/InsightsModule/interfaces/ContactsSummary';

export const useMetrics = () => {
  const [userData] = useUserData();
  const service = useInsightsService();

  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState<IContactsMetric>({} as IContactsMetric);

  const getMetrics = useCallback(async () => {
    setIsLoading(true);
    const response = await service.getContactsMetrics(+userData.companyId);

    if (!response) return;

    const resultReduce: IContactsMetric = {
      status: response.status.reduce((acc, metric) => {
        const { metricName } = metric;
        acc[metricName] = metric;
        return acc;
      }, {} as { [key in ContactsMetricsType]: InsightsContacts }),
      statistics: response.statistics,
    };

    setMetrics(resultReduce);
    setIsLoading(false);
  }, [service, userData.companyId]);

  useEffect(() => {
    void getMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: {
      metrics,
      loading: isLoading,
    },
  };
};
