import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import {
  atomActionTypePath,
  atomCallbackPauseStep,
  atomCallbackStep,
  atomHeaderActionLabelButton,
  atomHeaderActionTypeLastStep,
  atomToggleConfirmActionModal,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { atomActionData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  CAMPAIGNS_PATH,
  STATES_PROCESS_ACTION,
  TRANSLATE_TOAST_CONFIRM_ACTION,
} from '@/modules/CampaignsModule/constants';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';
import { EActionTypesPath } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useWhatsAppSummaryMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId } = useParams();

  const [actionTypePath, setActionTypePath] = useAtom(atomActionTypePath);
  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);
  const actionData = useAtomValue(atomActionData);
  const setActionTypeLastStep = useSetAtom(atomHeaderActionTypeLastStep);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setCallbackPauseStep = useSetAtom(atomCallbackPauseStep);
  const setToggleConfirmActionModal = useSetAtom(atomToggleConfirmActionModal);
  const setHeaderActionLabelButton = useSetAtom(atomHeaderActionLabelButton);

  const { active } = STATES_PROCESS_ACTION;

  const { setConfirmSms, setPauseSms, getSummaryInfoSms, getSmsReport, loading } = useActionSmsData(
    Number(actionId)
  );
  const { refetch } = useActionData(Number(actionId));

  /* cspell:disable */
  useLayoutEffect(() => {
    const labelButton = actionInfoWhatsApp.inmediato
      ? t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.send')
      : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.scheduled');

    setHeaderActionLabelButton(labelButton);

    return () =>
      setHeaderActionLabelButton(t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.continue'));
  }, [actionInfoWhatsApp, setHeaderActionLabelButton, t]);

  useEffect(() => setActionTypePath(EActionTypesPath.WHATSAPP), [setActionTypePath]);

  useLayoutEffect(() => {
    const fetchData = async () => await getSummaryInfoSms();
    void fetchData();
  }, [getSummaryInfoSms]);

  useLayoutEffect(() => {
    setActionTypeLastStep(true);
    return () => setActionTypeLastStep(false);
  }, [setActionTypeLastStep]);

  const handleConfirmStep = useCallback(
    () => setToggleConfirmActionModal(true),
    [setToggleConfirmActionModal]
  );

  const messagesForSchedulingType = useCallback(
    (isImmediateShipping: boolean, hasError: boolean) => {
      let messageType = 'SUCCESS_SCHEDULING';

      if (hasError) {
        messageType = 'ERROR';
      } else if (isImmediateShipping) {
        messageType = 'SUCCESS_ACTION';
      }

      return {
        title: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.${messageType}.title`),
        body: t(`${TRANSLATE_TOAST_CONFIRM_ACTION}.${messageType}.message`),
      };
    },
    [t]
  );

  const handleConfirmAction = useCallback(async () => {
    const responseConfirmSms = await setConfirmSms();
    const messages = messagesForSchedulingType(actionInfoWhatsApp?.inmediato, !responseConfirmSms);

    setToggleConfirmActionModal(false);

    toast({
      title: messages.title,
      body: messages.body,
      variant: responseConfirmSms ? 'success' : 'error',
    });

    navigate(`${CAMPAIGNS_PATH}/${campaignId}`);
  }, [
    actionInfoWhatsApp?.inmediato,
    campaignId,
    messagesForSchedulingType,
    navigate,
    setConfirmSms,
    setToggleConfirmActionModal,
  ]);

  const handlePauseStep = useCallback(async () => {
    if (!actionData) return;

    const { status } = actionData;
    if (active.includes(Number(status))) {
      await setPauseSms();
      refetch();
      navigate(`/v2/campaigns/${String(campaignId)}/${actionTypePath}/${String(actionId)}`);
    }
  }, [actionData, actionId, actionTypePath, active, campaignId, navigate, setPauseSms, refetch]);

  const handleReportClick = useCallback(async () => {
    if (!actionId) return;
    await getSmsReport();
  }, [actionId, getSmsReport]);

  useEffect(() => {
    setCallbackStep(() => handleConfirmStep);
  }, [setCallbackStep, handleConfirmStep]);

  useEffect(() => {
    setCallbackPauseStep(() => handlePauseStep);
  }, [setCallbackPauseStep, handlePauseStep]);
  /* cspell:enable */

  return {
    events: {
      handleConfirmAction,
      handleReportClick,
    },
    state: {
      loading,
    },
  };
};
