export const SuccessNewIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M14.32 18.16L11.74 15.58C11.52 15.36 11.24 15.25 10.9 15.25C10.56 15.25 10.28 15.36 10.06 15.58C9.84 15.8 9.73 16.08 9.73 16.42C9.73 16.76 9.84 17.04 10.06 17.26L13.48 20.68C13.72 20.92 14 21.04 14.32 21.04C14.64 21.04 14.92 20.92 15.16 20.68L21.94 13.9C22.16 13.68 22.27 13.4 22.27 13.06C22.27 12.72 22.16 12.44 21.94 12.22C21.72 12 21.44 11.89 21.1 11.89C20.76 11.89 20.48 12 20.26 12.22L14.32 18.16ZM16 28C14.34 28 12.78 27.685 11.32 27.055C9.86 26.425 8.59 25.57 7.51 24.49C6.43 23.41 5.575 22.14 4.945 20.68C4.315 19.22 4 17.66 4 16C4 14.34 4.315 12.78 4.945 11.32C5.575 9.86 6.43 8.59 7.51 7.51C8.59 6.43 9.86 5.575 11.32 4.945C12.78 4.315 14.34 4 16 4C17.66 4 19.22 4.315 20.68 4.945C22.14 5.575 23.41 6.43 24.49 7.51C25.57 8.59 26.425 9.86 27.055 11.32C27.685 12.78 28 14.34 28 16C28 17.66 27.685 19.22 27.055 20.68C26.425 22.14 25.57 23.41 24.49 24.49C23.41 25.57 22.14 26.425 20.68 27.055C19.22 27.685 17.66 28 16 28ZM16 25.6C18.68 25.6 20.95 24.67 22.81 22.81C24.67 20.95 25.6 18.68 25.6 16C25.6 13.32 24.67 11.05 22.81 9.19C20.95 7.33 18.68 6.4 16 6.4C13.32 6.4 11.05 7.33 9.19 9.19C7.33 11.05 6.4 13.32 6.4 16C6.4 18.68 7.33 20.95 9.19 22.81C11.05 24.67 13.32 25.6 16 25.6Z"
          fill="#18C678"
        />
      </g>
    </svg>
  );
};
