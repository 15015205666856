import { useAtomValue } from 'jotai';
import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useEmailFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailFormEvents';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { IEmailFormInfo } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IEmailSubjectProps {
  setValue: UseFormSetValue<IEmailFormInfo>;
  getValues: UseFormGetValues<IEmailFormInfo>;
}

export const useEmailSubject = ({ setValue, getValues }: IEmailSubjectProps) => {
  const { actionId } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);

  const [showFirstLineContent, setShowFirstLineContent] = useState(false);

  const { setInfoByFieldOnChange } = useEmailFormEvents({ actionId: Number(actionId) });

  const { emailInfo } = actionInfoEmail ?? {};

  useEffect(() => {
    if (!emailInfo) return;

    const { subject, firstLine } = emailInfo;

    setValue('subject', subject);
    setValue('firstLine', firstLine);
    if (firstLine) setShowFirstLineContent(true);
  }, [emailInfo, setValue]);

  const handleBlurInput = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      await setInfoByFieldOnChange({ [name]: value });
    },
    [setInfoByFieldOnChange]
  );

  const handleChangeSubjectButton = useCallback(
    async ({ item }: ISubjectChangeButtonsProps) => {
      const currentSubject = getValues('subject');

      await setInfoByFieldOnChange({ subject: `${currentSubject} ${item}` });
      setValue('subject', `${currentSubject} ${item}`);
    },
    [getValues, setInfoByFieldOnChange, setValue]
  );

  return {
    events: {
      handleBlurInput,
      handleChangeSubjectButton,
    },
    setter: {
      setShowFirstLineContent,
    },
    state: {
      firstLine: emailInfo?.firstLine,
      showFirstLineContent,
    },
  };
};
