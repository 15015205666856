import { lazy, memo, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useUserData } from '@/src/infrastructure/services/useUserData';
import { BackOfficeLayout } from '@/src/modules/BackofficeModule/layouts/BackOfficeLayout';

const ContractsBillings = lazy(() => import('../screens/ContractsBillings'));
const ContractsForm = lazy(() => import('../screens/ContractsForm'));
const ContractsLogs = lazy(() => import('../screens/ContractsLogs'));
const ContractsMain = lazy(() => import('../screens/ContractsMain'));
const PlansMain = lazy(() => import('../screens/PlansMain'));
const SmsMain = lazy(() => import('../screens/SmsMain'));
const WhatsappMain = lazy(() => import('../screens/WhatsappMain'));
const SurplusMain = lazy(() => import('../screens/SurplusMain'));

// TO DO: this file must be called BackOfficeRouter.tsx
const BackofficeModuleRouter = () => {
  const [userData] = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (!userData.email.includes('@embluemail.com')) {
      navigate('/v2');
    }
  }, [userData, navigate]);

  return (
    <Suspense>
      <Routes>
        <Route element={<BackOfficeLayout />} path="/">
          <Route index element={<Navigate to={`/v2/backoffice/contracts`} />} path="/" />
          <Route element={<ContractsMain />} path="/contracts" />
          <Route element={<ContractsLogs />} path="/contracts/logs" />
          <Route element={<ContractsBillings />} path="/contracts/billings" />
          <Route element={<ContractsForm />} path="/contracts/create" />
          <Route element={<ContractsForm isViewEdit />} path="/contracts/:id" />
          <Route element={<PlansMain />} path="/plans" />
          <Route element={<SmsMain />} path="/sms" />
          <Route element={<WhatsappMain />} path="/whatsapp" />
          <Route element={<SurplusMain />} path="/surplus" />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default memo(BackofficeModuleRouter);
