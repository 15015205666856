import { flexRender, Row } from '@tanstack/react-table';
import classNames from 'classnames';

interface RowProps<T> {
  row: Row<T>;
  onClick?: (rowData: T) => void;
  alignment?: 'center' | 'left' | 'right';
}

const RowItem = <T,>({ row, onClick, alignment }: RowProps<T>) => {
  const rowClasses = classNames('active:bg-slate-100 text-14', {
    'hover:bg-slate-50 cursor-pointer': !!onClick,
    '!bg-emblueLightGray': row.depth > 0,
  });

  const columnClasses = classNames('p-4 text-14 text-gray-900');

  const columnContent = classNames('flex flex-row', {
    'justify-center': alignment === 'center',
    'justify-start': alignment === 'left',
    'justify-end': alignment === 'right',
  });

  return (
    <tr key={row.id} className={rowClasses}>
      {row.getVisibleCells().map((cell) => {
        return (
          <td
            key={`${cell.id}-${cell.column.id}`}
            className={`${columnClasses} ${cell.column.columnDef.meta?.widthClassName ?? ''}`}
            role="gridcell"
            onClick={() => onClick?.(row.original)}
          >
            <div className={columnContent}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default RowItem;
