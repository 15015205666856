import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import {
  atomCallbackActivateRule,
  atomCallbackPauseRule,
  atomToggleActivateRuleModal,
} from '@/src/modules/RulesModule/atoms/rules';
import { HeaderSectionRules } from '@/src/modules/RulesModule/components/HeaderSectionRules';
import {
  useActionNodeInfo,
  useIsConditionFormComplete,
  useIsTriggerFormComplete,
  useRuleData,
} from '@/src/modules/RulesModule/hooks';
import { useResetRulesState } from '@/src/modules/RulesModule/hooks/useResetRulesState';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const RulesLayout = () => {
  const { campaignId, actionId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAutomation = pathname.includes('automation');

  const setCallbackActivateRule = useSetAtom(atomCallbackActivateRule);
  const setCallbackPauseRule = useSetAtom(atomCallbackPauseRule);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const setToggleActivateRuleModal = useSetAtom(atomToggleActivateRuleModal);

  const { rule, activateRule, pauseRule } = useRuleData(Number(actionId));
  const { refetchData } = useResetRulesState();

  useIsTriggerFormComplete(rule);
  useIsConditionFormComplete(rule);
  useActionNodeInfo();

  const handleActivateRule = useCallback(async () => {
    await activateRule();
    setToggleActivateRuleModal(false);

    refetchData();
    if (isAutomation) {
      const isRulesPath = pathname.includes(
        `${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`
      );
      const rulesPath = `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`;
      const journeysPath = `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.JOURNEYS_PATH}`;
      navigate(isRulesPath ? rulesPath : journeysPath);
      return;
    }
    navigate(`${CAMPAIGNS_PATH}/${String(campaignId)}`);
  }, [
    activateRule,
    setToggleActivateRuleModal,
    refetchData,
    isAutomation,
    navigate,
    campaignId,
    pathname,
  ]);

  const handlePauseRule = useCallback(async () => {
    await pauseRule();
    refetchData();
  }, [pauseRule, refetchData]);

  useEffect(() => {
    setCallbackActivateRule(() => handleActivateRule);
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleActivateRule]);

  useEffect(() => {
    setCallbackPauseRule(() => handlePauseRule);
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePauseRule]);

  return (
    <>
      <HeaderSectionRules />
      <ReactFlowProvider>
        <div className="relative flex bg-white">
          <Outlet />
        </div>
      </ReactFlowProvider>
    </>
  );
};

export default RulesLayout;
