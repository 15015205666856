const RouterSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div
        className="w-full animate-pulse space-y-4 divide-y divide-gray-200 border-b bg-gray-100 p-4 py-6"
        role="status"
      >
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-row">
            <div className="h-2.5 w-80 rounded-full bg-gray-400 "></div>
          </div>
        </div>
      </div>

      <div className="w-full animate-pulse space-y-4 divide-y divide-gray-200 p-4" role="status">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-row justify-between">
            <div className="h-[calc(100vh-170px)] w-full bg-gray-300 "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouterSkeleton;
