import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection } from '@/lib/v2/components';
import { Tab } from '@/lib/v3/components';

interface BillingAndConsumptionTablesProps {
  consumptionTable: JSX.Element;
  billingTable: JSX.Element;
}

const BillingAndConsumptionTables = ({
  consumptionTable,
  billingTable,
}: BillingAndConsumptionTablesProps) => {
  const [currentTab, setCurrentTab] = useState(1);
  const { t } = useTranslation();

  return (
    <Card className="mt-4">
      <CardSection>
        <Tab isUnderlined currentTab={currentTab}>
          <Tab.TabNav>
            <Tab.TabNavItem tabId={1} onClick={() => setCurrentTab(1)}>
              {t('MY_PLAN.TABS.consumption')}
            </Tab.TabNavItem>
            <Tab.TabNavItem tabId={2} onClick={() => setCurrentTab(2)}>
              {t('MY_PLAN.TABS.billings')}
            </Tab.TabNavItem>
          </Tab.TabNav>
          <Tab.TabContent>
            <Tab.TabContentPane tabId={1}>
              <div className="mt-2">{consumptionTable}</div>
            </Tab.TabContentPane>
            <Tab.TabContentPane tabId={2}>
              <div className="mt-2">{billingTable}</div>
            </Tab.TabContentPane>
          </Tab.TabContent>
        </Tab>
      </CardSection>
    </Card>
  );
};

export default memo(BillingAndConsumptionTables);
