import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/lib/v2/components';

import {
  atomEmailAddresseesInfo,
  atomIsActionInfoEmailErrors,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomCallbackPauseStep,
  atomCallbackReportSmsStep,
  atomCallbackStep,
  atomDownloadingReport,
  atomHeaderActionLabelButton,
  atomHeaderActionTypeLastStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionData } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { CAMPAIGNS_PATH, STATES_PROCESS_ACTION } from '@/modules/CampaignsModule/constants';
import {
  EActionTypesPath,
  EStepHeaderAction,
  IActionHeaderCurrentStep,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
interface IHeaderActionButtonsProps {
  loading?: boolean;
  step: IActionHeaderCurrentStep;
  disabled?: boolean;
  processing?: boolean;
}

const HeaderActionButtons = ({
  loading,
  step,
  disabled = false,
  processing = false,
}: IHeaderActionButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { campaignId, actionId } = useParams();

  const callbackStep = useAtomValue(atomCallbackStep);
  const callbackPauseStep = useAtomValue(atomCallbackPauseStep);
  const actionTypePath = useAtomValue(atomActionTypePath);
  const actionData = useAtomValue(atomActionData);
  const [actionTypeLastStep, setActionTypeLastStep] = useAtom(atomHeaderActionTypeLastStep);
  const headerActionLabelButton = useAtomValue(atomHeaderActionLabelButton);
  const haveErrors = useAtomValue(atomIsActionInfoEmailErrors);
  const callbackReportSmsStep = useAtomValue(atomCallbackReportSmsStep);
  const downloadingReport = useAtomValue(atomDownloadingReport);
  const emailAddresseesInfo = useAtomValue(atomEmailAddresseesInfo);

  const { status } = actionData || {};
  const isReinforcementShipment = emailAddresseesInfo?.reinforcement.isReinforcementShipment;

  const { finished, active, draft } = STATES_PROCESS_ACTION;

  const canPause = useMemo(() => {
    if (status === 3) return true;
    if (status === 8) return false;
  }, [status]);

  const actionPath = useMemo(() => {
    return `${CAMPAIGNS_PATH}/${String(campaignId)}/${actionTypePath}/${String(actionId)}`;
  }, [actionId, actionTypePath, campaignId]);

  const handleCallbackStep = useCallback(() => {
    callbackStep && void callbackStep();
  }, [callbackStep]);

  const handleCallbackPauseCancel = useCallback(() => {
    callbackPauseStep && void callbackPauseStep();
  }, [callbackPauseStep]);

  const handleEditAction = useCallback(() => {
    setActionTypeLastStep(false);
    if (!finished.includes(Number(actionData?.status))) {
      navigate(`${actionPath}`);
      return;
    }

    navigate(-1);
  }, [setActionTypeLastStep, finished, actionData?.status, navigate, actionPath]);

  const handleReportEmail = useCallback(() => {
    navigate(`${actionPath}/report`);
  }, [navigate, actionPath]);

  const handleReportSms = useCallback(() => {
    callbackReportSmsStep && void callbackReportSmsStep();
  }, [callbackReportSmsStep]);

  const handleReportClick = useCallback(() => {
    //TODO: move this methods to sms & email root component
    if (window.location.pathname.includes(EActionTypesPath.EMAIL)) {
      handleReportEmail();
    }
    if (
      window.location.pathname.includes(EActionTypesPath.SMS) ||
      window.location.pathname.includes(EActionTypesPath.WHATSAPP)
    )
      handleReportSms();
  }, [handleReportEmail, handleReportSms]);

  const onHandleBack = () => navigate(-1);

  const reportLabel = useMemo(() => {
    if (window.location.pathname.includes(EActionTypesPath.EMAIL)) {
      return t('REPORT');
    }
    if (
      window.location.pathname.includes(EActionTypesPath.SMS) ||
      window.location.pathname.includes(EActionTypesPath.WHATSAPP)
    ) {
      return t('CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.REPORT.download');
    }
    return t('REPORT');
  }, [t]);

  const loadingReport = useMemo(() => {
    if (window.location.pathname.includes(EActionTypesPath.SMS)) return downloadingReport;
    return loading;
  }, [downloadingReport, loading]);

  if (
    finished.includes(Number(status)) &&
    actionTypeLastStep &&
    (actionTypePath === EActionTypesPath.EMAIL ||
      actionTypePath === EActionTypesPath.SMS ||
      actionTypePath === EActionTypesPath.WHATSAPP)
  ) {
    return (
      <div className="flex items-center">
        <Button outline isLoading={loadingReport} size="small" onClick={handleReportClick}>
          {reportLabel}
        </Button>
      </div>
    );
  }

  if (!actionTypeLastStep) {
    return (
      <div className="flex items-center">
        {step === EStepHeaderAction.MIDDLE && (
          <Button
            outline
            className="ml-2"
            id="header-action__back"
            size="small"
            onClick={onHandleBack}
          >
            {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.back')}
          </Button>
        )}
        <Button
          className="ml-2"
          disabled={disabled || loading || haveErrors}
          id="confirm-button"
          isLoading={processing}
          size="small"
          onClick={handleCallbackStep}
        >
          {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.continue')}
        </Button>
      </div>
    );
  }

  if (isReinforcementShipment === undefined && actionTypePath === 'email') return null;

  if (active.includes(Number(status)) && actionTypeLastStep && actionTypePath === 'sms') {
    return (
      <div className="flex items-center">
        <Button
          className="ml-2"
          disabled={loading}
          id={`header-action__pause`}
          isLoading={processing}
          size="small"
          onClick={handleCallbackPauseCancel}
        >
          {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.pause')}
        </Button>
      </div>
    );
  }

  if (active.includes(Number(status)) && actionTypeLastStep) {
    return (
      <Button
        className="ml-2"
        disabled={loading}
        id={`header-action__${canPause ? 'pause' : 'cancel'}`}
        isLoading={processing}
        size="small"
        onClick={handleCallbackPauseCancel}
      >
        {canPause
          ? t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.pause')
          : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.cancel')}
      </Button>
    );
  }

  if (!finished.includes(Number(status))) {
    return (
      <div className="flex items-center">
        {actionTypeLastStep && (
          <Button
            outline
            className="ml-2"
            disabled={loading}
            id="header-action__edit"
            size="small"
            onClick={handleEditAction}
          >
            {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.edit')}
          </Button>
        )}
        <Button
          className="ml-2"
          disabled={draft.includes(Number(status)) || disabled || loading}
          id="header-action__continue-last"
          isLoading={processing}
          size="small"
          onClick={handleCallbackStep}
        >
          {headerActionLabelButton || t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.continue')}
        </Button>
      </div>
    );
  }

  return <></>;
};

export default HeaderActionButtons;
