import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { SEGMENT_PATH } from '@/src/modules/ContactsModule/constants';
import { FiltersType, ISegment, ISegmentFilter } from '@/src/modules/ContactsModule/types/Segments';
import { adaptV2SegmentToFront } from '@/src/modules/ContactsModule/util/v2SegmentFrontAdapter';

import { useFiltersValidator } from './useFiltersValidator';

export const useSegmentFilters = (filtersOptionsIsLoading: boolean) => {
  const filterByDefault: FiltersType = useMemo(
    () => [
      {
        key: _.uniqueId(),
        dataType: undefined,
        condition: undefined,
        operator: undefined,
        field: undefined,
        values: undefined,
      },
    ],
    []
  );
  const [segmentName, setSegmentName] = useState('');
  const [filters, setFilters] = useState(filterByDefault);
  const [emblueSegmentsInUse] = useEmblue(ServiceMethods.getEmblueSegmentsInUse, {});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const isEditPath = useMatch('/v2/contacts/segments/edit-segment/:segmentId');

  const [amountContactsFilter, setAmountContactsFilter] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingEditData, setIsLoadingEditData] = useState(false);

  const { currentFiltersWithRestriction } = useFiltersValidator(filters);

  const service = useService();

  const clearFilters = useCallback(() => {
    setFilters(filterByDefault);
  }, [filterByDefault]);

  const lastRefreshSegmentsIncrement = useCallback(() => {
    service?.incrementLastRefreshSegmentsValue();
  }, [service]);

  const getQuantityTemporaryFilters = useCallback(async () => {
    setIsLoadingData(true);
    const response = await service?.getQuantityTemporarySegment({
      name: segmentName,
      v2SegmentFilters: filters as ISegmentFilter[],
    });
    if (typeof response === 'number') setAmountContactsFilter(response);
    if (typeof response === 'string')
      toast({
        variant: 'error',
        title: 'Error',
        body: response,
        autoCloseDelay: 5000,
      });
    setIsLoadingData(false);
  }, [filters, segmentName, service]);

  const getSegmentFilter = useCallback(
    async (segmentId: number) => {
      if (!service) {
        return;
      }
      setIsLoadingEditData(true);
      const segmentResponse = await service?.getSegmentFilterById(segmentId);
      if (segmentResponse?.success && segmentResponse.data !== null) {
        const { name, filters } = segmentResponse.data as ISegment;
        const filtersType: FiltersType = adaptV2SegmentToFront(filters);
        setSegmentName(name);
        setFilters(filtersType);
      } else {
        navigate(SEGMENT_PATH);
        toast({
          variant: 'error',
          title: t('CREATE_SEGMENT.error'),
          body: 'We still can not edit Segments from V1 in the new interface of V2',
          autoCloseDelay: 5000,
        });
      }
      setIsLoadingEditData(false);
    },
    [navigate, service, t]
  );

  /**
   * Avoid changes in emblue segments
   */
  useEffect(() => {
    if (isEditPath && emblueSegmentsInUse) {
      const segmentId = Number(isEditPath.params.segmentId);
      if (emblueSegmentsInUse?.some((emblueSegment) => emblueSegment.id === segmentId)) {
        toast({
          variant: 'warning',
          title: t('EDIT_SEGMENT.warning'),
          body: t('EDIT_SEGMENT.bodyMessageCannotEdit'),
          autoCloseDelay: 5000,
        });
        navigate(`${SEGMENT_PATH}/${segmentId}`);
      }
    }
  }, [isEditPath, emblueSegmentsInUse, navigate, t]);

  const filtersIsValid = useCallback((): boolean => {
    if (filters.length === 0) return false;

    let isValid = true;

    filters.forEach((filter) => {
      if (_.isEmpty(filter.condition) || _.isNil(filter.condition)) isValid = false;
      if (filter.dataType === 'ACTIVITY' && !filter.lastDays) isValid = false;
      if (
        filter.dataType !== 'ACTIVITY' &&
        _.isEmpty((filter as ISegmentFilter & { values: string[] }).values)
      )
        isValid = false;
    });

    return isValid;
  }, [filters]);

  const createSegmentFilters = useCallback(async () => {
    setIsLoadingData(true);
    const result = await service?.createV2Segment({
      name: segmentName,
      v2SegmentFilters: filters as ISegmentFilter[],
    });
    if (result?.success) {
      setFilters(filterByDefault);
      setAmountContactsFilter(0);
      toast({
        variant: 'success',
        title: t('CREATE_SEGMENT.success'),
        body: t('CREATE_SEGMENT.bodyMessageSuccess'),
        autoCloseDelay: 5000,
      });
      lastRefreshSegmentsIncrement();
      navigate(`${SEGMENT_PATH}/${result.data.segmentId}`);
    } else {
      toast({
        variant: 'error',
        title: t('CREATE_SEGMENT.error'),
        body: result?.data.errorByName
          ? t('EMBLUE_SEGMENTS.Notification.ERROR.bodyRepeatedName')
          : t('CREATE_SEGMENT.bodyMessageError'),
        autoCloseDelay: 5000,
      });
    }
    setIsLoadingData(false);
  }, [service, segmentName, filters, filterByDefault, t, lastRefreshSegmentsIncrement, navigate]);

  const editSegmentFilters = useCallback(async () => {
    setIsLoadingData(true);
    if (!isEditPath) return;
    const v2Segment: ISegment = {
      id: isEditPath ? Number(isEditPath.params.segmentId) : 0,
      name: segmentName,
      filters: filters as ISegmentFilter[],
    };
    const result = await service?.editV2Segment({ v2Segment });
    if (result?.success) {
      setFilters(filterByDefault);
      setAmountContactsFilter(0);
      toast({
        variant: 'success',
        title: t('CREATE_SEGMENT.success'),
        body: t('EDIT_SEGMENT.bodyMessageSuccess'),
        autoCloseDelay: 5000,
      });
      navigate(`${SEGMENT_PATH}/${result.data.segmentId}`);
      lastRefreshSegmentsIncrement();
    } else {
      toast({
        variant: 'error',
        title: t('CREATE_SEGMENT.error'),
        body: result?.data.errorByName
          ? t('EMBLUE_SEGMENTS.Notification.ERROR.bodyRepeatedName')
          : t('EDIT_SEGMENT.bodyMessageError'),
        autoCloseDelay: 5000,
      });
    }
    setIsLoadingData(false);
  }, [
    isEditPath,
    segmentName,
    filters,
    service,
    filterByDefault,
    t,
    navigate,
    lastRefreshSegmentsIncrement,
  ]);

  useEffect(() => {
    if (!isLoadingEditData && filtersIsValid()) {
      void getQuantityTemporaryFilters();
    }
  }, [isLoadingEditData]); // don't add more dependencies

  useEffect(() => {
    if (isEditPath) {
      void getSegmentFilter(Number(isEditPath.params.segmentId));
    } else {
      setSegmentName(params.segmentName ?? '');
    }
  }, [getSegmentFilter, isEditPath, params.segmentName]);

  return {
    numberOfContacts: amountContactsFilter,
    createSegmentFilters,
    editSegmentFilters,
    filtersIsValid,
    filters,
    setFilters,
    clearFilters,
    amountContactsFilter,
    isLoadingData,
    segmentName,
    setSegmentName,
    isEditPath: isEditPath !== null,
    filtersOptionsIsLoading,
    isLoadingEditData,
    setAmountContactsFilter,
    getQuantityTemporaryFilters,
    currentFiltersWithRestriction,
  };
};
