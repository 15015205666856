import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Button, Flex, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/BadgeText';
import { FolderIcon, FolderToolIcon, ReportIcon as ReportIconSolid } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  ECampaignsTypeOptions,
  EStates,
  ICampaign,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useHandleEvents } from './useHandleEvents';

import { atomOrderBy } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { STRATEGY_MAP_BY_ID } from '@/modules/CampaignsModule/components/CampaignsModal/ManagementCampaign/constants';
import { TitleHeadCell } from '@/modules/CampaignsModule/components/TitleHeadCell';
import { DEFAULT_ORDERBY_CAMPAIGNS, TABLE_COLUMN_WIDTH } from '@/modules/CampaignsModule/constants';
import { DateFormat, formatDate, formattedNumber } from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const useCampaignTableColumns = () => {
  const { t, i18n } = useTranslation();
  const { handleClickReport } = useHandleEvents();
  const showNewAutomationModule = useFeatureFlag('showNewAutomationModule');

  const setOrderBy = useSetAtom(atomOrderBy);

  const [tableOrderByList, setTableOrderByList] = useState(DEFAULT_ORDERBY_CAMPAIGNS);

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
      setOrderBy({ sortField: element.id, sortOrder: orderDirectionValue });
    },
    [setOrderBy]
  );

  /** cspell:disable */
  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.name')} />
        <SortTableArrow
          elementName={'name'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'campaign',
    maxWidth: '300px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <div className="grid grid-cols-campaigns gap-4">
        <div className="shrink-0">
          {value.campaignTypeId === ECampaignsTypeOptions.MANUAL ? (
            <IconSvg
              rounded
              bgColor="inactive"
              fillColor="inactive"
              height="auto"
              svgComponent={<FolderIcon />}
              width="40px"
              withPadding="small"
            />
          ) : (
            <IconSvg
              rounded
              bgColor="inactive"
              height="auto"
              svgComponent={<FolderToolIcon />}
              width="40px"
              withPadding="small"
            />
          )}
        </div>
        <Tooltip isClickable noArrow alignment="start" content={value.name} position="top">
          <div className="self-start text-left !text-14">
            <div className="emblue-subheadings max-w-[85%] truncate font-medium">{value.name}</div>
            {value.strategyId && (
              <span className="emblue-text font-normal">
                {t(
                  `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${
                    STRATEGY_MAP_BY_ID[Number(value.strategyId)]
                  }`,
                  String(value.strategy)
                )}
              </span>
            )}
          </div>
        </Tooltip>
      </div>
    ),
  };

  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.state')} />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'state',
    maxwidth: '120px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <BadgeText
            text={
              value.state === EStates.ACTIVE
                ? t('CAMPAIGNS_MAIN.STATES.active')
                : t('CAMPAIGNS_MAIN.STATES.inactive')
            }
            variantColor={value.state === EStates.ACTIVE ? 'success' : 'inactive'}
          />
        </Flex>
      </Flex>
    ),
  };

  const lastUpdateDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.update')} />
        <SortTableArrow
          elementName={'lastUpdate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'lastUpdate',
    maxwidth: '120px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => {
      const formattedDate = value.lastUpdate
        ? formatDate(value.lastUpdate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const durationColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.duration')} />
        <SortTableArrow
          elementName={'duration'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'duration',
    maxwidth: '120px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {value.duration ? `${value.duration} days` : '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const audienceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.audience')} />
        <SortTableArrow
          elementName={'audience'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'audience',
    maxwidth: '120px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.audience), i18n.language as keyof typeof ELanguageMap) ||
              '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const actionsColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.actions')} />
        <SortTableArrow
          elementName={'actions'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'actions',
    maxwidth: '120px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.actions), i18n.language as keyof typeof ELanguageMap) ||
              0}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const buttonColumn = {
    Header: () => (
      <Flex withGap alignment="center">
        <TitleHeadCell text={t('CAMPAIGNS_MAIN.TABLE.COLUMNS.report')} />
      </Flex>
    ),
    accessor: 'campaign',
    id: 'buttons',
    width: TABLE_COLUMN_WIDTH.report,
    Cell: ({ cell: { value } }: { cell: { value: ICampaign } }) => (
      <Flex withGap alignment="center" className="z-10">
        <Button
          gray
          outline
          data-id={value.campaignId}
          id="detail-actions"
          size="small"
          onClick={(event) => handleClickReport(event, value)}
        >
          <IconSvg
            fillColor="gray-dark"
            height="16px"
            svgComponent={<ReportIconSolid />}
            width="13px"
          />
        </Button>
      </Flex>
    ),
  };
  /** cspell:enable */

  const campaignsColumns: Column<object>[] = [
    nameColumn,
    ...(showNewAutomationModule ? [] : [statusColumn]),
    lastUpdateDateColumn,
    durationColumn,
    audienceColumn,
    actionsColumn,
    buttonColumn,
  ].filter((x) => x) as Column<object>[];

  return { campaignsColumns };
};
