import { cloneElement, memo, ReactElement } from 'react';

import { AlertProps } from '@/lib/v3/components/Alert';

interface ScheduledAlertProps {
  children: ReactElement<AlertProps>;
  enabled?: boolean;
}

const ScheduledAlert = ({ children, enabled = true }: ScheduledAlertProps) => {
  if (!enabled) return null;

  return (
    <div className="fixed bottom-[60px] left-1/2 z-[9999] mx-auto w-full max-w-[760px] -translate-x-1/2 px-4">
      {cloneElement(children, {
        onClose: () => {
          if (children.props.onClose) {
            children.props.onClose();
          }
        },
      })}
    </div>
  );
};

export default memo(ScheduledAlert);
