import { CalendarIcon } from '@heroicons/react/outline';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, Flex, IconSvg } from '@/lib/v2/components';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon } from '@/lib/v2/icons/solid';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { TotalCountDisplay } from '@/src/modules/CampaignsModule/components/TotalCountDisplay';
import { TRANSLATE_CALENDAR_HEADER } from '@/src/modules/CampaignsModule/constants';
import { useCampaignData } from '@/src/modules/CampaignsModule/hooks/useCampaignData';
import { useCampaignModal } from '@/src/modules/CampaignsModule/hooks/useCampaignModal';
import { useStrategies } from '@/src/modules/CampaignsModule/hooks/useStrategies';

import { TableContainer } from '@/modules/CampaignsModule/components/Campaigns/TableContainer';

const CampaignsMain = () => {
  const { t } = useTranslation();

  const { campaignList, loadingProcess, totalsCampaignList, totalApplyFilters } = useCampaignData();
  const { handleOnOpenModal, handleLinkCalendar } = useCampaignModal();
  const { getStrategies, strategyList } = useStrategies();

  useShowModalSignal('CreateCampaign', handleOnOpenModal);

  useEffect(() => {
    strategyList.length === 0 && void getStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardSection noPadding>
        <Flex column>
          <SectionHeader
            actionsButtons={
              <>
                <Button
                  gray
                  outline
                  iconLeft={<IconSvg strokeColor="secondary" svgComponent={<CalendarIcon />} />}
                  id="campaigns-calendar"
                  size="small"
                  onClick={handleLinkCalendar}
                >
                  {t(`${TRANSLATE_CALENDAR_HEADER}.title`)}
                </Button>
                <Button
                  iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                  id="create-campaigns-button"
                  size="small"
                  onClick={handleOnOpenModal}
                >
                  {t('CAMPAIGNS_MAIN.button')}
                </Button>
              </>
            }
            icon={<IconSvg height="20px" svgComponent={<CampaignsIcon />} width="20px" />}
            subtitle={
              <SectionHeaderSubtitle
                countFilters={totalApplyFilters}
                countRecordsPartial={totalsCampaignList.partial}
                dataList={campaignList}
                i18nKey="CAMPAIGNS_MAIN.search"
                loading={loadingProcess}
              />
            }
            title={t('CAMPAIGNS_MAIN.title')}
            titleDetail={
              <TotalCountDisplay
                hasItems={totalsCampaignList.total > 0}
                isLoading={loadingProcess}
                total={totalsCampaignList.total}
              />
            }
          />
          <Flex column withGap gapSize="medium">
            <TableContainer />
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  );
};

export default memo(CampaignsMain);
