import { MouseEventHandler, ReactElement } from 'react';

import { Button, IconSvg, IconSvgProps } from '@/lib/v2/components';

export interface ITableButtonProps {
  id: string;
  label?: string;
  icon?: ReactElement<IconSvgProps>;
  tooltip?: string;
  hide?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

const TableToolsBarButton = ({ id, tooltip, onClick, label, icon }: ITableButtonProps) => {
  return (
    <Button
      gray
      outline
      standard
      iconLeft={<IconSvg fillColor="gray-dark" svgComponent={icon} />}
      id={id}
      tooltip={tooltip}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default TableToolsBarButton;
