import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Option } from '@/lib/v2/components/Select/Select';

import { useIntegrationTabVisibility } from '@/src/application/hooks/useIntegrationTabVisibility';
import { IContactCustom } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import { ECommerceEnum } from '@/src/ContactsModule/utils/enums/Enums';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomContactCustomFields } from '@/src/modules/ContactsModule/atoms/Contacts';

import { ContactsProfileActivityTable } from './components/ContactsProfileActivityTables';

import ContactsProfileDetailsPanel from '@/modules/ContactsModule/screens/ContactProfile/components/ContactsProfileDetailsPanel/ContactsProfileDetailsPanel';
import HeaderBannerProfile from '@/modules/ContactsModule/screens/ContactProfile/components/HeaderBannerProfile/HeaderBannerProfile';

export const ContactsProfile = () => {
  const { emailId } = useParams();
  const [countries] = useEmblue(ServiceMethods.getCountries);
  const { visibility } = useIntegrationTabVisibility([
    ECommerceEnum.VTEX,
    ECommerceEnum.PRESTASHOP,
    ECommerceEnum.MAGENTO2,
    ECommerceEnum.SHOPIFY,
  ]);
  const service = useService();

  const [customFields, setCustomFields] = useAtom<IContactCustom | null>(atomContactCustomFields);
  const fetchCustomFields = useCallback(async () => {
    const response =
      service && (await service.getContactCustomFields({ emailId: Number(emailId) }));
    setCustomFields(response);
  }, [emailId, service]);

  useEffect(() => {
    void fetchCustomFields();
    return () => {
      setCustomFields(null);
    };
  }, []);

  const updateCustomFields = () => {
    void fetchCustomFields();
  };

  return (
    <>
      <div className="grid grid-cols-12 md:gap-4 lg:gap-4 xl:gap-4 2xl:gap-6">
        <div className="col-span-12 min-w-[960px]">
          <HeaderBannerProfile
            contactDetailInfo={customFields as IContactCustom}
            emailId={Number(emailId)}
          />
        </div>
        <div className="col-span-3">
          <ContactsProfileDetailsPanel
            contactDetailInfo={customFields as IContactCustom}
            countries={countries?.list as Option[]}
            emailId={Number(emailId)}
            onUpdate={updateCustomFields}
          />
        </div>
        <div className="col-span-9 ml-2 min-w-[630px]">
          <ContactsProfileActivityTable emailId={Number(emailId)} tabVisibility={[visibility]} />
        </div>
      </div>
    </>
  );
};
