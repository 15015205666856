import { useAtomValue, useSetAtom } from 'jotai';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { SortTableArrow } from '@/lib/components/SortTableArrow';
import { Button, Flex, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/BadgeText';
import { ColorsBadge } from '@/lib/v2/components/BadgeText/types';
import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import {
  ActionMasterIcon,
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailRemarketingIcon,
  EmailSplitIcon,
  EmailTriggerIcon,
  SmsActionIcon,
  SmsAPIIcon,
  WhatsAppIcon,
} from '@/lib/v2/icons/outline';
import { ReportIcon as ReportIconSolid, ViewsIcon as ViewsIconSolid } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ActionMenuTools } from '@/src/modules/CampaignsModule/components/ActionMenuTools';

import {
  atomActionType,
  atomCurrentActionId,
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomOrderByAction,
  atomPagerAction,
  atomSelectedRowsAction,
  atomSelectedRowsQuickTools,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomDetailCampaign,
  atomInfoCampaignV1,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { TitleHeadCell } from '@/modules/CampaignsModule/components/TitleHeadCell';
import {
  CAMPAIGNS_PATH,
  defaultPager,
  DROPDOWN_ACTIONS_STATE,
  LIST_ACTIONS_STATE,
  ORDERBY_ACTIONS,
  TABLE_COLUMN_WIDTH,
  WHATSAPP_PREFIX,
} from '@/modules/CampaignsModule/constants';
import { useActionTableUtils } from '@/modules/CampaignsModule/hooks/useActionTableUtils';
import {
  EActionTypes,
  EChannelsID,
  ICampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { DateFormat, formatDate, formattedNumber } from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const ChannelInfoByID = {
  [EChannelsID.MASTER]: {
    icon: <ActionMasterIcon />,
    route: 'master',
  },
  [EChannelsID.EMAIL]: {
    icon: <EmailActionIcon />,
    route: 'email',
  },
  [EChannelsID.EMAIL_TRIGGER]: {
    icon: <EmailTriggerIcon />,
    route: 'email-trigger',
  },
  [EChannelsID.EMAIL_ATTACHMENT]: {
    icon: <EmailAttachmentIcon />,
    route: 'email-attachment',
  },
  [EChannelsID.EMAIL_API]: {
    icon: <EmailAPIIcon />,
    route: 'mail-api',
  },
  [EChannelsID.SMS]: {
    icon: <SmsActionIcon />,
    route: 'sms',
  },
  [EChannelsID.SMS_API]: {
    icon: <SmsAPIIcon />,
    route: 'sms-api',
  },
};

const ICON_BY_ACTION_TYPE: Record<string, JSX.Element> = {
  email: <EmailActionIcon />,
  'email-remarketing': <EmailRemarketingIcon />,
  'email-split': <EmailSplitIcon />,
  'email-trigger': <EmailTriggerIcon />,
  'email-attachment': <EmailAttachmentIcon />,
  'mail-api': <EmailAPIIcon />,
  sms: <SmsActionIcon />,
  'sms-api': <SmsAPIIcon />,
  whatsapp: <WhatsAppIcon />,
};

export const useActionsTableColumns = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const previewAvailable = useFeatureFlag('campaignsShowTablePreview');
  const createSimpleSMS = useFeatureFlag('createSimpleSMS');
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');
  const enableWhatsAppModule = useFeatureFlag('enableWhatsAppModule');

  const detailCampaign = useAtomValue(atomDetailCampaign);
  const actionType = useAtomValue(atomActionType);
  const filters = useAtomValue(atomFiltersAction);
  const pager = useAtomValue(atomPagerAction);

  const setOrderBy = useSetAtom(atomOrderByAction);
  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const setCurrentActionId = useSetAtom(atomCurrentActionId);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);
  const setSelectedRowsQuickTools = useSetAtom(atomSelectedRowsQuickTools);

  const {
    state: { DD_ACTION_TYPE_MAP },
    events: { getRoutePathAction },
  } = useActionTableUtils();

  const [tableOrderByList, setTableOrderByList] = useState(() => {
    const baseOrderList = ORDERBY_ACTIONS[actionType ?? EActionTypes.DETAIL];

    if (!filters?.sortField) return baseOrderList;

    return baseOrderList.map((item) => ({
      ...item,
      isAsc: item.id === filters.sortField ? filters.sortOrder === 'asc' : undefined,
    }));
  });

  const getCurrentState = useCallback(
    (status: number) => {
      const [filter] = DROPDOWN_ACTIONS_STATE.filter((item) => Number(item.value) === status);
      return t(`CAMPAIGN_ACTIONS_MAIN.STATES.${filter.label}`) ?? '-';
    },
    [t]
  );

  const getStatusColor = useCallback(
    (statusId: number): { badge: string; background: ColorsIcon } | null => {
      const group = LIST_ACTIONS_STATE.find((group) => group.values.includes(statusId));
      return group
        ? {
            badge: group.badge,
            background: group.background,
          }
        : null;
    },
    []
  );

  const changeOrderBy = useCallback(
    (element: { id: string; isAsc: boolean | undefined }) => {
      const orderDirectionValue = element.isAsc === true ? 'asc' : 'desc';
      setOrderBy({ sortField: element.id, sortOrder: orderDirectionValue });
    },
    [setOrderBy]
  );

  const isPreviewDisabled = useCallback((channelId: number) => {
    return channelId === EChannelsID.EMAIL_API || channelId === EChannelsID.SMS_API;
  }, []);

  const handleClickPreview = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: ICampaignActions) => {
      event.stopPropagation();

      if (value.channel === EChannelsID.EMAIL_API || value.channel === EChannelsID.SMS_API) {
        return;
      }

      if (value.channel === EChannelsID.SMS) {
        setCurrentActionId(value.actionId);
        return;
      }

      const { actionId, campaignId, channel, splitTest: isSplit, htmlOriginId, originId } = value;

      const router = getRoutePathAction({
        channel,
        htmlOriginId,
        isSplit,
        originId,
        createSimpleEmail,
      });

      navigate(`${CAMPAIGNS_PATH}/${campaignId}/${router}/${actionId}/preview`);
    },
    [createSimpleEmail, getRoutePathAction, navigate, setCurrentActionId]
  );

  const isWhatsAppAction = useCallback((actionName: string): boolean => {
    return actionName.startsWith(WHATSAPP_PREFIX);
  }, []);

  const handleClickReport = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: ICampaignActions) => {
      event.stopPropagation();
      const { channel, splitTest: isSplit, htmlOriginId, originId, name } = value;
      const isWhatsApp = isWhatsAppAction(name);
      const route = getRoutePathAction({
        channel,
        htmlOriginId,
        isSplit,
        originId,
        createSimpleEmail,
      });
      const baseRoute = `/v2/campaigns/${value.campaignId}/${isWhatsApp ? 'whatsapp' : route}/${
        value.actionId
      }`;

      setInfoCampaignV1({
        campaign: {
          id: Number(detailCampaign.campaignId),
          name: detailCampaign.name ?? '',
        },
        action: {
          id: value.actionId,
          name: value.name,
        },
        route: actionType
          ? `/v2/campaigns/actions-${actionType}`
          : `/v2/campaigns/${String(detailCampaign.campaignId)}`,
      });

      setHistorySearchPagerAction({
        filters: { ...filters },
        pager: {
          page: pager?.page || defaultPager.page,
          limit: pager?.limit || defaultPager.limit,
        },
      });

      if (enableWhatsAppModule && isWhatsApp) {
        navigate(`${baseRoute}/summary`);
      } else if (createSimpleSMS) {
        navigate(`${baseRoute}${route.includes('sms') ? '/summary' : '/report'}`);
      } else {
        navigate(`${baseRoute}${route.includes('sms') ? '' : '/report'}`);
      }
    },
    [
      detailCampaign.campaignId,
      detailCampaign.name,
      actionType,
      filters,
      pager?.page,
      pager?.limit,
      createSimpleSMS,
      createSimpleEmail,
      enableWhatsAppModule,
      getRoutePathAction,
      setInfoCampaignV1,
      setHistorySearchPagerAction,
      navigate,
      isWhatsAppAction,
    ]
  );

  const handleClickItemDropdown = useCallback(
    (index?: number) => {
      if (!index) return;
      setSelectedRows([index]);
      setSelectedRowsQuickTools(true);
    },
    [setSelectedRows, setSelectedRowsQuickTools]
  );

  const buttonColumnLogic = useMemo(() => {
    return (value: ICampaignActions) => {
      const status = DROPDOWN_ACTIONS_STATE.find((item) => Number(item.value) === value.status);
      const disabled =
        !value.report ||
        status?.label.includes('draft') ||
        value.channel === EChannelsID.SMS_API ||
        false;

      let previewTitle = 'preview email';
      if (value.channel === EChannelsID.SMS) {
        if (enableWhatsAppModule && value.name.startsWith(WHATSAPP_PREFIX)) {
          previewTitle = 'preview whatsapp';
        } else {
          previewTitle = 'preview sms';
        }
      }

      return {
        disabled,
        previewTitle,
        isPreviewDisabled: isPreviewDisabled(value.channel),
      };
    };
  }, [isPreviewDisabled, enableWhatsAppModule]);

  /** cspell:disable */
  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.name')} />
        <SortTableArrow
          elementName={'name'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'action',
    maxWidth: '340px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const { channel, splitTest: isSplit, htmlOriginId, originId } = value;
      const route = getRoutePathAction({
        channel,
        htmlOriginId,
        isSplit,
        originId,
        createSimpleEmail,
      });

      const isWhatsApp = isWhatsAppAction(value.name);
      const actionName = isWhatsApp ? value.name.replace(WHATSAPP_PREFIX, '') : value.name;

      return (
        <div className="ml-3 grid grid-cols-campaigns gap-4">
          <div className="shrink-0">
            <IconSvg
              rounded
              bgColor={getStatusColor(value.status)?.background as Exclude<ColorsIcon, 'white'>}
              fillColor={getStatusColor(value.status)?.background as ColorsIcon}
              height="40px"
              svgComponent={ICON_BY_ACTION_TYPE[isWhatsApp ? 'whatsapp' : route]}
              width="40px"
              withPadding="small"
            />
          </div>
          <Tooltip isClickable noArrow alignment="start" content={actionName} position="top">
            <div className="self-start text-left !text-14">
              <div className="emblue-subheadings max-w-[85%] truncate font-medium">
                {actionName}
              </div>
              <div className="emblue-text text-left font-normal">
                {t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${isWhatsApp ? 'whatsapp' : route}`)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    },
  };

  const campaignsNameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.campaign')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'campaignsName',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {value.campaignName || '-'}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.state')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'status',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <BadgeText
            text={getCurrentState(value.status)}
            variantColor={(getStatusColor(value.status)?.badge as ColorsBadge) ?? null}
          />
        </Flex>
      </Flex>
    ),
  };

  const creationDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.creation')} />
        <SortTableArrow
          elementName={'creationDate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'creationDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = formatDate(value.creationDate, DateFormat.LongDateOutSeconds);
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const executionDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.execution')} />
        <SortTableArrow
          elementName={'exeDate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'exeDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = value.exeDate
        ? formatDate(value.exeDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const modificationDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.lastUpdate')} />
        <SortTableArrow
          elementName={'modificationDate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'modificationDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = value.modificationDate
        ? formatDate(value.modificationDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const audienceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.audience')} />
        <SortTableArrow
          elementName={'audience'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'audience',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.audience), i18n.language as keyof typeof ELanguageMap) ||
              '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const openingRateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.openRate')} />
        <SortTableArrow
          elementName={'openingRate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'openingRate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {value.openingRate ? `${value.openingRate}%` : '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const clickRateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.clickRate')} />
        <SortTableArrow
          elementName={'clickRate'}
          getTableOrderByList={changeOrderBy}
          setTableOrderByList={setTableOrderByList}
          tableOrderByList={tableOrderByList}
        />
      </Flex>
    ),
    accessor: 'action',
    id: 'clickRate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {value.clickRate ? `${value.clickRate}%` : '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const buttonColumn = {
    accessor: 'action',
    id: 'buttons',
    width: TABLE_COLUMN_WIDTH.report,
    Cell: ({ cell: { value, row } }: { cell: { value: ICampaignActions; row: any } }) => {
      const { disabled, previewTitle, isPreviewDisabled } = buttonColumnLogic(value);

      return (
        <Flex alignment="center" className="z-10">
          {previewAvailable && (
            <Button
              gray
              onlyIcon
              outline
              data-dd-action-name={`action-preview_${DD_ACTION_TYPE_MAP[value.channel]}`}
              data-id={value.actionId}
              disabled={isPreviewDisabled}
              id="preview-actions"
              size="small"
              title={previewTitle}
              onClick={(event) => handleClickPreview(event, value)}
            >
              <IconSvg fillColor="gray-dark" svgComponent={<ViewsIconSolid />} />
            </Button>
          )}
          <Button
            gray
            onlyIcon
            outline
            data-id={value.actionId}
            disabled={disabled}
            id="detail-actions"
            size="small"
            onClick={(event) => handleClickReport(event, value)}
          >
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ReportIconSolid />}
              width="13px"
            />
          </Button>
          <ActionMenuTools
            id="options-actions-dropdown"
            onClick={() => handleClickItemDropdown(row.id)}
          />
        </Flex>
      );
    },
  };

  /** cspell:enable */

  const draftActionsColumns: Column<object>[] = [
    statusColumn,
    campaignsNameColumn,
    creationDateColumn,
    modificationDateColumn,
    buttonColumn,
  ];

  const finishedActionsColumns: Column<object>[] = [
    campaignsNameColumn,
    executionDateColumn,
    audienceColumn,
    openingRateColumn,
    clickRateColumn,
    buttonColumn,
  ];

  const allActionsColumns: Column<object>[] = [
    campaignsNameColumn,
    creationDateColumn,
    executionDateColumn,
    audienceColumn,
    buttonColumn,
  ];

  const detailActionsColumns: Column<object>[] = [
    statusColumn,
    creationDateColumn,
    executionDateColumn,
    audienceColumn,
    buttonColumn,
  ];

  const actionsColumns: Column<object>[] = [
    nameColumn,
    ...(actionType === EActionTypes.DETAIL ? detailActionsColumns : []),
    ...(actionType === EActionTypes.DRAFT ? draftActionsColumns : []),
    ...(actionType === EActionTypes.FINISHED ? finishedActionsColumns : []),
    ...(actionType === EActionTypes.ALL ? allActionsColumns : []),
  ].filter((x) => x) as Column<object>[];

  return {
    ChannelInfoByID,
    actionsColumns,
  };
};
