import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertType } from '@/lib/v3/components/Alert';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

declare global {
  interface Window {
    __emblueMetadata: {
      userId: string;
    };
  }
}

const STORAGE_KEY = 'deprecation-old-editors-alert';
const TRANSLATE_KEY = 'DeprecationOldEditors';

const getStorageKey = (type: AlertType) => {
  const userId = window.__emblueMetadata?.userId;
  return `${STORAGE_KEY}-${userId}-${type}`;
};

export interface NotificationConfig {
  type: AlertType;
  start: string;
  end: string;
  textButton: string;
  title: string;
  text: string;
  onClickButton?: () => void;
  onClose?: () => void;
}

export const useDeprecationOldEditors = () => {
  const { t } = useTranslation();
  const [userData] = useUserData();
  const [isVisible, setIsVisible] = useState(true);

  const accountGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );

  const handleCloseAlert = useCallback((type: AlertType, event: string) => {
    const key = getStorageKey(type);
    localStorage.setItem(key, 'true');
    datadogRum.addAction('notification.oldEditors', { event });
    setIsVisible(false);
  }, []);

  const notificationConfig = useMemo(
    () =>
      [
        {
          type: 'warning' as const,
          start: '2025-02-24T00:00:00',
          end: '2025-03-10T00:00:00',
          textButton: t(`${TRANSLATE_KEY}.warning.buttonText`),
          title: t(`${TRANSLATE_KEY}.warning.title`),
          text: t(`${TRANSLATE_KEY}.warning.description`),
          onClickButton: () => handleCloseAlert('warning', 'accept'),
          onClose: () => handleCloseAlert('warning', 'close'),
        },
      ] as const,
    [handleCloseAlert, t]
  );

  const alertInfoData = useMemo(() => {
    if (!accountGMT) return null;
    const now = new Date();
    const currentDate = DateConverter.toGMTDate(now.toISOString(), accountGMT);
    const currentTimestamp = currentDate.getTime();

    const validAlerts = notificationConfig
      .map((config, index) => ({
        id: index,
        ...config,
        startTimestamp: DateConverter.toGMTDate(config.start, accountGMT).getTime(),
        endTimestamp: DateConverter.toGMTDate(config.end, accountGMT).getTime(),
      }))
      .filter(({ startTimestamp, endTimestamp, type }) => {
        const key = getStorageKey(type);
        const isDismissed = localStorage.getItem(key) !== null;
        return (
          currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp && !isDismissed
        );
      })
      .sort((a, b) => b.startTimestamp - a.startTimestamp);

    return validAlerts[0] || null;
  }, [accountGMT, notificationConfig]);

  useEffect(() => {
    if (!alertInfoData) return;
    const key = getStorageKey(alertInfoData.type);
    const storedValue = localStorage.getItem(key);
    setIsVisible(storedValue === null);
  }, [alertInfoData]);

  return {
    alertInfoData,
    isVisible,
  };
};
