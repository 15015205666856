import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { formValidations } from '@/src/constants/FormValidations';

import { useSaveDataRules } from './useSaveDataRules';

import {
  atomDateExecutionValue,
  atomDateFrequencyValue,
  atomRuleData,
  atomRuleTypeSelected,
  atomToggleSidePanel,
  atomTouchRules,
} from '@/modules/RulesModule/atoms/rules';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';
import { IRuleForm } from '@/modules/RulesModule/interfaces';

export const useFormRule = () => {
  const { t } = useTranslation();

  const [ruleTypeSelected, setRuleTypeSelected] = useAtom(atomRuleTypeSelected);

  const rulesData = useAtomValue(atomRuleData);
  const touchRules = useAtomValue(atomTouchRules);

  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const setDateFrequencyValue = useSetAtom(atomDateFrequencyValue);
  const setDateExecutionValue = useSetAtom(atomDateExecutionValue);

  const { saveRule } = useSaveDataRules();
  const { refetchData } = useResetRulesState();

  const { formRegExp } = formValidations;

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IRuleForm>();

  useEffect(() => {
    setValue('trigger', rulesData?.trigger as Option);
    setValue('customField', rulesData?.customField as Option);
    setValue('frequency', rulesData?.frequency as Option);
    setValue('execution', rulesData?.execution as Option);
    setValue('days', rulesData?.days as Option);
    setValue('uniqueExecution', String(rulesData?.days?.value));
    setValue('period', rulesData?.period as Option);
    setValue('hours', rulesData?.hours as Option);
    setValue('minutes', rulesData?.minutes as Option);
    setValue('repeatEmails', rulesData?.repeatEmails as Option);
    setValue('groupContacts', rulesData?.groupContacts as OptionExternal);

    setValue('redirectSuccess', rulesData?.redirectSuccess as Option);
    setValue('destinationSuccessUrl', rulesData?.destinationSuccessUrl as string);
    setValue('redirectError', rulesData?.redirectError as Option);
    setValue('destinationErrorUrl', rulesData?.destinationErrorUrl as string);

    setValue('tagTypeField', rulesData?.tagTypeField as Option);
    setValue('tagsGroupField', rulesData?.tagsGroupField as Option[]);

    setDateFrequencyValue(rulesData?.frequency?.value as string);
    setDateExecutionValue(rulesData?.execution?.value as string);
  }, [rulesData, setDateExecutionValue, setDateFrequencyValue, setValue, touchRules]);

  const onSubmit: SubmitHandler<IRuleForm> = useCallback(
    async (triggerValues) => {
      const updatedRulesData = {
        ...rulesData,
        ...triggerValues,
        trigger: ruleTypeSelected,
        touchRules: triggerValues.touchRules,
      };

      await saveRule(updatedRulesData);
      setToggleSidePanel(false);
      refetchData();
    },
    [rulesData, ruleTypeSelected, saveRule, setToggleSidePanel, refetchData]
  );

  const RULES_DATE = useMemo(() => {
    const fields = [
      'trigger',
      'customField',
      'frequency',
      'execution',
      'days',
      'period',
      'hours',
      'minutes',
      'tagTypeField',
      'tagsGroupField',
      'uniqueExecution',
    ];

    return fields.reduce((acc, field) => {
      acc[field] = { required: t('FORMS_RULES_MESSAGES.required') };
      if (field === 'uniqueExecution') {
        acc[field] = {
          required: t('FORMS_RULES_MESSAGES.required'),
          min: { value: 1, message: t('FORMS_RULES_MESSAGES.minValue') },
        };
      }
      return acc;
    }, {} as Record<string, { required: string } | { required: string; min: { value: number; message: string } }>);
  }, [t]);

  const RULES_EXTERNAL_CALL = useMemo(() => {
    const httpsPatternRule = {
      pattern: {
        value: formRegExp.https,
        message: t('FORMS_RULES_MESSAGES.https'),
      },
    };

    return {
      redirectError: { required: t('FORMS_RULES_MESSAGES.required') },
      redirectSuccess: { required: t('FORMS_RULES_MESSAGES.required') },
      destinationSuccessUrl: {
        required: t('FORMS_RULES_MESSAGES.required'),
        ...httpsPatternRule,
      },
      destinationErrorUrl: {
        required: t('FORMS_RULES_MESSAGES.required'),
        ...httpsPatternRule,
      },
    };
  }, [formRegExp.https, t]);

  const RULES_WEB_FORM = useMemo(() => {
    const httpsPatternRule = {
      pattern: {
        value: formRegExp.https,
        message: t('FORMS_RULES_MESSAGES.https'),
      },
    };
    return {
      redirectError: { required: t('FORMS_RULES_MESSAGES.required') },
      redirectSuccess: { required: t('FORMS_RULES_MESSAGES.required') },
      destinationSuccessUrl: {
        required: t('FORMS_RULES_MESSAGES.required'),
        ...httpsPatternRule,
      },
      destinationErrorUrl: {
        required: t('FORMS_RULES_MESSAGES.required'),
        ...httpsPatternRule,
      },
    };
  }, [formRegExp.https, t]);

  const handleCancel = useCallback(() => {
    setRuleTypeSelected(rulesData?.trigger);
    setToggleSidePanel(false);
  }, [rulesData?.trigger, setToggleSidePanel, setRuleTypeSelected]);

  return {
    state: {
      control,
      errors,
      RULES_DATE,
      RULES_EXTERNAL_CALL,
      RULES_WEB_FORM,
    },
    events: {
      handleSubmit: handleSubmit(onSubmit),
      handleCancel,
      watch,
      getValues,
    },
    setter: {
      setValue,
    },
  };
};
