import { useAtomValue, useSetAtom } from 'jotai';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DateInput, TimeInput } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';
import useSchedulingForm, {
  SchedulingWhatsAppForm,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppScheduling/hooks/useSchedulingForm';

import { atomProcessingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomCallbackStep } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoWhatsApp } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import {
  CAMPAIGNS_PATH,
  NEXT_YEAR_OFFSET,
  TRANSLATE_WHATSAPP_SCHEDULING,
} from '@/modules/CampaignsModule/constants';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import { IScheduledSMSDeliveryV1 } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const SchedulingDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  /* cspell:disable */
  const { t } = useTranslation('inbox');
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const setProcessingAction = useSetAtom(atomProcessingAction);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);

  const [userData] = useUserData();
  const { control, RULES, handleSubmit, setValue } = useSchedulingForm();
  const { setScheduledWhatsAppDelivery } = useActionWhatsAppData(Number(actionId));

  const formatGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );
  const currentDateRoot = useMemo(
    () => (formatGMT ? DateConverter.toGMTDate(new Date().toISOString(), formatGMT) : undefined),
    [formatGMT]
  );

  const dateWhatsApp = useMemo(() => {
    if (!actionInfoWhatsApp) return;

    const { anio, mes, dia, hora, minutos } = actionInfoWhatsApp;

    const dateTime = new Date(
      parseInt(anio),
      parseInt(mes) - 1,
      parseInt(dia),
      parseInt(hora),
      parseInt(minutos)
    );

    const date = new Date(parseInt(anio), parseInt(mes) - 1, parseInt(dia));

    return {
      date,
      dateTime,
    };
  }, [actionInfoWhatsApp]);

  const [scheduledDate, setScheduledDate] = useState<Date | undefined>(
    !actionInfoWhatsApp?.inmediato ? dateWhatsApp?.date : undefined
  );
  const [scheduledTime, setScheduledTime] = useState<Date | undefined>(
    !actionInfoWhatsApp?.inmediato ? dateWhatsApp?.dateTime : undefined
  );
  const [scheduledTimeMinDateTime, setScheduledTimeMinDateTime] = useState<Date | undefined>(
    undefined
  );

  const maxDateScheduledDelivery = useMemo(() => {
    if (!formatGMT) return undefined;
    const date = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
    date.setFullYear(date.getFullYear() + NEXT_YEAR_OFFSET);
    return date;
  }, [formatGMT]);

  useEffect(() => {
    if (!dateWhatsApp?.date || !formatGMT) return;

    const dateWhatsAppGMT = new Date(dateWhatsApp.dateTime);
    setValue('sendDate', dateWhatsAppGMT);
    setValue('sendTime', dateWhatsAppGMT);

    const currentDateGMT = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);

    if (
      dateWhatsAppGMT.getDate() === currentDateGMT.getDate() &&
      dateWhatsAppGMT.getMonth() === currentDateGMT.getMonth() &&
      dateWhatsAppGMT.getFullYear() === currentDateGMT.getFullYear()
    ) {
      setScheduledTimeMinDateTime(
        dateWhatsAppGMT > currentDateGMT ? currentDateGMT : dateWhatsAppGMT
      );
    } else {
      setScheduledTimeMinDateTime(undefined);
    }
  }, [formatGMT, setValue]);

  const handleSubmitData = useCallback(
    async (formData: SchedulingWhatsAppForm) => {
      if (!actionId || !userData.timeOffset) return;

      setProcessingAction(true);

      const { sendDate, sendTime } = formData;
      if (!sendDate || !sendTime) return;

      const utcDate = new Date(
        Date.UTC(sendDate.getFullYear(), sendDate.getMonth(), sendDate.getDate(), 0, 0, 0)
      );

      const gmtOffset = parseInt(userData.timeOffset.toString());
      const schedulingTimestamp = utcDate.getTime() - gmtOffset * 60 * 60 * 1000;

      const queryParams: IScheduledSMSDeliveryV1 = {
        elementoId: +actionId,
        hora: sendTime.getHours(),
        minuto: sendTime.getMinutes(),
        milis: schedulingTimestamp,
      };

      await setScheduledWhatsAppDelivery(queryParams);

      setProcessingAction(false);
      navigate(`${CAMPAIGNS_PATH}/${Number(campaignId)}/whatsapp/${Number(actionId)}/summary`);
    },
    [
      actionId,
      userData.timeOffset,
      setProcessingAction,
      setScheduledWhatsAppDelivery,
      navigate,
      campaignId,
    ]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const inputDate = event as Date;
      setScheduledDate(inputDate);
      setScheduledTime(undefined);
      if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
      ) {
        setScheduledTimeMinDateTime(currentDate);
      } else {
        setScheduledTimeMinDateTime(undefined);
      }
    },
    [formatGMT]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setScheduledTime(event as Date);
  }, []);

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setProcessingAction(false);
  }, [handleSubmit, handleSubmitData, setCallbackStep, setProcessingAction]);

  /* cspell:enable */
  return (
    <form
      className="pt-12"
      id="immediate-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="flex gap-3">
        <div className="flex w-200">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={scheduledDate}
            disabled={disabled}
            id="sendDate"
            label={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.date`)}
            maxDate={maxDateScheduledDelivery}
            minDate={currentDateRoot}
            name="sendDate"
            placeholder={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.datePlaceholder`)}
            rules={RULES.sendDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={scheduledTime}
            disabled={disabled}
            id="sendTime"
            label={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.time`)}
            minTime={scheduledTimeMinDateTime}
            name="sendTime"
            placeholder={t(`${TRANSLATE_WHATSAPP_SCHEDULING}.timePlaceholder`)}
            rules={RULES.sendTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default SchedulingDeliveryForm;
