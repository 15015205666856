/**
 * Formats numbers to compact notation using metric prefixes (K for thousands, M for millions)
 * @param value - The number to format
 * @returns string - Formatted number with corresponding metric prefix
 */
export const formatNumberToMetricPrefix = (value: number): string => {
  if (value >= 1000000) return `${(value / 1000000).toFixed(0)}M`;
  if (value >= 1000) return `${(value / 1000).toFixed(0)}K`;
  return value.toString();
};
