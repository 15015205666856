import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import {
  IAddresseesInfoResponse,
  IAddresseesPayload,
  IEmailInfoResponse,
  IListAddresseesByType,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

export const atomGlobalLoadingAction = atom<boolean>(true);
atomGlobalLoadingAction.debugLabel = 'atomGlobalLoadingAction';

export const atomProcessingAction = atom<boolean>(false);
atomProcessingAction.debugLabel = 'atomProcessingAction';

export const atomActionInfoEmail = atomWithReset<IEmailInfoResponse | undefined>(undefined);
atomActionInfoEmail.debugLabel = 'atomActionInfoEmail';

export const atomEmailAddresseesInfo = atomWithReset<IAddresseesInfoResponse | undefined>(
  undefined
);
atomEmailAddresseesInfo.debugLabel = 'atomEmailAddresseesInfo';

export const atomActionEmailAddresseesByType = atom<IListAddresseesByType | undefined>(undefined);
atomActionEmailAddresseesByType.debugLabel = 'atomActionEmailAddresseesByType';

//TODO: Delete in SMS refactor
export const atomAddresseesListPayload = atom<IAddresseesPayload>({
  offset: 0,
  groupQuantity: 20,
  filterType: {
    user: true,
    profile: true,
    integration: true,
    interest: true,
  },
});
atomAddresseesListPayload.debugLabel = 'atomAddresseesListPayload';

export const atomActionFinished = atom<boolean>(false);
atomActionFinished.debugLabel = 'atomActionFinished';

export const atomTotalAddressees = atom<number>(0);
atomTotalAddressees.debugLabel = 'atomTotalAddressees';

export const atomDisableEditAction = atom<boolean>(true);
atomDisableEditAction.debugLabel = 'atomDisableEditAction';

export const atomIsActionInfoEmailErrors = atomWithReset<boolean>(false);
atomIsActionInfoEmailErrors.debugLabel = 'atomIsActionInfoEmailErrors';
