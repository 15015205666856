import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import { Size, useStateTabContext } from './Tab';

export type TabItemProps = {
  children?: ReactNode;
  tabId: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  size?: Size;
  isFullWidth?: boolean;
  isUnderlined?: boolean;
  isImaginary?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isDisabled?: boolean;
};

const TabItem = ({
  children,
  tabId,
  onClick,
  id,
  size,
  isFullWidth = false,
  isUnderlined = false,
  isImaginary = false,
  leftIcon,
  rightIcon,
  isDisabled = false,
}: TabItemProps) => {
  const { currentTab } = useStateTabContext();

  const active = currentTab === tabId;

  const classes = classNames('inline-block rounded-t-lg py-2 px-4 w-full', {
    'flex items-center justify-center gap-1.5': leftIcon || rightIcon,
    'border-b-transparent': !active && !isUnderlined,
    'border-b-emblue-secondary-stroke-disable': !active && isUnderlined,
    'text-emblue-primary border-b-emblue-primary': active,
    'border-b-[1px]': size === 'small',
    'border-b-2': size === 'medium',
    'border-b-[3px]': size === 'large',
    'text-14': size === 'small',
    'text-16': size === 'medium',
    'text-18': size === 'large',
    'text-emblue-subheadings': !isImaginary && !active && !isDisabled,
    '!text-emblue-tertiary-content-disable': isDisabled,
    'text-transparent': isImaginary,
    'hover:text-emblue-primary-content-input-label hover:border-emblue-primary-content-input-label':
      !isDisabled && !isImaginary && !active,
  });

  return (
    <li className={isFullWidth ? 'flex-1' : ''} id={id}>
      <button
        aria-current="page"
        className={classes}
        disabled={isImaginary || isDisabled}
        onClick={onClick}
      >
        {isImaginary ? (
          'I'
        ) : (
          <>
            {leftIcon && <span aria-hidden="true">{leftIcon}</span>}
            {children}
            {rightIcon && <span aria-hidden="true">{rightIcon}</span>}
          </>
        )}
      </button>
    </li>
  );
};

export default TabItem;
