import { memo } from 'react';

import ActionsSummary from '@/src/modules/InsightsModule/components/ActionsSummary';
import ContactsGraph from '@/src/modules/InsightsModule/components/ContactsGraph';
import ChannelSummary from '@/src/modules/InsightsModule/components/Shipping';

import ContactsSummary from '@/modules/InsightsModule/components/ContactsSummary';
import InsightsProductTour from '@/modules/InsightsModule/components/InsightsProductTour';

const InsightsMain = () => {
  return (
    <>
      <InsightsProductTour />
      <div className="flex min-h-svh flex-col flex-wrap gap-4 bg-emblue-secondary-hover px-8 py-4 align-baseline">
        <div className="grid grid-cols-5 gap-4">
          <div className="contacts-summary col-span-3">
            <ContactsSummary />
          </div>
          <div className="contacts-graph col-span-2">
            <ContactsGraph />
          </div>
        </div>
        <div className="channel-summary">
          <ChannelSummary />
        </div>
        <div className="actions-summary">
          <ActionsSummary />
        </div>
      </div>
    </>
  );
};

export default memo(InsightsMain);
