import { useAtomValue } from 'jotai';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { Card, IconSvg, If, Text, Tooltip } from '@/lib/v2/components';
import { InfoIcon } from '@/lib/v2/icons/outline';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { atomChannelTotals, atomMetricType } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import CardShippingElements from '@/src/modules/InsightsModule/components/CardShippingElements';
import SenderScore from '@/src/modules/InsightsModule/components/SenderScore';
import ShippingMetrics from '@/src/modules/InsightsModule/components/ShippingMetrics';
import ShippingRanges from '@/src/modules/InsightsModule/components/ShippingRanges';
import { useShippingMetric } from '@/src/modules/InsightsModule/hooks/useShippingMetrics';
import { ChannelTotalType } from '@/src/modules/InsightsModule/interfaces/Channel';

import './channelMetrics.css';

const ChannelSummary = () => {
  const { t } = useTranslation(['insights']);

  const channelTotals = useAtomValue(atomChannelTotals);
  const metricType = useAtomValue(atomMetricType);

  const {
    state: { loading },
    events: { getData },
  } = useShippingMetric();

  useEffect(() => {
    void getData();
  }, []);

  return (
    <Card noShadow className="flex w-full justify-between" isFullHeight={false}>
      <div className="flex flex-row justify-between gap-4">
        <div className="flex w-2/3 flex-col justify-between">
          <div className="mb-2 flex flex-col justify-center">
            <div className="flex w-full items-center justify-between">
              <Text className="pl-4" color="black" fontWeight="normal" variant="headline">
                {t('shipping.title')}
              </Text>
            </div>
            <div className="flex items-center gap-2 pl-4">
              <If
                condition={!loading}
                fallback={
                  <Text className="h-10 leading-tight" variant="title-3">
                    <TextSkeleton size="sm" />
                  </Text>
                }
              >
                <Text className="h-10 leading-tight" variant="title-3">
                  {quantifier(channelTotals?.all ?? 0)}
                </Text>
                <div className="flex items-end">
                  <Text variant="text">({t('shipping.all')})</Text>
                  <div className="mb-3 flex">
                    <Tooltip
                      isDark
                      content={
                        <Text className="w-40 text-white" variant="text-sm">
                          {t('shipping.tooltip')}
                        </Text>
                      }
                      position="top"
                    >
                      <IconSvg
                        height="24"
                        strokeColor="secondary"
                        svgComponent={<InfoIcon />}
                        width="24"
                      />
                    </Tooltip>
                  </div>
                </div>
              </If>
            </div>
          </div>
        </div>

        <div className="flex w-1/3 flex-col items-end justify-end">
          <ShippingRanges />
        </div>
      </div>

      <div className="grid grid-cols-7 gap-4">
        <div className="col-span-5">
          <CardShippingElements />
        </div>

        <div className="col-span-2">
          <div className="flex h-full flex-col justify-normal gap-4">
            <If condition={metricType === ChannelTotalType.EMAIL}>
              <SenderScore />
            </If>
            <ShippingMetrics />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(ChannelSummary);
