import { timeout } from '@/src/compat/util';
import {
  CreateEventBody,
  EditEventBody,
  GetApiResponse,
  GetJourneyMessagePayload,
  GetRulesPayload,
  IGetAllEventsPayload,
  IGetAllEventsResponse,
  IGetAllFlowsPayload,
  IGetAllFlowsResponse,
  IGetDetailExecutedCountResponse,
  IGetExecutedFilters,
  IRequestErrorResult,
  PaginationListResponse,
} from '@/src/infrastructure/interfaces/IAutomation.interface';
import { CreateFlowResponse } from '@/src/infrastructure/interfaces/IResponses';

import { IAutomationService } from './IAutomationService';

import { EventData, IEventsStatus } from '@/modules/AutomationModule/types/events';
import {
  JourneysMessagesTable,
  RulesTable,
} from '@/modules/AutomationModuleNew/interfaces/automation';
import { DataResponse } from '@/modules/ContactsModule/types/Segments';

export class StubAutomationService implements IAutomationService {
  getRulesList(query: GetRulesPayload): Promise<DataResponse<PaginationListResponse<RulesTable>>> {
    throw new Error('Method not implemented.');
  }
  getJourneysMessages(
    filters: GetJourneyMessagePayload
  ): Promise<DataResponse<PaginationListResponse<JourneysMessagesTable>>> {
    throw new Error('Method not implemented.');
  }

  editEvent(editEvent: EditEventBody): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  getEventById(id: number): Promise<EventData | null> {
    throw new Error('Method not implemented.');
  }
  createEvent(createEvent: CreateEventBody): Promise<DataResponse<string>> {
    throw new Error('Method not implemented.');
  }
  getApiKey(): Promise<DataResponse<GetApiResponse>> {
    throw new Error('Method not implemented.');
  }

  async duplicateEvents(id: number, name: string) {
    throw new Error('Method not implemented.');
    await timeout(2000);
    return true;
  }
  async duplicateFlows(id: number, name: string) {
    throw new Error('Method not implemented.');
    await timeout(2000);
    return true;
  }

  async getDetailEventsExecutions(
    id: number,
    filters?: IGetExecutedFilters | null
  ): Promise<IGetDetailExecutedCountResponse | null> {
    throw new Error('Method not implemented.');
    await timeout(2000);
    return null;
  }

  createFlow(flowName: string): Promise<DataResponse<CreateFlowResponse | string>> {
    throw new Error('Method not implemented.');
  }
  changeEventStatus(id: number, status: IEventsStatus): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  static create(): IAutomationService {
    return new StubAutomationService();
  }

  /**
   * @desc Get all flows of automation
   *
   */
  async getFlows(payload: IGetAllFlowsPayload): Promise<IGetAllFlowsResponse> {
    const response = {
      result: [],
      pager: {
        countPartial: 0,
        countTotal: 0,
      },
    };
    try {
      await timeout(2000);
      return response;
    } catch (error) {
      return response;
    }
  }

  /**
   * @desc get total of executed in a flow
   */
  async getFlowsExecutions(triggerIds: number[], filters?: IGetExecutedFilters | null) {
    try {
      await timeout(2000);
      return [
        {
          triggerId: 1,
          total: 6,
        },
      ];
    } catch (error) {
      return null;
    }
  }
  async getEventsExecutions(eventsIds: number[], filters?: IGetExecutedFilters | null) {
    try {
      await timeout(2000);
      return [
        {
          triggerId: 1,
          total: 6,
        },
      ];
    } catch (error) {
      return null;
    }
  }

  async deleteFlows(id: number[]) {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @desc Authentication in beefree
   *
   */
  async getEvents(payload: IGetAllEventsPayload): Promise<IGetAllEventsResponse> {
    const response = {
      result: [],
      pager: {
        countPartial: 0,
        countTotal: 0,
      },
    };
    try {
      await timeout(2000);
      return response;
    } catch (error) {
      return response;
    }
  }
  async changeStatus(id: number, status: number): Promise<IRequestErrorResult | boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }
  async deleteEvent(id: number[]): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description Change name of the flow
   */
  async renameFlow(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description Change name of the event
   */
  async renameEvent(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description Change name of the event
   */
  async getReportEvents(): Promise<string | null> {
    try {
      await timeout(2000);
      return 'report.zip';
    } catch (error) {
      return null;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description Change name of the event
   */
  async getReportFlows(): Promise<string | null> {
    try {
      await timeout(2000);
      return 'report.zip';
    } catch (error) {
      return null;
    }
  }

  async downloadReport(url: string): Promise<null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      return null;
    }
  }

  async getDetailFlowsExecutions(id: number, filters?: IGetExecutedFilters): Promise<null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      return null;
    }
  }
  async executeFlow(id: number): Promise<boolean> {
    await timeout(2000);
    return true;
  }
}
