import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option, Select, Text } from '@/lib/v2/components';

import { atomDateFrequencyValue } from '@/modules/RulesModule/atoms/rules';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import {
  TRANSLATE_RULES_PERIOD_OPTIONS,
  TRANSLATE_RULES_PLACEHOLDERS,
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
} from '@/modules/RulesModule/constants';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IFrequencySelectProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

export const FrequencySelect = ({ form, disabled = false, rules }: IFrequencySelectProps) => {
  const { t } = useTranslation('rules');

  const setDateFrequencyValue = useSetAtom(atomDateFrequencyValue);

  const { frequencyOptions } = useRuleDefaultOptions();

  /** cspell:disable */
  const handleChangeFrequency = useCallback(
    (value: Option | Option[]) => {
      const frequency = value as Option;

      if (frequency.value !== 'anio') {
        form.setValue('days', {
          id: '1',
          name: '1',
          value: '1',
        } as Option);

        form.setValue('period', {
          id: 'dia',
          name: `${t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.dia`)}`,
          value: 'dia',
        } as Option);
      }

      setDateFrequencyValue(frequency.value as string);
    },
    [form, setDateFrequencyValue, t]
  );
  /** cspell:enable */

  return (
    <div className="mb-5 flex items-center">
      <Text className="mr-2 whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.sendFrequency`)}
      </Text>
      <div className="min-w-40">
        <Select
          isRequired
          control={form.control}
          disabled={disabled}
          name="frequency"
          options={frequencyOptions}
          placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.frequency`)}
          rules={rules.frequency}
          onChange={handleChangeFrequency}
        />
      </div>
    </div>
  );
};
