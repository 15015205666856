import { IAssignmentRule } from '@/src/apps/chat/components/AssignmentRules/interfaces/assignment.interface';
import { timeout } from '@/src/compat/util';

import {
  IContactActivityList,
  IInboxEventsPayload,
  IInboxEventsResponse,
  IInboxService,
  OpenNewConversationFromTemplatePayload,
  OpenNewConversationFromTemplateResponse,
  SummaryConversationResponse,
  WhatsAppInboxResponse,
  WhatsAppSessionDeleteResponse,
  WhatsAppSessionStatusResponse,
  WhatsAppTemplate,
  WhatsAppTemplateCreate,
  WhatsAppTemplateResponse,
} from './IInboxService';
import {
  mockInboxEvents,
  mockOpenNewConversationResponse,
  mockWhatsAppTemplates,
} from './Inbox.mocks';

export class StubInboxService implements IInboxService {
  static create(): IInboxService {
    return new StubInboxService();
  }

  async getEventsInbox(query: IInboxEventsPayload): Promise<IInboxEventsResponse> {
    try {
      await timeout(2000);

      return {
        events: mockInboxEvents,
        total: mockInboxEvents.length,
        partial: mockInboxEvents.length,
      };
    } catch (error) {
      console.error('Error en StubInboxService.getEventsInbox:', error);
      return { events: [], total: 0, partial: 0 };
    }
  }

  async getWhatsAppTemplatesByAccount(): Promise<WhatsAppTemplate[]> {
    try {
      await timeout(2000);
      return mockWhatsAppTemplates;
    } catch (error) {
      console.error('Error en StubInboxService.getWhatsAppTemplatesByAccount:', error);
      return [];
    }
  }

  async openNewConversationFromTemplate(
    query: OpenNewConversationFromTemplatePayload
  ): Promise<OpenNewConversationFromTemplateResponse> {
    try {
      await timeout(2000);
      return mockOpenNewConversationResponse;
    } catch (error) {
      console.error('Error en StubInboxService.openNewConversationFromTemplate:', error);
      return { success: false, twilioMessageStatus: 'failed' };
    }
  }

  async checkInboxAccountExists(): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async getInboxContractStatus(): Promise<boolean> {
    await timeout(2000);
    return true;
  }

  async createTextTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async createMediaTemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async getSummaryConversation(accountId: number): Promise<SummaryConversationResponse | null> {
    await timeout(1000);
    return null;
  }

  async createCTATemplate(template: WhatsAppTemplateCreate): Promise<WhatsAppTemplateResponse> {
    await timeout(1000);
    return {};
  }

  async saveAssignmentRule(rule: IAssignmentRule): Promise<boolean> {
    try {
      await timeout(1000);
      return true;
    } catch (error) {
      return false;
    }
  }

  async getContactActivityList(contactId: string): Promise<IContactActivityList[]> {
    await timeout(2000);
    return [];
  }

  async createWhatsAppInbox(accountId: number | string): Promise<WhatsAppInboxResponse> {
    try {
      await timeout(1500);
      const response: WhatsAppInboxResponse = {
        id: 12345,
        name: 'WhatsApp Business',
        channel_type: 'whatsapp',
        avatar_url: '',
        channel_id: 67890,
        inbox_identifier: 'whatsapp_12345',
        additional_attributes: {},
        provider: 'whatsapp',
        greeting_enabled: false,
        greeting_message: '',
        out_of_office_message: '',
        working_hours_enabled: false,
        working_hours: [],
        timezone: 'UTC',
        enable_auto_assignment: true,
        auto_assignment_config: {},
        website_url: null,
        website_token: null,
        web_widget_script: null,
        widget_color: null,
        welcome_title: null,
        welcome_tagline: null,
        allow_messages_after_resolved: true,
        lock_to_single_conversation: false,
        sender_name_type: 'business',
        business_name: 'WhatsApp Business',
        reply_time: null,
        messaging_service_sid: null,
        phone_number: null,
        hmac_mandatory: false,
        hmac_token: '',
        callback_webhook_url: null,
        webhook_url: null,
        enable_email_collect: false,
        csat_survey_enabled: false,
        selected_feature_flags: null,
      };

      return response;
    } catch (error) {
      console.error('StubInboxService - Error in createWhatsAppInbox:', error);
      throw error;
    }
  }

  async checkWhatsAppInboxCreated(
    accountId: number | string,
    inboxName: string
  ): Promise<number | boolean> {
    await timeout(2000);
    return true;
  }

  async createWhatsAppSession(
    accountId: number | string,
    inboxId: number | string
  ): Promise<boolean> {
    try {
      await timeout(2000);
      return true;
    } catch (error) {
      console.error('StubInboxService - Error in createWhatsAppSession:', error);
      throw error;
    }
  }

  async deleteWhatsAppSession(
    accountId: number | string,
    inboxId: number | string
  ): Promise<WhatsAppSessionDeleteResponse> {
    try {
      await timeout(1500);
      return {
        success: true,
        message: 'Session deleted successfully',
      };
    } catch (error) {
      console.error('StubInboxService - Error in deleteWhatsAppSession:', error);
      throw error;
    }
  }

  async getWhatsAppSessionStatus(
    accountId: number | string,
    inboxId: number | string
  ): Promise<WhatsAppSessionStatusResponse> {
    try {
      await timeout(1000);
      return {
        success: true,
        data: {
          name: `${accountId}_${inboxId}`,
          status: 'SCAN_QR_CODE',
          config: {
            debug: false,
            noweb: {
              markOnline: true,
              store: {
                enabled: true,
                fullSync: false,
              },
            },
            webhooks: [
              {
                url: 'https://example.com/webhook/incoming',
                events: ['message'],
              },
            ],
          },
          me: null,
          engine: {
            engine: 'NOWEB',
          },
        },
      };
    } catch (error) {
      console.error('StubInboxService - Error in getWhatsAppSessionStatus:', error);
      throw error;
    }
  }

  async getWhatsAppQRCode(accountId: number | string, inboxId: number | string): Promise<string> {
    try {
      await timeout(1000);
      return 'https://example.com/qrcode';
    } catch (error) {
      console.error('StubInboxService - Error in getWhatsAppQRCode:', error);
      throw error;
    }
  }
}
