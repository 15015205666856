import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { atomConversation } from '@/src/apps/chat/components/IA/atoms/WhatsApp';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useAccountChatwoot = () => {
  const [chatwootAccountId, setChatwootAccount] = useAtom(atomConversation);
  const service = useService();
  const getChatwootAccount = async () => {
    try {
      let id = 1;
      try {
        const chatwootAccountIdResponse = await service?.getChatwootAccountId();
        if (chatwootAccountIdResponse && chatwootAccountIdResponse.chatwoot_account_id)
          id = chatwootAccountIdResponse.chatwoot_account_id;
        setChatwootAccount(id);
      } catch (error) {
        console.error('Error getting Chatwoot account ID:', error);
      }
    } catch (error) {
      console.error('Error getting Chatwoot account ID:', error);
    }
  };
  useEffect(() => {
    if (!chatwootAccountId) void getChatwootAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { chatwootAccountId };
};
