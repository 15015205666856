//cspell:disable

import { useBilling } from '@/modules/MyPlanModule/hooks/useBilling';
import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';

interface ICountry {
  value: string;
  prefix: string;
  id: string;
}

const countriesWithPrefixes: ICountry[] = [
  { value: 'Afghanistan', prefix: '93', id: 'AF' },
  { value: 'Albania', prefix: '355', id: 'AL' },
  { value: 'Algeria', prefix: '213', id: 'DZ' },
  { value: 'American Samoa', prefix: '1684', id: 'AS' },
  { value: 'Andorra', prefix: '376', id: 'AD' },
  { value: 'Angola', prefix: '244', id: 'AO' },
  { value: 'Anguilla', prefix: '1264', id: 'AI' },
  { value: 'Antarctica', prefix: '672', id: 'AQ' },
  { value: 'Antigua and Barbuda', prefix: '1268', id: 'AG' },
  { value: 'Argentina', prefix: '54', id: 'AR' },
  { value: 'Armenia', prefix: '374', id: 'AM' },
  { value: 'Aruba', prefix: '297', id: 'AW' },
  { value: 'Australia', prefix: '61', id: 'AU' },
  { value: 'Austria', prefix: '43', id: 'AT' },
  { value: 'Azerbaijan', prefix: '994', id: 'AZ' },
  { value: 'Bahamas', prefix: '1242', id: 'BS' },
  { value: 'Bahrain', prefix: '973', id: 'BH' },
  { value: 'Bangladesh', prefix: '880', id: 'BD' },
  { value: 'Barbados', prefix: '1246', id: 'BB' },
  { value: 'Belarus', prefix: '375', id: 'BY' },
  { value: 'Belgium', prefix: '32', id: 'BE' },
  { value: 'Belize', prefix: '501', id: 'BZ' },
  { value: 'Benin', prefix: '229', id: 'BJ' },
  { value: 'Bermuda', prefix: '1441', id: 'BM' },
  { value: 'Bhutan', prefix: '975', id: 'BT' },
  { value: 'Bolivia', prefix: '591', id: 'BO' },
  { value: 'Bosnia and Herzegovina', prefix: '387', id: 'BA' },
  { value: 'Botswana', prefix: '267', id: 'BW' },
  { value: 'Brazil', prefix: '55', id: 'BR' },
  { value: 'British Indian Ocean Territory', prefix: '246', id: 'IO' },
  { value: 'Brunei Darussalam', prefix: '673', id: 'BN' },
  { value: 'Bulgaria', prefix: '359', id: 'BG' },
  { value: 'Burkina Faso', prefix: '226', id: 'BF' },
  { value: 'Burundi', prefix: '257', id: 'BI' },
  { value: 'Cambodia', prefix: '855', id: 'KH' },
  { value: 'Cameroon', prefix: '237', id: 'CM' },
  { value: 'Canada', prefix: '1', id: 'CA' },
  { value: 'Cape Verde', prefix: '238', id: 'CV' },
  { value: 'Cayman Islands', prefix: '1345', id: 'KY' },
  { value: 'Central African Republic', prefix: '236', id: 'CF' },
  { value: 'Chad', prefix: '235', id: 'TD' },
  { value: 'Chile', prefix: '56', id: 'CL' },
  { value: 'China', prefix: '86', id: 'CN' },
  { value: 'Christmas Island', prefix: '61', id: 'CX' },
  { value: 'Cocos (Keeling) Islands', prefix: '61', id: 'CC' },
  { value: 'Colombia', prefix: '57', id: 'CO' },
  { value: 'Comoros', prefix: '269', id: 'KM' },
  { value: 'Congo', prefix: '242', id: 'CG' },
  { value: 'Congo, The Democratic Republic of the', prefix: '243', id: 'CD' },
  { value: 'Cook Islands', prefix: '682', id: 'CK' },
  { value: 'Costa Rica', prefix: '506', id: 'CR' },
  { value: "Cote D'Ivoire", prefix: '225', id: 'CI' },
  { value: 'Croatia', prefix: '385', id: 'HR' },
  { value: 'Cuba', prefix: '53', id: 'CU' },
  { value: 'Cyprus', prefix: '357', id: 'CY' },
  { value: 'Czech Republic', prefix: '420', id: 'CZ' },
  { value: 'Denmark', prefix: '45', id: 'DK' },
  { value: 'Djibouti', prefix: '253', id: 'DJ' },
  { value: 'Dominica', prefix: '1767', id: 'DM' },
  { value: 'Dominican Republic', prefix: '1809', id: 'DO' },
  { value: 'Ecuador', prefix: '593', id: 'EC' },
  { value: 'Egypt', prefix: '20', id: 'EG' },
  { value: 'El Salvador', prefix: '503', id: 'SV' },
  { value: 'Equatorial Guinea', prefix: '240', id: 'GQ' },
  { value: 'Eritrea', prefix: '291', id: 'ER' },
  { value: 'Estonia', prefix: '372', id: 'EE' },
  { value: 'Ethiopia', prefix: '251', id: 'ET' },
  { value: 'Falkland Islands (Malvinas)', prefix: '500', id: 'FK' },
  { value: 'Faroe Islands', prefix: '298', id: 'FO' },
  { value: 'Fiji', prefix: '679', id: 'FJ' },
  { value: 'Finland', prefix: '358', id: 'FI' },
  { value: 'France', prefix: '33', id: 'FR' },
  { value: 'French Guiana', prefix: '594', id: 'GF' },
  { value: 'French Polynesia', prefix: '689', id: 'PF' },
  { value: 'Gabon', prefix: '241', id: 'GA' },
  { value: 'Gambia', prefix: '220', id: 'GM' },
  { value: 'Georgia', prefix: '995', id: 'GE' },
  { value: 'Germany', prefix: '49', id: 'DE' },
  { value: 'Ghana', prefix: '233', id: 'GH' },
  { value: 'Gibraltar', prefix: '350', id: 'GI' },
  { value: 'Greece', prefix: '30', id: 'GR' },
  { value: 'Greenland', prefix: '299', id: 'GL' },
  { value: 'Grenada', prefix: '1473', id: 'GD' },
  { value: 'Guadeloupe', prefix: '590', id: 'GP' },
  { value: 'Guam', prefix: '1671', id: 'GU' },
  { value: 'Guatemala', prefix: '502', id: 'GT' },
  { value: 'Guinea', prefix: '224', id: 'GN' },
  { value: 'Guinea-Bissau', prefix: '245', id: 'GW' },
  { value: 'Guyana', prefix: '592', id: 'GY' },
  { value: 'Haiti', prefix: '509', id: 'HT' },
  { value: 'Honduras', prefix: '504', id: 'HN' },
  { value: 'Hong Kong', prefix: '852', id: 'HK' },
  { value: 'Hungary', prefix: '36', id: 'HU' },
  { value: 'Iceland', prefix: '354', id: 'IS' },
  { value: 'India', prefix: '91', id: 'IN' },
  { value: 'Indonesia', prefix: '62', id: 'ID' },
  { value: 'Iran, Islamic Republic Of', prefix: '98', id: 'IR' },
  { value: 'Iraq', prefix: '964', id: 'IQ' },
  { value: 'Ireland', prefix: '353', id: 'IE' },
  { value: 'Israel', prefix: '972', id: 'IL' },
  { value: 'Italy', prefix: '39', id: 'IT' },
  { value: 'Jamaica', prefix: '1876', id: 'JM' },
  { value: 'Japan', prefix: '81', id: 'JP' },
  { value: 'Jordan', prefix: '962', id: 'JO' },
  { value: 'Kazakhstan', prefix: '7', id: 'KZ' },
  { value: 'Kenya', prefix: '254', id: 'KE' },
  { value: 'Kiribati', prefix: '686', id: 'KI' },
  { value: 'Korea, Republic of', prefix: '82', id: 'KR' },
  { value: 'Kuwait', prefix: '965', id: 'KW' },
  { value: 'Kyrgyzstan', prefix: '996', id: 'KG' },
  { value: 'Latvia', prefix: '371', id: 'LV' },
  { value: 'Lebanon', prefix: '961', id: 'LB' },
  { value: 'Lesotho', prefix: '266', id: 'LS' },
  { value: 'Liberia', prefix: '231', id: 'LR' },
  { value: 'Libyan Arab Jamahiriya', prefix: '218', id: 'LY' },
  { value: 'Liechtenstein', prefix: '423', id: 'LI' },
  { value: 'Lithuania', prefix: '370', id: 'LT' },
  { value: 'Luxembourg', prefix: '352', id: 'LU' },
  { value: 'Macao', prefix: '853', id: 'MO' },
  { value: 'Madagascar', prefix: '261', id: 'MG' },
  { value: 'Malawi', prefix: '265', id: 'MW' },
  { value: 'Malaysia', prefix: '60', id: 'MY' },
  { value: 'Maldives', prefix: '960', id: 'MV' },
  { value: 'Mali', prefix: '223', id: 'ML' },
  { value: 'Malta', prefix: '356', id: 'MT' },
  { value: 'Marshall Islands', prefix: '692', id: 'MH' },
  { value: 'Martinique', prefix: '596', id: 'MQ' },
  { value: 'Mauritania', prefix: '222', id: 'MR' },
  { value: 'Mauritius', prefix: '230', id: 'MU' },
  { value: 'Mexico', prefix: '52', id: 'MX' },
  { value: 'Moldova, Republic of', prefix: '373', id: 'MD' },
  { value: 'Monaco', prefix: '377', id: 'MC' },
  { value: 'Mongolia', prefix: '976', id: 'MN' },
  { value: 'Montenegro', prefix: '382', id: 'ME' },
  { value: 'Montserrat', prefix: '1664', id: 'MS' },
  { value: 'Morocco', prefix: '212', id: 'MA' },
  { value: 'Mozambique', prefix: '258', id: 'MZ' },
  { value: 'Myanmar', prefix: '95', id: 'MM' },
  { value: 'Namibia', prefix: '264', id: 'NA' },
  { value: 'Nauru', prefix: '674', id: 'NR' },
  { value: 'Nepal', prefix: '977', id: 'NP' },
  { value: 'Netherlands', prefix: '31', id: 'NL' },
  { value: 'Netherlands Antilles', prefix: '599', id: 'AN' },
  { value: 'New Caledonia', prefix: '687', id: 'NC' },
  { value: 'New Zealand', prefix: '64', id: 'NZ' },
  { value: 'Nicaragua', prefix: '505', id: 'NI' },
  { value: 'Niger', prefix: '227', id: 'NE' },
  { value: 'Nigeria', prefix: '234', id: 'NG' },
  { value: 'Niue', prefix: '683', id: 'NU' },
  { value: 'Norfolk Island', prefix: '672', id: 'NF' },
  { value: 'Northern Mariana Islands', prefix: '1670', id: 'MP' },
  { value: 'Norway', prefix: '47', id: 'NO' },
  { value: 'Oman', prefix: '968', id: 'OM' },
  { value: 'Pakistan', prefix: '92', id: 'PK' },
  { value: 'Palau', prefix: '680', id: 'PW' },
  { value: 'Panama', prefix: '507', id: 'PA' },
  { value: 'Papua New Guinea', prefix: '675', id: 'PG' },
  { value: 'Paraguay', prefix: '595', id: 'PY' },
  { value: 'Peru', prefix: '51', id: 'PE' },
  { value: 'Philippines', prefix: '63', id: 'PH' },
  { value: 'Poland', prefix: '48', id: 'PL' },
  { value: 'Portugal', prefix: '351', id: 'PT' },
  { value: 'Puerto Rico', prefix: '1787', id: 'PR' },
  { value: 'Qatar', prefix: '974', id: 'QA' },
  { value: 'Romania', prefix: '40', id: 'RO' },
  { value: 'Russian Federation', prefix: '7', id: 'RU' },
  { value: 'Rwanda', prefix: '250', id: 'RW' },
  { value: 'Saint Kitts and Nevis', prefix: '1869', id: 'KN' },
  { value: 'Saint Lucia', prefix: '1758', id: 'LC' },
  { value: 'Saint Vincent and the Grenadines', prefix: '1784', id: 'VC' },
  { value: 'Samoa', prefix: '685', id: 'WS' },
  { value: 'San Marino', prefix: '378', id: 'SM' },
  { value: 'Sao Tome and Principe', prefix: '239', id: 'ST' },
  { value: 'Saudi Arabia', prefix: '966', id: 'SA' },
  { value: 'Senegal', prefix: '221', id: 'SN' },
  { value: 'Serbia', prefix: '381', id: 'RS' },
  { value: 'Seychelles', prefix: '248', id: 'SC' },
  { value: 'Sierra Leone', prefix: '232', id: 'SL' },
  { value: 'Singapore', prefix: '65', id: 'SG' },
  { value: 'Slovakia', prefix: '421', id: 'SK' },
  { value: 'Slovenia', prefix: '386', id: 'SI' },
  { value: 'Solomon Islands', prefix: '677', id: 'SB' },
  { value: 'Somalia', prefix: '252', id: 'SO' },
  { value: 'South Africa', prefix: '27', id: 'ZA' },
  { value: 'Spain', prefix: '34', id: 'ES' },
  { value: 'Sri Lanka', prefix: '94', id: 'LK' },
  { value: 'Sudan', prefix: '249', id: 'SD' },
  { value: 'Suriname', prefix: '597', id: 'SR' },
  { value: 'Swaziland', prefix: '268', id: 'SZ' },
  { value: 'Sweden', prefix: '46', id: 'SE' },
  { value: 'Switzerland', prefix: '41', id: 'CH' },
  { value: 'Syrian Arab Republic', prefix: '963', id: 'SY' },
  { value: 'Taiwan, Province of China', prefix: '886', id: 'TW' },
  { value: 'Tajikistan', prefix: '992', id: 'TJ' },
  { value: 'Tanzania, United Republic of', prefix: '255', id: 'TZ' },
  { value: 'Thailand', prefix: '66', id: 'TH' },
  { value: 'Timor-Leste', prefix: '670', id: 'TL' },
  { value: 'Togo', prefix: '228', id: 'TG' },
  { value: 'Tokelau', prefix: '690', id: 'TK' },
  { value: 'Tonga', prefix: '676', id: 'TO' },
  { value: 'Trinidad and Tobago', prefix: '1868', id: 'TT' },
  { value: 'Tunisia', prefix: '216', id: 'TN' },
  { value: 'Turkey', prefix: '90', id: 'TR' },
  { value: 'Turkmenistan', prefix: '993', id: 'TM' },
  { value: 'Turks and Caicos Islands', prefix: '1649', id: 'TC' },
  { value: 'Tuvalu', prefix: '688', id: 'TV' },
  { value: 'Uganda', prefix: '256', id: 'UG' },
  { value: 'Ukraine', prefix: '380', id: 'UA' },
  { value: 'United Arab Emirates', prefix: '971', id: 'AE' },
  { value: 'United Kingdom', prefix: '44', id: 'GB' },
  { value: 'United States', prefix: '1', id: 'US' },
  { value: 'Uruguay', prefix: '598', id: 'UY' },
  { value: 'Uzbekistan', prefix: '998', id: 'UZ' },
  { value: 'Vanuatu', prefix: '678', id: 'VU' },
  { value: 'Venezuela', prefix: '58', id: 'VE' },
  { value: 'Viet Nam', prefix: '84', id: 'VN' },
  { value: 'Virgin Islands, British', prefix: '1284', id: 'VG' },
  { value: 'Virgin Islands, U.S.', prefix: '1340', id: 'VI' },
  { value: 'Wallis and Futuna', prefix: '681', id: 'WF' },
  { value: 'Yemen', prefix: '967', id: 'YE' },
  { value: 'Zambia', prefix: '260', id: 'ZM' },
  { value: 'Zimbabwe', prefix: '263', id: 'ZW' },
];

export const useValidateCountryPrefix = ({
  phoneNumber,
  country,
}: {
  phoneNumber: string;
  country: string;
}) => {
  const { billingInformation } = useBilling();
  const { getCountryCode } = useCountriesOptions();
  const contractCountryCode = getCountryCode(billingInformation?.country ?? '');

  const cleanPhoneNumber = (number: string): string => {
    return number.replace(/\s+/g, '');
  };

  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);

  const hasCountryPrefix = (phoneNumber: string): boolean => {
    const sanitizedNumber = phoneNumber.replace(/[^\d+]/g, '');

    return countriesWithPrefixes.some((country) => {
      const prefixWithPlus = `+${country.prefix}`;
      const prefixWithoutPlus = country.prefix;

      if (sanitizedNumber.startsWith(prefixWithPlus)) {
        const remainingNumber = sanitizedNumber.slice(prefixWithPlus.length);
        return /^\d+$/.test(remainingNumber);
      }

      if (sanitizedNumber.startsWith(prefixWithoutPlus)) {
        const remainingNumber = sanitizedNumber.slice(prefixWithoutPlus.length);
        return /^\d+$/.test(remainingNumber);
      }

      return false;
    });
  };

  const getCountryCodeFromPrefix = (phoneNumber: string): string => {
    const sanitizedNumber = phoneNumber.replace(/[^\d+]/g, '');

    for (const country of countriesWithPrefixes) {
      const prefixWithPlus = `+${country.prefix}`;
      const prefixWithoutPlus = country.prefix;

      if (
        sanitizedNumber.startsWith(prefixWithPlus) ||
        sanitizedNumber.startsWith(prefixWithoutPlus)
      ) {
        return country.id;
      }
    }

    return '';
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    if (hasCountryPrefix(phoneNumber)) {
      return phoneNumber;
    }

    if (country) {
      const countryData = countriesWithPrefixes.find(
        (c) => c.value.toLowerCase() === country.toLowerCase()
      );
      if (countryData) {
        return `+${countryData.prefix}${phoneNumber}`;
      }
    }

    if (contractCountryCode) {
      const countryData = countriesWithPrefixes.find(
        (c) => c.id.toLowerCase() === contractCountryCode.toLowerCase()
      );
      if (countryData) {
        return `+${countryData.prefix}${phoneNumber}`;
      }
    }

    return phoneNumber;
  };

  const contactPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);

  let countryCode = '';

  if (hasCountryPrefix(cleanedPhoneNumber)) {
    countryCode = getCountryCodeFromPrefix(cleanedPhoneNumber);
  } else if (country) {
    const countryData = countriesWithPrefixes.find(
      (c) => c.value.toLowerCase() === country.toLowerCase()
    );
    if (countryData) {
      countryCode = countryData.id;
    }
  }

  if (!countryCode && contractCountryCode) {
    countryCode = contractCountryCode;
  }

  return {
    contactPhoneNumber,
    countryCode,
  };
};

/*

1. The `formatPhoneNumber` function first checks if the number already has a prefix using the existing `hasCountryPrefix` function. If it does, it returns the number without modification.

2. If it doesn't have a prefix, it follows this logic:
   - First, it checks if there's a `country` provided and searches in `countriesWithPrefixes` for the country value (ignoring case)
   - If it finds the country, it adds the prefix with the format `+{prefix}{number}`

3. If there's no country or it wasn't found, it checks if there's a `contractCountryCode`:
   - It searches in `countriesWithPrefixes` for the country ID (ignoring case)
   - If it finds a match, it adds the prefix with the format `+{prefix}{number}`

4. If no match is found, it returns the original number without modification.

*/

//cspell:enable
