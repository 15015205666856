import { useCallback } from 'react';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { WHATSAPP_PREFIX } from '@/src/modules/CampaignsModule/constants';

export const useInboxUtils = () => {
  const enableWhatsAppModule = useFeatureFlag('enableWhatsAppModule');

  const isWhatsAppAction = useCallback(
    (actionName: string): boolean => {
      return actionName.startsWith(WHATSAPP_PREFIX) && enableWhatsAppModule;
    },
    [enableWhatsAppModule]
  );

  return {
    events: {
      isWhatsAppAction,
    },
  };
};
