import { SimpleColumnSkeleton } from '..';

export const SummaryMetricsSkeleton = ({ columns = 2 }) => {
  return (
    <div className="flex flex-row justify-between">
      {new Array(columns).fill('').map((_, key) => (
        <SimpleColumnSkeleton key={key} />
      ))}
    </div>
  );
};
