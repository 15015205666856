import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '@/lib/components/ActionBar';
import { openModal } from '@/lib/components/Modal/Modal';
import {
  DeleteIconSolid,
  FlowsIcon,
  GroupAddIconSolid,
  SuspendContactIcon,
  TagAddIconSolid,
} from '@/lib/icon';
import { DropdownMailIcon } from '@/lib/icon/DropdownMailIcon';
import { DropdownPush } from '@/lib/icon/DropdownPushIcon';
import { DropdownSmsIcon } from '@/lib/icon/DropdownSmsIcon';
import { Button, IconSvg, Modal } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';
import { ReactivateIcon } from '@/lib/v2/icons/solid';

import {
  ContactsToFlowModal,
  DeleteModal,
  SuspendContactModal,
} from '@/src/ContactsModule/components/ContactsModals';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { ActionConfirmModal } from '@/modules/ContactsModule/components';

export const dropdownOptions = {
  sendingType: [
    {
      title: 'Send Email',
      icon: <DropdownMailIcon />,
      onAction: () => {},
    },
    {
      title: 'Send Sms',
      icon: <DropdownSmsIcon />,
      onAction: () => {},
    },
    {
      title: 'Push Notifications',
      icon: <DropdownPush />,
      onAction: () => {},
    },
  ],
};

interface IContactsActionBarProps {
  contactSelection: number;
  flowsLists?: Option[];
  setFlowId: Dispatch<SetStateAction<number>>;
  setShowGroupsModal: Dispatch<SetStateAction<boolean>>;
  setShowTagsModal: Dispatch<SetStateAction<boolean>>;
  setConfirmDelete: Dispatch<SetStateAction<boolean>>;
  setConfirmSuspend: Dispatch<SetStateAction<boolean>>;
  setConfirmToFlow: Dispatch<SetStateAction<boolean>>;
  onReactivateDiscardedUsers: () => void;
  isValidReactivateDiscardedUsers?: boolean;
  discardedView?: boolean;
}

export const ContactsActionBar = ({
  flowsLists,
  contactSelection,
  setShowGroupsModal,
  setShowTagsModal,
  setConfirmDelete,
  setConfirmSuspend,
  setConfirmToFlow,
  setFlowId,
  isValidReactivateDiscardedUsers,
  onReactivateDiscardedUsers,
  discardedView,
}: IContactsActionBarProps) => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reactivateUsersModalIsOpen, setReactivateUsersModalIsOpen] = useState(false);
  const [childrenModal, setChildrenModal] = useState<ReactNode>(null);

  const handleReactivateDiscardedUsers = useCallback(() => {
    setReactivateUsersModalIsOpen(true);
  }, []);

  const iconsActions = [
    {
      icon: <IconSvg height="28px" svgComponent={<TagAddIconSolid />} width="28px" />,
      handleOnClick: () => setShowTagsModal(true),
      tooltip: t('CONTACTS_ACTIONS_DROPDOWN.AddToTag'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'action-add-tag',
    },
    {
      icon: <IconSvg height="28px" svgComponent={<GroupAddIconSolid />} width="28px" />,
      handleOnClick: () => setShowGroupsModal(true),
      tooltip: t('CONTACTS_ACTIONS_DROPDOWN.AddToGroup'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'action-add-group',
    },
    {
      icon: <FlowsIcon />,
      handleOnClick: () =>
        //TO DO: change this openModal to new Modal component v2
        openModal({
          element: (
            <ContactsToFlowModal
              itemList={flowsLists ?? []}
              setConfirmToFlow={setConfirmToFlow}
              setFlowId={setFlowId}
            />
          ),
        }),
      tooltip: t('CONTACT_FLOW_MODAL.toolTip'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'action-connect-flow',
    },
    {
      icon: <SuspendContactIcon />,
      handleOnClick: () => {
        setChildrenModal(
          <SuspendContactModal
            onClick={() => {
              setConfirmSuspend(true);
              setModalIsOpen(false);
            }}
          />
        );
        setModalIsOpen(true);
      },
      tooltip: t('CONTACT_SUSPEND_MODAL.title'),
      flagOn: !discardedView,
      disabled: false,
      disabledTooltip: '',
      id: 'action-suspend-contact',
    },
    {
      icon: <IconSvg height="30px" svgComponent={<ReactivateIcon />} width="30px" />,
      handleOnClick: handleReactivateDiscardedUsers,
      tooltip: t('REACTIVATE_DISCARDED_USER.tooltip'),
      flagOn: discardedView,
      disabled: !isValidReactivateDiscardedUsers,
      disabledTooltip: t('REACTIVATE_DISCARDED_USER.tooltipDisabled'),
      id: 'action-reactivate-contact',
    },
    {
      icon: <DeleteIconSolid />,
      handleOnClick: () =>
        //TO DO: change this openModal to new Modal component v2
        openModal({
          element: (
            <DeleteModal
              description={t(
                contactSelection === 1
                  ? 'CONTACT_DELETE_MODAL.description'
                  : 'CONTACT_DELETE_MODAL.descriptionPlural'
              )}
              setConfirmDelete={setConfirmDelete}
              title={t(
                contactSelection === 1
                  ? 'CONTACT_DELETE_MODAL.title'
                  : 'CONTACT_DELETE_MODAL.titlePlural'
              )}
            />
          ),
        }),
      tooltip: t(
        contactSelection === 1 ? 'CONTACT_DELETE_MODAL.title' : 'CONTACT_DELETE_MODAL.titlePlural'
      ),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
      id: 'action-delete',
    },
  ];

  const reactivateDiscardedUsers = () => {
    onReactivateDiscardedUsers();
    setReactivateUsersModalIsOpen(false);
  };

  return (
    <ActionBar>
      <div>
        <section className="flex h-full items-center">
          <div className="text-xs ml-10 text-white" style={{ fontSize: '12px' }}>
            {`${quantifier(contactSelection) || ''} ${t('CONTACTS_ACTIONS_DROPDOWN.Selected')}`}
          </div>
          <div className="ml-5 flex">
            {iconsActions.map((element) => {
              return (
                element.flagOn && (
                  <div key={element.id} className="mr-2">
                    <Button
                      outline
                      primary
                      standard
                      disabled={element.disabled}
                      iconLeft={element.icon}
                      id={element.id}
                      tooltip={element.disabled ? element.disabledTooltip : element.tooltip}
                      onClick={element.handleOnClick}
                    />
                  </div>
                )
              );
            })}
          </div>
        </section>
      </div>
      <Modal open={modalIsOpen} onClose={setModalIsOpen}>
        {childrenModal}
      </Modal>
      <ActionConfirmModal
        description={t('REACTIVATE_DISCARDED_USER.description')}
        isOpen={reactivateUsersModalIsOpen}
        textButton={t('REACTIVATE_DISCARDED_USER.reactivate')}
        title={t('REACTIVATE_DISCARDED_USER.title')}
        onClick={reactivateDiscardedUsers}
        onClose={setReactivateUsersModalIsOpen}
      />
    </ActionBar>
  );
};
