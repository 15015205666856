import classNames from 'classnames';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { If } from '@/lib/v2/components/If';
import { CloseIcon } from '@/lib/v2/icons/outline';

import { useAlertIcon } from './hooks/useAlertIcon';
import type { AlertProps } from '.';
import { FILL_COLOR } from './types';

import './Alert.tailwind.css';

const Alert = ({
  type = 'success',
  title,
  onClose,
  textButton,
  onClickButton,
  textButtonSecondary,
  onClickButtonSecondary,
  children,
}: AlertProps) => {
  const alertIcon = useAlertIcon(type);

  const classes = classNames(
    `flex gap-3 p-4 relative w-full rounded-lg border border-${type} border border-l-[6px]`,
    `bg-alert-${type}`
  );

  const hasButtons = textButton || textButtonSecondary;

  return (
    <div className={classes} data-testid="alert">
      <div className="-mt-0.5 size-6 shrink-0">
        <IconSvg fillColor={FILL_COLOR[type]} height="28px" svgComponent={alertIcon} width="32px" />
      </div>
      <div className="min-w-0 flex-1">
        <Text as="h2" color="dark" fontWeight="medium">
          {title}
        </Text>
        <If condition={!!children}>
          <Text as="p" className="mt-2 pr-6" color="dark" fontWeight="normal" variant="text">
            {children}
          </Text>
        </If>
        <If condition={!!hasButtons}>
          <div className="mt-4 flex items-center justify-end gap-6">
            <If condition={!!textButtonSecondary && !!onClickButtonSecondary}>
              <Link
                className="text-14 font-medium leading-tight text-emblue-text"
                to="#"
                onClick={onClickButtonSecondary}
              >
                {textButtonSecondary}
              </Link>
            </If>
            <If condition={!!textButton && !!onClickButton}>
              <Button gray outline size="small" onClick={onClickButton}>
                {textButton}
              </Button>
            </If>
          </div>
        </If>
      </div>
      <If condition={!!onClose}>
        <Button onlyIcon standard className="absolute right-4 top-2" onClick={onClose}>
          <IconSvg fillColor="gray-dark" height="12px" svgComponent={<CloseIcon />} width="12px" />
        </Button>
      </If>
    </div>
  );
};

export default memo(Alert);
