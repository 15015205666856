import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { INTEGRATIONS_PATHS } from '@/src/modules/IntegrationsModule/constants';

import RouterSkeleton from '@/modules/AutomationModuleNew/components/RouterSkeleton';

const IntegrationHome = lazy(
  () => import('@/src/modules/IntegrationsModule/screens/IntegrationHome/IntegrationHome')
);

const CreateOrEditIntegrationScreen = lazy(
  () =>
    import(
      '@/src/modules/IntegrationsModule/screens/CreateOrEditIntegrationScreen/CreateOrEditIntegrationScreen'
    )
);

const IntegrationReportScreen = lazy(
  () =>
    import(
      '@/src/modules/IntegrationsModule/screens/IntegrationReportScreen/IntegrationReportScreen'
    )
);

const IntegrationsModuleRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route element={<IntegrationHome />} path="/" />
        <Route element={<CreateOrEditIntegrationScreen />} path=":id" />
        <Route element={<IntegrationReportScreen />} path={`:id/${INTEGRATIONS_PATHS.REPORT}`} />
      </Routes>
    </Suspense>
  );
};

export default IntegrationsModuleRouter;
