import { Listbox, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { t } from 'i18next';
import _ from 'lodash';
import {
  ChangeEvent,
  forwardRef,
  Fragment,
  KeyboardEvent,
  memo,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconSvg, Input } from '@/lib/v2/components';
import { withController } from '@/lib/v2/HOCs/WithController';

import CreateItem from './CreateItem';
import SelectedOptions from './SelectedOptions';
import SelectGroup from './SelectGroup';
import SelectOption from './SelectOption';

import './Select.tailwind.css';

import { SenderStatus } from '@/modules/SendersModule/interfaces/Senders';
export interface Option extends Object {
  id: number | string;
  name: string | number;
  value?: string | number | boolean;
  group?: string;
  groupValue?: string;
  disabled?: boolean;
  iconStatus?: SenderStatus;
}

export interface SelectProps {
  name?: string;
  options: Option[];
  label?: string;
  multiSelect?: boolean;
  value?: Option | Option[];
  onChange?: (value: Option | Option[]) => void;
  onCreate?: (toCreate: string) => void;
  defaultValue?: Option;
  placeholder?: string;
  error?: boolean;
  message?: string;
  isLoading?: boolean;
  orderBy?: 'asc' | 'desc';
  withSearch?: boolean;
  searchPlaceholder?: string;
  id?: string;
  readOnly?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  /** active input to create item */
  withInputCreate?: boolean;
  /** label or title to create item with input */
  labelInputCreate?: string;
  /** placeholder input to create item */
  placeHolderInputCreate?: string;
  /** message of no result in search */
  emptyResults?: string;
  classNameMenuContainer?: string;
  withoutDefaultOption?: boolean;
}

const Select = forwardRef(
  (
    {
      label,
      multiSelect,
      onCreate,
      value,
      onChange,
      options,
      defaultValue,
      placeholder = t('SELECT.selectItem'),
      name,
      error,
      message,
      isLoading,
      orderBy,
      withSearch = false,
      searchPlaceholder,
      id,
      readOnly,
      disabled,
      isRequired,
      withInputCreate = false,
      labelInputCreate = 'Create item',
      emptyResults = t('IMPORT_MAIN.empty_results'),
      placeHolderInputCreate,
      classNameMenuContainer,
      withoutDefaultOption = false,
    }: SelectProps,
    ref: Ref<HTMLElement>
  ) => {
    const [search, setSearch] = useState('');
    const [debounceSearch, setDebounceSearch] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [filterOptions, setFilterOptions] = useState<Record<string, Option[]> | Option[]>();

    const inputSearchRef = useRef<HTMLInputElement>(null);

    const order = (mode: 'asc' | 'desc', a: string | number, b: string | number) => {
      if (mode === 'asc') return a > b ? 1 : -1;
      else return a < b ? 1 : -1;
    };

    const filteredSelectableOptions = useMemo(() => {
      if (!multiSelect && !Array.isArray(value)) {
        const selectedOptionId = (value as Option)?.name;
        if (!selectedOptionId || selectedOptionId === placeholder) {
          return options.filter((option) => option.name !== placeholder);
        }
      }
      return options;
    }, [options, value, multiSelect, placeholder]);

    const optionsWithGroup = useMemo(() => {
      if (!options[0]?.group) return {};

      const newOptions = filteredSelectableOptions.reduce((prevValue, currentValue) => {
        const group = currentValue.group ? currentValue.group : '';
        return {
          ...prevValue,
          [group]: !prevValue[group] ? [currentValue] : [...prevValue[group], currentValue],
        };
      }, {} as Record<string, Option[]>);

      let resultOptions = newOptions;

      if (orderBy) {
        const orderedOptions = Object.keys(newOptions)
          .sort((keyA, keyB) => {
            return order(orderBy, keyA, keyB);
          })
          .reduce((acc, key) => {
            acc[key] = newOptions[key];
            return acc;
          }, {} as Record<string, Option[]>);

        const orderedOptionsValueByName = Object.fromEntries(
          Object.entries(orderedOptions).map(([key, valueOption]) => [
            key,
            _.cloneDeep(valueOption).sort((a, b) => {
              return order(orderBy, a.name, b.name);
            }),
          ])
        );

        resultOptions = orderedOptionsValueByName;
      }

      return resultOptions;
    }, [options, orderBy, filteredSelectableOptions]);

    const optionsValues = useMemo(() => {
      let optionsResult = filteredSelectableOptions;

      if (orderBy) {
        const orderOptions = filteredSelectableOptions.sort((a, b) => {
          return order(orderBy, a.name, b.name);
        });
        optionsResult = orderOptions;
      }

      return optionsResult;
    }, [filteredSelectableOptions, orderBy]);

    const [groupsOpen, setGroupsOpen] = useState<boolean[]>(
      new Array(Object.keys(optionsWithGroup).length).fill(false)
    );

    const classes = classNames('eb-select');
    const classesSelect = classNames('eb-select--select', 'min-w-full', 'overflow-x-hidden', {
      'eb-select--error': error,
      readOnly,
      disabled: disabled && !readOnly,
    });
    const classesMessage = classNames(error ? 'message-error' : 'eb-select--message-info');
    const classesMenuOptions = classNames(
      'sm:text-sm absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none',
      classNameMenuContainer
    );

    const deleteOption = useCallback(
      (index: number) => () => {
        if (Array.isArray(value)) {
          const newOptions = [...value];
          newOptions.splice(index, 1);
          onChange?.(newOptions);
        }
      },
      [onChange, value]
    );

    const withGroup = useMemo(() => {
      return Object.keys(optionsWithGroup).length > 0;
    }, [optionsWithGroup]);

    const handleOpenGroup = useCallback(
      (indexGroupClicked: number) => {
        const copyGroupsOpen = [...groupsOpen];
        copyGroupsOpen[indexGroupClicked] = !copyGroupsOpen[indexGroupClicked];
        setGroupsOpen(copyGroupsOpen);
      },
      [groupsOpen]
    );

    const getFilterOptionsGroups = useCallback(() => {
      const filterOptionsValues = Object.fromEntries(
        Object.entries(optionsWithGroup).map(([key, valueOption]) => {
          const filterValueOption = valueOption.filter((opt) =>
            String(opt.name).toLowerCase().includes(debounceSearch.toLowerCase())
          );

          return [key, filterValueOption];
        })
      );

      return filterOptionsValues;
    }, [debounceSearch, optionsWithGroup]);

    const getFilterOptionsValues = useCallback(() => {
      const filterOptionsValues = filteredSelectableOptions.filter((opt) =>
        String(opt.name).toLowerCase().includes(debounceSearch.toLowerCase())
      );

      return filterOptionsValues;
    }, [debounceSearch, filteredSelectableOptions]);

    const openGroups = useCallback(
      (open: boolean) =>
        setGroupsOpen(_.cloneDeep(new Array(Object.keys(optionsWithGroup).length).fill(open))),
      [optionsWithGroup]
    );

    const autoFocusSearch = useCallback(() => {
      inputSearchRef.current?.focus();
    }, []);

    const hideCreateItem = useCallback(() => {
      setIsCreating(false);
    }, []);

    const showCreateItem = useCallback(() => {
      setIsCreating(true);
    }, []);

    const clearAllInputs = () => {
      setDebounceSearch('');
      setSearch('');
      setIsCreating(false);
    };

    const handleKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.stopPropagation();
      }
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounceSearch(search);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [search]);

    useEffect(() => {
      const filterOptionsValues = withGroup ? getFilterOptionsGroups() : getFilterOptionsValues();
      setFilterOptions(filterOptionsValues);
      debounceSearch.length > 0 ? openGroups(true) : openGroups(false);
    }, [
      debounceSearch,
      getFilterOptionsGroups,
      getFilterOptionsValues,
      openGroups,
      optionsWithGroup,
      withGroup,
    ]);

    const isSearching = withSearch && debounceSearch.length > 0;

    const isSelectAnItem = value && !Array.isArray(value) && value.name === placeholder;

    const handleOnItemCreated = useCallback(
      (value: string) => {
        onCreate?.(value);
        clearAllInputs();
      },
      [onCreate]
    );

    if (isLoading) {
      return (
        <div className="flex w-full animate-pulse flex-col" data-testid="select-component">
          {label && <div className="mt-1.5 h-2.5 w-28 rounded-full bg-gray-200"></div>}
          <div className="my-1 h-[38px] w-full rounded bg-gray-200"></div>
        </div>
      );
    }

    const haveOptions = (isSearching ? (filterOptions as Option[]) : optionsValues).length > 0;
    const defaultValueOption = withoutDefaultOption ? undefined : { id: 0, name: placeholder };

    return (
      <Listbox
        by="id"
        data-testid="select-component"
        multiple={multiSelect}
        name={name}
        value={value ?? defaultValueOption}
        onChange={onChange}
        {...(defaultValue && { defaultValue })}
        ref={ref}
        disabled={disabled || readOnly}
      >
        {({ open }) => (
          <div className={classes}>
            {label && (
              <Listbox.Label className="label">
                {label} {isRequired && <span className="text-red-500"> * </span>}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={classesSelect}
                id={id}
                onClick={(e) => e.stopPropagation()}
              >
                {placeholder &&
                  ((Array.isArray(value) && value.length === 0) ||
                    value === undefined ||
                    (!Array.isArray(value) &&
                      typeof value === 'object' &&
                      String(value.name) === '')) && (
                    <span className="block truncate text-emblue-disabled">{placeholder}</span>
                  )}
                {value && Array.isArray(value) && (
                  <span
                    className={`block truncate ${isSelectAnItem ? 'text-emblue-disabled' : ''}`}
                  >
                    <SelectedOptions deleteOption={deleteOption} value={value} />
                  </span>
                )}
                {value && !Array.isArray(value) && (
                  <span
                    dangerouslySetInnerHTML={{ __html: value?.name }}
                    className={`block truncate ${isSelectAnItem ? 'text-emblue-disabled' : ''}`}
                  />
                )}

                {defaultValue && !value && (
                  <span className="block truncate">{defaultValue?.name}</span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={`size-5 text-gray-400${open ? 'rotate-180' : ''} transition-all`}
                  />
                </span>
              </Listbox.Button>

              <Transition
                afterEnter={autoFocusSearch}
                afterLeave={hideCreateItem}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options static className={classesMenuOptions}>
                  {withInputCreate && (
                    <CreateItem
                      isCreating={isCreating}
                      placeHolder={placeHolderInputCreate}
                      title={labelInputCreate}
                      onCreateItemClick={showCreateItem}
                      onItemCreated={handleOnItemCreated}
                    />
                  )}
                  {withSearch && !isCreating && (
                    <div className="relative w-full px-2">
                      <Input
                        ref={inputSearchRef}
                        iconLeft={<IconSvg strokeColor="gray" svgComponent={<SearchIcon />} />}
                        id={`${id ?? ''}-search`}
                        placeHolder={searchPlaceholder}
                        value={search}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                        onKeyDown={handleKeyEvent}
                      />
                    </div>
                  )}
                  {!isCreating &&
                    // eslint-disable-next-line no-nested-ternary
                    (withGroup ? (
                      Object.entries(
                        isSearching ? (filterOptions as Record<string, Option[]>) : optionsWithGroup
                      ).map(([group, groupValues], index) => {
                        return (
                          <SelectGroup
                            key={group}
                            groupName={group}
                            index={index}
                            isOpened={groupsOpen[index]}
                            optionsGroup={groupValues}
                            onOpen={handleOpenGroup}
                          />
                        );
                      })
                    ) : haveOptions ? (
                      (isSearching ? (filterOptions as Option[]) : optionsValues).map((option) => (
                        <SelectOption key={option.id} option={option} />
                      ))
                    ) : (
                      <span className="block truncate py-2 text-center text-emblue-disabled">
                        {emptyResults}
                      </span>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
            <p className={classesMessage} id={name && `${name}-message`}>
              {message}
            </p>
          </div>
        )}
      </Listbox>
    );
  }
);

export default memo(withController(Select));
