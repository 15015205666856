import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { atomMetricGraph, atomMetricType } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import {
  BAR_GRAPH_CONFIG,
  COLOR_METRICS_HISTORY,
  METRICS_HISTORY,
} from '@/src/modules/InsightsModule/constants';
import { formatNumberToMetricPrefix } from '@/src/utils/FormatNumberToMetricPrefix';

import { ShippingGraphTooltip } from './components/ShippingGraphTooltip';

const ShippingGraph = () => {
  const metricType = useAtomValue(atomMetricType);
  const metricGraph = useAtomValue(atomMetricGraph);

  const colorMapping = COLOR_METRICS_HISTORY[metricType];
  const metricMapping = METRICS_HISTORY[metricType];

  const dataChart = useMemo(
    () =>
      metricGraph.map((item, key) => {
        return {
          key: key + 1,
          opens: item.opens,
          bounces: item.bounces,
          clicks: item.clicks,
          delivered: item.delivered,
          unsubscribes: item.unsubscribes,
          date: item.date,
        };
      }),
    [metricGraph]
  );

  return (
    <div className="flex h-96">
      <ResponsiveContainer height="100%" width="100%">
        <AreaChart data={dataChart}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            axisLine={false}
            dataKey="key"
            style={BAR_GRAPH_CONFIG.labels}
            tickFormatter={(value: number) => formatNumberToMetricPrefix(Number(value))}
            tickLine={false}
          />
          <YAxis
            axisLine={false}
            style={BAR_GRAPH_CONFIG.labels}
            tickFormatter={(value: number) => formatNumberToMetricPrefix(Number(value))}
            tickLine={false}
          />
          <Tooltip content={<ShippingGraphTooltip />} cursor={{ fill: 'transparent' }} />
          {metricMapping.map((item, key) => (
            <Area
              key={item}
              dataKey={item}
              fill={colorMapping[key]}
              stackId={key}
              stroke={colorMapping[key]}
              type="monotone"
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShippingGraph;
