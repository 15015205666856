import { useAtomValue } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, IconSvg, Text } from '@/lib/v2/components';
import { CalendarIcon, StatusCampaignIcon, UserIcon } from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { IActionsFooterItem } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EStates } from '@/modules/CampaignsModule/interfaces/Campaigns';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils';

const Footer = () => {
  const { t } = useTranslation();
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const showNewAutomationModule = useFeatureFlag('showNewAutomationModule');

  const infoCreator = useMemo(() => {
    const { creatorUser } = detailCampaign;

    if (creatorUser?.name && creatorUser?.lastName)
      return `${creatorUser.name} ${creatorUser.lastName}`;

    if (creatorUser?.email) return creatorUser.email;

    return t('CAMPAIGN_ACTIONS_MAIN.HEADER.WithoutData');
  }, [detailCampaign, t]);

  const rangeDate = useMemo(() => {
    const { startDate, endDate } = detailCampaign;

    if (!startDate || !endDate) return t('CAMPAIGN_ACTIONS_MAIN.HEADER.WithoutData');

    const startDateFormatted = formatDate(startDate, DateFormat.ShortDate);
    const endDateFormatted = formatDate(endDate, DateFormat.ShortDate);

    return `${startDateFormatted} - ${endDateFormatted}`;
  }, [detailCampaign, t]);

  const state = useMemo(() => {
    const { state } = detailCampaign;
    return state === EStates.ACTIVE
      ? t('CAMPAIGNS_MAIN.STATES.active')
      : t('CAMPAIGNS_MAIN.STATES.inactive');
  }, [detailCampaign, t]);

  const footerItems: IActionsFooterItem[] = useMemo(() => {
    const items = [];

    if (!showNewAutomationModule) {
      items.push({
        id: crypto.randomUUID(),
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.state'),
        value: state,
        icon: <IconSvg strokeColor="gray" svgComponent={<StatusCampaignIcon />} />,
      });
    }

    items.push(
      {
        id: crypto.randomUUID(),
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.creator'),
        value: infoCreator,
        icon: <IconSvg fillColor="gray" svgComponent={<UserIcon />} />,
      },
      {
        id: crypto.randomUUID(),
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.duration'),
        value: rangeDate,
        icon: <IconSvg strokeColor="gray" svgComponent={<CalendarIcon />} />,
      }
    );

    return items;
  }, [showNewAutomationModule, t, state, infoCreator, rangeDate]);

  return (
    <footer
      className={`grid ${
        showNewAutomationModule ? 'grid-cols-2' : 'grid-cols-3'
      } rounded-b-lg bg-[#F9FAFB]`}
    >
      {footerItems.map((item) => (
        <Flex
          key={item.id}
          withGap
          alignment="center"
          className="border-r-2 p-3 last-of-type:border-r-0"
          gapSize="small"
          textAlignment="center"
        >
          <IconSvg svgComponent={item.icon} />
          <Text as="span" className="flex" color="dark" variant="text">
            {item.label} {item.value}
          </Text>
        </Flex>
      ))}
    </footer>
  );
};

export default memo(Footer);
