import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Input, TimeInput } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

import {
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomDeliveryInformation,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import useSchedulingForm, {
  SchedulingFormState,
} from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useSchedulingForm';
import SubjectButtons from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { NEXT_YEAR_OFFSET } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  IDeliveryActionResponse,
  ISetDeliveryActionPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const ImmediateDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const [userData] = useUserData();
  const { control, RULES, handleSubmit, setValue, getValues, resetField, reset } =
    useSchedulingForm();
  const { saveDeliveryAction, setActionState } = useActionEmailData(Number(actionId));

  const [reinforcementDateState, setReinforcementDateState] = useState<Date | undefined>(undefined);
  const [reinforcementTimeState, setReinforcementTimeState] = useState<Date | undefined>(undefined);
  const [reinforcementMinDateTime, setReinforcementMinDateTime] = useState<Date | undefined>(
    undefined
  );
  const [reinforcementCheckbox, setReinforcementCheckbox] = useState<boolean>(
    deliveryInformation?.reinforcement?.isReinforcementShipment || false
  );
  const [reinforcementTimeMinDateTime, setReinforcementTimeMinDateTime] = useState<
    Date | undefined
  >(undefined);

  const getGMTDate = (date: Date, time: Date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');

    const hour = String(time?.getHours()).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    const minutes = String(time?.getMinutes()).padStart(2, '0');

    return {
      date: `${year}-${month}-${day}`,
      hour: `${hour}:${minutes}`,
    };
  };

  const formatGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );

  const maxDateScheduledDelivery = useMemo(() => {
    if (!formatGMT) return undefined;
    const date = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
    date.setFullYear(date.getFullYear() + NEXT_YEAR_OFFSET);
    return DateConverter.toGMTDate(date.toISOString(), formatGMT);
  }, [formatGMT]);

  useEffect(() => {
    const {
      reinforcement: { subject, startDate: reinforcementStartDate },
    } = deliveryInformation as IDeliveryActionResponse;

    if (!formatGMT) return;

    const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
    const newReinforcementDate = DateConverter.toGMTDate(reinforcementStartDate, formatGMT);

    const minDateTime = new Date(currentDate);
    minDateTime.setDate(minDateTime.getDate() + 1);
    setReinforcementMinDateTime(minDateTime);

    if (reinforcementStartDate) {
      setValue(
        'reinforcementCheckbox',
        deliveryInformation?.reinforcement?.isReinforcementShipment || false
      );
      setValue('reinforcementSubject', subject || '');
      setValue('reinforcementDate', newReinforcementDate);
      setValue('reinforcementTime', newReinforcementDate);
      setReinforcementDateState(newReinforcementDate);
      setReinforcementTimeState(newReinforcementDate);

      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (
        newReinforcementDate.getDate() === tomorrow.getDate() &&
        newReinforcementDate.getMonth() === tomorrow.getMonth() &&
        newReinforcementDate.getFullYear() === tomorrow.getFullYear()
      ) {
        setReinforcementTimeMinDateTime(tomorrow);
      } else {
        setReinforcementTimeMinDateTime(undefined);
      }
    }
  }, [deliveryInformation, formatGMT, setValue]);

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeReinforcement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setReinforcementCheckbox(checked);
      if (!checked) reset();
    },
    [reset]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const inputDate = event as Date;

      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);

      setReinforcementDateState(inputDate);
      setReinforcementTimeState(undefined);
      resetField('reinforcementTime');

      if (
        inputDate.getDate() === tomorrow.getDate() &&
        inputDate.getMonth() === tomorrow.getMonth() &&
        inputDate.getFullYear() === tomorrow.getFullYear()
      ) {
        setReinforcementTimeMinDateTime(tomorrow);
      } else {
        setReinforcementTimeMinDateTime(undefined);
      }
    },
    [formatGMT, resetField]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    const newTime = event as Date;
    setReinforcementTimeState(newTime);
  }, []);

  const handleSubmitData = useCallback(
    async (formData: SchedulingFormState) => {
      setProcessingAction(true);

      const { reinforcementCheckbox, reinforcementSubject, reinforcementDate, reinforcementTime } =
        formData;

      const deliveryReinforcement =
        reinforcementDate && reinforcementTime
          ? getGMTDate(reinforcementDate, reinforcementTime)
          : null;

      const payload: ISetDeliveryActionPayload = {
        actionType: EChannelsID.EMAIL,
        actionId: Number(actionId),
        isImmediateShipment: true,
        reinforcement: {
          subject: reinforcementCheckbox ? reinforcementSubject : null,
          isReinforcementShipment: reinforcementCheckbox ?? false,
          date: deliveryReinforcement ? deliveryReinforcement?.date : null,
          hour: deliveryReinforcement ? deliveryReinforcement.hour : null,
        },
      };

      await saveDeliveryAction(payload);
      await setActionState({
        actionId: Number(actionId),
        statusId: EActionStatusAvailable.CONFIRMABLE_DRAFT,
      });

      setProcessingAction(false);
      navigate(`/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, saveDeliveryAction, setActionState, setProcessingAction, navigate]
  );

  const handleChangeSubjectButton = ({ item }: ISubjectChangeButtonsProps) => {
    const currentSubject = getValues('reinforcementSubject');
    setValue('reinforcementSubject', `${currentSubject} ${item}`);
  };

  return (
    <form
      id="immediate-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="mb-5 flex items-center gap-3">
        <Checkbox
          checked={reinforcementCheckbox}
          color="#D3DDEA"
          control={control}
          disabled={disabled}
          id="reinforcementCheckbox"
          label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.reinforcement')}
          name="reinforcementCheckbox"
          size={16}
          onChange={handleChangeReinforcement}
        />
      </div>
      <div className="mb-4 flex w-3/4 gap-2">
        <Input
          control={control}
          disabled={!reinforcementCheckbox || disabled}
          id="reinforcementSubject"
          name="reinforcementSubject"
          placeHolder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.subjectPlaceholder`)}
          rules={RULES.reinforcementSubject}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={!reinforcementCheckbox || disabled}
            id="email-immediate-buttons"
            searchPlaceholder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex w-200">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={reinforcementDateState}
            disabled={!reinforcementCheckbox || disabled}
            id="reinforcementDate"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            maxDate={maxDateScheduledDelivery}
            minDate={reinforcementMinDateTime}
            name="reinforcementDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.reinforcementDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={reinforcementTimeState}
            disabled={!reinforcementCheckbox || disabled}
            id="reinforcementTime"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={reinforcementTimeMinDateTime}
            name="reinforcementTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.reinforcementTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default ImmediateDeliveryForm;
