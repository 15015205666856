import { useCallback } from 'react';

import { ArrowDownTableIcon, ArrowUpTableIcon } from '@/lib/icon';
import { Flex } from '@/lib/v2/components';

import { OrderColumnState } from './Table';

type OrderColumnProps = {
  columnId: string;
  columnIdSelected?: string;
  isAsc: boolean;
  onClick: (value: OrderColumnState) => void;
};

const OrderColumn = ({ columnId, isAsc, columnIdSelected, onClick }: OrderColumnProps) => {
  const handleOrderColumn = useCallback(() => {
    onClick({ orderByColumn: columnId, orderByDirection: isAsc ? 'desc' : 'asc' });
  }, [columnId, isAsc, onClick]);

  if (columnId !== columnIdSelected) {
    return (
      <button onClick={handleOrderColumn}>
        <Flex column noGrow>
          <div className="pb-1">
            <ArrowUpTableIcon />
          </div>
          <div>
            <ArrowDownTableIcon />
          </div>
        </Flex>
      </button>
    );
  }

  return columnId === columnIdSelected ? (
    <button onClick={handleOrderColumn}>
      <Flex column noGrow>
        <div className="pb-1">
          <ArrowUpTableIcon {...(isAsc && { color: '#364365' })} />
        </div>
        <div>
          <ArrowDownTableIcon {...(!isAsc && { color: '#364365' })} />
        </div>
      </Flex>
    </button>
  ) : null;
};

export default OrderColumn;
