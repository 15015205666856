import { memo } from 'react';

import { If } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import FilterCampaignState from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/components/FilterCampaignState';
import FilterCampaignStrategy from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/components/FilterCampaignStrategy';

const FiltersCampaignDropdown = () => {
  const showNewAutomationModule = useFeatureFlag('showNewAutomationModule');

  return (
    <>
      <If condition={!showNewAutomationModule}>
        <FilterCampaignState />
      </If>
      <FilterCampaignStrategy />
    </>
  );
};

export default memo(FiltersCampaignDropdown);
