import classNames from 'classnames';

interface IGraphSkeletonProps {
  className?: string;
}
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const GraphSkeleton = ({ className }: IGraphSkeletonProps) => {
  const classRoot = classNames(`flex w-full animate-pulse bg-gray-200 h-6`, className);

  return <div className={classRoot}></div>;
};
