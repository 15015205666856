export const API_DOCUMENTATION_LINK = 'https://api.embluemail.com/v2.3/docs/es#section/overview';

export const TRANSLATE_API_MANAGER_NAV = 'MAIN.NAVIGATION';
export const TRANSLATE_API_MANAGER_HEADER = 'MAIN.HEADER';
export const TRANSLATE_API_MANAGER_TABLE = 'MAIN.TABLE';
export const TRANSLATE_API_MANAGER_MODAL = 'MAIN.MODAL';
export const TRANSLATE_API_MANAGER_TOAST = 'MAIN.TOAST';

export const API_MANAGER_FORM_LIMITS = {
  MIN: 2,
  MAX: 60,
};
