import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card, Spinner, Text } from '@/lib/v2/components';

import configData from '@/src/config.json';
import ScoreOverview from '@/src/presentation/components/Main/components/ScoreOverview/ScoreOverview';

import { useSenderScoreLogic } from './hooks/useSenderScore';

const SenderScore = () => {
  const { t } = useTranslation();
  const {
    events: { handleClose, handleOpen },
    state: { scoring, open, scoringClass, label },
  } = useSenderScoreLogic();

  const senderScoreUrl = configData.HOME.SENDER_SCORING_GUIDE_URL;

  return (
    <>
      <Card noPadding noShadow className="!h-auto !flex-row justify-between overflow-hidden">
        {scoring === undefined || isNaN(scoring) ? (
          <div className="item-center  flex flex-col p-8">
            <Spinner withoutOverlay />
          </div>
        ) : (
          <>
            <div className="self-center p-4 align-middle">
              <Text className="mb-2" fontWeight="medium" variant="title-1">
                {t('SENDER_SCORING.title')}
              </Text>
              <Text
                alignment="left"
                className="truncate leading-tight"
                color="black"
                variant="text"
              >
                {label}
              </Text>
              <Link
                className="relative w-full cursor-pointer overflow-hidden  "
                id="insights-sender-score-link"
                rel="noopener noreferrer"
                target="_blank"
                to={senderScoreUrl}
              >
                <Text
                  alignment="left"
                  as="h3"
                  className="truncate pt-1 leading-tight "
                  color="primary"
                  variant="text-sm"
                >
                  {t('SENDER_SCORING.textLink')}
                </Text>
              </Link>
            </div>
            <div className={scoringClass} id="insights-sender-score-modal" onClick={handleOpen}>
              <Text
                alignment="center"
                as="h3"
                className="p-3 leading-tight"
                color="white"
                fontWeight="normal"
                variant="title-3"
              >
                {scoring.toString()}
              </Text>
            </div>
          </>
        )}
      </Card>

      {open && (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center">
          <div className="fixed inset-0 bg-black/25" onClick={handleClose}></div>
          <div className="z-[1000000] rounded-lg bg-white p-8 shadow-lg">
            <ScoreOverview onClose={handleClose} />
          </div>
        </div>
      )}
    </>
  );
};

export default SenderScore;
