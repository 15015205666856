import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo, MouseEvent, ReactNode, useCallback, useMemo } from 'react';

import { Button } from '@/lib/v2/components/Button';
import { Text } from '@/lib/v2/components/Text';

export const StepperUpcomingIcon = () => (
  <span className="relative flex size-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
    <span className="size-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
  </span>
);

export const StepperCurrentIcon = () => (
  <span className="relative flex size-6 items-center justify-center rounded-full border-2 border-emblueGreen bg-white">
    <span className="size-2.5 rounded-full bg-emblueGreen" />
  </span>
);

export const StepperDoneIcon = () => (
  <span className="relative flex size-6 items-center justify-center rounded-full bg-emblueGreen group-hover:bg-emblueGreen">
    <CheckIcon aria-hidden="true" className="size-5 text-white" />
  </span>
);

export type StatusStepperItem = 'upcoming' | 'current' | 'done';

export interface StepperItemProps {
  id: number | string;
  stepName: string;
  status: StatusStepperItem;
  title?: string;
  subTitle?: string;
  description?: string;
  onClickButton?: () => void;
  buttonText?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const StepperItem = ({
  status,
  stepName,
  title,
  subTitle,
  description,
  id,
  buttonText,
  onClickButton,
  children,
  onClick,
}: StepperItemProps) => {
  const icon = useMemo(() => {
    if (status === 'upcoming') return <StepperUpcomingIcon />;
    else if (status === 'current') return <StepperCurrentIcon />;
    else return <StepperDoneIcon />;
  }, [status]);

  const classesLine = classNames('line', {
    'bg-gray-300': status !== 'done',
    'bg-emblueGreen': status === 'done',
  });

  const classesSteep = classNames('flex gap-4');

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick?.();
    },
    [onClick]
  );

  return (
    <div className={'stepper-item hover:cursor-pointer'} id={id?.toString()}>
      <div className={classesSteep}>
        <div
          className="relative pt-1"
          role="button"
          tabIndex={0}
          onClick={handleOnClick}
          onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && onClick?.()}
        >
          <div className={classesLine}></div>
          {icon}
        </div>
        <div className="flex flex-auto flex-col text-left">
          <div
            className="flex flex-col"
            role="button"
            tabIndex={0}
            onClick={handleOnClick}
            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && onClick?.()}
          >
            <Text color="light-black" variant="text-sm">
              {stepName.toUpperCase()}
            </Text>
            <Text color="primary" fontWeight="normal" variant="sub-headline">
              {title}
            </Text>
            <Text color="light-black" variant="text-sm">
              {subTitle}
            </Text>
          </div>
          {status === 'current' && (
            <div className="mt-4 flex w-full flex-col gap-4">
              {description && <Text variant="text">{description}</Text>}
              {buttonText && (
                <Button size="small" onClick={onClickButton}>
                  {buttonText}
                </Button>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(StepperItem);
