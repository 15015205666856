/* eslint-disable @typescript-eslint/no-unused-vars */

import { timeout } from '@/src/compat/util';

import {
  INotificationBase,
  INotificationPayload,
  INotificationsService,
} from './INotificationsService';

export class StubNotificationsService implements INotificationsService {
  static create(): INotificationsService {
    return new StubNotificationsService();
  }

  getNotification<T>(params: INotificationBase): Promise<T | null> {
    return (async () => {
      try {
        await timeout(2000);
        return {} as T;
      } catch (error) {
        return null;
      }
    })();
  }

  saveNotification(params: INotificationPayload): Promise<boolean | null> {
    return (async () => {
      try {
        await timeout(2000);
        return true;
      } catch (error) {
        return null;
      }
    })();
  }
}
