import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import BeefreeModal from '@/src/modules/CampaignsModule/components/BeefreeModal';
import EditorActions from '@/src/modules/CampaignsModule/components/EditorActions';
import { useCustomFIelds } from '@/src/modules/CampaignsModule/hooks/useCustomFields';
import { usePreview } from '@/src/modules/CampaignsModule/hooks/useEditorActions';
import { useEditor, useServicesEditor } from '@/src/modules/CampaignsModule/hooks/useEditorContent';
import { BEEFREE_SIGNATURES } from '@/src/modules/CampaignsModule/utils/beefree';

interface EditorContentProps {
  isAutomation?: boolean;
}
const EditorContent = ({ isAutomation = false }: EditorContentProps) => {
  const [userData] = useUserData();
  const accountId = +userData.companyId;
  const listCustomField = useCustomFIelds();
  const { actionId } = useParams();
  const tagsIdentifier = useMemo(() => ({ identifier: 'TAGS_DROP_DOWN' }), []);
  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, tagsIdentifier);

  const optionsServices = useMemo(
    () => ({
      accountId,
      actionId: actionId ? +actionId : 0,
      listCustomField: listCustomField ?? [],
      tags: tags?.list || null,
      signature: BEEFREE_SIGNATURES,
    }),
    [accountId, actionId, listCustomField, tags]
  );
  const { onSaveMail, onSend, onSaveTemplate, onSaveRow, onRemoveRow } =
    useServicesEditor(optionsServices);

  const optionsEditor = useMemo(
    () => ({
      onSaveRow,
      onSaveMail,
      onSend,
      onSaveTemplate,
      onRemoveRow,
      accountId,
      listCustomField,
      tags: tags?.list || null,
      signature: BEEFREE_SIGNATURES,
    }),
    [accountId, listCustomField, onRemoveRow, onSaveMail, onSaveRow, onSaveTemplate, onSend, tags]
  );
  const { beefree, disabled, saving, saved } = useEditor(optionsEditor);
  const { clearPreview } = usePreview({ beefree });

  useEffect(() => {
    return () => {
      clearPreview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute left-0 top-0 flex h-[calc(100vh-64px)] w-full flex-col">
      <EditorActions
        beefree={beefree}
        disabled={disabled}
        isAutomation={isAutomation}
        saved={saved}
        saving={saving}
      />
      <div key={'beefree'} className="h-screen w-full bg-[#32373a]" id="bee-plugin-container" />
      <BeefreeModal />
    </div>
  );
};

export default memo(EditorContent);
