import { memo } from 'react';

import { CardSection, Flex } from '@/lib/v2/components';

import { SmsAction } from '@/modules/CampaignsModule/components/actionManagement/SmsAction';

const SmsMain = () => {
  return (
    <div className="relative z-0 bg-white">
      <CardSection noPadding>
        <Flex column>
          <SmsAction />
        </Flex>
      </CardSection>
    </div>
  );
};

export default memo(SmsMain);
