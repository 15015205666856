import { IActionState } from '@/src/modules/AutomationModuleNew/interfaces/automation';

export const AUTOMATION_PATHS = {
  MODULE_PATH: 'automation',
  FLOW_DETAIL_PATH: 'flow',
  EVENT_PATH: 'event',
  CREATE_EVENT_PATH: 'event/create',
  RULES_PATH: 'rules',
  JOURNEYS_PATH: 'journeys',
  RECURRENCES_PATH: 'recurring-messages',
  ACTION_REPORT_PATH: 'action-report',
  REPORT_PATH: 'report',
  CONFIG: 'configuration',
  PREVIEW: 'preview',
  EDITOR: 'editor',
};
export const TRANSLATE_MODAL_RENAME = 'AUTOMATION_MAIN_MODULE.RENAME_FLOW';

export const EVENT_TYPES = {
  0: 'Custom',
  1: 'VTEX',
  8: 'Facebook',
  12: 'Tokko Broker',
  99: 'onSite',
};

export const FLOWS_TYPES = {
  0: 'Custom',
  1: 'VTEX',
  8: 'Facebook',
  12: 'Tokko Broker',
};

export const FLOW_ALREADY_EXISTS = 'FLOW_ALREADY_EXISTS';
export const EVENT_ALREADY_EXISTS = 'EVENT_ALREADY_EXISTS';

export const eventValidation = {
  elementPosition: 0,
  domain: '',
  query: '',
  text: '',
  domItems: {
    active: true,
    attributes: [],
  },
  fieldsToTrack: [],
  getElements: true,
};

export const LIST_ACTIONS_STATE: IActionState[] = [
  { type: 'draft', values: [1, 2, 11, 23, 22], badge: 'inactive', background: 'inactive' },
  {
    type: 'active',
    values: [18, 3, 4, 8, 9, 16, 15, 16, 17, 7],
    badge: 'success',
    background: 'success_new',
  },
  { type: 'finished', values: [6, 5, 12], badge: 'frequent', background: 'frequent' },
  { type: 'inactive', values: [19, 20], badge: 'inactive', background: 'inactive' },
];

interface RecurringMessageDetailFilter {
  label: string;
  values: number[];
}

export interface RecurringMessageDetailFilters {
  completed: RecurringMessageDetailFilter;
  draft: RecurringMessageDetailFilter;
  inProgress: RecurringMessageDetailFilter;
}

export const RECURRING_MESSAGE_DETAIL_FILTERS: RecurringMessageDetailFilters = {
  completed: {
    label: 'completed',
    values: [6, 5, 12],
  },
  draft: {
    label: 'draft',
    values: [1, 2, 11, 23, 22],
  },
  inProgress: {
    label: 'inProgress',
    values: [18, 3, 4, 8, 9, 15, 16, 17, 7, 19, 20],
  },
};

export const BACK_URL_KEY = 'backUrl';

export const CAMPAIGN_ACTION_STATES = {
  confirmableDraft: [2, 23],
  draft: [1, 11, 22, 20],
  active: [18, 3, 4, 8, 9, 15, 16, 17, 7],
  inactive: [19],
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const JOURNEY_EMAIL_TEMPLATES_STATES = {
  active: CAMPAIGN_ACTION_STATES.active,
  inactive: [...CAMPAIGN_ACTION_STATES.inactive, ...CAMPAIGN_ACTION_STATES.confirmableDraft],
  draft: CAMPAIGN_ACTION_STATES.draft,
};
