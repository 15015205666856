/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { ChannelType } from '@/src/ContactsModule/interfaces/Interfaces';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IEmblueApiMethodNameMap, IHtmlEditorService } from './IHtmlEditorService';

import {
  IGetElementHtml,
  IGetHtmlEditor,
  IHtmlEditorParamStart,
  ISendElement,
  NpsWidgets,
} from '@/modules/CampaignsModule/interfaces/HTMLEditor';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class HtmlEditorService implements IHtmlEditorService {
  private api: APIService;
  private apiV1: APIService;
  private apiNodeV2: APIService;

  private constructor(payload: IHtmlEditorParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
    this.apiV1 = new APIService(payload.url_ema_app_v1, payload.jwt);
    this.apiNodeV2 = new APIService(payload.url_api_node, payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error HtmlEditorService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of HtmlEditorService
   */
  static create(payload: IHtmlEditorParamStart): IHtmlEditorService {
    return new HtmlEditorService(payload);
  }

  /**
   * @desc Update a email template
   */
  async updateElementHtml(
    sendElement: ISendElement
  ): Promise<{ status: number; error?: string; message?: string } | null> {
    try {
      const { actionId, htmlBody, htmlSource } = sendElement;
      const { status } = await this.api.post(`/api/v2.1/editor/html/save`, {
        contentHtml: htmlBody,
        actionId: actionId,
        htmlSource: htmlSource,
      });
      return { status };
    } catch (error) {
      let message;

      if (error instanceof AxiosError) {
        message = error.response?.data.message[0] || message;
        return { status: error.response?.status || 500, message };
      }

      this.catchErrors(error as AxiosError<Error>, 'updateElementHtml');
      return { status: 500, message };
    }
  }

  /**
   * @desc Update a email template
   */
  async getUrlAndUpdateElementHtml(
    sendElement: IGetHtmlEditor
  ): Promise<{ status: number } | null> {
    try {
      const { actionId, url, code } = sendElement;
      const { status } = await this.api.post(`/api/v2.1/editor/html/save`, {
        contentHtml: '',
        actionId,
        htmlSource: ChannelType.EMAIL,
        urlArgs: {
          url,
          code,
        },
      });
      return { status };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getUrlAndUpdateElementHtml');
      return null;
    }
  }

  /**
   * @desc Get a email template of html editor
   */
  async getElementHtml(actionId: number): Promise<IGetElementHtml | null> {
    try {
      const { data } = await this.api.get(`/api/v2.1/editor/message?actionId=${actionId}`);
      return data as IGetElementHtml;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getElementHtml');
      return null;
    }
  }

  /**
   * @desc Get a email template of html editor
   */
  async getWidgetsList(): Promise<NpsWidgets[]> {
    try {
      const { data }: any = await this.api.get('/api/v2.1/nps/list?status=active&activeEmail=1');
      return data?.npsList;
    } catch (error) {
      console.error(`❌ EM_Error getWidgetsList:`, error);
      return [];
    }
  }

  /**
   * @desc Generate a token for a widget
   */
  async generateWidgetToken(widgetId: number): Promise<string> {
    try {
      const { data }: any = await this.apiNodeV2.get(`/v2/nps/generate_token?widgetId=${widgetId}`);
      return data.token;
    } catch (error) {
      console.error(`❌ EM_Error generateWidgetToken:`, error);
      return '';
    }
  }
}

export const HtmlEditorServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  HtmlEditorService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof HtmlEditorService);
