/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { ReactNode, useEffect, useState } from 'react';

import { getEnv } from '@/src/application/hooks/util/useEnv';
import { parseJwt, readCookie } from '@/src/compat/util';
import configData from '@/src/config.json';

import { SOURCE_INBOX } from '@/modules/AuthModule/constants';

interface InboxProxyProps {
  children: ReactNode;
}

const InboxProxy: React.FC<InboxProxyProps> = ({ children }): JSX.Element | null => {
  const env = getEnv();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const handleInboxRedirection = () => {
      if (window.location.pathname === '/v2/logout') {
        setShouldRender(true);
        return;
      }

      const { JWT_DEV_TOKEN } = configData.endpoints[env === 'storybook' ? 'development' : env];
      const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : (window as any).__JWTToken;
      const mfaCookie = readCookie('mfaCookie');

      const urlParams = new URLSearchParams(window.location.search);
      const sourceParam = urlParams.get('source');

      if (sourceParam && !localStorage.getItem('redirectInbox')) {
        localStorage.setItem('redirectInbox', 'true');
      }

      const redirectInboxValue = localStorage.getItem('redirectInbox');

      if (jwtToken && (redirectInboxValue || sourceParam === SOURCE_INBOX) && mfaCookie) {
        localStorage.removeItem('redirectInbox');

        const baseUrl = configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
        const loginPath = '/emblue/login';
        const redirectUrl = `${String(baseUrl)}${loginPath}?token=${String(jwtToken)}`;

        window.location.href = redirectUrl;
        return;
      }

      if (jwtToken && mfaCookie) {
        try {
          const userPayload = jwtToken ? parseJwt(String(jwtToken)) : {};
          const isInboxAgent = userPayload.isInboxAgent === 'True';

          if (isInboxAgent) {
            const baseUrl =
              configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
            const loginPath = '/emblue/login';
            const redirectUrl = `${String(baseUrl)}${loginPath}?token=${String(jwtToken)}`;

            window.location.href = redirectUrl;
            return;
          }
        } catch (error) {
          console.error('Error parsing JWT token:', error);
        }
      }

      setShouldRender(true);
    };

    handleInboxRedirection();
  }, [env]);

  return shouldRender ? <>{children}</> : null;
};

export default InboxProxy;
