import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { FieldErrors, RegisterOptions } from 'react-hook-form';

import RedirectExternalUrl from '@/src/modules/RulesModule/components/FormRule/components/RedirectExternalUrl';

import FormTabs from './components/FormTabs';
import { useTabVisibility } from './hooks/useTabVisibility';

import { atomFormFieldsStatusAtom } from '@/modules/RulesModule/atoms/rules';
import GroupContacts from '@/modules/RulesModule/components/FormRule/components/GroupContacts';
import RepeatedEmails from '@/modules/RulesModule/components/FormRule/components/RepeatedEmails';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';
interface IFormWebFormProps {
  errors: FieldErrors<IRuleForm>;
  form: IFormProps<IRuleForm>;
  disabled?: boolean;
  rules: Record<string, RegisterOptions>;
  errorCount?: number;
}
const FormWebForm = ({ form, errors, disabled = false, rules, errorCount }: IFormWebFormProps) => {
  const { activeTab, setActiveTab, isVisible } = useTabVisibility();
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const handleTabChange = useCallback((tabId: number) => setActiveTab(tabId), [setActiveTab]);

  const classVisibility = classNames(
    'transition-all duration-300 ease-in-out',
    isVisible(1) ? 'opacity-100 max-h-full' : 'opacity-0 h-0 overflow-hidden w-0 absolute'
  );

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [errorCount, errors]);

  const setFormFieldsStatus = useSetAtom(atomFormFieldsStatusAtom);

  useEffect(() => {
    return () => {
      setFormFieldsStatus(RESET);
    };
  }, [setFormFieldsStatus]);

  return (
    <div className="visible-scrollbar h-[calc(100vh-300px)] overflow-y-auto pr-4">
      <FormTabs
        activeTab={activeTab}
        disabled={disabled}
        form={form}
        setActiveTab={setActiveTab}
        onTabChange={handleTabChange}
      />
      <div className={classVisibility}>
        <RepeatedEmails disabled={disabled} form={form} />
        <GroupContacts disabled={disabled} errors={errors} form={form} />
        <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="success" />
        <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="error" />
        <div ref={bottomRef} />
      </div>
    </div>
  );
};

export default FormWebForm;
