import { datadogRum } from '@datadog/browser-rum';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import extractContactData from '@/src/ContactsModule/utils/extractContactData';
import { OpenNewConversationFromTemplatePayload } from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';
import { useUserData } from '@/src/infrastructure/services/useUserData';

import { useValidateCountryPrefix } from './useValidateCountryPrefix';

import { atomContactCustomFields } from '@/modules/ContactsModule/atoms/Contacts';

export const useOpenNewConversation = () => {
  const { t } = useTranslation('inbox');
  const [userData] = useUserData();

  const inboxService = useInboxService();
  const customFields = useAtomValue(atomContactCustomFields);

  const contactData = useMemo(() => {
    return customFields && extractContactData(customFields);
  }, [customFields]);

  const { contactPhoneNumber, countryCode } = useValidateCountryPrefix({
    phoneNumber: contactData?.mainPhone || contactData?.secondaryPhone || '',
    country: contactData?.country || '',
  });

  const openNewConversation = useCallback(
    async ({ templateContent, templateId }: { templateContent: string; templateId: string }) => {
      try {
        const payload: OpenNewConversationFromTemplatePayload = {
          contactName: contactData?.names || '',
          contactPhoneNumber:
            contactPhoneNumber || contactData?.mainPhone || contactData?.secondaryPhone || '',
          templateContent,
          templateId,
        };

        if (countryCode) {
          payload.countryCode = countryCode;
        }

        const { success } = await inboxService.openNewConversationFromTemplate(payload);

        if (success) {
          toast({
            title: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.successTitle'),
            body: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.successBody'),
            variant: 'success',
          });
        } else {
          toast({
            title: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.errorTitle'),
            body: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.errorBody'),
            variant: 'error',
          });
        }
      } catch (error) {
        datadogRum.addAction('openNewConversationFromTemplate.error', {
          error,
          accountId: Number(userData.companyId),
          contactName: contactData?.names || '',
          contactPhoneNumber: contactData?.mainPhone || contactData?.secondaryPhone || '',
          templateId,
          templateContent,
          countryCode,
        });

        toast({
          title: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.errorTitle'),
          body: t('INBOX.OPEN_NEW_CONVERSATION_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    },
    [contactPhoneNumber, userData.companyId, contactData, inboxService, t, countryCode]
  );

  return { openNewConversation };
};
