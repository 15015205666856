import classNames from 'classnames';
import { cloneElement, isValidElement, ReactElement } from 'react';

import { Size } from './Tab';
import TabItem, { TabItemProps } from './TabItem';

import './Tab.tailwind.css';

export type TabNavProps = {
  /** Receives components of type TabItem v2 */
  children: ReactElement<TabItemProps> | ReactElement<TabItemProps>[];
  size?: Size;
  className?: string;
  isFullWidth?: boolean;
  isUnderlined?: boolean;
  disabledTabsIds?: (string | number)[];
  alignment?: 'left' | 'center' | 'right';
};

const TabNav = ({
  children,
  size,
  isFullWidth = false,
  isUnderlined = false,
  disabledTabsIds,
  alignment = 'left',
}: TabNavProps) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  for (const child of children) {
    if (!isValidElement(child) || child.type !== TabItem) {
      throw new Error('the "children" in TabNav prop only accepts components of type TabItem v3');
    }
  }

  const classes = classNames('flex font-normal text-emblue-text w-full', {
    'flex items-center justify-center': isFullWidth,
    'justify-center': alignment === 'center',
    'justify-start': alignment === 'left',
    'justify-end': alignment === 'right',
  });

  const navItemsChildren = children.map((child, index) => {
    const isDisabled = disabledTabsIds?.includes(child.props.tabId) || child.props.isDisabled;
    return cloneElement(child, {
      key: child.props.id || `tab-item-${index}`,
      isFullWidth,
      size,
      isUnderlined,
      isDisabled,
    });
  });

  if (!isFullWidth && isUnderlined) {
    if (alignment === 'left') {
      navItemsChildren.push(
        <TabItem
          key="tab-item-imaginary"
          isFullWidth
          isImaginary
          isUnderlined
          size={size}
          tabId="imaginary"
        />
      );
    }

    if (alignment === 'right') {
      navItemsChildren.unshift(
        <TabItem
          key="tab-item-imaginary"
          isFullWidth
          isImaginary
          isUnderlined
          size={size}
          tabId="imaginary"
        />
      );
    }

    if (alignment === 'center') {
      navItemsChildren.unshift(
        <TabItem
          key="tab-item-imaginary"
          isFullWidth
          isImaginary
          isUnderlined
          size={size}
          tabId="imaginary"
        />
      );
      navItemsChildren.push(
        <TabItem
          key="tab-item-imaginary-2"
          isFullWidth
          isImaginary
          isUnderlined
          size={size}
          tabId="imaginary-2"
        />
      );
    }
  }

  return <ul className={classes}>{navItemsChildren}</ul>;
};

export default TabNav;
