import classNames from 'classnames';
import { memo } from 'react';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';

interface QuantifierTextProps {
  loading?: boolean;
  text?: string;
  value: number;
}

const QuantifierText = ({ loading, text, value }: QuantifierTextProps) => {
  const classTabDisable = classNames('nowrap flex gap-2', {
    'opacity-50': loading,
    '!pointer-events-none opacity-50': value === 0,
  });
  return (
    <div className={classTabDisable}>
      {text} ({quantifier(value)})
    </div>
  );
};

export default memo(QuantifierText);
