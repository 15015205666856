import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FloatModal, Modal } from '@/lib/components/Modal';
import { closeModal } from '@/lib/components/Modal/Modal';
import { Button, Select, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Option } from '@/lib/v2/components/Select/Select';

type FormInput = {
  flows: Option;
};

interface IContactsToFlowModal {
  itemList: Option[];
  setConfirmToFlow?: Function;
  setFlowId?: Dispatch<SetStateAction<number>>;
  onClose?: Dispatch<SetStateAction<void>>;
  loading?: boolean;
  wordings?: {
    title?: string;
    description?: string;
    sendTo?: string;
    error?: string;
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ContactsToFlowModal = ({
  itemList,
  setConfirmToFlow,
  setFlowId,
  onClose,
  loading,
  wordings,
}: IContactsToFlowModal) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<FormInput>();

  const RULES = {
    flows: {
      required: t('MANAGE_TAG_MODAL.emptyField'),
    },
    onActionCreate: (flowId: number) => {
      setFlowId && setFlowId(flowId);
      typeof setConfirmToFlow === 'function' && setConfirmToFlow(flowId);
      closeModal();
    },
  };

  const onAction = handleSubmit((values: FormInput) => {
    const flowId =
      typeof values.flows.id === 'string' ? parseInt(values.flows.id) : values.flows.id;
    RULES.onActionCreate(flowId);
  });

  return (
    <FloatModal>
      <Modal>
        <div className="p-6">
          <Modal.Title onAction={() => (onClose ? onClose() : closeModal())}>
            <Text color="light-black" variant="headline">
              {t(wordings?.title ? wordings.title : 'CONTACT_FLOW_MODAL.title')}
            </Text>
          </Modal.Title>
          <Modal.Body>
            {itemList.length > 0 ? (
              <div className="w-[35rem] py-[5%]">
                {t(wordings?.description ? wordings.description : 'CONTACT_FLOW_MODAL.description')}
              </div>
            ) : (
              <div className="w-[35rem] py-[5%]">
                {t(wordings?.error ? wordings.error : 'CONTACT_FLOW_MODAL.error')}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Flex withGap alignment="start" gapSize="medium">
              {itemList.length && (
                <>
                  <div className="mt-4">
                    <Button isLoading={loading} onClick={onAction}>
                      <div className="text-12 lg:text-12 xl:text-14 2xl:text-16">
                        {t(wordings?.sendTo ? wordings.sendTo : 'CONTACT_FLOW_MODAL.sendTo')}
                      </div>
                    </Button>
                  </div>
                  <div className="mt-1 h-[34px] w-[42%]">
                    <Select control={control} name="flows" options={itemList} rules={RULES.flows} />
                  </div>
                </>
              )}
            </Flex>
          </Modal.Footer>
        </div>
      </Modal>
    </FloatModal>
  );
};
