import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModuleNew/constants';

export const useGetEditingRecurringMessage = () => {
  const { campaignId, actionId } = useParams();
  const service = useCampaignsService();
  const [showSpinnerByAction, setShowSpinnerByAction] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [infoCampaignV1, setInfoCampaignV1] = useState({
    campaign: { id: 0, name: '' },
    action: { id: 0, name: '' },
  });

  useMessage(EventListenerType.Loaded, (_) => {
    showSpinnerByAction && setIsLoading(true);
  });

  useEffect(() => {
    const type = 'manual';
    isLoading &&
      showSpinnerByAction &&
      SendActionsPostMessage('Campaign', 'ViewCampaign', {
        id: campaignId,
        type,
        isAutomatic: true,
        campaignName: infoCampaignV1?.campaign?.name,
      });
  }, [showSpinnerByAction, isLoading, actionId, campaignId, infoCampaignV1?.campaign?.name]);

  useMessage(EventListenerType.LoadedCampaign, (_, payload) => {
    if (isLoading && showSpinnerByAction) {
      // cspell:disable
      setInfoCampaignV1({
        campaign: {
          id: parseInt(campaignId || '0'),
          name: payload?.campaniaNombre ?? '',
        },
        action: {
          id: parseInt(actionId || '0'),
          name: payload?.nombre ?? '',
        },
      });
      // cspell:enable
      setShowSpinnerByAction(false);
    }
  });

  useEffect(() => {
    SendActionsPostMessage('Campaign', 'VerifyLoaded');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useMessage(EventListenerType.DeleteAction, async (_) => {
    if (!actionId) return;
    await service.deleteMailContent(+actionId);
    SendActionsPostMessage('Action', 'SuccessDeleteAction', {});
  });

  useMessage(EventListenerType.OpenBeefree, (_) => {
    navigate(
      `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${campaignId}/action/${actionId}/master/${AUTOMATION_PATHS.EDITOR}`
    );
  });

  useMessage(EventListenerType.OpenHtmlEditor, (_) => {
    navigate(`master/html-editor`);
  });

  return {
    actionId,
    actionName: infoCampaignV1.action.name,
    actionIsLoading: showSpinnerByAction,
  };
};
