import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, Text } from '@/lib/v2/components';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';

import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import { RulesFormIcon } from '@/modules/RulesModule/images/icons';

const DownloadSource = ({ contentHtml }: { contentHtml: string }) => {
  const { t } = useTranslation('rules');

  const handleDownload = useCallback(() => {
    if (!contentHtml) {
      return;
    }

    const blob = new Blob([contentHtml], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'index.html';

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [contentHtml]);

  return (
    <div className="flex items-center justify-between p-1">
      <div className="flex items-center">
        <IconSvg fillColor="primary" height="24px" svgComponent={<RulesFormIcon />} width="24px" />
        <Text truncate className="ml-2" fontWeight="normal" variant="text">
          {t(`${TRANSLATE_RULES_LABELS}.downloadForm`)}
        </Text>
      </div>
      <Button onlyIcon standard id="button-modal-preview" size="tiny" onClick={handleDownload}>
        <IconSvg fillColor="disabled" height="16px" svgComponent={<DownloadIcon />} width="24px" />
      </Button>
    </div>
  );
};

export default memo(DownloadSource);
