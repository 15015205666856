import { memo, useEffect, useRef } from 'react';

import { Iframe } from '@/lib/v2/components';

const LivePreview = ({ contentHtml }: { contentHtml: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const handleLoad = () => {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
      if (!iframeDocument) return;

      const form = iframeDocument.querySelector('form');
      if (!form) return;

      form.addEventListener('submit', (event) => event.preventDefault());
    };

    iframe.addEventListener('load', handleLoad);

    return () => {
      iframe.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div className="w-full overflow-y-auto !overflow-x-hidden">
      <div className="-mt-5 mb-5 h-[400px] w-full origin-top-left overflow-x-hidden">
        <Iframe
          ref={iframeRef}
          fullHeight={false}
          html={contentHtml}
          title="html"
          withoutOverflow={false}
        />
      </div>
    </div>
  );
};

export default memo(LivePreview);
