import classNames from 'classnames';
import { FC } from 'react';

import { CardCompositionProps } from './types';

/**
 * CardFooter component - Used as the footer section in a Card
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to render in the footer
 * @param {boolean} [props.hasFooter=false] - Whether the footer is present (used for styling)
 *
 * @example
 * // Basic usage
 * <Card.Footer>
 *   <Button primary>Action</Button>
 * </Card.Footer>
 *
 * @returns {JSX.Element} Footer section for the Card
 */
const CardFooter: FC<CardCompositionProps> = ({ children, hasFooter = false }) => {
  const footerClass = classNames('px-6', {
    'pt-2 pb-6': hasFooter,
  });

  return <div className={footerClass}>{children}</div>;
};

export default CardFooter;
