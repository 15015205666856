import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { ERulesActionType } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { IRestricted } from '@/src/presentation/types/types';

import { IRuleConfigCreation } from '@/modules/RulesModule/interfaces';

export const atomActionTypeSelected = atomWithReset<ActionTypeName>('email' as ActionTypeName);
atomActionTypeSelected.debugLabel = 'atomActionTypeSelected';

export const atomCreateActionsModalIsOpen = atom(false);
atomCreateActionsModalIsOpen.debugLabel = 'atomCreateActionsModalIsOpen';

export const atomActionInputModalIsOpen = atom(false);
atomActionInputModalIsOpen.debugLabel = 'atomActionInputModalIsOpen';

export const atomRestrictions = atom<IRestricted | null>(null);
atomRestrictions.debugLabel = 'atomRestrictions';

export const atomCreateRules = atom<ERulesActionType>(ERulesActionType.ALL);
atomRestrictions.debugLabel = 'atomCreateRules';

export const atomRuleConfigCreation = atomWithReset<IRuleConfigCreation | undefined>(undefined);
atomRuleConfigCreation.debugLabel = 'atomRuleConfigCreation';
