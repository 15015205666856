import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TextSkeleton } from '@/lib/components';
import { Card, IconSvg, If, Text } from '@/lib/v2/components';
import { OpenNewWindow } from '@/lib/v2/icons/outline';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import ContactsAdditional from '@/src/modules/InsightsModule/components/ContactsAdditional';
import ContactStatus from '@/src/modules/InsightsModule/components/ContactStatus';
import { useMetrics } from '@/src/modules/InsightsModule/hooks/useInsightsData';

const ContactsSummary = () => {
  const { t } = useTranslation(['insights']);
  const {
    state: { metrics, loading },
  } = useMetrics();

  const total = useMemo(() => metrics?.status?.totalAccountContacts?.total ?? 0, [metrics]);

  return (
    <Card
      noShadow
      className="flex w-full justify-between"
      id="insights-contacts-card"
      isFullHeight={false}
    >
      <div className="mb-5 flex w-full items-center justify-between pl-4">
        <Text color="black" fontWeight="normal" variant="headline">
          {t('contacts.title')}
        </Text>

        <Link
          className="flex flex-row items-center gap-2 text-12 text-emblue-primary"
          data-dd-action-name={`insights.goToContacts`}
          id="insights-contacts-goToContacts"
          to={'/v2/contacts'}
        >
          {t('contacts.goToContacts')}
          <IconSvg svgComponent={<OpenNewWindow />} />
        </Link>
      </div>

      <div className="mb-4 flex items-center justify-between gap-2 pl-4">
        <Text className="leading-8" id="insights-contacts-total" variant="title-3">
          <If condition={!loading} fallback={<TextSkeleton size="sm" />}>
            {quantifier(total)}
          </If>
        </Text>
        <Text isItalic variant="text">
          {t('contacts.updates')}
        </Text>
      </div>

      <ContactStatus metrics={metrics} />
      <ContactsAdditional metrics={metrics} />
    </Card>
  );
};

export default memo(ContactsSummary);
