import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Option } from '@/lib/v2/components';

import { useEmailFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailFormEvents';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { useSender } from '@/modules/CampaignsModule/hooks/useSender';
import { IEmailFormInfo } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { SenderStatus } from '@/modules/SendersModule/interfaces/Senders';

interface IEmailSenderProps {
  setValue: UseFormSetValue<IEmailFormInfo>;
}

export const useEmailSender = ({ setValue }: IEmailSenderProps) => {
  const { actionId } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);

  const [isInvalidSender, setIsAlertInvalidSender] = useState<boolean>(false);

  const { getSenders, getCurrentSender, senderDropdownOptions, loading } = useSender();
  const { setInfoByFieldOnChange } = useEmailFormEvents({ actionId: Number(actionId) });

  const { sender } = actionInfoEmail ?? {};

  useEffect(() => {
    if (!sender) return;

    !sender?.emailFrom && void setInfoByFieldOnChange({ senderId: Number(sender.id) });

    const currentSender = getCurrentSender(sender.id, senderDropdownOptions);
    if (!currentSender) return;

    setValue('sender', {
      id: sender.id,
      name: String(currentSender?.name) || `${sender?.nameFrom} <${String(sender?.emailFrom)}>`,
      value: String(currentSender?.value) || sender?.emailFrom,
    });

    setIsAlertInvalidSender(currentSender?.iconStatus === SenderStatus.Warning);
  }, [getCurrentSender, sender, senderDropdownOptions, setInfoByFieldOnChange, setValue]);

  const handleChangeSender = useCallback(
    async (value: Option | Option[]) => {
      const selectedOption = value as Option;
      await setInfoByFieldOnChange({ senderId: Number(selectedOption.id) });
      setIsAlertInvalidSender(selectedOption?.iconStatus === SenderStatus.Warning);
    },
    [setInfoByFieldOnChange]
  );

  return {
    events: {
      getSenders,
      handleChangeSender,
    },
    state: {
      loading,
      senderDropdownOptions,
      isInvalidSender,
    },
  };
};
