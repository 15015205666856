import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import CatalogTemplateMenuBar from '@/src/modules/CampaignsModule/components/CatalogTemplates';

interface CampaignsLayoutProps {
  basePath?: string;
}

const CampaignsLayout: React.FC<CampaignsLayoutProps> = ({ basePath }) => {
  return (
    <div>
      <CatalogTemplateMenuBar basePath={basePath} />
      <Outlet />
    </div>
  );
};

export default memo(CampaignsLayout);
