import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const ebInfoCookie = document.cookie.split('; ').find((row) => row.startsWith('eb_info='));
  if (ebInfoCookie) {
    config.headers['X-Session-Info'] = ebInfoCookie;
  }

  // specify the team for the request
  config.headers['X-Team'] = 'inbox';
  config.headers['ngrok-skip-browser-warning'] = '69420';

  return config;
});

export default axiosInstance;
