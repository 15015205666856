import { createColumnHelper } from '@tanstack/react-table';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BadgeText, Button, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { ColorsBadge } from '@/lib/v2/components/BadgeText/types';
import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import {
  ActionMasterIcon,
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailTriggerIcon,
  SmsActionIcon,
  SmsAPIIcon,
} from '@/lib/v2/icons/outline';
import { ReportIcon as ReportIconSolid } from '@/lib/v2/icons/solid';

import { AUTOMATION_PATHS, LIST_ACTIONS_STATE } from '@/src/modules/AutomationModuleNew/constants';

import { DROPDOWN_ACTIONS_STATE, DROPDOWN_CHANNELS } from '@/modules/CampaignsModule/constants';
import {
  EChannelsID,
  ICampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { DateFormat, formatDate, formattedNumber } from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const ChannelInfoByID = {
  [EChannelsID.MASTER]: {
    icon: <ActionMasterIcon />,
    route: 'master',
  },
  [EChannelsID.EMAIL]: {
    icon: <EmailActionIcon />,
    route: 'email',
  },
  [EChannelsID.EMAIL_TRIGGER]: {
    icon: <EmailTriggerIcon />,
    route: 'email-trigger',
  },
  [EChannelsID.EMAIL_ATTACHMENT]: {
    icon: <EmailAttachmentIcon />,
    route: 'email-attachment',
  },
  [EChannelsID.EMAIL_API]: {
    icon: <EmailAPIIcon />,
    route: 'mail-api',
  },
  [EChannelsID.SMS]: {
    icon: <SmsActionIcon />,
    route: 'sms',
  },
  [EChannelsID.SMS_API]: {
    icon: <SmsAPIIcon />,
    route: 'sms-api',
  },
};

export const useRecurringMessagesDetailColumns = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<ICampaignActions>();

  const getChannelLabel = useCallback(
    (channelId: number): string => {
      const labelChannel =
        DROPDOWN_CHANNELS.find((channel) => channel.value === channelId)?.label ?? 'email';
      return t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${labelChannel}`);
    },
    [t]
  );

  const getStatusColor = useCallback(
    (statusId: number): { badge: ColorsBadge; background: Exclude<ColorsIcon, 'white'> } | null => {
      const group = LIST_ACTIONS_STATE.find((group) => group.values.includes(statusId));
      return group
        ? {
            badge: group.badge,
            background: group.background,
          }
        : null;
    },
    []
  );

  const getCurrentState = useCallback(
    (status: number) => {
      const [filter] = DROPDOWN_ACTIONS_STATE.filter((item) => Number(item.value) === status);
      return t(`CAMPAIGN_ACTIONS_MAIN.STATES.${filter.label}`) ?? '-';
    },
    [t]
  );

  const iconByChannelStatus = useCallback(
    (channel: EChannelsID, status: number) => {
      const currentChannelInfo = ChannelInfoByID[channel];

      return (
        <IconSvg
          rounded
          bgColor={getStatusColor(status)?.background}
          fillColor={getStatusColor(status)?.background as ColorsIcon}
          height="auto"
          svgComponent={currentChannelInfo.icon}
          width="40px"
          withPadding="small"
        />
      );
    },
    [getStatusColor]
  );

  const handleClickReport = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: ICampaignActions) => {
      event.stopPropagation();
      const baseRoute = `${AUTOMATION_PATHS.ACTION_REPORT_PATH}/${value.actionId}`;
      navigate(baseRoute);
    },
    [navigate]
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: t('CAMPAIGNS_MAIN.TABLE.COLUMNS.name') as unknown as string,
        cell: (info) => (
          <div className="flex w-full flex-row items-center gap-4">
            <div className="shrink-0">
              {iconByChannelStatus(info.row.original.channel, info.row.original.status)}
            </div>
            <div className="flex-1">
              <div className="flex flex-col">
                <Tooltip
                  isClickable
                  noArrow
                  alignment="start"
                  content={info.getValue()}
                  position="top"
                >
                  <Text fontWeight="medium" variant="text">
                    {info.getValue()}
                  </Text>
                </Tooltip>
                <Text variant="text">{getChannelLabel(info.row.original.channel)}</Text>
              </div>
            </div>
          </div>
        ),
        meta: {
          enableOrderColumn: true,
          widthClassName: 'w-[30%]',
        },
      }),
      columnHelper.accessor('status', {
        header: t('CAMPAIGNS_MAIN.TABLE.COLUMNS.state') as unknown as string,
        cell: (info) => (
          <BadgeText
            text={getCurrentState(info.getValue())}
            variantColor={getStatusColor(info.getValue())?.badge ?? undefined}
          />
        ),
        meta: { widthClassName: 'w-[20%]' },
      }),
      columnHelper.accessor('creationDate', {
        header: t('RECURRENT_MESSAGE.TABLE.COLUMNS.date') as unknown as string,
        cell: (info) => {
          const formattedDate = info.getValue()
            ? formatDate(info.getValue(), DateFormat.LongDateOutSeconds)
            : '-';
          return formattedDate;
        },
        meta: {
          enableOrderColumn: true,
          widthClassName: 'w-[15%]',
        },
      }),
      columnHelper.accessor('exeDate', {
        header: t('RECURRENT_MESSAGE.TABLE.COLUMNS.execution') as unknown as string,
        cell: (info) => {
          const formattedDate = info.getValue()
            ? formatDate(info.getValue() ?? '', DateFormat.LongDateOutSeconds)
            : '-';
          return formattedDate;
        },
        meta: {
          enableOrderColumn: true,
          widthClassName: 'w-[15%]',
        },
      }),
      columnHelper.accessor('audience', {
        header: t('RECURRENT_MESSAGE.TABLE.COLUMNS.audience') as unknown as string,
        cell: (info) => {
          return (
            formattedNumber(Number(info.getValue()), i18n.language as keyof typeof ELanguageMap) ||
            '-'
          );
        },
        meta: {
          enableOrderColumn: true,
          widthClassName: 'w-[15%]',
        },
      }),
      columnHelper.display({
        header: t('RECURRENT_MESSAGE.TABLE.COLUMNS.report') as unknown as string,
        cell: (info) => (
          <Button
            gray
            outline
            data-id={info.row.original.campaignId}
            iconLeft={
              <IconSvg
                fillColor="gray-dark"
                height="16px"
                svgComponent={<ReportIconSolid />}
                width="13px"
              />
            }
            id="detail-actions"
            size="small"
            onClick={(event) => handleClickReport(event, info.row.original)}
          />
        ),
        meta: { widthClassName: 'w-[5%]' },
      }),
    ];
  }, [
    columnHelper,
    getChannelLabel,
    getCurrentState,
    getStatusColor,
    handleClickReport,
    i18n.language,
    iconByChannelStatus,
    t,
  ]);

  return { columns };
};
