import { useSetAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

import { Card } from '@/lib/v2/components';

import { atomCurrentFormType, atomToggleSidePanel } from '@/src/modules/RulesModule/atoms/rules';
import { FormTypes, NodeData } from '@/src/modules/RulesModule/interfaces';

import ConditionNodeContent from './components/ConditionNodeContent';

const ConditionNode = ({ id }: NodeProps<NodeData>) => {
  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const setCurrentFormType = useSetAtom(atomCurrentFormType);

  const handleEdit = useCallback(() => {
    setCurrentFormType(FormTypes.CONDITION);
    setToggleSidePanel(true);
  }, [setCurrentFormType, setToggleSidePanel]);

  return (
    <>
      <Handle position={Position.Top} type="target" />
      <Card
        className="!w-72 !px-4 pb-0 hover:outline-none hover:ring-2 hover:ring-emblue-primary hover:ring-offset-2"
        id={id}
        onClick={handleEdit}
      >
        <ConditionNodeContent />
      </Card>
      <Handle position={Position.Bottom} type="source" />
    </>
  );
};

export default memo(ConditionNode);
