import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { haveMessageAtom } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  atomIsLoadingRuleData,
  atomRuleDataRaw,
  atomToggleActivateRuleModal,
} from '@/modules/RulesModule/atoms/rules';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';
import {
  IRulesEmailFormInfo,
  IRulesEmailInfoPayload,
  RuleTypes,
} from '@/modules/RulesModule/interfaces';

/* cspell:disable*/
export const useRulesEmailFormSubmit = () => {
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setHaveMessage = useSetAtom(haveMessageAtom);
  const rulesDataRaw = useAtomValue(atomRuleDataRaw);
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const setProcessingAction = useSetAtom(atomProcessingAction);
  const setToggleActivateRuleModal = useSetAtom(atomToggleActivateRuleModal);

  const { saveInfoAction } = useActionEmailData(Number(actionId));
  const { refetchData } = useResetRulesState();

  const rulePath = useMemo(() => {
    const isRulesPath = pathname.includes(
      `${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`
    );
    const rulesPath = pathname.slice(0, pathname.lastIndexOf('/'));
    const campaignsPath = `${CAMPAIGNS_PATH}/${String(campaignId)}/${String(actionType)}/${String(
      actionId
    )}`;

    return isRulesPath ? rulesPath : campaignsPath;
  }, [actionId, campaignId, actionType, pathname]);

  const handleSubmitData = useCallback(
    async (data: IRulesEmailFormInfo) => {
      if (!actionInfoEmail?.content?.message) {
        setHaveMessage(true);
        return;
      }

      setProcessingAction(true);

      const { sender, subject, firstLine } = data;

      const payload: IRulesEmailInfoPayload = {
        senderId: Number(sender.id),
        subject: subject,
        firstLine: firstLine,
      };

      await saveInfoAction(payload);
      refetchData();
      setProcessingAction(false);

      if (rulesDataRaw?.desencadenante === RuleTypes.FLOW_TEMPLATE && !isLoadingRuleData) {
        setToggleActivateRuleModal(true);
        return;
      }

      navigate(`${rulePath}`);
    },
    [
      actionInfoEmail?.content?.message,
      isLoadingRuleData,
      navigate,
      refetchData,
      rulePath,
      rulesDataRaw?.desencadenante,
      saveInfoAction,
      setHaveMessage,
      setProcessingAction,
      setToggleActivateRuleModal,
    ]
  );

  return {
    handleSubmitData,
  };
};
/* cspell:enable*/
