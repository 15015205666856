import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { atomActionPathBeefreeNav } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { atomInfoCampaignV1 } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  EChannelsID,
  ICampaignActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useNavigateAndBack } from '@/modules/AutomationModuleNew/hooks/useNavigateAndBack';

export const useTemplates = () => {
  const { actionType } = useParams();
  const navigate = useNavigate();

  const [, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const setActionPathBeefreeNav = useSetAtom(atomActionPathBeefreeNav);
  const { navigateAndSavePathname } = useNavigateAndBack();

  const openTemplates = useCallback(
    (action: ICampaignActions | null, isAutomation: boolean = false) => {
      if (action === null) return;
      const { name, channel, actionId } = action;
      if (!actionId) return;

      let catalogUrl = `/v2/campaigns/catalogs/email/${actionId.toString()}`;
      if (isAutomation) {
        catalogUrl = `/v2/automation/catalogs/email/${actionId.toString()}`;
        navigateAndSavePathname(catalogUrl);
      }

      setInfoCampaignV1({
        campaign: {
          id: Number(action.campaignId),
          name: action.campaignName ?? '',
          isAutomatic: channel === EChannelsID.MASTER,
        },
        action: {
          id: actionId,
          name: name,
        },
      });

      setActionPathBeefreeNav(actionType as ActionTypeName);
      navigate(catalogUrl);
    },
    [actionType, navigate, navigateAndSavePathname, setActionPathBeefreeNav, setInfoCampaignV1]
  );

  return {
    openTemplates,
  };
};
