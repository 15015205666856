import { useSetAtom } from 'jotai';
import { FormEvent, useEffect, useMemo } from 'react';

import { atomCallbackSaveRule } from '@/src/modules/RulesModule/atoms/rules';

import { SenderInput } from './components/SenderInput';
import { SubjectInput } from './components/SubjectInput';
import { useRulesEmailForm } from './hooks/useRulesEmailForm';
import { useRulesEmailFormSubmit } from './hooks/useRulesEmailFormSubmit';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';

const RulesEmailForm = ({ loading, disableForm }: { loading: boolean; disableForm: boolean }) => {
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);
  const setCallbackSaveRule = useSetAtom(atomCallbackSaveRule);

  const { control, handleSubmit, setValue, getValues, RULES } = useRulesEmailForm();

  const formProps = useMemo(
    () => ({ control, setValue, getValues }),
    [control, setValue, getValues]
  );
  const { handleSubmitData } = useRulesEmailFormSubmit();

  useEffect(() => {
    setCallbackSaveRule(() => handleSubmit?.(handleSubmitData));
    return () => setGlobalLoadingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmitData]);

  return (
    <div className="visible-scrollbar h-[calc(100vh_-_210px)] overflow-y-auto overflow-x-hidden pb-4">
      <form
        autoComplete="off"
        className="pb-4 pr-3"
        id="create-actions"
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
          handleSubmit(handleSubmitData);
        }}
      >
        <div className="mb-12">
          <SenderInput disabled={disableForm} form={formProps} loading={loading} rules={RULES} />
        </div>
        <div className="mb-12">
          <SubjectInput disabled={disableForm} form={formProps} loading={loading} rules={RULES} />
        </div>
      </form>
    </div>
  );
};

export default RulesEmailForm;
