const SkeletonColumn = () => {
  return (
    <div
      className="max-w-fit animate-pulse space-y-4 divide-y divide-gray-200 p-4  md:p-6"
      role="status"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-gray-300 "></div>
          <div className="h-2 w-32 rounded-full bg-gray-200 "></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default SkeletonColumn;
