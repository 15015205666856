import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { PaginationState, Updater } from '@tanstack/react-table';
import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';

import { Option, Select, Text } from '@/lib/v2/components';

const pageSizes: Option[] = [
  { id: 10, name: 10, value: 10 },
  { id: 20, name: 20, value: 20 },
  { id: 30, name: 30, value: 30 },
  { id: 40, name: 40, value: 40 },
  { id: 50, name: 50, value: 50 },
];

interface PaginationProps {
  label?: string;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  isDisabled?: boolean;
  setPagination: (updater: Updater<PaginationState>) => void;
  previousPage: () => void;
  nextPage: () => void;
}

const Pagination = ({
  label = 'Show rows',
  pageSize,
  currentPage,
  totalPages,
  setPagination,
  isDisabled,
  previousPage,
  nextPage,
}: PaginationProps) => {
  const getButtonClasses = (side: 'left' | 'right') => {
    const buttonClasses = classNames(
      'inline-flex h-[38px] items-center border border-[#D3DDEA] bg-[#FAFBFD] px-1 py-2 hover:bg-[#F6FAFF] disabled:opacity-25',
      {
        'cursor-pointer': !isDisabled,
        'cursor-not-allowed': isDisabled,
        'rounded-l-md': side === 'left',
        'rounded-r-md': side === 'right',
      }
    );

    return buttonClasses;
  };

  const buttonLeftClasses = getButtonClasses('left');
  const buttonRightClasses = getButtonClasses('right');

  const handleSelectChange = useCallback(
    (option: Option | Option[]) => {
      if (option instanceof Array) return;
      setPagination({ pageIndex: 0, pageSize: Number(option.value) });
    },
    [setPagination]
  );

  const optionValue = useMemo(() => {
    return pageSizes.find((option) => option.value === pageSize);
  }, [pageSize]);

  return (
    <div className="mr-4 flex max-w-fit items-center gap-2">
      <Text as="span" variant="text">
        {label}
      </Text>
      <div className="w-16">
        <Select
          disabled={isDisabled}
          options={pageSizes}
          value={optionValue}
          onChange={handleSelectChange}
        />
      </div>
      <Text as="span" variant="text">{`${currentPage} - ${totalPages}`}</Text>
      <div aria-label="pagination" className="inline-flex -space-x-px rounded-md shadow-sm">
        <button
          className={buttonLeftClasses}
          disabled={currentPage === 1 || isDisabled}
          onClick={() => previousPage()}
        >
          <ChevronLeftIcon aria-hidden="true" className="size-6 fill-emblue-text" />
        </button>
        <button
          className={buttonRightClasses}
          disabled={totalPages === currentPage || totalPages === 0 || isDisabled}
          onClick={() => nextPage()}
        >
          <ChevronRightIcon aria-hidden="true" className="size-6 fill-emblue-text" />
        </button>
      </div>
    </div>
  );
};

export default memo(Pagination);
