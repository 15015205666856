import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useParseParamsURL from '@/src/application/hooks/useParseParamsURL';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  atomCampaignList,
  atomFiltersCampaign,
  atomFiltersDropdownCampaign,
  atomOrderBy,
  atomPagerCampaign,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  CAMPAIGNS_PATH,
  defaultOrderBy,
  defaultPager,
  initialOrderByKeys,
  initialPagerKeys,
} from '@/src/modules/CampaignsModule/constants';
import {
  ECampaignsTypeOptions,
  EChannelsID,
  ICampaignListPayload,
  IFiltersTableCampaign,
  IOrderByTable,
  IPager,
  PATH_TYPES_CAMPAIGN,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useCampaignLastPath } from './useCampaignLastPath';
import { useCampaign } from './useCampaigns';

export const useCampaignFilters = () => {
  const navigate = useNavigate();

  const [filtersCampaign, setFilters] = useAtom(atomFiltersCampaign);
  const orderBy = useAtomValue(atomOrderBy);
  const pageParams = useAtomValue(atomPagerCampaign);
  const setFiltersDropdownCampaign = useSetAtom(atomFiltersDropdownCampaign);
  const setCampaignList = useSetAtom(atomCampaignList);

  const { getList } = useCampaign();
  const { parseNumberArray, parseDateTimestamp } = useParseParamsURL();
  const { setCampaignPath } = useCampaignLastPath();
  const showNewAutomationModule = useFeatureFlag('showNewAutomationModule');

  const parseURLParams = useCallback((): Partial<IFiltersTableCampaign> => {
    const searchParams = new URLSearchParams(location.search);
    return {
      state: parseNumberArray(searchParams.get('state')),
      strategy: parseNumberArray(searchParams.get('strategy')),
      search: searchParams.get('search') || undefined,
      startDate: parseDateTimestamp(searchParams.get('startDate')),
      endDate: parseDateTimestamp(searchParams.get('endDate')),
      page: Number(searchParams.get('page')),
      limit: Number(searchParams.get('limit')),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateURLFromFilters = useCallback(
    (filters: ICampaignListPayload | undefined = filtersCampaign) => {
      if (!filters) return;

      const searchParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (
          value !== undefined &&
          value !== null &&
          !(
            (initialPagerKeys.includes(key) && value === defaultPager[key as keyof IPager]) ||
            (initialOrderByKeys.includes(key) &&
              value === defaultOrderBy[key as keyof IOrderByTable])
          )
        ) {
          if (Array.isArray(value)) {
            searchParams.set(key, value.join(','));
          } else if (value instanceof Date) {
            const timeStampCast = new Date(value).getTime() / 1000;
            searchParams.set(key, timeStampCast.toString());
          } else {
            searchParams.set(key, String(value));
          }
        }
      });

      setCampaignPath({
        pathType: PATH_TYPES_CAMPAIGN.CAMPAIGN,
        path: `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`,
      });
      /* navigate(`?${searchParams.toString()}`, { replace: false }); */
      window.history.replaceState(
        {},
        '',
        `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`
      );
    },
    [filtersCampaign, setCampaignPath]
  );

  const getCampaigns = useCallback(
    async (payload: ICampaignListPayload) => {
      await getList({
        ...payload,
        campaignTypeId: [ECampaignsTypeOptions.MANUAL],
        sortField: orderBy.sortField,
        sortOrder: orderBy.sortOrder,
        excludeCampaignStatus: showNewAutomationModule,
        channelsToExclude: showNewAutomationModule ? [EChannelsID.EMAIL_TRIGGER] : undefined,
      });

      updateURLFromFilters({
        ...payload,
        sortField: orderBy.sortField,
        sortOrder: orderBy.sortOrder,
      });
    },
    [getList, orderBy, updateURLFromFilters, showNewAutomationModule]
  );

  const CAMPAIGNS_URL = useMemo(() => {
    return pageParams.limit > defaultPager.limit
      ? `${CAMPAIGNS_PATH}?limit=${pageParams.limit}`
      : CAMPAIGNS_PATH;
  }, [pageParams.limit]);

  const resetFilters = useCallback(() => {
    setFilters({ ...pageParams, page: 1 });
    setFiltersDropdownCampaign(RESET);
    navigate(CAMPAIGNS_URL);
  }, [CAMPAIGNS_URL, navigate, pageParams, setFilters, setFiltersDropdownCampaign]);

  const resetUnmount = useCallback(() => {
    setCampaignList(RESET);
    setFiltersDropdownCampaign(RESET);
    setFilters(RESET);
  }, [setCampaignList, setFilters, setFiltersDropdownCampaign]);

  return {
    resetUnmount,
    parseURLParams,
    updateURLFromFilters,
    getCampaigns,
    resetFilters,
  };
};
