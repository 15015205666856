import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '@/lib/v3/components';

import { formatNormalizeLocalizedDate } from '@/src/application/util/formatLocalizedDate';
import { atomShippingFilters } from '@/src/modules/InsightsModule/atoms/InsightsAtom';
import { SHIPPING_RANGE_TOTAL_DAYS } from '@/src/modules/InsightsModule/constants';
import { useShippingMetric } from '@/src/modules/InsightsModule/hooks/useShippingMetrics';

import { ShippingRange } from '@/modules/InsightsModule/interfaces/Channel';

//TODO: Migrate tabs for custom buttons. Unnecessary component
const ShippingRanges = () => {
  const { t } = useTranslation(['insights']);

  const [currentTab, setCurrentTab] = useState<ShippingRange>(ShippingRange.WEEKLY);

  const filters = useAtomValue(atomShippingFilters);

  const {
    events: { updateFilters },
  } = useShippingMetric();

  const getDateRange = (days: number) => {
    const adjustedDays = days - 1;
    const startDate = formatNormalizeLocalizedDate(
      new Date(Date.now() - adjustedDays * 24 * 60 * 60 * 1000)
    ).substring(0, 10);
    const endDate = formatNormalizeLocalizedDate(new Date()).substring(0, 10);

    return { startDate, endDate };
  };

  const handleChangeRange = (type: ShippingRange) => {
    setCurrentTab(type);

    const ranges = getDateRange(SHIPPING_RANGE_TOTAL_DAYS[type]);
    updateFilters({ ...filters, ...ranges });
  };
  return (
    <div className="flex py-3">
      <Tab currentTab={currentTab}>
        <Tab.TabNav className="justify-end">
          <Tab.TabNavItem
            id="insights-channel-tab-weekly"
            tabId={ShippingRange.WEEKLY}
            onClick={() => handleChangeRange(ShippingRange.WEEKLY)}
          >
            {t('shipping.ranges.weekly')}
          </Tab.TabNavItem>
          <Tab.TabNavItem
            id="insights-channel-tab-biweekly"
            tabId={ShippingRange.BIWEEKLY}
            onClick={() => handleChangeRange(ShippingRange.BIWEEKLY)}
          >
            {t('shipping.ranges.biweekly')}
          </Tab.TabNavItem>
          <Tab.TabNavItem
            id="insights-channel-tab-monthly"
            tabId={ShippingRange.MONTHLY}
            onClick={() => handleChangeRange(ShippingRange.MONTHLY)}
          >
            {t('shipping.ranges.monthly')}
          </Tab.TabNavItem>
          <Tab.TabNavItem
            id="insights-channel-tab-quarterly"
            tabId={ShippingRange.QUARTERLY}
            onClick={() => handleChangeRange(ShippingRange.QUARTERLY)}
          >
            {t('shipping.ranges.quarterly')}
          </Tab.TabNavItem>
        </Tab.TabNav>
        <Tab.TabContent>
          <Tab.TabContentPane tabId={1}></Tab.TabContentPane>
          <Tab.TabContentPane tabId={2}></Tab.TabContentPane>
          <Tab.TabContentPane tabId={3}></Tab.TabContentPane>
          <Tab.TabContentPane tabId={4}></Tab.TabContentPane>
        </Tab.TabContent>
      </Tab>
    </div>
  );
};

export default ShippingRanges;
