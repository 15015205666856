import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, If, Input, Modal, Text } from '@/lib/v2/components';
import { AlertProps } from '@/lib/v2/components/Alert/Alert';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';

import { formValidations } from '@/src/constants/FormValidations';

import ButtonsSection from './ButtonsSection';

type FormInput = {
  input: string;
};

export interface IValidationResponse {
  isValidInput: boolean | undefined;
  message: string;
}

type IOnSubmit = (nameValue: string) => void;
type IOnSubmitAsync = (nameValue: string) => Promise<void | IValidationResponse>;

export type IOnSubmitModalSingleInput = IOnSubmit | IOnSubmitAsync;

interface ModalCreateItemProps {
  id?: string;
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  onSubmit?: IOnSubmitModalSingleInput;
  isLoading?: boolean;
  disabled?: boolean;
  inputLabel?: string;
  textAction?: string;
  textClose?: string;
  rules?: {
    input: object;
  };
  /** controller value input */
  value?: string;
  defaultValue?: string;
  disabledUntilOnChange?: boolean;
  disabledSubmitButton?: boolean;
  validateFieldsOnOpen?: boolean;
  alert?: AlertProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onValidateInputFetch?: (value: string) => Promise<IValidationResponse>;
}

const ModalSingleInput = ({
  id,
  isOpen,
  onClose,
  title,
  onSubmit,
  inputLabel,
  textAction,
  isLoading,
  textClose,
  rules,
  disabledSubmitButton,
  disabled,
  value,
  alert,
  disabledUntilOnChange,
  validateFieldsOnOpen,
  onChange,
  onValidateInputFetch,
  defaultValue,
}: ModalCreateItemProps) => {
  const {
    handleSubmit,
    setError,
    trigger,
    control,
    formState: { isValid, isDirty },
    setValue,
  } = useForm<FormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      input: value || defaultValue || '',
    },
  });
  const [show, setShow] = useState(isOpen);

  const { inputLengths } = formValidations;

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const onAction = handleSubmit(async (values) => {
    const { input } = values;

    if (onValidateInputFetch) {
      const response = await onValidateInputFetch(input);
      const { isValidInput, message } = response;
      if (isValidInput === false) {
        return setError('input', { type: 'validation', message });
      }
    }

    if (typeof onSubmit === 'function') {
      const result = onSubmit(input);
      if (result instanceof Promise) {
        const asyncResult = await result;
        if (
          asyncResult &&
          asyncResult.isValidInput === false &&
          typeof asyncResult.message === 'string'
        ) {
          return setError('input', { type: 'validation', message: asyncResult.message });
        }
      } else {
        return result;
      }
    }
  });

  const RULES = {
    input: {
      required: t('FORMS_RULES_MESSAGES.required'),
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
  };

  const label = inputLabel ?? t('CREATE_SEGMENT_MODAL.fieldName');

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (value) {
      setValue('input', value);
      void trigger();
    }
  }, [value, setValue, trigger]);

  useEffect(() => {
    if (isOpen && !value) {
      setValue('input', defaultValue || '');
    }
  }, [defaultValue, isOpen, setValue, value]);

  return (
    <Modal
      noPadding
      withAuto
      id={id}
      mountTransitionEnd={validateFieldsOnOpen ? () => void trigger() : undefined}
      open={show}
      unMountTransitionEnd={onClose}
      onClose={handleClose}
    >
      <div className="h-full w-[90vw] p-6 sm:w-[550px]">
        <Divide>
          <Flex column gapSize="small" itemAlignment="stretch">
            <Text color="light-black" fontWeight="bold">
              {title}
            </Text>
            <div className="flex flex-col">
              <div className="w-full py-4">
                <div className="w-full">
                  <section className="mb-3">
                    <div className="pt-4">
                      <Input
                        isRequired
                        control={control}
                        disabled={disabled}
                        id={`${id ?? 'name'}-input`}
                        label={label}
                        name="input"
                        rules={rules?.input ?? RULES.input}
                        type="text"
                        onChange={onChange}
                      />
                    </div>
                  </section>
                </div>
              </div>
              <If condition={alert !== undefined}>
                <Alert {...alert} />
              </If>
            </div>
            <ButtonsSection
              disabledSubmitButton={
                (disabledUntilOnChange && !isDirty) || disabledSubmitButton || !isValid
              }
              id={id}
              isLoading={isLoading}
              textAction={textAction}
              textClose={textClose}
              onAction={onAction}
              onClose={handleClose}
            />
          </Flex>
        </Divide>
      </div>
    </Modal>
  );
};

export default memo(ModalSingleInput);
