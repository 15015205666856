import { cloneElement, createContext, isValidElement, ReactElement } from 'react';

import { useGetContext } from '@/src/utils/ContextUtils';

import TabContent, { TabContentProps } from './TabContent';
import TabItem from './TabItem';
import TabNav, { TabNavProps } from './TabNav';
import TabPane from './TabPane';

export type Size = 'small' | 'medium' | 'large';

export interface TabProps {
  children?: [ReactElement<TabNavProps>, ReactElement<TabContentProps>];
  /** Currently activated tab id */
  currentTab: string | number;
  /** Tab Item size */
  size?: Size;
  /** Tab items stretch to the width of the container */
  isFullWidth?: boolean;
  /** show bottom line in all tabItems */
  isUnderlined?: boolean;
  /** Tab items ids that are disabled. You can send isDisabled prop boolean in Tab.TabNavItem component
   * @default undefined
   * @example [1, 2]
   * @example ['tab1', 'tab2']
   */
  disabledTabsIds?: (string | number)[];
  /** You can align tabItems on the left, center or right.
   * @default left
   */
  alignment?: 'left' | 'center' | 'right';
}

interface StateContext {
  currentTab: string | number;
}

const StateTabContext = createContext<StateContext | undefined>(undefined);

/**
 * The Tab component allows users to switch between different views or sections within the same page
 * @component
 * @param {TabProps} props - TabProps Component props
 * @typedef {object} TabProps
 *
 */
const Tab = ({
  children,
  currentTab,
  size = 'medium',
  isFullWidth = false,
  isUnderlined = false,
  disabledTabsIds,
  alignment = 'left',
}: TabProps) => {
  const initialState = { currentTab };

  if (!children) {
    return <h3>TabNav and TabContent were not provided as children of Tab.</h3>;
  }

  const [tabNav, tabContent] = children;

  if (
    !isValidElement(tabNav) ||
    !isValidElement(tabContent) ||
    tabNav.type !== TabNav ||
    tabContent.type !== TabContent
  ) {
    throw new Error(
      'The "children" prop in Tab must contain first one TabNav component and the second one TabContent component.'
    );
  }

  return (
    <StateTabContext.Provider value={initialState}>
      <div className="flex size-full flex-col gap-4">
        {cloneElement(tabNav, { isFullWidth, size, isUnderlined, disabledTabsIds, alignment })}
        {tabContent}
      </div>
    </StateTabContext.Provider>
  );
};

export const useStateTabContext = (): StateContext =>
  useGetContext<StateContext>(StateTabContext, 'Tab component context');

Tab.TabNav = TabNav;
Tab.TabNavItem = TabItem;
Tab.TabContent = TabContent;
Tab.TabContentPane = TabPane;

export default Tab;
