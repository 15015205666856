import { ReactNode } from 'react';

import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';

export type AlertType = 'success' | 'warning' | 'info' | 'error';

export const FILL_COLOR: Record<AlertType, ColorsIcon> = {
  success: 'success',
  warning: 'passive',
  info: 'primary',
  error: 'destructive',
} as const;

export interface AlertProps {
  /** The type of alert to display */
  type?: AlertType;
  /** The title of the alert - Required */
  title: string;
  /** Function to call when the close button is clicked */
  onClose?: () => void;
  /** Text to display in the primary button */
  textButton?: string;
  /** Function to call when the primary button is clicked */
  onClickButton?: () => void;
  /** Text to display in the secondary button */
  textButtonSecondary?: string;
  /** Function to call when the secondary button is clicked */
  onClickButtonSecondary?: () => void;
  /** The content of the alert */
  children?: ReactNode;
}
