import { useCallback, useState } from 'react';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { DEFAULT_PAYLOAD_RULE, RULES_TYPES_DICTIONARY } from '@/src/modules/RulesModule/constants';
import { PayloadSaveRules, RuleTypes } from '@/src/modules/RulesModule/interfaces';

import { ACTION_TYPE_MAPPING_ROUTES } from '@/modules/CampaignsModule/constants';
import { ActionTypeName } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface CreateRuleParams {
  ruleType: RuleTypes;
  campaignId: string;
  actionName: string;
  actionType: Extract<ActionTypeName, 'email-trigger'>;
}

const useCreateRule = () => {
  const rulesService = useRulesService();
  const service = useService();
  const campaignService = useCampaignsService();
  const [isLoading, setIsLoading] = useState(false);
  const createNewActionsV2 = useFeatureFlag('createNewActionsV2');

  /* cspell:disable */
  const createDefaultRule = useCallback(
    async (actionId: number, ruleType: RuleTypes) => {
      if (!ruleType) return;

      const payloadSave = {
        ...DEFAULT_PAYLOAD_RULE,
        elementoId: actionId,
        fechaEnvio:
          ruleType === RuleTypes.DATE || ruleType === RuleTypes.TAG
            ? '1|mes|antes|dia'
            : 'de inmediato',
        desencadenante: ruleType,
      } as PayloadSaveRules;

      await rulesService.saveRule(payloadSave);
    },
    [rulesService]
  );
  /* cspell:enable */

  const createRule = useCallback(
    async (params: CreateRuleParams): Promise<number | null> => {
      const { campaignId, actionName, actionType } = params;

      try {
        setIsLoading(true);

        if (createNewActionsV2) {
          const ruleType =
            RULES_TYPES_DICTIONARY[params.ruleType as keyof typeof RULES_TYPES_DICTIONARY];
          const payload = {
            elementName: actionName,
            elementType: ACTION_TYPE_MAPPING_ROUTES[actionType],
            campaignId: +campaignId,
            ruleType: ruleType?.id,
          };
          const response = await campaignService.createAction(payload);
          if (!response?.actionId) return null;
          await createDefaultRule(response.actionId, params.ruleType);
          return response.actionId;
        } else {
          const response = await service.createCampaignAction(actionName, actionType, campaignId);
          await createDefaultRule(response.id, params.ruleType);
          return response.id;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [createNewActionsV2, campaignService, createDefaultRule, service]
  );

  return {
    events: {
      createRule,
    },
    state: {
      loading: isLoading,
    },
  };
};

export default useCreateRule;
