import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { IDetailViewList } from '@/src/ContactsModule/components/ContactsViewInfo/ContactsDetailTable';
import { velocityBar } from '@/src/ContactsModule/components/ContactsViewInfo/ProgressVelocityEnum';
import { DELIMITER_LIST } from '@/src/modules/ContactsModule/constants';
import { CustomsFieldsProps } from '@/src/modules/ContactsModule/screens/ImportMain/V2ImportTypes';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';
import { FileStateProps } from '@/src/presentation/types/interfaces/IImport.interface';

import { ImportSteps } from '@/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';
/**
 * @desc Atom of the name of filename
 */
export const atomFiles = atomWithReset<FileStateProps>({
  step: ImportSteps.import,
  showStateCard: false,
});
atomFiles.debugLabel = 'atomFiles';

/**
 * @desc Atom of the name of filename
 */
export const atomFileName = atomWithReset<string>('');
atomFileName.debugLabel = 'atomFileName';

/**
 * @desc Atom of the process id of import contacts
 */
export const atomIdProcess = atomWithReset<number>(0);
atomIdProcess.debugLabel = 'atomIdProcess';

/**
 * @desc Atom of the process id of import contacts
 */
export const atomDelimiter = atom(DELIMITER_LIST[0]);
atomDelimiter.debugLabel = 'atomDelimiter';

/**
 * @desc Atom of the process id of import contacts
 */
export const atomDetailList = atomWithReset<IDetailViewList[] | undefined>(undefined);
atomDetailList.debugLabel = 'atomDetailList';

/**
 * @desc Atom of loading bar
 */
export const atomLoadingBar = atom<boolean>(false);
atomLoadingBar.debugLabel = 'atomLoadingBar';

/**
 * @desc Atom of progress notification
 */
export const atomShowProgressNotification = atomWithReset({
  show: false,
  percentage: 0,
  status: loadingStatus.initial,
  description: '',
  velocity: velocityBar.slow,
  step: 0,
});
atomShowProgressNotification.debugLabel = 'atomShowProgressNotification';

/**
 * @desc Atom of progress notification indeterminate progress
 */
export const atomShowIndeterminateNotification = atomWithReset({
  show: false,
  status: loadingStatus.initial,
  description: '',
});
atomShowIndeterminateNotification.debugLabel = 'atomShowIndeterminateNotification';

interface FileDownloadInfo {
  nameDownload: string;
  descriptionDownloaded: string;
}

/**
 * @desc Atom of information for the file download
 */
export const atomFileDownloadInfo = atom<FileDownloadInfo>({
  nameDownload: 'file.zip',
  descriptionDownloaded: '',
});

/**
 * @desc Atom of service response list custom field
 */
export const atomListCustomFieldServiceResponse = atom<CustomsFieldsProps[]>([]);
atomListCustomFieldServiceResponse.debugLabel = 'atomListCustomFieldServiceResponse';
