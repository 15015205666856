import { memo } from 'react';

import { Checkbox, Switch, Text } from '@/lib/v2/components';

import { IIncomingField } from '@/modules/RulesModule/interfaces';

interface ICustomFieldProps {
  field: IIncomingField;
  switchStatus: boolean;
  requiredStatus: boolean;
  onChange: (id: number, isCheckbox: boolean) => void;
  disabled?: boolean;
}

const CustomFieldControl = ({
  field,
  switchStatus,
  requiredStatus,
  onChange,
  disabled = false,
}: ICustomFieldProps) => {
  const handleSwitchChange = () => {
    onChange(field.id, false);
  };

  const handleCheckboxChange = () => {
    onChange(field.id, true);
  };

  return (
    <div className="my-2 flex items-center justify-between">
      <div className="flex items-center">
        <Switch
          checked={switchStatus}
          disabled={disabled}
          id={`switch-custom-field-${field.id}`}
          withLabels={false}
          onChange={handleSwitchChange}
        />
        <Text className="ml-4" variant="text">
          {field.label}
        </Text>
      </div>
      <div className="mr-6 flex items-center justify-end">
        <Checkbox
          checked={requiredStatus}
          disabled={disabled}
          id={`check-custom-field-${field.id}`}
          onChange={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default memo(CustomFieldControl);
