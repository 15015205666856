import { datadogRum } from '@datadog/browser-rum';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FloatModal, TableSkeleton, TextSkeleton } from '@/lib/components';
import { CleanGroupBoxRef } from '@/lib/components/Select/Select';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';
import { Container, Spinner, toast } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import ErrorBoundary from '@/src/application/components/ErrorBoundary';
import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import useMessage from '@/src/compat/useMessage';
import configData from '@/src/config.json';
import { ContactsActionBar } from '@/src/ContactsModule/components/ContactsActionBar';
import { ContactsFilters } from '@/src/ContactsModule/components/ContactsFilters';
import {
  ContactsGroupModal,
  ContactsTagModal,
} from '@/src/ContactsModule/components/ContactsModals';
import { ContactsTable } from '@/src/ContactsModule/components/ContactsTable/ContactsTable';
import { ITableContact, IUpdateItemsToPropsV2 } from '@/src/ContactsModule/interfaces/Interfaces';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { useContactList } from '@/src/infrastructure/services/useContactList';
import { useUserData } from '@/src/infrastructure/services/useUserData';

import { ContactsGroupsAssignmentModal } from '@/modules/ContactsModule/components/ContactsGroupsAssignmentModal';
import { ContactsTagsAssignmentModal } from '@/modules/ContactsModule/components/ContactsTagsAssignmentModal';
import {
  useMutationContactsContext,
  useStateContactsContext,
} from '@/modules/ContactsModule/contexts/ContactsContext';
import { DiscaredStatus } from '@/modules/ContactsModule/types/Contacts';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';

type ContactsTableContainerProps = {
  setCountMessage?: Dispatch<SetStateAction<ReactNode | undefined>>;
  setContactsCount?: Dispatch<SetStateAction<number | undefined>>;
  setContactsCountFiltered?: Dispatch<SetStateAction<number | undefined>>;
  isDisableGroupDropdown?: boolean;
  isDisableTagDropdown?: boolean;
  isDisableStatusDropdown?: boolean;
  isDisableHappinessDropdown?: boolean;
  statusFilterAction?: string;
  isDiscarded?: boolean;
  classNamePagination?: string;
  setLastRefreshParent?: Dispatch<SetStateAction<number>>;
};

const validReasonsReactivate = ['suspended', 'quarantine'];

export const ContactsTableContainer = ({
  setCountMessage,
  setContactsCount,
  setContactsCountFiltered,
  isDisableGroupDropdown,
  isDisableTagDropdown,
  isDisableStatusDropdown,
  isDisableHappinessDropdown,
  statusFilterAction,
  isDiscarded,
  classNamePagination,
  setLastRefreshParent,
}: ContactsTableContainerProps) => {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const service = useService();

  const segmentDetailId = segmentId ? Number(segmentId) : 0;
  const [initialLastRefreshValue] = useEmblue(ServiceMethods.getLastRefreshContactsValue);
  const [lastRefreshGroupsAndTagsList, setLastRefreshGroupsAndTagsList] = useState(0);

  // Context
  const {
    searchQuery,
    tagsFilter,
    groupsFilter,
    statusFilter,
    discardedFilter,
    happinessFilter,
    lastPageViewed,
    pageSize,
    pageIndex,
    totalPages,
    gotoPageIndex,
    lastRefresh,
  } = useStateContactsContext();

  const {
    setSearchQuery,
    changeTablePage,
    setLastRefresh,
    setListTotalPagination,
    resetPagination,
    setLastPageViewed,
  } = useMutationContactsContext();

  const { t } = useTranslation();

  const [contactsCount, , isLoadingContactsCount] = useEmblue(ServiceMethods.getContactsCount, {
    lastRefresh,
  }); // total total

  const [orderColumn, setOrderColumn] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>('desc');
  const [isChecked, setIsChecked] = useState(false);

  const [statusDiscarded] = useEmblue(ServiceMethods.getContactDiscardedStatus);

  const [contactList, , isLoadingContacts] = useContactList({
    page: pageIndex,
    pageSize,
    search: searchQuery,
    orderColumn,
    orderDirection,
    tagsFilter,
    statusFilter: statusFilter,
    happinessFilter: happinessFilter,
    groupsFilter,
    segmentsFilter: segmentDetailId,
    lastRefresh,
    discardedFilter,
  });

  const [contactsCountOfSearch, , isLoadingContactsCountOfSearch] = useEmblue(
    ServiceMethods.getContactsCountOfSearch,
    {
      search: searchQuery,
      tagsFilter,
      statusFilter,
      happinessFilter,
      groupsFilter,
      segmentsFilter: segmentDetailId,
      lastRefresh,
      discardedFilter,
    }
  );

  const [totalDiscardedContacts] = useEmblue(ServiceMethods.getTotalDiscardedContacts, {
    isDiscardedView: isDiscarded ?? false,
    lastRefresh,
  });
  const [status] = useEmblue(ServiceMethods.getContactStatus);
  const [happiness] = useEmblue(ServiceMethods.getContactHappiness);
  const [groups] = useEmblue(ServiceMethods.getGroups, {
    identifier: 'GROUPS_DROP_DOWN',
    lastRefresh: lastRefreshGroupsAndTagsList,
  });
  const [tags] = useEmblue(ServiceMethods.getTagsDropDown, {
    identifier: 'TAGS_DROP_DOWN',
    lastRefresh: lastRefreshGroupsAndTagsList,
  });
  const [flowsLists] = useEmblue(ServiceMethods.getFlowsByAccount);

  const cleanGroupBoxRef = useRef<CleanGroupBoxRef>(null);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [showSelectAllResultsButton, setShowSelectAllResultsButton] = useState(false);
  const [selectAllResults, setSelectAllResults] = useState(false);

  const [showSpinnerByAction, setShowSpinnerByAction] = useState(false);

  /***** Hooks to handle contactActionBar clicks ******/
  const [flowId, setFlowId] = useState(0);
  const [confirmToFlow, setConfirmToFlow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSuspend, setConfirmSuspend] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  /***** FIN Hooks to handle contactActionBar clicks ******/

  /// selectedContactsCountToAction will be : contactsCount (no filters applied) || contactsCountOfSearch (filters applied)
  /// || configData.CONTACTS_ACTIONS.maxContactsToAction (if the above conditions are not met)
  const [selectedContactsCountToAction, setSelectedContactsCountToAction] = useState<number>(
    configData.CONTACTS_ACTIONS.maxContactsToAction
  );

  const [maxGroupsToAdd, setMaxGroupsToAdd] = useState<number>();
  const [maxTagsToAdd, setMaxTagsToAdd] = useState<number>();
  const groupsListAux = groups?.list?.filter((g) => g && g.group && g.group.id).map((g) => g.group);

  const isValidUsersToReactivate = useCallback(
    (discaredStatus: DiscaredStatus[]) => {
      if (!contactList) return;
      const listAux: ITableContact[] = (contactList as { list: ITableContact[] }).list;
      const isNoValid =
        selectedContacts.length === pageSize
          ? listAux.some((c) => !discaredStatus.includes(c.contact.discarded as DiscaredStatus))
          : listAux
              .filter((c, i) => selectedContacts.includes(i))
              .some((c) => !discaredStatus.includes(c.contact.discarded as DiscaredStatus));
      return !isNoValid;
    },
    [contactList, pageSize, selectedContacts]
  );

  useEffect(() => {
    if (initialLastRefreshValue && initialLastRefreshValue > lastRefresh) {
      setLastRefresh(initialLastRefreshValue);
      setLastRefreshParent && setLastRefreshParent((prevState: number) => prevState + 1);
    }
  }, [initialLastRefreshValue, lastRefresh]);

  const lastRefreshIncrement = useCallback(() => {
    setLastRefresh((prevState: number) => prevState + 1);
    setLastRefreshParent && setLastRefreshParent((prevState: number) => prevState + 1);
    service?.incrementLastRefreshContactsValue();
  }, [service]); // setLastRefreshParent must NOT be part of dependencies

  //Remove this when create and import contact V2 its on production
  useMessage(EventListenerType.RefreshContacts, () => {
    lastRefreshIncrement();
  });

  const initialStateOrderByList = [
    { id: 'Name', isAsc: undefined },
    { id: 'Created', isAsc: false },
    { id: 'LastActivity', isAsc: undefined },
  ];
  const [tableOrderByList, setTableOrderByList] =
    useState<Array<{ id: string; isAsc: boolean | undefined }>>(initialStateOrderByList);

  const changeOrderBy = useCallback((element: { id: string; isAsc: boolean | undefined }) => {
    const orderDirectionValue = element.isAsc === true ? 'desc' : 'asc';
    setOrderDirection(orderDirectionValue);
    setOrderColumn(element.id);
  }, []);

  const resetContactList = () => {
    resetPagination();
    setOrderColumn(undefined);
    setOrderDirection('desc');
    cleanGroupBoxRef?.current?.cleanGroupBox();
    setTableOrderByList(initialStateOrderByList);
    setSearchQuery('');
  };

  useEffect(() => {
    const total =
      (contactsCountOfSearch?.count !== 0 ? contactsCountOfSearch?.count : contactsCount?.count) ||
      0;
    setListTotalPagination(total);
  }, [contactsCount?.count, setListTotalPagination, contactsCountOfSearch?.count]);

  const totalContactsCount = useMemo(() => {
    if (isDiscarded && totalDiscardedContacts) {
      return totalDiscardedContacts.count;
    } else if (contactsCount && !isDiscarded) {
      return contactsCount.count;
    }
    return undefined;
  }, [contactsCount, isDiscarded, totalDiscardedContacts]);

  useEffect(() => {
    if (setContactsCount) setContactsCount(totalContactsCount);
  }, [setContactsCount, totalContactsCount]);

  useEffect(() => {
    contactsCountOfSearch && setContactsCountFiltered && !searchQuery
      ? setContactsCountFiltered(contactsCountOfSearch?.count)
      : setContactsCountFiltered && setContactsCountFiltered(0);
  }, [searchQuery, setContactsCountFiltered, contactsCountOfSearch]);

  useEffect(() => {
    if (!setCountMessage) return;
    let countMessage: string | JSX.Element;
    //Waiting countOfSearch api response
    if (isLoadingContactsCountOfSearch) {
      countMessage = <TextSkeleton />;
    }
    //Total contacts is zero case
    else if (!contactsCount?.count) {
      countMessage = t('noResultsFound');
    }
    //No filters applied case
    else if (
      !searchQuery &&
      !tagsFilter.length &&
      !statusFilter.length &&
      !happinessFilter.length &&
      !discardedFilter.length &&
      !groupsFilter.length &&
      segmentDetailId === 0
    ) {
      countMessage = <span>{t('CONTACTS_MAIN.noFilters')}</span>;
    }
    //Any filter applied case
    else if (
      (tagsFilter.length ||
        statusFilter.length ||
        happinessFilter.length ||
        discardedFilter.length ||
        groupsFilter.length ||
        segmentDetailId !== 0 ||
        searchQuery) &&
      contactsCountOfSearch
    ) {
      // DiscardedMain section without filters
      if (
        isDiscarded &&
        statusFilter.includes('discarded') &&
        !discardedFilter.length &&
        !happinessFilter.length &&
        !groupsFilter.length &&
        !tagsFilter.length &&
        !searchQuery
      ) {
        countMessage = <span>{t('CONTACTS_MAIN.noFilters')}</span>;
      }
      // any view with filters and no results found
      else if (!contactsCountOfSearch?.count) {
        countMessage = t('noResultsFound');
      }
      //In any section with filters and api response OK
      else {
        countMessage = (
          <span>
            {' '}
            {t('CONTACTS_MAIN.youHave')}{' '}
            <span className="font-medium text-emblue-primary">
              {' '}
              {quantifier(contactsCountOfSearch?.count)}{' '}
            </span>{' '}
            {contactsCountOfSearch?.count === 1
              ? t('CONTACTS_MAIN.contactsMatchSingular')
              : t('CONTACTS_MAIN.contactsMatch')}
          </span>
        );
      }
    }
    //No results case
    else {
      countMessage = t('noResultsFound');
    }
    setCountMessage(countMessage);
  }, [
    contactsCount?.count,
    groupsFilter,
    segmentDetailId,
    searchQuery,
    isLoadingContactsCount,
    statusFilter,
    happinessFilter,
    discardedFilter,
    tagsFilter,
    isDiscarded,
    isLoadingContactsCountOfSearch,
    t,
    contactsCountOfSearch,
  ]);

  useEffect(() => {
    if (!isChecked && selectedContacts.length > 0) {
      setIsChecked(true);
    }
    if (isChecked && selectedContacts.length === 0) {
      setIsChecked(false);
      setSelectAllResults(false);
    }
    setShowSelectAllResultsButton(selectedContacts.length === pageSize);
    setSelectedContactsCountToAction(
      contactsCountOfSearch?.count
        ? contactsCountOfSearch.count <= configData.CONTACTS_ACTIONS.maxContactsToAction
          ? contactsCountOfSearch.count
          : configData.CONTACTS_ACTIONS.maxContactsToAction
        : contactsCount?.count &&
          contactsCount.count <= configData.CONTACTS_ACTIONS.maxContactsToAction
        ? contactsCount?.count
        : configData.CONTACTS_ACTIONS.maxContactsToAction
    );
  }, [selectedContacts]);

  useEffect(() => {
    if (confirmDelete) {
      deleteContacts()
        .then(() => setLastRefresh(lastRefresh + 1))
        .catch(console.error);
      setConfirmDelete(false);
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (confirmSuspend) {
      suspendContacts()
        .then(() => setLastRefresh(lastRefresh + 1))
        .catch(console.error);
      setConfirmSuspend(false);
    }
  }, [confirmSuspend]);

  useEffect(() => {
    if (confirmToFlow && flowId) {
      addContactToFlow(flowId)
        .then(() => setLastRefresh(lastRefresh + 1))
        .catch(console.error);
      setConfirmToFlow(false);
    }
  }, [confirmToFlow, flowId]);

  useEffect(() => {
    if (selectAllResults) {
      const maxGroups = Math.floor(
        configData.CONTACTS_ACTIONS.maxRowsGroups / selectedContactsCountToAction
      );
      const maxTags = Math.floor(
        configData.CONTACTS_ACTIONS.maxRowsTags / selectedContactsCountToAction
      );
      setMaxGroupsToAdd(maxGroups === 0 ? 1 : maxGroups);
      setMaxTagsToAdd(maxTags === 0 ? 1 : maxTags);
    } else {
      setMaxGroupsToAdd(undefined);
      setMaxTagsToAdd(undefined);
    }
  }, [selectAllResults]);

  // TODO: Refactor this to avoid refresh the entire window
  const redirectToView = (path: string, withRefresh?: boolean) => {
    navigate(path);
    withRefresh && navigate(0);
  };

  const deleteContacts = async () => {
    // eslint-disable-next-line eqeqeq
    if (contactList == undefined) return;
    setShowSpinnerByAction(true);
    let result: boolean | undefined;
    if (selectAllResults) {
      result = await service?.deleteAllSelectedContacts({
        search: searchQuery,
        tagsFilter,
        groupsFilter,
        statusFilter,
        happinessFilter,
        segmentsFilter: segmentDetailId,
        discardedFilter,
        resultCount: selectedContactsCountToAction,
      });
    } else {
      const contactsIds = getContactsIds();
      result = await service?.deleteSelectedContacts({ contactsIds: contactsIds });
    }
    setShowSpinnerByAction(false);
    if (result) {
      lastRefreshIncrement();
      redirectToView(`/v2/contacts${window.location.search}`);
    }
    showNotification(result ? result : false);
  };

  const getContactsIds = useCallback(
    (list?: ITableContact[]) => {
      const listAux: ITableContact[] = list ?? (contactList as { list: ITableContact[] }).list;
      const contactsIds: number[] =
        selectedContacts.length === pageSize
          ? listAux.map((c) => c.contact.emailId) // all ids of page
          : listAux.filter((c, i) => selectedContacts.includes(i)).map((c) => c.contact.emailId); // ids of some contacts in page
      return contactsIds;
    },
    [contactList, pageSize, selectedContacts]
  );

  const suspendContacts = async () => {
    if (contactList === undefined) return;

    setShowSpinnerByAction(true);
    let result: boolean | undefined;
    if (selectAllResults) {
      result = await service?.suspendAllSelectedContacts({
        search: searchQuery,
        tagsFilter,
        groupsFilter,
        statusFilter,
        happinessFilter,
        segmentsFilter: segmentDetailId,
        discardedFilter,
        resultCount: selectedContactsCountToAction,
      });
    } else {
      const contactsIds = getContactsIds();
      result = await service?.suspendSelectedContacts({ contactsIds: contactsIds });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      lastRefreshIncrement();
      redirectToView(`/v2/contacts${window.location.search}`);
    }
  };

  const hasInvalidFilters = useMemo(
    () => discardedFilter.some((filter) => !validReasonsReactivate.includes(filter)),
    [discardedFilter]
  );

  const reactivateUsers = useCallback(async () => {
    if (!contactList) return;

    setShowSpinnerByAction(true);
    setIsChecked(false);
    setSelectAllResults(false);
    setShowSelectAllResultsButton(false);
    let emailsIds: number[] = [];

    if (selectAllResults) {
      const response = await service?.getAllEmailsIdsDiscardedUsers({
        search: searchQuery,
        tagsFilter,
        groupsFilter,
        statusFilter: validReasonsReactivate,
        happinessFilter,
        segmentsFilter: segmentDetailId,
        discardedFilter,
        resultCount: selectedContactsCountToAction,
      });
      if (response?.success) emailsIds = response.data;
    } else {
      emailsIds = getContactsIds();
    }

    const result = await service?.reactivateDiscardedUsersSelected(emailsIds);

    if (result?.success) {
      showNotification(true);
      lastRefreshIncrement();
    } else {
      showNotification(false);
    }
    setShowSpinnerByAction(false);
  }, [
    contactList,
    discardedFilter,
    getContactsIds,
    groupsFilter,
    happinessFilter,
    lastRefreshIncrement,
    searchQuery,
    segmentDetailId,
    selectAllResults,
    selectedContactsCountToAction,
    service,
    tagsFilter,
  ]);

  const addContactToFlow = async (flowsId: number) => {
    if (contactList === undefined) return;

    setShowSpinnerByAction(true);
    let result: boolean | undefined;
    if (selectAllResults) {
      result = await service?.addFlowsToAllContacts({
        flowId: flowsId,
        search: searchQuery,
        tagsFilter,
        groupsFilter,
        statusFilter,
        happinessFilter,
        segmentsFilter: segmentDetailId,
        discardedFilter,
        resultCount: selectedContactsCountToAction,
      });
    } else {
      const contactsIds = getContactsIds();
      result = await service?.addFlowsToContacts({ flowId: flowId, contactsIds: contactsIds });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      lastRefreshIncrement();
      redirectToView(`/v2/contacts${window.location.search}`);
    }
  };

  const showNotification = (result: boolean) => {
    toast({
      title: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkTitle')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertTitle'),
      body: result
        ? t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.OkBody')
        : t('CONTACTS_ACTIONS_DROPDOWN.NOTIFICATION.AlertBody'),
      variant: result ? 'success' : 'error',
    });
  };

  const isEmptySegment =
    window.location.pathname.includes('segments') &&
    !searchQuery &&
    contactsCountOfSearch?.count === 0;

  const handleLastRefresh = useCallback(() => {
    setLastRefreshGroupsAndTagsList((prevState) => prevState + 1);
  }, []);

  const onActionImportContacts = useCallback(() => {
    navigate({ pathname: `/v2/${RouteEnum.ContactsImport}` });
  }, [navigate]);

  /************************ Add / Remove contacts to groups and tags region ************************/

  const isGroupsByContactsEnabled = useFeatureFlag('groupsByContacts');
  const isTagsByContactsEnabled = useFeatureFlag('tagsByContacts');
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useUserData();

  const [contactsFilters, setContactsFilters] = useState<ContactTableSearchFilters>({
    search: searchQuery,
    tagsFilter,
    groupsFilter,
    statusFilter,
    happinessFilter,
    segmentsFilter: segmentDetailId,
    discardedFilter,
    resultCount: selectedContactsCountToAction,
  });

  useEffect(() => {
    setContactsFilters({
      search: searchQuery,
      tagsFilter,
      groupsFilter,
      statusFilter,
      happinessFilter,
      segmentsFilter: segmentDetailId,
      discardedFilter,
      resultCount: selectedContactsCountToAction,
    });
  }, [
    searchQuery,
    tagsFilter,
    groupsFilter,
    statusFilter,
    happinessFilter,
    segmentDetailId,
    discardedFilter,
    selectedContactsCountToAction,
  ]);

  const handleUpdateContactsToGroups = async ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => {
    let result: boolean | undefined;

    const selectedItemsArray = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unselectedItems);

    if (selectAllResults) {
      setIsLoading(true);
      try {
        result = await service?.addAllContactsToGroup({
          groupsToAssign: selectedItemsArray.map((g) => parseInt(g.id)),
          groupsToUnassign: unselectedItemsArray.map((g) => parseInt(g.id)),
          ...contactsFilters,
        });
      } catch (error) {
        datadogRum.addAction('addAllContactsToGroup.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    } else {
      setIsLoading(true);
      try {
        result = await service?.updateContactsToGroup({
          contactsIds: selectedContacts,
          groupsToAssign: selectedItemsArray.map((g) => parseInt(g.id)),
          groupsToUnassign: unselectedItemsArray.map((g) => parseInt(g.id)),
        });
      } catch (error) {
        datadogRum.addAction('addTableContactsToGroup.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    }

    setIsLoading(false);
    setShowGroupsModal(false);
    showNotification(result ? result : false);

    const totalSelected = [...selectedItemsArray, ...unselectedItemsArray];
    if (result) {
      if (totalSelected.length === 1) {
        redirectToView(`/v2/contacts/groups/${totalSelected.at(0)?.id}`, true);
      } else {
        redirectToView('/v2/contacts/groups');
      }
    }
    return;
  };

  const handleUpdateContactsToTags = async ({
    selectedContacts,
    selectedItems,
    unselectedItems,
  }: IUpdateItemsToPropsV2) => {
    let result: boolean | undefined;

    const selectedItemsArray = Object.values(selectedItems);
    const unselectedItemsArray = Object.values(unselectedItems);

    if (selectAllResults) {
      setIsLoading(true);
      try {
        result = await service?.associateTagsToAllContacts({
          tagsToAssign: selectedItemsArray.map((t) => parseInt(t.id)),
          tagsToUnassign: unselectedItemsArray.map((t) => parseInt(t.id)),
          ...contactsFilters,
        });
      } catch (error) {
        datadogRum.addAction('addAllContactsToTag.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    } else {
      setIsLoading(true);
      try {
        result = await service?.updateContactsToTags({
          contactsIds: selectedContacts,
          tagsToAssign: selectedItemsArray.map((t) => parseInt(t.id)),
          tagsToUnassign: unselectedItemsArray.map((t) => parseInt(t.id)),
        });
      } catch (error) {
        datadogRum.addAction('addTableContactsToTag.error', {
          error,
          accountId: Number(userData.companyId),
          contactsFilters,
        });

        toast({
          title: t('MAPPING_NOTIFICATION.errorTitle'),
          body: t('MAPPING_NOTIFICATION.errorBody'),
          variant: 'error',
        });
      }
    }
    setIsLoading(false);
    setShowTagsModal(false);
    showNotification(result ? result : false);

    if (result) {
      if (selectedItemsArray.length === 1 && unselectedItemsArray.length === 0) {
        redirectToView(`/v2/contacts/tags/${selectedItemsArray[0].id}`, true);
      } else {
        redirectToView('/v2/contacts/tags');
      }
    }
    return;
  };

  /************************ END Add / Remove contacts to groups and tags region ************************/

  /************************ Remove this when feature in V2 is checked ************************/

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [unselectedGroups, setUnselectedGroups] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [unselectedTags, setUnselectedTags] = useState<number[]>([]);
  /* eslint-enable @typescript-eslint/no-unused-vars */

  useEffect(() => {
    if (selectedGroups.length >= 1) {
      updateContactsToGroupsLegacy().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  useEffect(() => {
    if (selectedTags.length >= 1) {
      updateContactsToTagsLegacy().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const updateContactsToGroupsLegacy = async () => {
    let result: boolean | undefined;
    if (!selectAllResults) {
      setShowGroupsModal(false);
      setShowSpinnerByAction(true);
      result = await service?.updateContactsToGroup({
        contactsIds: getContactsIds(),
        groupsToAssign: selectedGroups,
        groupsToUnassign: unselectedGroups,
      });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      if (selectedGroups.length === 1)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        redirectToView(`/v2/contacts/groups/${selectedGroups.at(0)}`, true);
      else redirectToView('/v2/contacts/groups');
    }
    return;
  };

  const updateContactsToTagsLegacy = async () => {
    let result: boolean | undefined;
    if (!selectAllResults) {
      setShowTagsModal(false);
      setShowSpinnerByAction(true);
      result = await service?.updateContactsToTags({
        contactsIds: getContactsIds(),
        tagsToAssign: selectedTags,
        tagsToUnassign: unselectedTags,
      });
    }
    setShowSpinnerByAction(false);
    showNotification(result ? result : false);
    if (result) {
      redirectToView(
        selectedTags.length === 1
          ? `/v2/contacts/tags/${selectedTags.at(0) || ''}`
          : '/v2/contacts/tags'
      );
    }
    return;
  };
  /************************ Remove this when feature in V2 is checked ************************/

  return (
    <ErrorBoundary>
      <Flex column withGap gapSize="medium">
        <Flex alignment="start">
          {!isChecked ? (
            <Container>
              {((contactsCount && contactsCount.count > 0) || contactList?.list) && (
                <ContactsFilters
                  cleanGroupBoxRef={cleanGroupBoxRef}
                  disableGroupDropdown={isDisableGroupDropdown}
                  disableHappinessDropdown={isDisableHappinessDropdown}
                  disableStatusDropdown={isDisableStatusDropdown}
                  disableTagDropdown={isDisableTagDropdown}
                  discardedView={isDiscarded}
                  groups={
                    groups && groups.list?.filter((g) => g.group.contacts && g.group.contacts > 0)
                  }
                  happiness={happiness && happiness.list}
                  status={
                    isDiscarded ? statusDiscarded && statusDiscarded.list : status && status.list
                  }
                  statusFilterAction={statusFilterAction}
                  tags={tags && tags.list}
                  onReset={resetContactList}
                />
              )}
            </Container>
          ) : (
            <ContactsActionBar
              contactSelection={
                selectAllResults ? selectedContactsCountToAction : selectedContacts.length
              }
              discardedView={isDiscarded}
              flowsLists={flowsLists?.list ?? []}
              isValidReactivateDiscardedUsers={
                selectAllResults
                  ? !hasInvalidFilters
                  : isValidUsersToReactivate(['suspended', 'quarantine'])
              }
              setConfirmDelete={setConfirmDelete}
              setConfirmSuspend={setConfirmSuspend}
              setConfirmToFlow={setConfirmToFlow}
              setFlowId={setFlowId}
              setShowGroupsModal={setShowGroupsModal}
              setShowTagsModal={setShowTagsModal}
              onReactivateDiscardedUsers={reactivateUsers}
            />
          )}
        </Flex>
        {showSelectAllResultsButton && (
          <Flex alignment="center">
            {!selectAllResults ? (
              <div>
                <span>
                  {t('CONTACTS_ACTIONS_DROPDOWN.All')} <b>{pageSize}</b>{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.ContactsPageSelected')}
                </span>
                <span
                  aria-hidden="true"
                  className="ml-1 cursor-pointer rounded-md p-2 font-medium text-[#004dbc] hover:bg-slate-50"
                  onClick={() => setSelectAllResults(true)}
                >
                  {selectedContactsCountToAction ===
                  configData.CONTACTS_ACTIONS.maxContactsToAction ? (
                    <span title={t('CONTACTS_ACTIONS_DROPDOWN.WorkingOnIncreasingNumber')}>
                      {t('CONTACTS_ACTIONS_DROPDOWN.SelectFirst')}{' '}
                      {quantifier(selectedContactsCountToAction)}{' '}
                      {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}
                    </span>
                  ) : (
                    <span>
                      {t('CONTACTS_ACTIONS_DROPDOWN.SelectAll')}{' '}
                      {quantifier(selectedContactsCountToAction)}{' '}
                      {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}
                    </span>
                  )}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  {t('CONTACTS_ACTIONS_DROPDOWN.All')}{' '}
                  <b>{quantifier(selectedContactsCountToAction)}</b>{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.ContactsMatchSearch')}{' '}
                  {t('CONTACTS_ACTIONS_DROPDOWN.AreSelected')}
                </span>
                <span
                  aria-hidden="true"
                  className="ml-1 cursor-pointer rounded-md p-2 font-medium text-[#004dbc] hover:bg-slate-50"
                  onClick={() => setSelectAllResults(false)}
                >
                  {t('CONTACTS_ACTIONS_DROPDOWN.ClearSelection')}
                </span>
              </div>
            )}
          </Flex>
        )}
        {!contactList && (
          <div className="size-full py-10">
            <TableSkeleton />
          </div>
        )}
        {contactList && (
          <ContactsTable
            noNegativeMargin
            callToAction={[resetContactList, onActionImportContacts]}
            changeTableOrder={changeOrderBy}
            changeTablePage={changeTablePage}
            classNamePagination={classNamePagination}
            contactList={contactList.list}
            gotoPageIndex={gotoPageIndex}
            isDiscarded={isDiscarded}
            isEmptySegment={isEmptySegment}
            isLoading={isLoadingContacts}
            lastPageViewed={lastPageViewed}
            pageIndex={pageIndex}
            setLastPageViewed={setLastPageViewed}
            setSelectedContacts={setSelectedContacts}
            setTableOrderByList={setTableOrderByList}
            tableOrderByList={tableOrderByList}
            toggleAllRowsSelected={selectAllResults}
            totalContacts={contactsCount ? contactsCount.count : 0}
            totalPages={totalPages}
          />
        )}
        {showGroupsModal && isGroupsByContactsEnabled && (
          <ContactsGroupsAssignmentModal
            allContactsCount={selectedContactsCountToAction}
            groups={groupsListAux || [{ id: 0, groupName: '' }]}
            isLoading={isLoading}
            isOpen={showGroupsModal}
            selectAllResults={selectAllResults}
            selectedContacts={getContactsIds()}
            onAssignment={handleUpdateContactsToGroups}
            onClose={setShowGroupsModal}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showGroupsModal && !isGroupsByContactsEnabled && (
          <ContactsGroupModal
            groups={groupsListAux === undefined ? [{ id: 0, groupName: '' }] : groupsListAux}
            maxGroupsToAdd={maxGroupsToAdd}
            selectedContacts={
              isGroupsByContactsEnabled && getContactsIds().length < (maxGroupsToAdd ?? Infinity)
                ? getContactsIds()
                : undefined
            }
            setSelectedGroups={setSelectedGroups}
            setShowGroupsModal={setShowGroupsModal}
            setUnselectedGroups={setUnselectedGroups}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showTagsModal && isTagsByContactsEnabled && (
          <ContactsTagsAssignmentModal
            allContactsCount={selectedContactsCountToAction}
            isLoading={isLoading}
            isOpen={showTagsModal}
            maxTagsToAdd={3}
            selectAllResults={selectAllResults}
            selectedContacts={getContactsIds()}
            tags={tags === undefined ? [{ id: 0, name: '' }] : tags.list}
            onAssignment={handleUpdateContactsToTags}
            onClose={setShowTagsModal}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showTagsModal && !isTagsByContactsEnabled && (
          <ContactsTagModal
            maxTagsToAdd={maxTagsToAdd}
            selectedContacts={
              isTagsByContactsEnabled && getContactsIds().length < (maxTagsToAdd ?? Infinity)
                ? getContactsIds()
                : undefined
            }
            setSelectedTags={setSelectedTags}
            setShowTagsModal={setShowTagsModal}
            setUnselectedTags={setUnselectedTags}
            tags={tags === undefined ? [{ id: 0, name: '' }] : tags.list}
            onLastRefresh={handleLastRefresh}
          />
        )}
        {showSpinnerByAction && (
          <FloatModal>
            <Spinner withoutOverlay />
          </FloatModal>
        )}
      </Flex>
    </ErrorBoundary>
  );
};
