import { useFlag } from '@unleash/proxy-client-react';

import { getEnv } from './useEnv';

const allFlags = {
  // BETA:
  showNpsModule: true,
  showInsights: true,
  createSimpleEmail: true,
  createSimpleSMS: true,
  showVideoContactsV2: true,
  showFinishedActionsDashboard: true,
  createNewActionsV2: true,
  //TODO: remove this FF after refactor campaigns [stage 2]
  showRecurrentMessage: true,
  //TODO: Evaluate whether this FF can be removed
  hideMainContainerV1: false,
  //TODO: remove this FF after refactor campaigns [stage 2]
  defaultSortDateFinishActions: true,
  showCampaignInfoCards: false,
  //TODO: remove this FF
  campaignsShowTablePreview: true,
  insertWidgetNpsInHTML: true,
  showCreateEmailActionCard: true,
  //TODO: remove this FF after refactor campaigns [stage 2]
  showCampaignCalendar: true,
  campaignSearchAll: false,
  //TODO: remove this FF
  showDeprecateCampaignsV1: false,
  configMenuApiManager: true,
  //TODO: remove this FF
  showDragDropRemovalNotice: false,
  //TODO: remove this FF after refactor campaigns [stage 2]
  duplicateAction: true,
  showRulesTypeModalActions: true,
  duplicateCampaign: true,
  showCampaigns: true,
  tagsByContacts: true,
  groupsByContacts: true,
  showRulesModuleV2: true,
  showNotificationOldEditors: true,
  //TODO: remove this FF
  disableLegacyActionDuplication: false,
  // DELTA
  showSmartFinder: true,
  automaticDebitStripeButton: true, //TODO: check
  showAutomation: true,
  hiddenUsageAndBilling: false,
  showNewAutomationModule: true,
  flowsDisorderFix: true,
  showIntegrationModule: true,
  // Features no implemented
  activityLogCampaign: false,
  // 4 DEVELOPERS
  showCrashButton: true,
  loginV2CheckCountry: false,
  MFA: false,
  // Inbox: chat-staging-na.embluemail.com
  enableWhatsAppModule: true,
  limitInboxAgent: false,
  enableWhatsAppColumnConsumption: true,
};

type AppFlagKeys = keyof typeof allFlags;

export const useFeatureFlag = (flag: AppFlagKeys) => {
  const env = getEnv();
  const isFlagEnabled = useFlag(flag);

  if (env === 'development' || env === 'storybook') {
    return allFlags[flag];
  }
  return isFlagEnabled;
};
