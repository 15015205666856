import { datadogRum } from '@datadog/browser-rum';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CallBackProps, STATUS, Step } from 'react-joyride';

import { useUserNotifications } from '@/src/application/hooks/useUserNotifications';

import { atomProductTourToggle } from '@/modules/InsightsModule/atoms/InsightsAtom';
import { TourStep } from '@/modules/InsightsModule/components/InsightsProductTour/components/TourStep';

const PREFERENCE_PAYLOAD = {
  module: 'insights',
  preferenceKey: 'product-tour',
};

export const useInsightsProductTour = () => {
  const { t } = useTranslation(['insights']);

  const setRunTour = useSetAtom(atomProductTourToggle);

  const { getNotification, saveNotification } = useUserNotifications();

  const checkPreference = useCallback(async () => {
    return await getNotification(PREFERENCE_PAYLOAD);
  }, [getNotification]);

  const datadogMetricShowTour = useCallback(() => {
    datadogRum.addAction('insights.productTour.show');
  }, []);

  const startTour = useCallback(
    async (delay = 1000) => {
      const preference = await checkPreference();
      if (!preference) {
        setTimeout(() => {
          setRunTour(true);
          datadogMetricShowTour();
        }, delay);
      }
    },
    [datadogMetricShowTour, setRunTour, checkPreference]
  );

  const handleJoyrideCallback = useCallback(
    async (data: CallBackProps) => {
      const { status } = data;
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        setRunTour(false);
        await saveNotification({
          ...PREFERENCE_PAYLOAD,
          value: {
            type: status,
          },
        });
      }
    },
    [setRunTour, saveNotification]
  );

  const steps: Step[] = [
    {
      target: '.contacts-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.ONE.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.ONE.title')}
        />
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.contacts-graph',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.TWO.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.TWO.title')}
        />
      ),
      placement: 'left',
    },
    {
      target: '.channel-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.THREE.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.THREE.title')}
        />
      ),
      placement: 'top-start',
      isFixed: true,
    },
    {
      target: '.actions-summary',
      content: (
        <TourStep
          description={t('INSIGHTS_PRODUCT_TOUR.STEPS.FOUR.description')}
          title={t('INSIGHTS_PRODUCT_TOUR.STEPS.FOUR.title')}
        />
      ),
      placement: 'top-start',
      isFixed: true,
    },
  ];

  return {
    events: {
      handleJoyrideCallback,
      startTour,
    },
    state: {
      steps,
    },
  };
};
