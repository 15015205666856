import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { formatNormalizeLocalizedDate } from '@/src/application/util/formatLocalizedDate';
import { IGraphEvolutionList } from '@/src/modules/InsightsModule/interfaces/ContactsEvolution';
import {
  IChannelTotals,
  InsightsMetricActionsType,
  ITrackingTotals,
} from '@/src/modules/InsightsModule/interfaces/ContactsSummary';

import {
  ChannelTotalType,
  ShippingFilters,
  ShippingGraphMetric,
} from '@/modules/InsightsModule/interfaces/Channel';

export const atomMetricType = atom<ChannelTotalType>(ChannelTotalType.ALL);
atomMetricType.debugLabel = 'atomMetricType';

export const atomChannelTotals = atom<IChannelTotals | null>(null);
atomChannelTotals.debugLabel = 'atomChannelTotals';

export const atomChannelTracking = atom<ITrackingTotals | null>(null);
atomChannelTracking.debugLabel = 'atomChannelTracking';

export const atomMetricGraph = atom<ShippingGraphMetric[]>([]);
atomMetricGraph.debugLabel = 'atomMetricGraph';

const startDate = formatNormalizeLocalizedDate(
  new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
).substring(0, 10);
const endDate = formatNormalizeLocalizedDate(new Date()).substring(0, 10);

export const atomShippingFilters = atom<ShippingFilters>({
  startDate: startDate,
  endDate: endDate,
  filterType: InsightsMetricActionsType.ALL,
});
atomShippingFilters.debugLabel = 'atomShippingFilters';

export const atomShippingMetricLoading = atom(false);
atomShippingMetricLoading.debugLabel = 'atomShippingMetricLoading';

export const atomGraphEvolutionList = atomWithReset<IGraphEvolutionList[]>([]);
atomGraphEvolutionList.debugLabel = 'atomGraphEvolutionList';

export const atomProductTourToggle = atom(false);
atomProductTourToggle.debugLabel = 'atomProductTourToggle';
