import { Text } from '@/lib/v2/components';

interface TourStepProps {
  title: string;
  description: string;
}

export const TourStep = ({ title, description }: TourStepProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Text as="h2" color="black" fontWeight="bold">
        {title}
      </Text>
      <Text as="p" color="black" variant="text">
        {description}
      </Text>
    </div>
  );
};
