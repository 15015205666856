import { useAtomValue } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection } from '@/lib/v2/components';
import { Tab } from '@/lib/v3/components';

import { ECustomFieldsByType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';
import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { useUserData } from '@/src/infrastructure/services/useUserData';
import CustomFieldsForm from '@/src/modules/RulesModule/components/FormRule/components/forms/FormWebForm/components/CustomFieldsForm';
import DownloadSource from '@/src/modules/RulesModule/components/FormRule/components/forms/FormWebForm/components/DownloadSource';
import LivePreview from '@/src/modules/RulesModule/components/FormRule/components/forms/FormWebForm/components/LivePreview';
import SourceCode from '@/src/modules/RulesModule/components/FormRule/components/forms/FormWebForm/components/SourceCode';

import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { atomFormFieldsStatusAtom, atomRuleDataRaw } from '@/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import {
  IFormProps,
  IRuleForm,
  IRulesV1Response,
  ISelectCountryOption,
} from '@/modules/RulesModule/interfaces';
import { generateHtmlContent } from '@/modules/RulesModule/utils/generateHtmlContent';

interface IFormTabsProps {
  onTabChange: (tabId: number) => void;
  form: IFormProps<IRuleForm>;
  activeTab: number;
  setActiveTab: (tabId: number) => void;
  disabled?: boolean;
}

const FormTabs = ({
  onTabChange,
  form,
  activeTab,
  setActiveTab,
  disabled = false,
}: IFormTabsProps) => {
  const { t } = useTranslation('rules');

  const [userData] = useUserData();
  const accountId = userData.companyId;

  const env = getEnv() as Exclude<Environments, 'storybook'>;

  const { URL_ONLINE_EXTERNAL_URL } = configData.endpoints[env];
  const newUrlOnlineExternalUrl = URL_ONLINE_EXTERNAL_URL.replace(/account-id/g, accountId);

  const formFieldsStatus = useAtomValue(atomFormFieldsStatusAtom);
  const rulesData = useAtomValue(atomRuleDataRaw);

  const {
    events: { getCustomFieldsByType, translateCustomField },
  } = useCustomField();
  const { countriesOptions } = useCountriesOptions();

  const customFieldsDTOList = useMemo(() => {
    const fields = getCustomFieldsByType({ type: ECustomFieldsByType.ALL });

    return fields
      .filter((field) => field.name !== 'email')
      .map((field) => ({
        id: field.id,
        label: `${translateCustomField(field.name).charAt(0).toUpperCase()}${translateCustomField(
          field.name
        )
          .slice(1)
          .toLowerCase()}`,
        value: field.metadata.internalNumber,
        data: field.metadata,
        type: field.type,
      }));
  }, [getCustomFieldsByType, translateCustomField]);

  const mappedCountriesOptions: ISelectCountryOption[] = useMemo(
    () =>
      countriesOptions.map((country) => ({
        value: String(country.value),
        label: String(country.name),
      })),
    [countriesOptions]
  );

  const htmlContent = useMemo(() => {
    return generateHtmlContent(
      customFieldsDTOList,
      formFieldsStatus,
      mappedCountriesOptions,
      t,
      rulesData as IRulesV1Response,
      newUrlOnlineExternalUrl
    );
  }, [
    customFieldsDTOList,
    formFieldsStatus,
    mappedCountriesOptions,
    t,
    rulesData,
    newUrlOnlineExternalUrl,
  ]);

  const handleTabChange = useCallback(
    (tabId: number, event?: React.MouseEvent) => {
      event?.preventDefault();
      setActiveTab(tabId);
      onTabChange(tabId);
    },
    [onTabChange, setActiveTab]
  );

  return (
    <>
      <DownloadSource contentHtml={htmlContent} />
      <Card noBorder noShadow className="!h-auto pt-6 text-14">
        <CardSection noPadding>
          <Tab currentTab={activeTab}>
            <Tab.TabNav>
              <Tab.TabNavItem tabId={1} onClick={(event) => handleTabChange(1, event)}>
                {t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.formFields`)}
              </Tab.TabNavItem>
              <Tab.TabNavItem tabId={2} onClick={(event) => handleTabChange(2, event)}>
                {t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.livePreview`)}
              </Tab.TabNavItem>
              <Tab.TabNavItem tabId={3} onClick={(event) => handleTabChange(3, event)}>
                {t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.sourceCode`)}
              </Tab.TabNavItem>
            </Tab.TabNav>
            <Tab.TabContent>
              <Tab.TabContentPane tabId={1}>
                <CustomFieldsForm
                  customFieldsDTO={customFieldsDTOList}
                  disabled={disabled}
                  form={form}
                />
              </Tab.TabContentPane>
              <Tab.TabContentPane tabId={2}>
                <LivePreview contentHtml={htmlContent} />
              </Tab.TabContentPane>
              <Tab.TabContentPane tabId={3}>
                <SourceCode contentHtml={htmlContent} />
              </Tab.TabContentPane>
            </Tab.TabContent>
          </Tab>
        </CardSection>
      </Card>
    </>
  );
};

export default FormTabs;
