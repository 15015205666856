import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomContractCountry,
  atomDownloadingReport,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomActionInfoSms,
  atomActionRecipients,
  atomActionReportSms,
  atomInfoSmsPayload,
} from '@/src/modules/CampaignsModule/atoms/actionSMSAtom';
import {
  REPORT_PARAMS,
  TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT,
  TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT,
} from '@/src/modules/CampaignsModule/constants';
import {
  EActionTypesPath,
  IReportParams,
  IScheduledSMSDeliveryV1,
  ISmsInfoPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ISmsRecipientsResponseV1 } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { loadingStatus } from '@/src/presentation/types/enum/importStatus.enum';

import {
  atomFileDownloadInfo,
  atomShowIndeterminateNotification,
} from '@/modules/ContactsModule/atoms/Steps';

export const useActionSmsData = (actionId: number) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const serviceV1 = useService();
  const setGlobalLoadingAction = useSetAtom(atomGlobalLoadingAction);

  const [infoSmsPayload, setInfoSmsPayload] = useAtom(atomInfoSmsPayload);
  const setActionInfoSms = useSetAtom(atomActionInfoSms);
  const setActionReportSms = useSetAtom(atomActionReportSms);
  const setActionRecipients = useSetAtom(atomActionRecipients);
  const setContractCountry = useSetAtom(atomContractCountry);
  const processingAction = useSetAtom(atomProcessingAction);
  const setShowProgressIndeterminateNotification = useSetAtom(atomShowIndeterminateNotification);
  const setFileDownloadInfo = useSetAtom(atomFileDownloadInfo);
  const downloadingReport = useSetAtom(atomDownloadingReport);

  const saveInfoSms = useCallback(
    async (payload: ISmsInfoPayload) => {
      setLoading(true);
      setGlobalLoadingAction(true);
      await serviceV1.getSetInfoSms(payload);
      setLoading(false);
      setGlobalLoadingAction(false);
    },
    [serviceV1, setGlobalLoadingAction]
  );

  const getContractCountry = useCallback(async () => {
    const data = await serviceV1.getContractCountry();
    setContractCountry(data as string);
  }, [serviceV1, setContractCountry]);

  //cspell:disable
  const getSmsInfo = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsInfo(actionId);
    setActionInfoSms(data);
    setInfoSmsPayload({
      ...infoSmsPayload,
      elementoId: data?.elementoId || Number(actionId),
      mensaje: data?.mensaje || '',
      campoId: data?.campo,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, serviceV1, setActionInfoSms, setInfoSmsPayload]);
  //cspell:enable

  const getSmsRecipients = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsRecipientsV1(actionId);
    setActionRecipients(data as ISmsRecipientsResponseV1);
    setLoading(false);
  }, [actionId, serviceV1, setActionRecipients]);

  const setSmsRecipients = useCallback(
    async (payload: unknown) => {
      await serviceV1.setSmsRecipientsV1(payload as object);
    },
    [serviceV1]
  );

  const setSmsDelivery = useCallback(async () => {
    await serviceV1.setImmediateSMSDeliveryV1(actionId);
  }, [actionId, serviceV1]);

  const setScheduledSMSDelivery = useCallback(
    async (queryParams: IScheduledSMSDeliveryV1) => {
      queryParams;
      await serviceV1.setScheduledSMSDeliveryV1(queryParams);
    },
    [serviceV1]
  );

  //cspell:disable
  const getSmsInfoReport = useCallback(async () => {
    setLoading(true);
    const data = await serviceV1.getSmsInfoReport(actionId);
    setActionReportSms(data);
    setLoading(false);
  }, [actionId, serviceV1, setActionReportSms, setLoading]);
  //cspell:enable

  const getSummaryInfoSms = useCallback(async () => {
    setGlobalLoadingAction(true);

    try {
      await Promise.all([
        getSmsInfo(),
        getSmsRecipients(),
        getSmsInfoReport(),
        getContractCountry(),
      ]);
    } finally {
      setGlobalLoadingAction(false);
    }
  }, [getSmsInfo, getSmsInfoReport, getSmsRecipients, getContractCountry, setGlobalLoadingAction]);

  /* cspell:disable */
  const setConfirmSms = useCallback(async () => {
    setLoading(true);
    const dataService = await serviceV1.setConfirmSMSV1(actionId);
    setLoading(false);
    return dataService;
  }, [actionId, serviceV1]);
  /* cspell:enable */

  /* cspell:disable */
  const setPauseSms = useCallback(async () => {
    processingAction(true);
    const dataService = await serviceV1.setPauseSMSV1(actionId);
    processingAction(false);
    return dataService;
  }, [actionId, serviceV1, processingAction]);
  /* cspell:enable */

  const isWhatsApp = useMemo(() => {
    return window.location.pathname.includes(EActionTypesPath.WHATSAPP);
  }, []);

  const getSmsReport = useCallback(async () => {
    downloadingReport(true);
    const reportParamsSms: IReportParams[] = [
      ...REPORT_PARAMS,
      //cspell:disable
      { clave: 'elementos', valor: actionId?.toString() || '' },
      //cspell:enable
    ];

    const response = await serviceV1?.downloadReport(reportParamsSms);
    const description = response
      ? t(
          `${
            isWhatsApp
              ? TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT
              : TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT
          }.downloading`
        ) || ''
      : t(
          `${
            isWhatsApp
              ? TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT
              : TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT
          }.pendingDownload`
        ) || '';

    setShowProgressIndeterminateNotification({
      show: true,
      description: description,
      status: loadingStatus.loading,
    });

    setFileDownloadInfo({
      nameDownload: t(
        `${
          isWhatsApp
            ? TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT
            : TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT
        }.nameFile`,
        {
          date: new Date().toLocaleString('en-CA', { hour12: false }).replace(',', ''),
        }
      ),
      descriptionDownloaded: t(
        `${
          isWhatsApp
            ? TRANSLATE_WHATSAPP_ACTION_DOWNLOAD_REPORT
            : TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT
        }.downloaded`
      ),
    });

    downloadingReport(false);
  }, [
    actionId,
    downloadingReport,
    isWhatsApp,
    serviceV1,
    setFileDownloadInfo,
    setShowProgressIndeterminateNotification,
    t,
  ]);

  return {
    setInfoSmsPayload,
    saveInfoSms,
    getContractCountry,
    getSmsInfo,
    getSmsRecipients,
    setSmsRecipients,
    setSmsDelivery,
    setScheduledSMSDelivery,
    getSummaryInfoSms,
    setConfirmSms,
    setPauseSms,
    getSmsInfoReport,
    getSmsReport,
    loading,
    infoSmsPayload,
  };
};
