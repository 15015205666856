import { memo, ReactNode } from 'react';

interface EmptyScreenProps {
  component: ReactNode;
  colSpan: number;
}

const EmptyScreen = ({ component, colSpan }: EmptyScreenProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>{component}</td>
    </tr>
  );
};

export default memo(EmptyScreen);
