import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

import { atomActiveFormTab } from '@/modules/RulesModule/atoms/rules';

export const useTabVisibility = () => {
  const [activeTab, setActiveTab] = useAtom(atomActiveFormTab);

  const isVisible = useMemo(() => (tabId: number) => activeTab === tabId, [activeTab]);

  const setActiveTabCallback = useCallback(
    (tabId: number) => {
      setActiveTab(tabId);
    },
    [setActiveTab]
  );

  useEffect(() => {
    return () => {
      setActiveTab(1);
    };
  }, [setActiveTab]);

  return { activeTab, setActiveTab: setActiveTabCallback, isVisible };
};
