import classNames from 'classnames';
import { memo } from 'react';

import { IconSvg } from '@/lib/v2/components/IconSvg';
import { CloseIcon } from '@/lib/v2/icons/outline';

import { ColorsBadge } from './types';

import './BadgeText.tailwind.css';

export type BadgeTextProps = {
  variantColor?: ColorsBadge;
  text: string | number;
  withCloseButton?: boolean;
  isDisabled?: boolean;
  onClose?: () => void;
};

const BadgeText = ({
  text,
  variantColor = 'primary',
  isDisabled,
  withCloseButton,
  onClose,
}: BadgeTextProps) => {
  const classes = classNames('eb-badgeText', `eb-badgeText--colors-${variantColor}`);

  const classesClose = classNames('bg-transparent cursor-pointer ml-2');

  return (
    <span className={classes}>
      {text}{' '}
      {withCloseButton && (
        <button className={classesClose} disabled={isDisabled} type="button" onClick={onClose}>
          <span className="sr-only">Close</span>
          <IconSvg fillColor={variantColor} height="7px" svgComponent={<CloseIcon />} width="7px" />
        </button>
      )}
    </span>
  );
};

export default memo(BadgeText);
