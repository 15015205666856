export const WarningNewIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M3.31795 28C3.0735 28 2.85128 27.9389 2.65128 27.8167C2.45128 27.6944 2.29573 27.5333 2.18462 27.3333C2.0735 27.1333 2.01239 26.9167 2.00128 26.6833C1.99017 26.45 2.05128 26.2222 2.18462 26L14.5179 4.66667C14.6513 4.44444 14.8235 4.27778 15.0346 4.16667C15.2457 4.05556 15.4624 4 15.6846 4C15.9068 4 16.1235 4.05556 16.3346 4.16667C16.5457 4.27778 16.7179 4.44444 16.8513 4.66667L29.1846 26C29.3179 26.2222 29.3791 26.45 29.3679 26.6833C29.3568 26.9167 29.2957 27.1333 29.1846 27.3333C29.0735 27.5333 28.9179 27.6944 28.7179 27.8167C28.518 27.9389 28.2957 28 28.0513 28H3.31795ZM5.61795 25.3333H25.7513L15.6846 8L5.61795 25.3333ZM15.6846 24C16.0624 24 16.3791 23.8722 16.6346 23.6167C16.8902 23.3611 17.0179 23.0444 17.0179 22.6667C17.0179 22.2889 16.8902 21.9722 16.6346 21.7167C16.3791 21.4611 16.0624 21.3333 15.6846 21.3333C15.3068 21.3333 14.9902 21.4611 14.7346 21.7167C14.4791 21.9722 14.3513 22.2889 14.3513 22.6667C14.3513 23.0444 14.4791 23.3611 14.7346 23.6167C14.9902 23.8722 15.3068 24 15.6846 24ZM15.6846 20C16.0624 20 16.3791 19.8722 16.6346 19.6167C16.8902 19.3611 17.0179 19.0444 17.0179 18.6667V14.6667C17.0179 14.2889 16.8902 13.9722 16.6346 13.7167C16.3791 13.4611 16.0624 13.3333 15.6846 13.3333C15.3068 13.3333 14.9902 13.4611 14.7346 13.7167C14.4791 13.9722 14.3513 14.2889 14.3513 14.6667V18.6667C14.3513 19.0444 14.4791 19.3611 14.7346 19.6167C14.9902 19.8722 15.3068 20 15.6846 20Z"
          fill="#FDCA46"
        />
      </g>
    </svg>
  );
};
