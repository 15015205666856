import { useAtom } from 'jotai';

import { atomSummaryMessage, atomSummaryOpen } from '@/src/apps/chat/components/IA/atoms/Summary';

export const useInboxSummaryConversation = () => {
  const [open, setOpen] = useAtom(atomSummaryOpen);
  const [summary, setSummary] = useAtom(atomSummaryMessage);
  const handleOpenSummary = (resume: string) => {
    setSummary(resume);
    setOpen(true);
  };
  const handleCloseSummary = () => {
    setSummary('');
    setOpen(false);
  };
  return {
    summary,
    open,
    handleOpenSummary,
    handleCloseSummary,
  };
};
