export const PdfIcon = () => {
  return (
    <svg
      height="500"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      viewBox="0 0 375 374.999991"
      width="500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
    >
      <defs>
        <g />
        <clipPath id="31ffdf503b">
          <path
            clipRule="nonzero"
            d="M 64.757812 13 L 308.507812 13 L 308.507812 363 L 64.757812 363 Z M 64.757812 13 "
          />
        </clipPath>
        <clipPath id="64db64b3eb">
          <path
            clipRule="nonzero"
            d="M 252 13 L 308.507812 13 L 308.507812 61 L 252 61 Z M 252 13 "
          />
        </clipPath>
        <clipPath id="257f66e1c4">
          <path clipRule="nonzero" d="M 255 10 L 311 10 L 311 65 L 255 65 Z M 255 10 " />
        </clipPath>
        <clipPath id="5c637a6189">
          <path
            clipRule="nonzero"
            d="M 285.199219 87.566406 L 229.964844 40.835938 L 255.625 10.511719 L 310.855469 57.246094 Z M 285.199219 87.566406 "
          />
        </clipPath>
        <clipPath id="0055b85dcf">
          <path
            clipRule="nonzero"
            d="M 257.105469 63.175781 L 255.0625 11.484375 C 255.050781 11.222656 255.195312 10.980469 255.429688 10.867188 C 255.667969 10.75 255.945312 10.785156 256.148438 10.953125 L 310.3125 56.785156 C 310.511719 56.953125 310.59375 57.222656 310.519531 57.476562 C 310.445312 57.726562 310.230469 57.910156 309.96875 57.941406 L 258.652344 64.484375 C 258.269531 64.535156 257.886719 64.417969 257.589844 64.171875 C 257.296875 63.921875 257.121094 63.5625 257.105469 63.175781 Z M 257.105469 63.175781 "
          />
        </clipPath>
      </defs>
      <path
        d="M 256.160156 15.355469 L 66.359375 15.355469 L 66.359375 361.261719 L 306.988281 361.261719 L 306.988281 58.613281 Z M 256.160156 15.355469 "
        fill="#ffffff"
        fillOpacity="1"
        fillRule="nonzero"
      />
      <g clipPath="url(#31ffdf503b)">
        <path
          d="M 306.988281 362.828125 L 66.359375 362.828125 C 65.488281 362.828125 64.789062 362.128906 64.789062 361.261719 L 64.789062 15.355469 C 64.789062 14.488281 65.488281 13.785156 66.359375 13.785156 L 256.160156 13.785156 C 256.535156 13.785156 256.894531 13.914062 257.175781 14.15625 L 308.011719 57.421875 C 308.363281 57.714844 308.5625 58.152344 308.5625 58.613281 L 308.5625 361.261719 C 308.558594 362.125 307.859375 362.828125 306.988281 362.828125 Z M 67.929688 359.6875 L 305.417969 359.6875 L 305.417969 59.335938 L 255.582031 16.917969 L 67.929688 16.917969 Z M 67.929688 359.6875 "
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#64db64b3eb)">
        <path
          d="M 306.996094 60.179688 C 306.996094 60.179688 306.996094 60.179688 306.988281 60.179688 L 253.742188 60.179688 C 253.3125 60.179688 252.898438 60.007812 252.605469 59.691406 C 252.308594 59.378906 252.15625 58.957031 252.183594 58.527344 L 254.589844 15.265625 C 254.625 14.667969 254.992188 14.144531 255.542969 13.910156 C 256.085938 13.679688 256.71875 13.777344 257.171875 14.164062 L 307.964844 57.390625 C 308.324219 57.671875 308.558594 58.125 308.558594 58.617188 C 308.558594 59.480469 307.859375 60.179688 306.996094 60.179688 Z M 255.40625 57.046875 L 302.734375 57.046875 L 257.550781 18.59375 Z M 255.40625 57.046875 "
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#257f66e1c4)">
        <g clipPath="url(#5c637a6189)">
          <g clipPath="url(#0055b85dcf)">
            <path
              d="M 285.394531 88.558594 L 228.957031 40.808594 L 255.023438 10.003906 L 311.457031 57.753906 Z M 285.394531 88.558594 "
              fill="#d9d9d9"
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
      <g fill="#ff5757" fillOpacity="1">
        <g transform="translate(118.490555, 213.671659)">
          <g>
            <path d="M 10.546875 17.015625 C 9.335938 17.015625 8.425781 16.691406 7.8125 16.046875 C 7.207031 15.410156 6.90625 14.488281 6.90625 13.28125 L 6.90625 -41.125 C 6.90625 -42.332031 7.207031 -43.253906 7.8125 -43.890625 C 8.425781 -44.523438 9.3125 -44.84375 10.46875 -44.84375 C 11.675781 -44.84375 12.597656 -44.523438 13.234375 -43.890625 C 13.867188 -43.253906 14.1875 -42.332031 14.1875 -41.125 L 14.1875 -32.46875 L 13.1875 -33.75 C 14.15625 -37.207031 16.078125 -39.953125 18.953125 -41.984375 C 21.835938 -44.015625 25.285156 -45.03125 29.296875 -45.03125 C 33.234375 -45.03125 36.671875 -44.101562 39.609375 -42.25 C 42.554688 -40.40625 44.832031 -37.769531 46.4375 -34.34375 C 48.039062 -30.914062 48.84375 -26.835938 48.84375 -22.109375 C 48.84375 -17.429688 48.039062 -13.378906 46.4375 -9.953125 C 44.832031 -6.535156 42.570312 -3.882812 39.65625 -2 C 36.75 -0.125 33.296875 0.8125 29.296875 0.8125 C 25.285156 0.8125 21.851562 -0.203125 19 -2.234375 C 16.15625 -4.265625 14.21875 -6.976562 13.1875 -10.375 L 14.28125 -10.375 L 14.28125 13.28125 C 14.28125 14.488281 13.945312 15.410156 13.28125 16.046875 C 12.613281 16.691406 11.703125 17.015625 10.546875 17.015625 Z M 27.75 -5.09375 C 30.539062 -5.09375 32.953125 -5.757812 34.984375 -7.09375 C 37.015625 -8.425781 38.585938 -10.363281 39.703125 -12.90625 C 40.828125 -15.457031 41.390625 -18.523438 41.390625 -22.109375 C 41.390625 -27.628906 40.160156 -31.84375 37.703125 -34.75 C 35.242188 -37.65625 31.925781 -39.109375 27.75 -39.109375 C 25.019531 -39.109375 22.625 -38.457031 20.5625 -37.15625 C 18.5 -35.851562 16.90625 -33.941406 15.78125 -31.421875 C 14.65625 -28.910156 14.09375 -25.804688 14.09375 -22.109375 C 14.09375 -16.648438 15.335938 -12.445312 17.828125 -9.5 C 20.316406 -6.5625 23.625 -5.09375 27.75 -5.09375 Z M 27.75 -5.09375 " />
          </g>
        </g>
      </g>
      <g fill="#ff5757" fillOpacity="1">
        <g transform="translate(171.528981, 213.671659)">
          <g>
            <path d="M 23.75 0.8125 C 19.800781 0.8125 16.351562 -0.125 13.40625 -2 C 10.46875 -3.882812 8.195312 -6.535156 6.59375 -9.953125 C 4.988281 -13.378906 4.1875 -17.429688 4.1875 -22.109375 C 4.1875 -26.835938 4.988281 -30.914062 6.59375 -34.34375 C 8.195312 -37.769531 10.46875 -40.40625 13.40625 -42.25 C 16.351562 -44.101562 19.800781 -45.03125 23.75 -45.03125 C 27.75 -45.03125 31.171875 -44.03125 34.015625 -42.03125 C 36.867188 -40.03125 38.78125 -37.332031 39.75 -33.9375 L 38.75 -33.9375 L 38.75 -61.046875 C 38.75 -62.253906 39.066406 -63.175781 39.703125 -63.8125 C 40.335938 -64.445312 41.265625 -64.765625 42.484375 -64.765625 C 43.628906 -64.765625 44.519531 -64.445312 45.15625 -63.8125 C 45.800781 -63.175781 46.125 -62.253906 46.125 -61.046875 L 46.125 -3.1875 C 46.125 -1.96875 45.816406 -1.023438 45.203125 -0.359375 C 44.597656 0.304688 43.691406 0.640625 42.484375 0.640625 C 41.328125 0.640625 40.429688 0.304688 39.796875 -0.359375 C 39.160156 -1.023438 38.84375 -1.96875 38.84375 -3.1875 L 38.84375 -11.828125 L 39.84375 -10.546875 C 38.875 -7.085938 36.945312 -4.328125 34.0625 -2.265625 C 31.1875 -0.210938 27.75 0.8125 23.75 0.8125 Z M 25.296875 -5.09375 C 28.023438 -5.09375 30.414062 -5.757812 32.46875 -7.09375 C 34.53125 -8.425781 36.125 -10.363281 37.25 -12.90625 C 38.375 -15.457031 38.9375 -18.523438 38.9375 -22.109375 C 38.9375 -27.628906 37.707031 -31.84375 35.25 -34.75 C 32.789062 -37.65625 29.472656 -39.109375 25.296875 -39.109375 C 22.503906 -39.109375 20.09375 -38.457031 18.0625 -37.15625 C 16.03125 -35.851562 14.453125 -33.941406 13.328125 -31.421875 C 12.203125 -28.910156 11.640625 -25.804688 11.640625 -22.109375 C 11.640625 -16.648438 12.882812 -12.445312 15.375 -9.5 C 17.863281 -6.5625 21.171875 -5.09375 25.296875 -5.09375 Z M 25.296875 -5.09375 " />
          </g>
        </g>
      </g>
      <g fill="#ff5757" fillOpacity="1">
        <g transform="translate(224.567407, 213.671659)">
          <g>
            <path d="M 12.921875 0.640625 C 11.765625 0.640625 10.867188 0.304688 10.234375 -0.359375 C 9.597656 -1.023438 9.28125 -1.96875 9.28125 -3.1875 L 9.28125 -38.484375 L 3.453125 -38.484375 C 2.484375 -38.484375 1.707031 -38.738281 1.125 -39.25 C 0.550781 -39.769531 0.265625 -40.484375 0.265625 -41.390625 C 0.265625 -42.296875 0.550781 -42.988281 1.125 -43.46875 C 1.707031 -43.957031 2.484375 -44.203125 3.453125 -44.203125 L 11.28125 -44.203125 L 9.28125 -42.296875 L 9.28125 -45.75 C 9.28125 -51.519531 10.6875 -55.921875 13.5 -58.953125 C 16.320312 -61.984375 20.460938 -63.738281 25.921875 -64.21875 L 28.5625 -64.5 C 29.414062 -64.5625 30.097656 -64.410156 30.609375 -64.046875 C 31.128906 -63.679688 31.460938 -63.222656 31.609375 -62.671875 C 31.765625 -62.128906 31.78125 -61.566406 31.65625 -60.984375 C 31.53125 -60.410156 31.253906 -59.910156 30.828125 -59.484375 C 30.410156 -59.066406 29.898438 -58.828125 29.296875 -58.765625 L 27.203125 -58.578125 C 23.503906 -58.273438 20.816406 -57.15625 19.140625 -55.21875 C 17.472656 -53.28125 16.640625 -50.429688 16.640625 -46.671875 L 16.640625 -42.84375 L 15.5625 -44.203125 L 26.46875 -44.203125 C 27.5 -44.203125 28.285156 -43.957031 28.828125 -43.46875 C 29.378906 -42.988281 29.65625 -42.296875 29.65625 -41.390625 C 29.65625 -40.484375 29.378906 -39.769531 28.828125 -39.25 C 28.285156 -38.738281 27.5 -38.484375 26.46875 -38.484375 L 16.640625 -38.484375 L 16.640625 -3.1875 C 16.640625 -0.632812 15.398438 0.640625 12.921875 0.640625 Z M 12.921875 0.640625 " />
          </g>
        </g>
      </g>
    </svg>
  );
};
