import classNames from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

export interface IframeProps {
  html: string;
  title: string;
  withoutOverflow?: boolean;
  id?: string;
  fullHeight?: boolean;
  isMobile?: boolean;
}

const Iframe = forwardRef<HTMLIFrameElement, IframeProps>(
  ({ html, isMobile = false, title, withoutOverflow, id, fullHeight }: IframeProps, ref) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

        if (iframeDoc) {
          iframeDoc.open();
          iframeDoc.write(html);
          iframeDoc.close();

          iframe.onload = () => {
            if (fullHeight) {
              iframe.style.height = `${iframeDoc.body.scrollHeight}px`;
            }
          };
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [html]);

    useEffect(() => {
      const iframe = iframeRef.current;

      if (iframe && fullHeight) {
        iframe.style.height = 'auto';
        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
          iframe.style.height = `${iframeDoc.body.scrollHeight}px`;
        }
      }
    }, [isMobile, fullHeight]);

    useImperativeHandle(ref, () => iframeRef.current as HTMLIFrameElement);

    const classes = classNames('w-full h-full', {
      'overflow-hidden': withoutOverflow,
    });

    return (
      <iframe
        ref={iframeRef}
        className={classes}
        id={id}
        title={title}
        {...(withoutOverflow ? { scrolling: 'no' } : {})}
      />
    );
  }
);

export default Iframe;
