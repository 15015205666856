import { memo } from 'react';

import { Card } from '@/lib/v2/components';

import ShippingTab from '@/src/modules/InsightsModule/components/ShippingTab';

const CardShippingElements = () => {
  return (
    <Card noShadow className="w-full">
      <ShippingTab />
    </Card>
  );
};

export default memo(CardShippingElements);
