export const useStructureDataForm = () => {
  const handleEditorWillMount = (monaco: {
    editor: { defineTheme: (name: string, config: object) => void };
  }) => {
    monaco.editor.defineTheme('my-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: 'delimiter', foreground: '#ffffff' },
        { token: 'string.key.json', foreground: '#FC3293' },
        { token: 'string.value.json', foreground: '#8543D3' },
      ],
      colors: {
        'editor.background': '#002646',
      },
    });
  };

  return { handleEditorWillMount };
};
