import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, PhonePreviewWhatsApp } from '@/lib/v2/components';

import { WhatsAppForm } from './components/WhatsAppForm';
import { useExtractBodyFromJson } from './hooks/useExtractBodyFromJson';
import { TEMPLATE_OPTION } from './constants';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionTypePath,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomCurrentWhatsAppMessage,
  atomMediaUrl,
  atomMessageWhatsApp,
  atomTemplateOption,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { useActionWhatsAppData } from '@/modules/CampaignsModule/hooks/useActionWhatsAppData';
import {
  EActionTypesPath,
  EStepHeaderAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { atomWhatsAppTemplateSelected } from '@/modules/ContactsModule/atoms/WhatsApp';

const WhatsAppAction = () => {
  const { actionId } = useParams();

  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);
  const [messageWhatsApp, setMessageWhatsApp] = useAtom(atomMessageWhatsApp);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const [currentMessage, setCurrentMessage] = useAtom(atomCurrentWhatsAppMessage);
  const templateOption = useAtomValue(atomTemplateOption);
  const mediaUrl = useAtomValue(atomMediaUrl);
  const setActionTypePath = useSetAtom(atomActionTypePath);

  const { getSummaryInfoWhatsApp, getContractCountry } = useActionWhatsAppData(Number(actionId));

  const [selectedTemplate, setSelectedTemplate] = useAtom(atomWhatsAppTemplateSelected);
  const bodyMessage = useExtractBodyFromJson(messageWhatsApp.original, selectedTemplate?.variables);

  useLayoutEffect(() => {
    const fetchData = async () => await getSummaryInfoWhatsApp();
    void fetchData();
  }, [getSummaryInfoWhatsApp]);

  useEffect(() => {
    if (currentStep !== EStepHeaderAction.FIRST) setCurrentStep(EStepHeaderAction.FIRST);
  }, [currentStep, setCurrentStep]);

  //TODO: check if this is needed, because it has been inherited from the sms action
  useEffect(() => {
    return () => {
      setMessageWhatsApp({
        original: '',
        shortened: '',
        track: false,
      });
      setCurrentMessage('');
      setSelectedTemplate(null);
    };
  }, [setMessageWhatsApp, setCurrentMessage, setSelectedTemplate]);

  useEffect(() => void getContractCountry(), [getContractCountry]);
  useEffect(() => setActionTypePath(EActionTypesPath.WHATSAPP), [setActionTypePath]);

  return (
    <Flex itemAlignment="start">
      <div className="my-12 w-1/2 bg-white px-10">
        {<WhatsAppForm loading={globalLoadingAction} />}
      </div>
      <div className="grid min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-emblue-secondary-hover py-4 ">
        <PhonePreviewWhatsApp
          mediaUrl={mediaUrl}
          message={templateOption === TEMPLATE_OPTION.CREATE ? currentMessage : bodyMessage}
        />
      </div>
    </Flex>
  );
};

export default WhatsAppAction;
