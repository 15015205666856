export const RejectedIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" fill="#FF4242" r="7" />
      <rect
        fill="white"
        height="7.54235"
        rx="0.471397"
        transform="matrix(0.707109 0.707105 -0.707109 0.707105 9.33325 4)"
        width="0.942794"
      />
      <rect
        fill="white"
        height="7.54235"
        rx="0.471397"
        transform="matrix(0.707109 -0.707105 0.707109 0.707105 4 4.66602)"
        width="0.942794"
      />
    </svg>
  );
};
