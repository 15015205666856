import { ArrowLeftIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, If, InputSearch, Modal, Text } from '@/lib/v2/components';
import { Tab } from '@/lib/v3/components';

import { useTabsPanes } from '@/modules/ContactsModule/components/SendWhatsAppModal/hooks/useTabsPanes';

interface ModalTemplatesInboxProps {
  isOpen: boolean;
  title?: string;
  icon?: JSX.Element;
  onClose: () => void;
  onBack?: () => void;
  currentTab: string | number;
}

const ModalTemplatesInbox = ({
  isOpen,
  icon,
  title,
  onClose,
  onBack,
  currentTab,
}: ModalTemplatesInboxProps) => {
  const { t } = useTranslation('inbox');
  const [activeTab, setActiveTab] = useState(currentTab);
  const [searchTerm, setSearchTerm] = useState('');

  const { state } = useTabsPanes({ onClose, searchTerm });
  const { tabItems, tabPanes } = state;

  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [tabItems]);

  const handleTabClick = useCallback((tabId: string | number) => {
    setActiveTab(tabId);
  }, []);

  const handleClose = useCallback(() => {
    setActiveTab(1);
    onClose();
  }, [onClose]);

  const handleBack = useCallback(() => {
    setActiveTab(1);
    onBack?.();
  }, [onBack]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleResize = useCallback(() => {
    if (childrenRef.current && containerRef.current) {
      const headerHeight = 73;
      const searchHeight = 42;
      const tabNavHeight = 48;
      const padding = 32;

      const containerHeight =
        containerRef.current.clientHeight - (headerHeight + searchHeight + tabNavHeight + padding);

      childrenRef.current.style.maxHeight = `${containerHeight}px`;

      requestAnimationFrame(() => {
        if (childrenRef.current) {
          const childrenHeight = childrenRef.current.scrollHeight;
          childrenRef.current.style.overflowY =
            childrenHeight > containerHeight ? 'auto' : 'hidden';
        }
      });
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize, tabPanes, searchTerm]);

  const mountTransitionEnd = useCallback(
    (refContainerModal?: RefObject<HTMLDivElement>) => {
      if (refContainerModal?.current && containerRef?.current) {
        containerRef.current.style.height = `${refContainerModal.current.clientHeight}px`;
      }
      handleResize();
    },
    [handleResize]
  );

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    //TODO: Fix ResizeObserver, that broke the build when you add or reduce zoom
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const containerContentClass = classNames('relative size-full overflow-hidden px-6 py-4', {
    invisible: !isOpen,
  });

  return (
    <Modal
      noPadding
      withAuto
      externalClose={true}
      mountTransitionEnd={mountTransitionEnd}
      open={isOpen}
      onClose={handleClose}
    >
      <div
        ref={containerRef}
        className={'flex h-[70vh] w-[90vw] flex-col md:w-[80vw] 2xl:w-[65vw]'}
      >
        <div className="flex w-full items-center gap-2 border-b px-6 py-4">
          <If condition={!!onBack}>
            <Button
              onlyIcon
              outline
              standard
              className="-ml-2.5"
              id="modal-back-button"
              onClick={handleBack}
            >
              <IconSvg fillColor="gray-dark" svgComponent={<ArrowLeftIcon />} />
            </Button>
          </If>
          {icon}
          <Text fontWeight="medium" variant="sub-headline">
            {title}
          </Text>
        </div>
        <div className={containerContentClass}>
          <div className="absolute right-0 top-0 mr-9 mt-3 flex justify-end">
            <div className="z-10 h-fit w-64">
              <InputSearch
                placeholder={t('INBOX.SEND_WHATSAPP_MODAL.searchInputPlaceholder')}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <Tab currentTab={activeTab}>
            <Tab.TabNav>
              {tabItems.map((tab) => {
                const { tabId, onClick, ...restTabProps } = tab;
                return (
                  <Tab.TabNavItem
                    key={tabId}
                    tabId={tabId}
                    onClick={(e) => (handleTabClick(tab.tabId), onClick?.(e))}
                    {...restTabProps}
                  />
                );
              })}
            </Tab.TabNav>
            <Tab.TabContent ref={childrenRef} className="visible-scrollbar overflow-y-auto pr-2">
              {tabPanes.map((pane) => {
                const { tabId, children } = pane;
                return (
                  <Tab.TabContentPane key={tabId} tabId={tabId}>
                    {children}
                  </Tab.TabContentPane>
                );
              })}
            </Tab.TabContent>
          </Tab>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalTemplatesInbox);
