import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSenderScore as useBaseSenderScore } from '@/src/infrastructure/services/useSenderScore';
import { SenderScoring } from '@/src/presentation/util/enum/senderScoringEnum';

export const useSenderScoreLogic = () => {
  const { t } = useTranslation();

  const [scoring] = useBaseSenderScore();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const getColorClass = useCallback((score?: number): string => {
    if (score === undefined) return '';

    if (score >= SenderScoring.maxScore) return 'card blue';
    if (score <= SenderScoring.minScore) return 'card red';
    return 'card yellow';
  }, []);

  const getLabel = useCallback(
    (score?: number): string => {
      if (score === undefined) return '';

      if (score >= SenderScoring.maxScore) return t('SENDER_SCORING.high');
      if (score <= SenderScoring.minScore) return t('SENDER_SCORING.low');
      return t('SENDER_SCORING.medium');
    },
    [t]
  );

  const colorClass = useMemo(() => getColorClass(scoring), [scoring, getColorClass]);
  const label = useMemo(() => getLabel(scoring), [scoring, getLabel]);

  const scoringClass = classNames(
    'w-5/12 text-white flex flex-row items-center justify-center cursor-pointer',
    colorClass
  );

  return {
    events: {
      handleClose,
      handleOpen,
    },
    state: {
      scoringClass,
      label,
      open,
      scoring,
    },
  };
};
