/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback } from 'react';

export interface IParsedContent {
  isValid: boolean;
  body: string;
  contentSid?: string;
  mediaUrl?: string;
  actions?: unknown;
  error?: Error;
}

/**
 * Centralizes the transformation of Twilio-like variables:
 * 1) Building JSON from placeholders like ${1#Name} => {{1}}
 * 2) Parsing and replacing placeholders ({{1}}, {{2}}, etc.) if we get a JSON string
 */
export function useContentVariables() {
  // Converts patterns like ${1#text} => {{1}}, storing each match in customVariables
  const transformToTwilioPlaceholders = useCallback((message: string) => {
    const variableRegex = /\$\{\d+#[^}]+\}/g;
    const customVariables: Record<string, string> = {};
    let index = 1;

    const updatedContent = message.replace(variableRegex, (match) => {
      const twilioVar = `{{${index}}}`;
      customVariables[`variable${index}`] = match;
      index++;
      return twilioVar;
    });

    return { updatedContent, customVariables };
  }, []);

  // Parses a JSON string that may contain Twilio placeholders ({{1}}, etc.),
  // then replaces them with actual values from ContentVariables
  const parseAndReplaceContentVariables = useCallback((jsonString: string): IParsedContent => {
    if (!jsonString || !jsonString.trim()) {
      return { isValid: false, body: '' };
    }

    try {
      const parsed = JSON.parse(jsonString);
      if (!parsed.body) {
        return { isValid: false, body: '' };
      }

      let replacedMessage = parsed.body;
      if (parsed.ContentVariables && typeof parsed.ContentVariables === 'object') {
        const variablesArray = Object.values(parsed.ContentVariables);
        variablesArray.forEach((value, index) => {
          const twilioVar = `{{${index + 1}}}`;
          replacedMessage = replacedMessage.replaceAll(twilioVar, String(value));
        });
      }

      return {
        isValid: true,
        body: replacedMessage,
        contentSid: parsed.ContentSid,
        mediaUrl: parsed.mediaUrl,
        actions: parsed.actions,
      };
    } catch (error) {
      return { isValid: false, body: '', error: error as Error };
    }
  }, []);

  return {
    transformToTwilioPlaceholders,
    parseAndReplaceContentVariables,
  };
}
