/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import {
  IEmblueApiMethodNameMap,
  INotificationBase,
  INotificationPayload,
  INotificationsService,
} from './INotificationsService';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class NotificationsService implements INotificationsService {
  private api: APIService;
  private pathNotifications = 'api/v2.1/user/notifications';

  private constructor(api: APIService) {
    this.api = api;
  }

  /**
   * @param {AxiosError<Error>} error - The error thrown during API request
   * @param {string} method - The name of the method where the error occurred
   * @description Logs API errors to the console
   * @returns {void}
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error NotificationsService[${method}]:`, message);
  }

  /**
   * @param api APIService
   * @returns {INotificationsService}
   * @description Create new instance of NotificationsService
   */
  static create(api: APIService): INotificationsService {
    return new NotificationsService(api);
  }

  /**
   * @param {INotificationBase} params - Parameters for the notification request
   * @returns {Promise<T | null>} Returns notification data or null if error occurs
   * @description Retrieves notification preferences from the API
   * @template T - The type of the notification data
   */
  getNotification<T>(params: INotificationBase): Promise<T | null> {
    return (async () => {
      try {
        const response = await this.api.get(`${this.pathNotifications}/preferences`, params);
        return response.data as T;
      } catch (error) {
        this.catchErrors(error as AxiosError<Error>, 'getNotification');
        return null;
      }
    })();
  }

  /**
   * @param {INotificationPayload} params - Parameters containing notification preferences to save
   * @returns {Promise<boolean | null>} Returns true if successful, null if error occurs
   * @description Saves notification preferences to the API
   */
  saveNotification(params: INotificationPayload): Promise<boolean | null> {
    return (async () => {
      try {
        const response = await this.api.post(`${this.pathNotifications}/preferences`, params);
        return response.data as boolean;
      } catch (error) {
        this.catchErrors(error as AxiosError<Error>, 'saveNotification');
        return null;
      }
    })();
  }
}

export const NotificationsServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  NotificationsService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return h;
}, {} as typeof NotificationsService);
