import { useState } from 'react';

import { useInsightsService } from '@/src/infrastructure/Protocol/Insights/useInsightsService';

export const useDataInsights = () => {
  const [loading, setLoading] = useState(false);
  const serviceInsights = useInsightsService();

  const getContactsGraphData = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    setLoading(true);
    const response = await serviceInsights.getContactsGraphEvolution(startDate, endDate);
    setLoading(false);
    return response;
  };

  return {
    state: {
      loading,
    },
    events: {
      getContactsGraphData,
    },
  };
};
