import { CreateOrEditIntegrationForm } from '@/src/modules/IntegrationsModule/hooks/CreateOrEditIntegration/useCreateOrEditIntegrationForm';

export const INTEGRATIONS_PATHS = {
  MODULE: 'integrations',
  REPORT: 'report',
};

export enum STEP_THREE_OPTIONS {
  NOTHING = 'nothing',
  GROUPS = 'groups',
}

//cspell:disable
export const MAP_DEFAULT_VALUES = {
  email: 'email',
  names: 'name',
  lastNames: 'lastname',
  idContact: 'id_contact',
  gender: 'gender',
  mainPhone: 'phone_1',
  secondaryPhone: 'phone_2',
  birthday: 'birthday',
  secondaryEmail: 'secondary_email',
  website: 'web_url',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  address: 'address',
  city: 'city',
  country: 'country',
  company: 'company',
  position: 'position',
  idTrigger: 'id_trigger',
};
//cspell:enable

export const DEFAULT_INTEGRATION_FORM: CreateOrEditIntegrationForm = {
  stepOne: undefined,
  stepTwo: [],
  stepThree: undefined,
  stepThreeGroups: [],
  stepFour: {
    id: '',
    name: '',
    value: '',
  },
  stepFourSwitch: false,
};
