export const OpenNewWindow = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="20"
        id="mask0_1717_600"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="20"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g mask="url(#mask0_1717_600)">
        <path
          d="M5.33333 16C4.96667 16 4.65278 15.8694 4.39167 15.6083C4.13056 15.3472 4 15.0333 4 14.6667V5.33333C4 4.96667 4.13056 4.65278 4.39167 4.39167C4.65278 4.13056 4.96667 4 5.33333 4H9.33333C9.52222 4 9.68056 4.06389 9.80833 4.19167C9.93611 4.31944 10 4.47778 10 4.66667C10 4.85556 9.93611 5.01389 9.80833 5.14167C9.68056 5.26944 9.52222 5.33333 9.33333 5.33333H5.33333V14.6667H14.6667V10.6667C14.6667 10.4778 14.7306 10.3194 14.8583 10.1917C14.9861 10.0639 15.1444 10 15.3333 10C15.5222 10 15.6806 10.0639 15.8083 10.1917C15.9361 10.3194 16 10.4778 16 10.6667V14.6667C16 15.0333 15.8694 15.3472 15.6083 15.6083C15.3472 15.8694 15.0333 16 14.6667 16H5.33333ZM14.6667 6.26667L8.93333 12C8.81111 12.1222 8.65556 12.1833 8.46667 12.1833C8.27778 12.1833 8.12222 12.1222 8 12C7.87778 11.8778 7.81667 11.7222 7.81667 11.5333C7.81667 11.3444 7.87778 11.1889 8 11.0667L13.7333 5.33333H12C11.8111 5.33333 11.6528 5.26944 11.525 5.14167C11.3972 5.01389 11.3333 4.85556 11.3333 4.66667C11.3333 4.47778 11.3972 4.31944 11.525 4.19167C11.6528 4.06389 11.8111 4 12 4H15.3333C15.5222 4 15.6806 4.06389 15.8083 4.19167C15.9361 4.31944 16 4.47778 16 4.66667V8C16 8.18889 15.9361 8.34722 15.8083 8.475C15.6806 8.60278 15.5222 8.66667 15.3333 8.66667C15.1444 8.66667 14.9861 8.60278 14.8583 8.475C14.7306 8.34722 14.6667 8.18889 14.6667 8V6.26667Z"
          fill="#004DBC"
        />
      </g>
    </svg>
  );
};
