import { Switch as SwitchHeadlessUI } from '@headlessui/react';
import classNames from 'classnames';
import { forwardRef, memo, Ref } from 'react';

import { withController } from '@/lib/v2/HOCs/WithController';

export interface SwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  error?: boolean;
  message?: string;
  name?: string;
  defaultChecked?: boolean;
  value?: string;
  withLabels?: boolean;
  rightLabel?: string;
  disabled?: boolean;
  leftLabel?: string;
  id?: string;
}

const Switch = forwardRef(
  (
    {
      checked = false,
      onChange,
      error,
      message,
      name,
      defaultChecked,
      value,
      withLabels,
      leftLabel,
      rightLabel,
      disabled,
      id,
    }: SwitchProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const classesLeft = classNames({
      'bg-white text-emblue-primary font-medium h5 text-14 w-fit px-2 rounded-full': !checked,
      'text-emblue-white mx-2 text-14 font-medium w-full': checked,
    });

    const classesRight = classNames({
      'bg-white text-emblue-primary font-medium h5 text-14 w-fit px-2 rounded-full': checked,
      'text-emblue-white mx-2 text-14 font-medium w-full': !checked,
    });

    const classesDisabled = classNames({
      'opacity-40': disabled,
    });
    return (
      <div className="h-5" data-testid="switch-component" id={id}>
        <SwitchHeadlessUI
          ref={ref}
          checked={checked}
          className={classNames(
            withLabels
              ? 'group relative inline-flex w-fit flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none '
              : 'group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none',
            classesDisabled
          )}
          defaultChecked={defaultChecked}
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {withLabels && (
            <span
              aria-hidden="true"
              className={classNames(
                'pointer-events-none bg-emblue-disabled h6 flex flex-row items-center p-1 rounded-full'
              )}
            >
              <span aria-hidden="true" className={classesLeft}>
                {leftLabel}
              </span>
              <span aria-hidden="true" className={classesRight}>
                {rightLabel}
              </span>
            </span>
          )}
          {!withLabels && (
            <>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute size-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  checked ? 'bg-emblue-primary-hover' : 'bg-emblue-disabled',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  checked ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-emblue-disabled bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                )}
              />
            </>
          )}
        </SwitchHeadlessUI>
        {error && (
          <p className={'message-error'} id={`${name}-message`}>
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default memo(withController(Switch));
