import { useEffect } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectExternalSearch } from '@/lib/v2/components';

import { IFormProps } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';
import { useRecipientsRules } from '@/src/modules/RulesModule/components/FormCondition/hooks/useRecipientsRules.hook';

import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';

interface IRecipientDropdownSmsProps {
  loading?: boolean;
  form: IFormProps<IConditionForm>;
  rules?: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const RecipientDropdownRules = ({
  form,
  loading,
  rules,
  disabled = false,
}: IRecipientDropdownSmsProps) => {
  const { t } = useTranslation('rules');

  const {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: { setRecipientsSearch },
    state: { recipientListPayload, recipientsOptions, loading: loadingRecipients },
  } = useRecipientsRules({ setValue: form.setValue });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void getRecipients(recipientListPayload), [getRecipients]);

  const { control } = form;

  return (
    <>
      <SelectExternalSearch
        isRequired
        control={control}
        disabled={disabled}
        id="create-condition-recipients-select"
        isLoading={loading}
        isSearching={loadingRecipients}
        label={t(`${TRANSLATE_RULES_LABELS}.segmentsGroups`)}
        name="selectedGroupOrSegment"
        options={recipientsOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.segmentsGroups`)}
        rules={rules?.selectedGroupOrSegment}
        searchPlaceholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.searchSelect`)}
        onChange={handleChangeRecipients}
        onChangeSearch={setRecipientsSearch}
        onClose={handleCloseRecipients}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onLastItem={handleLoadLastRecipients}
      />
    </>
  );
};

export default RecipientDropdownRules;
