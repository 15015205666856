import { useAtomValue } from 'jotai';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useEmailFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/hooks/useEmailFormEvents';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { IEmailFormInfo } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IEmailSenderProps {
  setValue: UseFormSetValue<IEmailFormInfo>;
}

export const useEmailTouchRules = ({ setValue }: IEmailSenderProps) => {
  const { actionId } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);

  const [showTouchRulesContent, setTouchRulesContent] = useState<boolean>(false);

  const { setInfoByFieldOnChange } = useEmailFormEvents({ actionId: Number(actionId) });

  const { touchRules } = actionInfoEmail ?? {};

  useEffect(() => {
    setValue('touchRules', touchRules?.isTouchRules || false);
    setTouchRulesContent(touchRules?.isTouchRules || false);
  }, [touchRules?.isTouchRules, setTouchRulesContent, setValue]);

  const handleChangeTouchRules = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setTouchRulesContent(!showTouchRulesContent);
      await setInfoByFieldOnChange({ touchRules: checked });
    },
    [setInfoByFieldOnChange, setTouchRulesContent, showTouchRulesContent]
  );

  return {
    events: {
      handleChangeTouchRules,
    },
    state: {
      touchRules,
      showTouchRulesContent,
    },
  };
};
