import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { If, Input, Option, Select, Text } from '@/lib/v2/components';

import {
  atomDateExecutionValue,
  atomDateFrequencyValue,
  atomRuleData,
} from '@/modules/RulesModule/atoms/rules';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import { TRANSLATE_RULES_SEQUENCE_SETTINGS } from '@/modules/RulesModule/constants';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IExecutionDateFieldsProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}
/** cspell:disable */
const ExecutionDateFields = ({ form, disabled = false, rules }: IExecutionDateFieldsProps) => {
  const { t } = useTranslation('rules');

  const { period } = useAtomValue(atomRuleData);
  const dateFrequencyValue = useAtomValue(atomDateFrequencyValue);
  const dateExecutionValue = useAtomValue(atomDateExecutionValue);

  const { dayOptions, monthOptions, periodOptions, hourOptions, minuteOptions } =
    useRuleDefaultOptions();

  const [periodSelectOptions, setPeriodSelectOptions] = useState(() => {
    if (period?.value === 'mes') {
      return monthOptions;
    }
    return dayOptions;
  });

  const periodOptionsFrequency = useMemo(() => {
    if (dateFrequencyValue && dateFrequencyValue === 'dia') {
      return periodOptions.filter((option) => option.value !== 'mes');
    }
    return periodOptions;
  }, [dateFrequencyValue, periodOptions]);

  const handleChangePeriod = useCallback(
    (event: Option | Option[]) => {
      const currentPeriod = event as Option;
      setPeriodSelectOptions(currentPeriod.value === 'mes' ? monthOptions : dayOptions);

      if (dateFrequencyValue === 'anio') return;
      form.setValue('days', { id: '1', name: '1', value: '1' } as Option);
    },
    [dateFrequencyValue, dayOptions, form, monthOptions]
  );

  return (
    <div className="flex items-center">
      {dateExecutionValue !== 'mismo' && (
        <div className="flex items-center">
          <div className="w-20">
            <If condition={dateFrequencyValue === 'anio'}>
              <Input
                isRequired
                control={form.control}
                disabled={disabled}
                name="uniqueExecution"
                placeholder="-"
                rules={rules.uniqueExecution}
                type="number"
              />
            </If>
            <If condition={dateFrequencyValue !== 'anio'}>
              <Select
                isRequired
                classNameMenuContainer="visible-scrollbar"
                control={form.control}
                disabled={disabled}
                name="days"
                options={periodSelectOptions}
                placeholder="-"
                rules={rules.days}
              />
            </If>
          </div>
          <div className="mx-2 min-w-28">
            <Select
              isRequired
              control={form.control}
              disabled={disabled}
              name="period"
              options={periodOptionsFrequency}
              placeholder="-"
              rules={rules.period}
              onChange={handleChangePeriod}
            />
          </div>
        </div>
      )}
      <Text className="whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)}
      </Text>
      <div className="mx-2 min-w-16">
        <Select
          isRequired
          classNameMenuContainer="visible-scrollbar"
          control={form.control}
          disabled={disabled}
          name="hours"
          options={hourOptions}
          placeholder="-"
          rules={rules.hours}
        />
      </div>
      <Text variant="text">:</Text>
      <div className="mx-2 min-w-16">
        <Select
          isRequired
          classNameMenuContainer="visible-scrollbar"
          control={form.control}
          disabled={disabled}
          name="minutes"
          options={minuteOptions}
          placeholder="-"
          rules={rules.minutes}
        />
      </div>
      <Text className="whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.timeUnit`)}
      </Text>
    </div>
  );
};
/** cspell:enable */

export default memo(ExecutionDateFields);
