import classNames from 'classnames';
import { FC } from 'react';

import { CardCompositionProps } from './types';

/**
 * CardHeader component - Used as the header section in a Card
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to render in the header
 * @param {boolean} [props.hasHeader=false] - Whether the header is present (used for styling)
 *
 * @example
 * // Basic usage
 * <Card.Header>
 *   <h2>Card Title</h2>
 * </Card.Header>
 *
 * @returns {JSX.Element} Header section for the Card
 */
const CardHeader: FC<CardCompositionProps> = ({ children, hasHeader = false }) => {
  const headerClass = classNames('px-6', {
    'pt-6 pb-2': hasHeader,
  });

  return <div className={headerClass}>{children}</div>;
};

export default CardHeader;
