import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { If, Input, Text } from '@/lib/v2/components';

import CustomFieldControl from './components/CustomFieldControl';
import useFieldsStatus from './useFieldsStatus';

import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';
import { IFormProps, IIncomingField, IRuleForm } from '@/modules/RulesModule/interfaces';

interface ICustomFieldsFormProps {
  customFieldsDTO: IIncomingField[];
  form: IFormProps<IRuleForm>;
  disabled?: boolean;
}

const CustomFieldsForm = ({ customFieldsDTO, form, disabled = false }: ICustomFieldsFormProps) => {
  const { t } = useTranslation('rules');
  const [searchFormField, setSearchFormField] = useState('');
  const { formFieldsStatus, handleChange } = useFieldsStatus(customFieldsDTO, form);

  const filteredCustomFields = useMemo(() => {
    return searchFormField
      ? customFieldsDTO.filter((field: IIncomingField) =>
          field.label.toLowerCase().includes(searchFormField.toLowerCase())
        )
      : customFieldsDTO;
  }, [searchFormField, customFieldsDTO]);

  const activeFieldsCount = useMemo(() => {
    return formFieldsStatus.filter((status) => status.switchStatus).length;
  }, [formFieldsStatus]);

  return (
    <div className="mb-6">
      <div className="sticky">
        <Input
          disabled={disabled}
          placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.searchByCustomField`)}
          type="text"
          value={searchFormField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchFormField(e.target.value)}
        />
        <div className="mb-2 mt-4 flex items-center justify-between px-4 ">
          <Text variant="text">
            {t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.customFields`)}
            <If condition={activeFieldsCount > 0}>{` (${activeFieldsCount})`}</If>
          </Text>
          <Text variant="text">{t(`${TRANSLATE_RULES_LABELS}.CUSTOM_FIELDS_FORM.required`)}</Text>
        </div>
      </div>
      <div className="visible-scrollbar mb-4 h-300 overflow-y-auto border-b">
        <form className="pl-4">
          {filteredCustomFields.map((field) => {
            const fieldStatus = formFieldsStatus.find((status) => status.id === field.id);
            return (
              <CustomFieldControl
                key={field.id}
                disabled={disabled}
                field={field}
                requiredStatus={fieldStatus?.requiredStatus || false}
                switchStatus={fieldStatus?.switchStatus || false}
                onChange={handleChange}
              />
            );
          })}
        </form>
      </div>
    </div>
  );
};

export default memo(CustomFieldsForm);
