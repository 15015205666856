import { ChannelTotalType, ChannelTrackingType, ShippingGraphMetric } from './Channel';

export interface ICardContacts {
  id?: string;
  datadogKey?: string;
  linkTo: string;
  icon?: React.ReactElement;
  bgIcon?: string;
  total?: string | number | React.ReactElement;
  metric?: string;
  wordings?: {
    title: string;
    description?: string;
    tooltip: string;
  };
}
export enum ContactsMetricsType {
  NEW = 'new',
  TOTAL = 'totalAccountContacts',
  FREQUENT = 'frequent',
  ASLEEP = 'asleep',
  INACTIVE = 'inactive',
}

export interface InsightsContacts {
  metricName: ContactsMetricsType;
  total: number;
  date: string;
}

export type InsightsContactsMetrics = {
  status: InsightsContacts[];
  statistics: InsightsAdditionalMetricsContacts;
};

type LimitedResourceStats = {
  count: number;
  limit: number;
};

export interface InsightsAdditionalMetricsContacts {
  groups: LimitedResourceStats;
  segments: LimitedResourceStats;
  tags: LimitedResourceStats;
  contacts: {
    total: number;
    discarded: {
      count: number;
      percentage: number;
    };
  };
}

export type IContactsMetric = {
  status: {
    [key in ContactsMetricsType]: InsightsContacts;
  };
  statistics: InsightsAdditionalMetricsContacts;
};

export enum InsightsMetricActionsType {
  'ALL' = 1,
  'EMAIL' = 2,
  'SMS' = 3,
  'INBOX' = 4,
  'ONSITE' = 5,
}

export const InsightsMetricActionsId: Record<ChannelTotalType, number> = {
  all: 1,
  email: 2,
  sms: 3,
  inbox: 4,
  onsite: 5,
};
export interface InsightsFiltersActionsParam {
  startDate: string;
  endDate: string;
  filterType: number;
}

export interface InsightMetricAction {
  key: string;
  value: number;
}

export type IChannelTotals = {
  [key in ChannelTotalType]: number;
};

export type ITrackingTotals = {
  [key in ChannelTrackingType]: number;
};

export interface InsightsMetricsActions {
  type: string;
  metrics: {
    channels: IChannelTotals;
    tracking: ITrackingTotals;
  };
  history: ShippingGraphMetric[];
}

export interface InsightsMetricAction {
  label: string;
  color: string;
  value: number;
}

export interface ISectionMetrics {
  [key: string]: InsightsMetricAction;
}
export interface IContactsGraphEvolution {
  startDate: string;
  endDate: string;
}
