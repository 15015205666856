import { t } from 'i18next';

export const reformatDateString = (dateString: string): string => {
  // Define the date separators
  const separators = ['/', '-'];
  let dateParts;

  // Loop through the separators to find which one is used in the dateString
  for (const separator of separators) {
    if (dateString.includes(separator)) {
      dateParts = dateString.split(separator);
      break;
    }
  }

  // If no separator was found, return the original dateString
  if (!dateParts) return dateString;

  // If the date is in the correct format, reformat it to YYYY/MM/DD
  if (
    dateParts.length === 3 &&
    dateParts[2].length === 4 &&
    dateParts.every((part) => !isNaN(Number(part)))
  ) {
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  }

  // If the date is not in the correct format, return the original dateString
  return dateString;
};

export const convertStringToDate = (
  dateString: string | object | null
): Date | null | undefined => {
  // If the dateString is null or undefined, return null
  if (!dateString) {
    return null;
  }

  // If the dateString is a Date object, return a new Date object with the same time
  if (dateString instanceof Date) {
    return new Date(dateString.getTime());
  }

  // If the dateString is a string, process it depending on its format
  if (typeof dateString === 'string' && dateString.length > 0) {
    let tempDate;

    // Define the date formats and their corresponding handling functions
    const dateFormats = {
      'DD-MM-YYYY': () => {
        const dateParts = dateString.split('-');
        return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
      },
      DDMMYYYY: () => {
        return new Date(+dateString.slice(4), +dateString.slice(2, 4) - 1, +dateString.slice(0, 2));
      },
      OTHER: () => {
        const reformattedDateString = reformatDateString(dateString);
        const dateWithoutTimeZone = reformattedDateString.replace(/GMT.*$/, '').trim();
        const date = new Date(dateWithoutTimeZone);
        if (isNaN(date.getTime())) {
          return null;
        }
        return date;
      },
    };

    // Determine the format of the dateString and process it accordingly
    switch (true) {
      case /^\d{2}-\d{2}-\d{4}$/.test(dateString):
        tempDate = dateFormats['DD-MM-YYYY']();
        break;
      case /^\d{8}$/.test(dateString):
        tempDate = dateFormats['DDMMYYYY']();
        break;
      case dateString.includes('/') || dateString.includes('-'):
        tempDate = dateFormats['OTHER']();
        break;
      default:
        return null;
    }

    // Adjust the date to the local timezone
    if (tempDate) {
      const localOffset = tempDate.getTimezoneOffset() * 60 * 1000;
      const adjustedDate = new Date(tempDate.getTime() + localOffset);
      return adjustedDate;
    }
  }

  // If the dateString is an object, try to convert it to a date
  if (typeof dateString === 'object') {
    const timeValue = dateString.valueOf();
    if (typeof timeValue === 'number') {
      return new Date(timeValue);
    }
  }

  return null;
};

/**
 * Converts a UTC date string to local time and returns it in ISO string format.
 * If the input is null, undefined, or an empty string, it returns the input as is.
 *
 * @param {string} dateString - The UTC date string to be converted.
 *
 * @returns {string} The date in local time, formatted as an ISO string, or the input as is if it's null, undefined, or an empty string.
 *
 * @example
 *
 * let utcDate = "2023-11-24T21:16:04.937Z";
 * let localDate = convertToLocalTime(utcDate);
 * console.log(localDate); // Outputs the local date and time in ISO string format
 */
export const convertUTCToLocalTime = (dateString: string): string => {
  if (!dateString || !dateString.length) {
    // eslint-disable-next-line no-console
    console.warn('not valid dateString:', dateString);
    return dateString;
  }
  // Create a date object from the UTC date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    // eslint-disable-next-line no-console
    console.warn('not valid date:', dateString);
    return dateString;
  }

  // Convert to local time
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Return the date in ISO string format
  return localDate.toISOString();
};

/**
 * @desc Parse string utc to dd/mm/yyy
 */
export const utcToDate = (dateISO: string) => {
  const date = new Date(dateISO);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

/**
 * Converts a UTC date string to a specific timezone and returns it in ISO string format.
 * If the input is null, undefined, or an empty string, it returns the input as is.
 * If the GMT offset is not between -12 and +12, it returns the input date string as is and logs a warning.
 *
 * @param {string} dateString - The UTC date string to be converted.
 * @param {number} gmtOffset - The GMT offset in hours for the timezone to convert to.
 *
 * @returns {string} The date in the specified timezone, formatted as an ISO string, or the input as is if it's null, undefined, or an empty string, or if the GMT offset is not between -12 and +12.
 *
 * let utcDate = "2023-11-24T21:16:04.937Z";
 * let gmtOffset = 3; // GMT+3
 * let localDate = convertUTCToSpecificTimezone(utcDate, gmtOffset);
 * console.log(localDate); // Outputs the date and time in GMT+3 in ISO string format
 */
export const convertUTCToSpecificTimezone = (dateString: string, gmtOffset: number): string => {
  if (!dateString || !dateString.length) {
    // eslint-disable-next-line no-console
    console.warn('not valid dateString:', dateString);
    return dateString;
  }

  if (isNaN(gmtOffset) || gmtOffset < -12 || gmtOffset > 12) {
    // eslint-disable-next-line no-console
    console.warn('not valid gmtOffset:', gmtOffset);
    return dateString;
  }
  // Create a date object from the UTC date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    // eslint-disable-next-line no-console
    console.warn('not valid date:', dateString);
    return dateString;
  }

  // Convert to the specific timezone
  const timezoneDate = new Date(date.getTime() + gmtOffset * 3600000);

  // Return the date in ISO string format
  return timezoneDate.toISOString();
};

export const formatServeStringDateWithUserTimezone = (
  utcDate: string,
  timeZoneOffset: number,
  timeSeparator: string = ','
): string => {
  const date = new Date(utcDate);

  const localDate = new Date(date.getTime() + timeZoneOffset * 60 * 60 * 1000);

  const year = localDate.getUTCFullYear();
  const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(localDate.getUTCDate()).padStart(2, '0');
  const hours = String(localDate.getUTCHours()).padStart(2, '0');
  const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${timeSeparator} ${hours}:${minutes}`;
};

export function convertDate(dateText: string, withDay: boolean = false) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const yearMonthRegex = /(\d{4})[-/](\d{1,2})|(\d{1,2})[-/](\d{4})/;
  const matches = dateText.match(yearMonthRegex);

  if (!matches) {
    return dateText;
  }

  let year, monthIndex;
  if (matches[1]) {
    // 'YYYY-MM' o 'YYYY/MM'
    year = matches[1];
    monthIndex = parseInt(matches[2], 10) - 1;
  } else {
    // 'MM/YYYY'
    year = matches[4];
    monthIndex = parseInt(matches[3], 10) - 1;
  }

  if (monthIndex < 0 || monthIndex > 11) {
    return 'Invalid month';
  }
  let day = 0;
  if (withDay) {
    day = new Date(dateText).getDate();
  }

  const month = months[monthIndex];

  return `${t(`MONTHS.${month.toLocaleLowerCase()}`)} ${withDay ? `${day},` : ''} ${year}`;
}
