import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import {
  atomActionData,
  atomActionDataLoading,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';

export const useActionData = (actionId: number) => {
  const [loading, setLoading] = useAtom(atomActionDataLoading);
  const [actionData, setActionData] = useAtom(atomActionData);
  const campaignService = useCampaignsService();
  const { pathname } = useLocation();

  const getCampaignData = useCallback(async () => {
    if (!actionId) return;

    setLoading(true);
    const { action } = await campaignService.getActionByID(actionId);

    if (action) {
      setActionData(action);
    }
    setLoading(false);
  }, [actionId, campaignService, setActionData, setLoading]);

  const refetch = () => {
    void getCampaignData();
  };
  useEffect(() => {
    return () => {
      setActionData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (actionData) return;

    void getCampaignData();
  }, [actionData, getCampaignData, pathname]);

  return {
    action: actionData,
    refetch,
    loading,
  };
};
