export const getInboxApiUrl = () => {
  const { vueEnv } = window.emblueConfig;

  switch (vueEnv) {
    case 'development':
      return 'http://localhost:3002';
    case 'production':
      return 'https://inbox-api.embluemail.com';
    case 'staging':
      return 'https://chat-staging-na-api.embluemail.com';
    default:
      return 'https://chat-staging-na-api.embluemail.com';
  }
};

export const getInboxNodeApiUrl = () => {
  const { vueEnv } = window.emblueConfig;

  switch (vueEnv) {
    case 'development':
      return 'https://d41b-191-97-241-218.ngrok-free.app';
    case 'production':
      return 'https://inbox-node.embluemail.com';
    case 'staging':
      return 'https://inbox-node-staging.embluemail.com';
    default:
      return 'https://inbox-node-staging.embluemail.com';
  }
};
