import { useAtomValue } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Position } from 'reactflow';

import InitialNode from '@/src/modules/RulesModule/components/InitialNode';
import { SkeletonNode } from '@/src/modules/RulesModule/components/Skeleton';
import { TRANSLATE_RULES_TRIGGER_TYPES } from '@/src/modules/RulesModule/constants';
import {
  RulesAPIIcon,
  RulesDateIcon,
  RulesExternalURLIcon,
  RulesFlowIcon,
  RulesFormIcon,
} from '@/src/modules/RulesModule/images/icons';
import { RuleTypes } from '@/src/modules/RulesModule/interfaces';

import NodeContentApi from './NodeContentApi';
import NodeContentDate from './NodeContentDate';
import NodeContentExternalCall from './NodeContentExternalCall';
import NodeContentFlows from './NodeContentFlows';
import NodeContentTag from './NodeContentTag';
import NodeContentWebForm from './NodeContentWebForm';

import {
  atomIsLoadingRuleData,
  atomIsTriggerFormComplete,
  atomRuleData,
} from '@/modules/RulesModule/atoms/rules';

const TriggerNodeContent = () => {
  const { t } = useTranslation('rules');

  const rulesData = useAtomValue(atomRuleData);
  const isTriggerFormComplete = useAtomValue(atomIsTriggerFormComplete);
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);

  const { component: NodeComponent, icon: Icon } = useMemo(() => {
    const nodeContentMap: Record<
      string,
      { component: React.ElementType; icon: React.ElementType }
    > = {
      [RuleTypes.DATE]: { component: NodeContentDate, icon: RulesDateIcon },
      [RuleTypes.TAG]: { component: NodeContentTag, icon: RulesDateIcon },
      [RuleTypes.API_INTEGRATOR]: { component: NodeContentApi, icon: RulesAPIIcon },
      [RuleTypes.FLOW_TEMPLATE]: { component: NodeContentFlows, icon: RulesFlowIcon },
      [RuleTypes.EXTERNAL_CALL]: { component: NodeContentExternalCall, icon: RulesExternalURLIcon },
      [RuleTypes.WEB_FORM]: { component: NodeContentWebForm, icon: RulesFormIcon },
    };

    const ruleValue = rulesData.trigger?.value;

    return (
      nodeContentMap[ruleValue as keyof typeof nodeContentMap] || nodeContentMap[RuleTypes.DATE]
    );
  }, [rulesData.trigger?.value]);

  if (isLoadingRuleData) return <SkeletonNode />;

  if (!isTriggerFormComplete) {
    return (
      <InitialNode
        icon={<Icon />}
        title={t(
          `${TRANSLATE_RULES_TRIGGER_TYPES}.${String(rulesData.trigger?.value)
            ?.replace(/ /g, '')
            ?.toLowerCase()}`
        )}
      />
    );
  }

  return (
    <>
      <NodeComponent />
      <Handle className="handle" position={Position.Bottom} type="source" />
    </>
  );
};

export default memo(TriggerNodeContent);
