import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Button, Flex, IconSvg, Spinner, Text } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline';

import CustomTooltip from './components/CustomTooltip';
import useScoreOverview from './hooks/useScoreOverview';
import { POSITION_SENDER_OVERVIEW } from './constants';

interface ScoreOverviewProps {
  onClose: () => void;
}

interface DataPoint {
  date: string;
  value: number | null;
}

const ScoreOverview = ({ onClose }: ScoreOverviewProps) => {
  const { t } = useTranslation();
  const { dataScoring, loadingScoring } = useScoreOverview();

  const yTicks = useMemo(
    () => [
      { value: 3, label: t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.moderate') },
      { value: 7, label: t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.optimal') },
    ],
    [t]
  );

  const filledData: DataPoint[] = useMemo(
    () => [
      ...Array.from({ length: 30 - dataScoring.length }, (_, i) => ({
        date: `Day ${i + 1}`,
        value: null,
      })),
      ...dataScoring,
    ],
    [dataScoring]
  );

  const customLabels = useMemo(
    () => [
      `90 ${t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.days')}`,
      `60 ${t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.days')}`,
      `30 ${t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.days')}`,
      t('DASHBOARDS_MODULE.SCORING_WIDGET.scoreOverView.today'),
    ],
    [t]
  );

  const xTicks = useMemo(
    () => POSITION_SENDER_OVERVIEW.map((pos) => filledData[pos]?.date || ''),
    [filledData]
  );

  const formatXAxis = useCallback(
    (tickItem: string) => {
      const index = filledData.findIndex((data) => data.date === tickItem);
      const labelIndex = POSITION_SENDER_OVERVIEW.indexOf(index);
      return labelIndex !== -1 ? customLabels[labelIndex] : tickItem;
    },
    [filledData, customLabels]
  );

  if (loadingScoring) {
    return <Spinner withoutOverlay />;
  }

  return (
    <div
      className="relative flex h-[450px] w-[900px] flex-col items-center divide-y bg-white"
      id="insights-sender-score-details"
    >
      <Flex noGrow className="flex w-full items-center justify-between">
        <Text
          alignment="left"
          as="h3"
          className="p-3 leading-tight"
          color="black"
          fontWeight="medium"
          variant="sub-headline"
        >
          {t('DASHBOARDS_MODULE.SCORING_WIDGET.sender.title')}
        </Text>
        <Button onlyIcon id="insights-sender-score-details-close" onClick={onClose}>
          <IconSvg svgComponent={<CloseIcon />} />
        </Button>
      </Flex>
      <ResponsiveContainer className="p-5" height={400} width="100%">
        <LineChart
          data={filledData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          syncId="score-overview"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatXAxis} ticks={xTicks} />
          <YAxis
            domain={[0, 10]}
            tickFormatter={(value: number) =>
              yTicks.find((t) => t.value === value)?.label || value.toString()
            }
            ticks={yTicks.map((t) => t.value)}
          />
          <Tooltip content={<CustomTooltip />} />
          {dataScoring.length > 0 && (
            <Line dataKey="value" fill="#004DBC" stroke="#004DBC" type="monotone" />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScoreOverview;
