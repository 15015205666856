import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { atomRuleTypeSelected } from '@/src/modules/RulesModule/atoms/rules';
import TouchRules from '@/src/modules/RulesModule/components/TouchRules';
import { TRANSLATE_RULES_LABELS } from '@/src/modules/RulesModule/constants';
import { useRuleStatus } from '@/src/modules/RulesModule/hooks/useRuleStatus';
import { RuleTypes } from '@/src/modules/RulesModule/interfaces';

import FormApi from './components/forms/FormApi';
import FormDate from './components/forms/FormDate';
import FormExternalCall from './components/forms/FormExternalCall';
import FormTag from './components/forms/FormTag';
import FormWebForm from './components/forms/FormWebForm';
import HeaderActionButtons from './components/HeaderActionButtons';
import RuleDropdownType from './components/RuleDropdownType';
import { useFormRule } from './hooks/useFormRule';

const FormRule = () => {
  const { t } = useTranslation('rules');
  const ruleTypeSelected = useAtomValue(atomRuleTypeSelected);

  const {
    state: { control, errors, RULES_DATE, RULES_EXTERNAL_CALL, RULES_WEB_FORM },
    events: { handleSubmit, handleCancel, watch, getValues },
    setter: { setValue },
  } = useFormRule();

  const { isRuleActive } = useRuleStatus();

  const formProps = useMemo(
    () => ({ control, setValue, getValues, watch, errors }),
    [control, setValue, getValues, watch, errors]
  );

  const SelectedComponent = useMemo(() => {
    const ruleConfig = {
      [RuleTypes.DATE]: { component: FormDate, rules: RULES_DATE },
      [RuleTypes.TAG]: { component: FormTag, rules: RULES_DATE },
      [RuleTypes.API_INTEGRATOR]: { component: FormApi, rules: {} },
      [RuleTypes.EXTERNAL_CALL]: { component: FormExternalCall, rules: RULES_EXTERNAL_CALL },
      [RuleTypes.WEB_FORM]: { component: FormWebForm, rules: RULES_WEB_FORM },
    };

    const config = ruleConfig[ruleTypeSelected?.value as keyof typeof ruleConfig];

    if (!config) return null;

    const { component: Component, rules } = config;
    return <Component disabled={isRuleActive} errors={errors} form={formProps} rules={rules} />;
  }, [
    RULES_DATE,
    RULES_EXTERNAL_CALL,
    RULES_WEB_FORM,
    ruleTypeSelected?.value,
    isRuleActive,
    errors,
    formProps,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="pl-4 pr-2">
        <SectionHeader
          sticky
          withoutDivide
          actionsButtons={<HeaderActionButtons onCancel={handleCancel} />}
          isLoading={false}
          titleNode={
            <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
              <Flex withGap>{t(`${TRANSLATE_RULES_LABELS}.trigger`)}</Flex>
            </Text>
          }
        />
        <hr className="w-full" />
      </div>
      <div className="px-8 pb-8 pt-6">
        <RuleDropdownType disabled={isRuleActive} form={formProps} rules={RULES_DATE} />
        {SelectedComponent}
        {ruleTypeSelected?.value !== RuleTypes.FLOW_TEMPLATE && (
          <TouchRules disabled={isRuleActive} form={formProps} />
        )}
      </div>
    </form>
  );
};

export default FormRule;
