import { InsightsMetricActionsType } from './ContactsSummary';

export enum ShippingRange {
  WEEKLY = 'weekly',
  BIWEEKLY = 'bi-weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum ChannelTotalType {
  ALL = 'all',
  EMAIL = 'email',
  INBOX = 'inbox',
  ONSITE = 'onsite',
  SMS = 'sms',
}

export enum ChannelTrackingType {
  DELIVERED = 'delivered',
  OPENS = 'opens',
  CLICKS = 'clicks',
  BOUNCES = 'bounces',
  UNSUBSCRIBES = 'unsubscribes',
  FAILED = 'failed',
}

export interface ISectionMetrics {
  [key: string]: {
    title: string;
    metrics: ChannelTrackingType[];
    colors: string[];
  };
}

export interface ShippingGraphMetric {
  date: string;
  delivered: number;
  opens: number;
  bounces: number;
  clicks: number;
  unsubscribes: number;
}

export interface ShippingMetadataMetric {
  totalAll: number;
  totalEmail: number;
  totalSms: number;
  totalInbox: number;
  totalOnsite: number;
}

export interface ShippingFilters {
  startDate: string;
  endDate: string;
  filterType: InsightsMetricActionsType;
}
