import { useAtomValue } from 'jotai';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, Flex, IconSvg, Text } from '@/lib/v2/components';
import { EmailIcon, ViewsIcon as ViewsIconSolid } from '@/lib/v2/icons/solid';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  TRANSLATE_RULES_ACTION_TYPES,
  TRANSLATE_RULES_CALL_TO_ACTION,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';

const EmailContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('rules');
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();
  const isRulePath = pathname.includes('automation/rules');

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const { emailInfo } = actionInfoEmail || {};

  const handleClickPreview = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (isRulePath) {
        navigate(`/v2/automation/${campaignId}/action/${actionId}/${actionType}/preview`);
        return;
      }
      if (campaignId && actionId) {
        navigate(`/v2/campaigns/${campaignId}/${actionType}/${actionId}/preview`);
      }
    },
    [isRulePath, campaignId, actionId, navigate, actionType]
  );

  const subjectEmail = useMemo(
    () => emailInfo?.subject || t(`${TRANSLATE_RULES_PLACEHOLDERS}.withoutSubject`),
    [emailInfo?.subject, t]
  );
  const colorText = useMemo(() => (emailInfo?.subject ? 'dark' : 'gray'), [emailInfo?.subject]);
  const colorIcon = useMemo(() => (emailInfo?.subject ? 'primary' : 'gray'), [emailInfo?.subject]);

  return (
    <>
      <Flex withGap alignment="start" className="mb-2">
        <IconSvg
          rounded
          bgColor="inactive"
          fillColor={colorIcon}
          height="32px"
          id="email-icon"
          svgComponent={<EmailIcon />}
          width="32px"
          withPadding="small"
        />
        <Text fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_ACTION_TYPES}.sendEmail`)}
        </Text>
        <div className="ml-auto">
          <Button
            gray
            outline
            data-id={actionId}
            id="preview-actions"
            size="small"
            title={t(`${TRANSLATE_RULES_CALL_TO_ACTION}.previewEmail`)}
            onClick={handleClickPreview}
          >
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ViewsIconSolid />}
              width="13px"
            />
          </Button>
        </div>
      </Flex>
      <Flex column alignment="start" className="mb-4" itemAlignment="start" textAlignment="left">
        <hr className="w-full min-w-64 pb-2" />
        <div className="w-full truncate">
          <Text truncate color={colorText} fontWeight="normal" variant="text">
            {subjectEmail}
          </Text>
        </div>
      </Flex>
    </>
  );
};
export default EmailContent;
