import { useCallback, useState } from 'react';

import { useInsightsService } from '@/src/infrastructure/Protocol/Insights/useInsightsService';
import { useUserData } from '@/src/infrastructure/services/useUserData';

export const useUpdateContactsStatus = () => {
  const [userData] = useUserData();
  const service = useInsightsService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const success = await service.updateContactsStatus([+userData.companyId]);

      if (!success) {
        setError('Could not update contacts status');
      }

      return success;
    } catch (err) {
      setError('Error updating contacts status');
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [service, userData.companyId]);

  return {
    state: {
      isLoading,
      error,
    },
    events: {
      updateStatus,
    },
  };
};
