import classNames from 'classnames';
import { Children, isValidElement } from 'react';

import CardContent from './CardContent';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';
import { CardProps } from './types';

/**
 * Card component - A versatile container for displaying content in a structured format
 * Uses a composition pattern with Header, Content, and Footer subcomponents
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the card
 * @param {boolean} [props.isAutoHeight=false] - Whether the card should expand to fill its container height
 * @param {React.ReactElement|React.ReactElement[]} props.children - Only Card.Header, Card.Content, and Card.Footer
 *   components should be used as direct children
 *
 * @example
 * // With auto height
 * <Card id="auto-height-card" isAutoHeight>
 *   <Card.Content>
 *     <p>This card will expand to fill its container height</p>
 *   </Card.Content>
 * </Card>
 *
 * @returns {JSX.Element} The Card component
 */
const Card = ({ children, id, isAutoHeight = false }: CardProps) => {
  let hasHeader = false;
  let hasFooter = false;

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type !== CardHeader && child.type !== CardContent && child.type !== CardFooter) {
        throw new Error(
          `Card component only accepts Card.Header, Card.Content, and Card.Footer as children. Found: ${child.type}`
        );
      }
      if (child.type === CardHeader) {
        hasHeader = true;
      } else if (child.type === CardFooter) {
        hasFooter = true;
      }
    }
  });

  const clonedChildren = Children.map(children, (child) => {
    if (!isValidElement(child)) return null;

    if (child.type === CardHeader) {
      return { ...child, props: { ...child.props, hasHeader } };
    }
    if (child.type === CardContent) {
      return { ...child, props: { ...child.props, hasHeader, hasFooter } };
    }
    if (child.type === CardFooter) {
      return { ...child, props: { ...child.props, hasFooter } };
    }

    return null;
  });

  const classes = classNames('overflow-hidden rounded-lg border border-gray-200 bg-white', {
    'h-full': isAutoHeight,
    'h-auto': !isAutoHeight,
  });

  return (
    <div aria-hidden className={classes} id={id}>
      {clonedChildren}
    </div>
  );
};

// Attach subcomponents to Card
Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;

/**
 * Card component with Header, Content, and Footer subcomponents
 *
 * @type {Card}
 */
export default Card;
