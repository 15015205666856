import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/organisms';

import { atomToggleCreateRuleModal } from '@/src/modules/RulesModule/atoms/rules';

import { useCreateRuleModal } from './hooks/useCreateRuleModal';

const CreateRuleModal = () => {
  const { t } = useTranslation();

  const toggleCreateRuleModal = useAtomValue(atomToggleCreateRuleModal);

  const {
    events: { handleCloseRuleModal, handleCreateRule, validateRuleName },
    state: { processing, loadingValidation, RULE_VALIDATION_RULES },
  } = useCreateRuleModal();

  return (
    <ModalSingleInput
      disabledUntilOnChange
      id="create-action-modal"
      isLoading={loadingValidation || processing}
      isOpen={toggleCreateRuleModal}
      rules={RULE_VALIDATION_RULES}
      title={t('ALL_CARDS_ACTIONS.RULES_TYPES.title')}
      onClose={handleCloseRuleModal}
      onSubmit={handleCreateRule}
      onValidateInputFetch={(value) =>
        validateRuleName(value, t('CAMPAIGN_ACTIONS_MAIN.existAction'))
      }
    />
  );
};

export default memo(CreateRuleModal);
