import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CardSection, Flex } from '@/lib/v2/components';

import {
  atomDeliveryType,
  atomHeaderCurrentStep,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { SmsScheduling } from '@/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsScheduling';
import { useDeliverySms } from '@/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsScheduling/hooks/useDeliverySms';
import { useActionSmsData } from '@/modules/CampaignsModule/hooks/useActionSmsData';
import { EStepHeaderAction } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ISmsInfoResponseV1 } from '@/modules/CampaignsModule/interfaces/Campaigns';

const SmsSchedulingMain = () => {
  const { actionId } = useParams();

  const [actionInfoSms, setActionInfoSms] = useAtom(atomActionInfoSms);
  const setDeliveryTypeSelected = useSetAtom(atomDeliveryType);
  const [currentStep, setCurrentStep] = useAtom(atomHeaderCurrentStep);

  const [immediateDelivery, scheduledDelivery] = useDeliverySms();

  const deliveryOptionsSms = useDeliverySms();
  const { getSmsInfo, loading } = useActionSmsData(Number(actionId));

  useEffect(() => void getSmsInfo(), [getSmsInfo]);

  useEffect(() => {
    if (currentStep !== EStepHeaderAction.MIDDLE) {
      setCurrentStep(EStepHeaderAction.MIDDLE);
    }
  }, [currentStep, setCurrentStep]);

  // cspell:disable
  useEffect(() => {
    if (actionInfoSms) {
      setDeliveryTypeSelected(
        actionInfoSms?.inmediato ? immediateDelivery.id : scheduledDelivery.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionInfoSms]);
  // cspell:enable

  useEffect(() => {
    return () => {
      setActionInfoSms({} as ISmsInfoResponseV1);
    };
  }, []);

  return (
    <div className="relative z-0 bg-white">
      <CardSection noPadding>
        <Flex column>
          <SmsScheduling
            deliveryOptions={deliveryOptionsSms}
            loading={loading && !(Object.keys(actionInfoSms).length > 0)}
          />
        </Flex>
      </CardSection>
    </div>
  );
};

export default SmsSchedulingMain;
