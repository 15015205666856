import { useTranslation } from 'react-i18next';

import { Modal, Text } from '@/lib/v2/components';

import { useInboxSummaryConversation } from '@/src/apps/chat/hooks/useInboxSummaryConversation';

const ContactsSummaryConversation = () => {
  const { summary, open: openSummary, handleCloseSummary } = useInboxSummaryConversation();
  const { t } = useTranslation(['inbox']);
  return (
    <Modal open={openSummary} onClose={handleCloseSummary}>
      <Text className="mb-4" fontWeight="medium" variant="sub-headline-2">
        {t('INBOX.RESUME')}
      </Text>
      <hr className="mb-4" />
      <Text variant="text">{summary}</Text>
    </Modal>
  );
};
export default ContactsSummaryConversation;
