import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

export interface ILink {
  name: string;
  to?: string;
  onClick?: () => void;
  children?: Omit<ILink, 'children'>[];
  current?: boolean;
  childrenIsActive?: boolean;
}

type ILinks = ILink[];

const useLinks = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isWhatsAppColumnConsumptionEnabled = useFeatureFlag('enableWhatsAppColumnConsumption');

  const handleScroll = useCallback((id: string) => {
    document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const links: ILinks = [
    {
      name: t('BACKOFFICE.menu.contracts'),
      to: '/v2/backoffice/contracts',
      current:
        pathname.endsWith('/v2/backoffice/contracts') ||
        pathname.endsWith('/v2/backoffice/contracts/') ||
        pathname.includes('/v2/backoffice/contracts/') ||
        pathname.includes('/v2/backoffice/contracts/create'),
      childrenIsActive:
        pathname.includes('/v2/backoffice/contracts/') ||
        pathname.includes('/v2/backoffice/contracts/create'),
      children: [
        {
          name: t('CONTRACTS_MODULE.dataContract'),
          onClick: () => handleScroll('#contractForm'),
        },
        {
          name: t('CONTRACTS_MODULE.dataClient'),
          onClick: () => handleScroll('#clientForm'),
        },
        {
          name: t('CONTRACTS_MODULE.planDetails'),
          onClick: () => handleScroll('#planForm'),
        },
      ],
    },
    {
      name: t('BACKOFFICE.menu.logs'),
      to: '/v2/backoffice/contracts/logs',
      current:
        pathname.endsWith('/v2/backoffice/contracts/logs') ||
        pathname.endsWith('/v2/backoffice/contracts/logs/'),
    },
    {
      name: t('BACKOFFICE.menu.plans'),
      to: '/v2/backoffice/plans',
      current:
        pathname.endsWith('/v2/backoffice/plans') || pathname.endsWith('/v2/backoffice/plans/'),
    },
    {
      name: t('BACKOFFICE.menu.sms'),
      to: '/v2/backoffice/sms',
      current: pathname.endsWith('/v2/backoffice/sms') || pathname.endsWith('/v2/backoffice/sms/'),
    },
    ...(isWhatsAppColumnConsumptionEnabled
      ? [
          {
            name: t('BACKOFFICE.menu.whatsapp'),
            to: '/v2/backoffice/whatsapp',
            current:
              pathname.endsWith('/v2/backoffice/whatsapp') ||
              pathname.endsWith('/v2/backoffice/whatsapp/'),
          },
        ]
      : []),
    {
      name: t('CONTRACT_BILLINGS.title'),
      to: '/v2/backoffice/contracts/billings',
      current:
        pathname.endsWith('/v2/backoffice/contracts/billings') ||
        pathname.endsWith('/v2/backoffice/contracts/billings/'),
    },
    {
      name: t('BACKOFFICE.menu.surplus'),
      to: '/v2/backoffice/surplus',
      current:
        pathname.endsWith('/v2/backoffice/surplus') || pathname.endsWith('/v2/backoffice/surplus/'),
    },
  ];

  return { links };
};

export default useLinks;
