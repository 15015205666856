import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import {
  DEFAULT_MODAL_CONFIRMATION,
  DEFAULT_ORDERBY_ACTIONS,
  defaultActionsOrderBy,
  defaultPager,
  MASTER_ACTION,
} from '@/src/modules/CampaignsModule/constants';
import {
  DELIVERY_OPTIONS,
  EActionTypes,
  EActionTypesPath,
  EStepHeaderAction,
  IActionHeaderCurrentStep,
  ICalendarCampaignActions,
  ICalendarCampaignList,
  IDeliveryActionResponse,
  IDeliveryModalConfirmation,
  IEmailPreview,
  IFiltersDropdownTableActions,
  IFilterTableActions,
  IHistorySearchAction,
  ITableCampaignActions,
  ITouchRulesResponse,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IOrderByCell,
  IOrderByTable,
  IPager,
  IQuickToolsItem,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const atomActionsList = atomWithReset<ITableCampaignActions[] | undefined>(undefined);
atomActionsList.debugLabel = 'atomActionsList';

export const atomRecipientsList = atomWithReset<{ aaData: [] }>({ aaData: [] });
atomRecipientsList.debugLabel = 'atomRecipientsList';

export const atomTouchRules = atomWithReset<ITouchRulesResponse>({} as ITouchRulesResponse);
atomTouchRules.debugLabel = 'atomTouchRules';

export const atomDeliveryAction = atomWithReset<IDeliveryActionResponse>(
  {} as IDeliveryActionResponse
);
atomDeliveryAction.debugLabel = 'atomDeliveryAction';

type CallbackStep = (() => Promise<void>) | (() => void);

export const atomCallbackStep = atom<CallbackStep | null>(null);
atomCallbackStep.debugLabel = 'atomCallbackStep';

export const atomCallbackPauseStep = atom<CallbackStep | null>(null);
atomCallbackPauseStep.debugLabel = 'atomCallbackPauseStep';

export const haveMessageAtom = atom<boolean>(false);
haveMessageAtom.debugLabel = 'haveMessageAtom';

export const atomRecipientOptions = atomWithReset<OptionExternal[]>([]);
atomRecipientOptions.debugLabel = 'atomRecipientOptions';

export const atomOpenMessageModal = atom<boolean>(false);
atomOpenMessageModal.debugLabel = 'atomOpenMessageModal';

export const atomRecipientOffset = atom<number>(0);
atomRecipientOffset.debugLabel = 'atomRecipientOffset';

export const atomInputRecipientValue = atom<string>('');
atomInputRecipientValue.debugLabel = 'atomInputRecipientValue';

export const atomIsSearchingRecipients = atom<boolean>(false);
atomIsSearchingRecipients.debugLabel = 'atomIsSearchingRecipients';

//TODO: Remove this atom when refactor campaign actions
export const atomSelectedRowsQuickTools = atom<boolean>(false);
atomSelectedRowsQuickTools.debugLabel = 'atomSelectedRowsQuickTools';

export const atomSelectedRowsAction = atom<number[]>([]);
atomSelectedRowsAction.debugLabel = 'atomSelectedRowsAction';

export const atomTotalActionsSelectedRows = atom<number>((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  return selectedRows.length || 0;
});
atomTotalActionsSelectedRows.debugLabel = 'atomTotalActionsSelectedRows';

export const atomSelectedActionsRowsWithID = atom<number[]>((get) => {
  const selectedActionsRowsWithID = get(atomSelectedRowsAction)
    .map((index) => {
      const action = get(atomActionsList)?.[index]?.action;
      if (action && action.channel !== MASTER_ACTION) {
        return action.actionId;
      }
    })
    .filter((actionId) => actionId !== undefined) as number[];

  return selectedActionsRowsWithID;
});
atomSelectedActionsRowsWithID.debugLabel = 'atomSelectedActionsRowsWithID';

export const atomSelectedActionsRowsHasMaster = atom((get) => {
  const hasMaster = get(atomSelectedRowsAction).some(
    (index) => get(atomActionsList)?.[index]?.action?.channel === MASTER_ACTION
  );

  return hasMaster;
});
atomSelectedActionsRowsHasMaster.debugLabel = 'atomSelectedActionsRowsHasMaster';

export const atomSelectedRowActionName = atom((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  const lastIndex = selectedRows.length - 1;
  const selectedActionName = get(atomActionsList)?.[selectedRows[lastIndex]]?.action?.name;
  return selectedActionName;
});
atomSelectedRowActionName.debugLabel = 'atomSelectedRowActionName';

export const atomSelectedRowActionCampaignId = atom((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  const lastIndex = selectedRows.length - 1;
  const selectedActionCampaignId =
    get(atomActionsList)?.[selectedRows[lastIndex]]?.action?.campaignId;
  return selectedActionCampaignId;
});
atomSelectedRowActionCampaignId.debugLabel = 'atomSelectedActionCampaignId';

export const atomSelectedRowCampaignId = atom((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  const lastIndex = selectedRows.length - 1;
  const selectedCampaignId = get(atomActionsList)?.[selectedRows[lastIndex]]?.action?.campaignId;
  return selectedCampaignId;
});
atomSelectedRowCampaignId.debugLabel = 'atomSelectedRowCampaignId';

export const atomSelectedRowActionType = atom((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  const lastIndex = selectedRows.length - 1;
  const selectedActionName = get(atomActionsList)?.[selectedRows[lastIndex]]?.action?.channel;
  return selectedActionName;
});
atomSelectedRowActionType.debugLabel = 'atomSelectedRowActionType';

//TODO: Remove this atom when refactor campaign actions
export const atomIndexMasterAction = atom<number[]>((get) => {
  const masterIndexActions = get(atomActionsList)
    ?.map((actionObj, index) => (actionObj.action.channel === MASTER_ACTION ? index : -1))
    .filter((index) => index !== -1);

  return masterIndexActions || [];
});
atomIndexMasterAction.debugLabel = 'atomIndexMasterAction';

export const atomShowSpinnerByAction = atomWithReset(true);
atomShowSpinnerByAction.debugLabel = 'atomShowSpinnerByAction';

export const atomFiltersDropdownAction = atomWithReset<IFiltersDropdownTableActions | undefined>(
  undefined
);
atomFiltersDropdownAction.debugLabel = 'atomFiltersDropdownAction';

export const atomFiltersAction = atomWithReset<IFilterTableActions>({ ...defaultPager });
atomFiltersAction.debugLabel = 'atomFiltersAction';

export const atomPagerAction = atomWithReset<IPager>(defaultPager);
atomPagerAction.debugLabel = 'atomPagerAction';

export const atomOrderByAction = atom<IOrderByTable>(defaultActionsOrderBy);
atomOrderByAction.debugLabel = 'atomOrderByAction';

export const atomOrderByActionValues = atomWithReset<IOrderByCell[]>(DEFAULT_ORDERBY_ACTIONS);
atomOrderByActionValues.debugLabel = 'atomOrderByActionValues';

export const atomHistorySearchPagerActions = atomWithReset<IHistorySearchAction | undefined>(
  undefined
);
atomHistorySearchPagerActions.debugLabel = 'atomHistorySearchPagerActions';

//TODO: Remove this atom when refactor campaign actions
export const atomActionType = atomWithReset<EActionTypes | undefined>(undefined);
atomActionType.debugLabel = 'atomActionType';

export const atomHeaderCurrentStep = atom<IActionHeaderCurrentStep>(EStepHeaderAction.FIRST);
atomHeaderCurrentStep.debugLabel = 'atomHeaderCurrentStep';

export const atomHeaderActionTypeLastStep = atom<boolean>(false);
atomHeaderActionTypeLastStep.debugLabel = 'atomHeaderActionTypeLastStep';

export const atomHeaderActionLabelButton = atom<string | undefined>(undefined);
atomHeaderActionLabelButton.debugLabel = 'atomHeaderActionLabelButton';

export const atomToggleConfirmActionModal = atom<boolean>(false);
atomToggleConfirmActionModal.debugLabel = 'atomToggleConfirmActionModal';

export const atomToggleConfirmActionDateModal = atom<IDeliveryModalConfirmation>(
  DEFAULT_MODAL_CONFIRMATION
);
atomToggleConfirmActionDateModal.debugLabel = 'atomToggleConfirmActionDateModal';

export const atomDeliveryInformation = atom<IDeliveryActionResponse | undefined>(undefined);
atomDeliveryInformation.debugLabel = 'atomDeliveryInformation';

export const atomDeliveryType = atom<DELIVERY_OPTIONS | undefined>(DELIVERY_OPTIONS.immediate);
atomDeliveryType.debugLabel = 'atomDeliveryType';

export const atomActionTypePath = atom<EActionTypesPath>(EActionTypesPath.EMAIL);
atomActionTypePath.debugLabel = 'atomActionTypePath';

export const atomCurrentActionId = atom<number | undefined>(undefined);
atomCurrentActionId.debugLabel = 'atomCurrentActionId';

export const atomListCalendarSidebar = atom<ICalendarCampaignList | undefined>(undefined);
atomListCalendarSidebar.debugLabel = 'atomListCalendarSidebar';

export const atomListCalendar = atom<ICalendarCampaignActions[] | undefined>(undefined);
atomListCalendar.debugLabel = 'atomListCalendar';

export const atomCurrentDateCalendar = atom<Date>(new Date());
atomCurrentDateCalendar.debugLabel = 'atomCurrentDateCalendar';

export const atomCalendarLoaderSidebar = atom<boolean>(false);
atomCalendarLoaderSidebar.debugLabel = 'atomCalendarLoaderSidebar';

export const atomCalendarLoader = atom<boolean>(false);
atomCalendarLoader.debugLabel = 'atomCalendarLoader';

export const atomPreviewContactId = atom<number | undefined>(undefined);
atomPreviewContactId.debugLabel = 'atomPreviewContactId';

export const atomEmailPreview = atom<IEmailPreview | undefined>(undefined);
atomEmailPreview.debugLabel = 'atomEmailPreview';

export const atomContractCountry = atom<string | undefined>(undefined);
atomContractCountry.debugLabel = 'atomContractCountry';

//TODO: Remove this atom when refactor the component
export const atomStatusFilterCount = atom<number | undefined>(undefined);
atomStatusFilterCount.debugLabel = 'atomStatusFilterCount';

export const atomActionDetailQuickTools = atomWithReset<IQuickToolsItem | undefined>(undefined);
atomActionDetailQuickTools.debugLabel = 'atomActionDetailQuickTools';

export const atomCallbackReportSmsStep = atom<CallbackStep | null>(null);
atomCallbackReportSmsStep.debugLabel = 'atomCallbackReportSmsStep';

export const atomDownloadingReport = atom<boolean>(false);
atomDownloadingReport.debugLabel = 'atomDownloadingReport';
