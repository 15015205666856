import { useAtom } from 'jotai';

import { Button, IconSvg, If, Modal, Text } from '@/lib/v2/components';

import { atomToggleConfirmActionDateModal } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { DEFAULT_MODAL_CONFIRMATION } from '@/modules/CampaignsModule/constants';

interface IConfirmActionProps {
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmActionDate = ({ onConfirm, loading }: IConfirmActionProps) => {
  const [confirmActionDateModal, setConfirmActionDateModal] = useAtom(
    atomToggleConfirmActionDateModal
  );
  const handleConfirmAction = () => onConfirm?.();
  const handleCancel = () => setConfirmActionDateModal(DEFAULT_MODAL_CONFIRMATION);

  return (
    <Modal open={confirmActionDateModal.show} showCloseButton={false}>
      <div className="flex flex-col justify-center">
        <If condition={!!confirmActionDateModal?.wordings?.title}>
          <div className="flex w-full flex-col">
            <Text fontWeight="medium" variant="sub-headline">
              {confirmActionDateModal.wordings.title}
            </Text>
            <hr className="my-4 divide-y" />
          </div>
        </If>
        <div className="mx-auto flex flex-col justify-center align-middle">
          <IconSvg
            className="mx-auto my-6"
            height="164px"
            svgComponent={confirmActionDateModal.icon}
            width="164px"
          />
          <If condition={!!confirmActionDateModal?.wordings?.detail}>
            <Text alignment="center" as="p" variant="sub-headline">
              {confirmActionDateModal?.wordings?.detail}
            </Text>
          </If>
          <If condition={!!confirmActionDateModal?.wordings?.description}>
            <Text alignment="center" as="p" className="px-6" variant="sub-headline">
              {confirmActionDateModal?.wordings?.description}
            </Text>
          </If>
        </div>
        <div className="mt-9 flex w-full justify-center gap-3">
          <If condition={!!confirmActionDateModal?.wordings?.cancel}>
            <Button outline id="modal-confirm__cancel" onClick={handleCancel}>
              {confirmActionDateModal?.wordings?.cancel}
            </Button>
          </If>
          <Button id="modal-confirm__action" isLoading={loading} onClick={handleConfirmAction}>
            {confirmActionDateModal?.wordings?.confirm}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActionDate;
