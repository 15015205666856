import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Check, PendingIcon, RejectedIcon } from '@/lib/v2/icons/solid';

import {
  WHATSAPP_TEMPLATE_ERROR_TRANSLATION_PATH,
  WhatsAppTemplateRejectionErrorCode,
} from '@/src/modules/ContactsModule/constants';

import './CardTemplate.tailwind.css';

export enum CardTemplateStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export interface CardTemplateProps {
  id: string;
  description: string;
  title: string;
  latestUpdate: string;
  buttonText: string;
  onClick?: () => void;
  status?: CardTemplateStatus;
  rejectionReason?: string;
}

const CardTemplate: FC<CardTemplateProps> = ({
  id,
  description,
  title,
  latestUpdate,
  buttonText,
  onClick,
  status = CardTemplateStatus.PENDING,
  rejectionReason,
}) => {
  const { t } = useTranslation('inbox');

  const ICON_STATUS_MAP: Record<CardTemplateStatus, JSX.Element> = {
    [CardTemplateStatus.APPROVED]: <Check />,
    [CardTemplateStatus.PENDING]: <PendingIcon />,
    [CardTemplateStatus.REJECTED]: <RejectedIcon />,
  };

  const ERROR_CODE_TRANSLATION_MAP: Record<string, string> = {
    [WhatsAppTemplateRejectionErrorCode.INVALID_FORMAT]: `${WHATSAPP_TEMPLATE_ERROR_TRANSLATION_PATH}.${WhatsAppTemplateRejectionErrorCode.INVALID_FORMAT}`,
    [WhatsAppTemplateRejectionErrorCode.TAG_CONTENT_MISMATCH]: `${WHATSAPP_TEMPLATE_ERROR_TRANSLATION_PATH}.${WhatsAppTemplateRejectionErrorCode.TAG_CONTENT_MISMATCH}`,
  };

  const getRejectionReasonText = () => {
    if (!rejectionReason) return '';

    if (Object.keys(ERROR_CODE_TRANSLATION_MAP).includes(rejectionReason)) {
      return t(ERROR_CODE_TRANSLATION_MAP[rejectionReason]);
    }

    return rejectionReason;
  };

  const shouldApplyDefaultContent =
    status === CardTemplateStatus.APPROVED || status === CardTemplateStatus.REJECTED;

  return (
    <Card key={id} className="relative h-60 w-full overflow-hidden">
      <CardSection className="flex h-full flex-col justify-between">
        <div className="card-content size-full">
          <div className={`${shouldApplyDefaultContent ? 'default-content' : ''} h-40`}>
            <Flex column noGrow className="h-full p-3" itemAlignment="start">
              <Text
                className="line-clamp-4 whitespace-pre-wrap"
                fontWeight="normal"
                variant="sub-headline"
              >
                {description}
              </Text>
            </Flex>
          </div>
          {status === CardTemplateStatus.APPROVED && (
            <div className="hover-content h-40">
              <Flex alignment="center" className="size-full" itemAlignment="center">
                <Button primary onClick={onClick}>
                  {buttonText}
                </Button>
              </Flex>
            </div>
          )}
          {status === CardTemplateStatus.REJECTED && (
            <div className="hover-content h-40">
              <Flex
                column
                withGap
                alignment="start"
                className="size-full p-3"
                gapSize="small"
                itemAlignment="start"
              >
                <Text fontWeight="medium" variant="text">
                  {t('INBOX.SEND_WHATSAPP_MODAL.TEMPLATES_CONTAINER.REJECTION_REASON_TITLE')}
                </Text>
                <Text color="alert" variant="text-sm">
                  {getRejectionReasonText()}
                </Text>
              </Flex>
            </div>
          )}

          <Flex noGrow alignment="spaceBetween" className="px-3" itemAlignment="center">
            <Text fontWeight="medium" variant="text">
              {title}
            </Text>
            <IconSvg svgComponent={ICON_STATUS_MAP[status]} />
          </Flex>
          <Flex noGrow alignment="start" className="px-3" itemAlignment="center">
            <Text alignment="left" fontWeight="light" variant="text">
              {latestUpdate}
            </Text>
          </Flex>
        </div>
      </CardSection>
    </Card>
  );
};

export default memo(CardTemplate);
