/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';
import _ from 'lodash';

import { APIService } from '@/src/infrastructure/Protocol/APIService';
import { EmblueService } from '@/src/infrastructure/Protocol/EmblueService';
import { ValidationError } from '@/src/infrastructure/Protocol/Errors';
import { GenericServiceResponse } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { convertUTCToSpecificTimezone } from '@/src/utils/Date/dateUtils';

import { ICampaignsService, IEmblueApiMethodNameMap } from './ICampaignsService';

import {
  ConfirmEmailErrors,
  IAddresseesInfoResponse,
  IAddresseesList,
  IAddresseesListResponse,
  IAddresseesPayload,
  ICalendarActionsPayload,
  ICalendarCampaignActions,
  ICampaignActions,
  ICampaignActionsListPayload,
  ICampaignsActionsResponse,
  ICheckNameResource,
  IDeliveryActionPayload,
  IDeliveryActionResponse,
  IDuplicateCampaignActions,
  IDuplicateCampaignActionsResponse,
  IEditActionPayload,
  IEditActionResponse,
  IEmailInfoPayload,
  IEmailInfoResponse,
  IEmailPreview,
  ISetDeliveryActionPayload,
  ISetDeliveryActionResponse,
  ITableCampaignActions,
  ITouchRulesResponse,
  IUpdateStatusAction,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EChannelsID,
  IActionByIdResponse,
  ICampaign,
  ICampaignDetail,
  ICampaignDetailResponse,
  ICampaignDetailResult,
  ICampaignListPayload,
  ICampaignRecurrentCreated,
  ICampaignsListResponse,
  ICampaignsRecurrentListResponse,
  ICampaignsStrategiesResponse,
  ICreateAction,
  ICreateActionResponse,
  ICreateEditCampaigns,
  ICreateEditCampaignsRecurrentResponse,
  ICreateEditCampaignsResponse,
  IDuplicateCampaigns,
  IDuplicateCampaignsResponse,
  IListSearchCampaigns,
  IRecurrenceMessage,
  ISearchCampaignsGlobal,
  IStrategy,
  ITableCampaigns,
  ITableCampaignsRecurrent,
  TActionID,
  TCampaignID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

const STATUS_SUCCESS = 200;

/* eslint-disable @typescript-eslint/no-explicit-any */
export class CampaignsService implements ICampaignsService {
  private api: APIService;
  private pathEditor = 'api/v2.1/editor';
  private pathCampaigns = 'api/v2.1/campaigns';
  private pathActions = 'api/v2.1/campaigns/actions';

  private constructor(api: APIService) {
    this.api = api;
  }

  /**
   * @param error AxiosError<Error>
   * @description Catch errors from API
   * @returns void
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error CampaignsService[${method}]:`, message);
  }

  /**
   * @param api APIService
   * @returns {ICampaignsService}
   * @description Create new instance of CampaignsService
   */
  static create(api: APIService): ICampaignsService {
    return new CampaignsService(api);
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new campaign
   */
  async createCampaign(query: {
    campaign: ICreateEditCampaigns;
  }): Promise<ICreateEditCampaignsResponse> {
    try {
      const { campaign } = query;
      const { status } = await this.api.post(`${this.pathCampaigns}/create`, campaign);
      return { statusOK: status === STATUS_SUCCESS };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createCampaign');
      return { statusOK: false };
    }
  }

  /**
   * @description Create new action
   * @param { ICreateAction } payload
   * @return { ICreateActionResponse | null }
   */
  async createAction(payload: ICreateAction): Promise<ICreateActionResponse | null> {
    try {
      const { data } = await this.api.post<{ result: ICreateActionResponse }>(
        `${this.pathActions}`,
        payload
      );
      return data.result;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createAction');
      return null;
    }
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new recurrent campaign
   */
  async createCampaignRecurrent(query: {
    campaignRecurrent: ICreateEditCampaigns;
  }): Promise<ICreateEditCampaignsRecurrentResponse> {
    try {
      const { campaignRecurrent } = query;
      const { status, data } = await this.api.post(
        `${this.pathCampaigns}/recurrent`,
        campaignRecurrent
      );
      const { result } = data as { result: ICampaignRecurrentCreated };
      return { statusOK: status === STATUS_SUCCESS, recurrentCreated: result };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'createCampaignRecurrent');
      return { statusOK: false, recurrentCreated: {} as ICampaignRecurrentCreated };
    }
  }

  /**
   * @param query: { campaign: ICreateEditCampaigns }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Create new campaign
   */
  async getStrategiesByAccount(): Promise<ICampaignsStrategiesResponse> {
    try {
      const { status, data } = await this.api.get(`${this.pathCampaigns}/strategies/list`);
      const { result } = data as { result: IStrategy[] };
      return { statusOK: status === STATUS_SUCCESS, strategies: result };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getStrategiesByAccount');
      return { statusOK: false, strategies: [] };
    }
  }

  /**
   * @description The getCampaign function retrieves a list of campaigns from an API and returns them as a Promise.
   * @param {}
   * @returns {Promise<ICampaignsListResponse>}
   */
  async getCampaign(query: ICampaignListPayload): Promise<ICampaignsListResponse> {
    const {
      page,
      limit,
      search,
      state,
      strategy,
      startDate,
      endDate,
      sortField,
      sortOrder,
      campaignTypeId,
      excludeCampaignStatus,
      channelsToExclude,
    } = query;
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/list`, {
        page,
        limit,
        search,
        state,
        strategy,
        startDate,
        endDate,
        sortField,
        sortOrder,
        campaignTypeId,
        excludeCampaignStatus,
        channelsToExclude,
      });
      const { result } = data as { result: ICampaign[] };
      const { pager } = data as { pager: { countTotal: number; countPartial: number } };

      const { timeOffset } = await EmblueService.getInstance().getUserData();
      const listCampaignsFlatten = _.flatten(result).map((x) => ({
        campaign: {
          ...x,
          lastUpdate: convertUTCToSpecificTimezone(x.lastUpdate, timeOffset as number),
          creationDate: convertUTCToSpecificTimezone(x.creationDate, timeOffset as number),
        },
      }));
      const listCampaigns: ITableCampaigns[] = listCampaignsFlatten;

      return { campaigns: listCampaigns, total: pager.countTotal, partial: pager.countPartial };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCampaign');
      return { statusOK: false, campaigns: [] };
    }
  }

  /**
   * @description The getRecurrentMessage function retrieves a list of recurrent messages from an API and returns them as a Promise.
   * @param {}
   * @returns {Promise<ICampaignsRecurrentListResponse>}
   */
  async getRecurrentMessage(query: ICampaignListPayload): Promise<ICampaignsRecurrentListResponse> {
    const { page, limit, search, state, startDate, endDate, sortField, sortOrder } = query;
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/recurrent/list`, {
        page,
        limit,
        search,
        state,
        startDate,
        endDate,
        sortField,
        sortOrder,
      });
      const { result } = data as { result: IRecurrenceMessage[] };
      const { pager } = data as { pager: { countTotal: number; countPartial: number } };

      const { timeOffset } = await EmblueService.getInstance().getUserData();
      const listRecurrentMessageFlatten = _.flatten(result).map((x) => ({
        campaign: {
          ...x,
          executionDate: convertUTCToSpecificTimezone(x.executionDate, timeOffset as number),
          creationDate: convertUTCToSpecificTimezone(x.creationDate, timeOffset as number),
        },
      }));
      const listRecurrentMessage: ITableCampaignsRecurrent[] = listRecurrentMessageFlatten;

      return {
        campaigns: listRecurrentMessage,
        total: pager.countTotal,
        partial: pager.countPartial,
      };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getRecurrentMessage');
      return { statusOK: false, campaigns: [] };
    }
  }

  /**
   * @param query: { campaignID: TCampaignID }
   * @returns {Promise<ICreateEditCampaignsResponse>}
   * @description Get a campaign by id
   */
  async getCampaignByID(campaignID: TCampaignID): Promise<ICampaignDetailResponse> {
    try {
      const { data } = await this.api.get(`${this.pathCampaigns}/detail?campaignId=${campaignID}`);
      const dataResult: ICampaignDetailResult = data as ICampaignDetailResult;
      const campaign: ICampaignDetail = dataResult.result[0] ?? {};
      return { campaign };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCampaignByID');
      return { statusOK: false, campaign: {} };
    }
  }

  /**
   * @param query: { actionID: TActionID }
   * @returns {Promise<IActionByIdResponse>}
   * @description Get a action by id
   */
  async getActionByID(actionID: TActionID): Promise<IActionByIdResponse> {
    try {
      const { data } = await this.api.get(
        `${this.pathCampaigns}/actions/detail?actionId=${actionID}`
      );
      const action = data as ICampaignActions;
      return { action };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getActionByID');
      return { statusOK: false };
    }
  }

  /**
   * @param query: {ICampaignActionsListPayload}
   * @returns {Promise<ICampaignsActionsResponse>}
   * @description Get list actions by campaign
   */
  async getActionsByCampaign(
    query: ICampaignActionsListPayload
  ): Promise<ICampaignsActionsResponse> {
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/actions/list`, query);
      const { result } = data as { result: ICampaignActions[] };
      const { pager } = data as { pager: { countTotal: number; countPartial: number } };

      const { timeOffset } = await EmblueService.getInstance().getUserData();

      const listCampaignActionsFlatten = _.flatten(result).map((x) => ({
        action: {
          ...x,
          exeDate: convertUTCToSpecificTimezone(x.exeDate ?? '', timeOffset as number),
          creationDate: convertUTCToSpecificTimezone(x.creationDate, timeOffset as number),
        },
      }));
      const listCampaigns: ITableCampaignActions[] = listCampaignActionsFlatten;

      return { actions: listCampaigns, total: pager.countTotal, partial: pager.countPartial };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getActionsByCampaign');
      return { statusOK: false, actions: [] };
    }
  }

  /**
   * @param query: {IEditActionPayload}
   * @returns {Promise<IEditActionResponse>}
   * @description Update name action
   */
  async editAction(query: IEditActionPayload): Promise<IEditActionResponse> {
    try {
      const { status } = await this.api.patch(`${this.pathCampaigns}/actions/edit`, query);
      return { statusOK: status === STATUS_SUCCESS };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getActionsByCampaign');
      return { statusOK: false };
    }
  }

  /**
   * @param query: {ICheckNameResource}
   * @returns {Promise<boolean>}
   * @description Validate name if exist in campaign, action or segment
   */
  async checkNameResource(query: ICheckNameResource): Promise<boolean> {
    const { resource, name, campaignId } = query;
    const encodedName = encodeURIComponent(name);
    try {
      const { data } = await this.api.get(
        `${this.pathCampaigns}/validate?resource=${resource}&name=${encodedName}${
          campaignId ? `&campaignId=${campaignId}` : ''
        }`
      );
      const { valid } = data as { valid: boolean };
      return valid;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'checkNameResource');
      return false;
    }
  }

  /**
   * @returns {Promise<ITouchRulesResponse>}
   * @description Retrieve the configured touch rules information for an account
   */
  async getTouchRules(): Promise<ITouchRulesResponse> {
    try {
      const { data } = await this.api.get(`${this.pathCampaigns}/actions/touch_rules`);
      const rules = data as ITouchRulesResponse;
      return { ...rules };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getTouchRules');
      return { days: 0, emails: 0 };
    }
  }

  /**
   * @returns {Promise<IEmailInfoResponse>}
   * @description Return configuration info for an email action
   */
  async getEmailInfo(actionID: TActionID): Promise<IEmailInfoResponse | null> {
    try {
      const { data } = await this.api.get(`${this.pathCampaigns}/actions/${actionID}/info`);
      return data as IEmailInfoResponse;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEmailInfo');
      return null;
    }
  }

  /**
   * @returns {Promise<IAddresseesInfoResponse>}
   * @description Return configuration info for an email action
   */
  async getAddresseesInfo(actionID: TActionID): Promise<IAddresseesInfoResponse | null> {
    try {
      const { data } = await this.api.get(`${this.pathCampaigns}/actions/${actionID}/delivery`);
      return data as IAddresseesInfoResponse;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getAddresseesInfo');
      return null;
    }
  }

  /**
   * @returns {Promise<IDeliveryActionResponse>}
   * @description Retrieve the configured delivery information for an action
   */
  async getDeliveryAction(query: IDeliveryActionPayload): Promise<IDeliveryActionResponse> {
    try {
      const { data } = await this.api.get(
        `${this.pathCampaigns}/actions/${query.actionId}/delivery`
      );
      const deliveryData = data as IDeliveryActionResponse;
      return { ...deliveryData };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getDeliveryAction');
      return {
        isImmediateShipping: false,
        shippingDate: '',
        reinforcement: { isReinforcementShipment: false, startDate: '', subject: '' },
      };
    }
  }

  /**
   * @returns {Promise<ISetDeliveryActionResponse>}
   * @description Retrieve the message of the configured delivery information for an action
   */
  async setDeliveryAction(query: ISetDeliveryActionPayload): Promise<ISetDeliveryActionResponse> {
    try {
      const { data } = await this.api.post(
        `${this.pathCampaigns}/actions/${query.actionId}/delivery`,
        query
      );
      const deliveryData = data as ISetDeliveryActionResponse;
      return { ...deliveryData };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'setDeliveryAction');
      return { message: '', campaignElementId: 0, shippingElementId: 0 };
    }
  }

  /**
   * @returns {Promise<IAddresseesPayload>}
   * @description Return segments, groups and tags by account
   */
  async getAddressees(query: IAddresseesPayload): Promise<IAddresseesList[]> {
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/actions/recipients`, query);
      const addresseesListData = data as IAddresseesListResponse;
      const addresseesList = addresseesListData?.result;

      return addresseesList;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getAddressees');
      return [];
    }
  }

  /**
   * @returns {Promise<IEmailInfoPayload>}
   * @description Save action email info
   */
  async setInfoAction(query: Partial<IEmailInfoPayload>, actionID: TActionID): Promise<boolean> {
    try {
      await this.api.patch(`${this.pathCampaigns}/actions/${actionID}/info`, query);
      return true;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'setInfoAction');
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description Change state action
   */
  async setStateAction({ actionId, statusId }: IUpdateStatusAction): Promise<boolean> {
    try {
      await this.api.put(`${this.pathCampaigns}/actions/${actionId}/status/${statusId}`);
      return true;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'setStateAction');
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description confirm mail to send/scheduling
   */
  async confirmMail(actionID: TActionID): Promise<GenericServiceResponse<ConfirmEmailErrors>> {
    try {
      await this.api.put(`${this.pathCampaigns}/actions/${actionID}/${EChannelsID.EMAIL}/confirm`);
      return { success: true };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'confirmMail');
      return {
        success: false,
        errorType: (error as AxiosError<ValidationError>).response?.data.type as ConfirmEmailErrors,
      };
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description delete mail content
   */
  async deleteMailContent(id: number): Promise<boolean> {
    try {
      await this.api.delete(`${this.pathEditor}/mailContent/${id}`);
      return true;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteMailContent');
      return false;
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description pause mail to scheduling
   */
  async pauseMail(actionID: TActionID): Promise<boolean> {
    try {
      await this.api.put(`${this.pathCampaigns}/actions/${actionID}/${EChannelsID.EMAIL}/pause`);
      return true;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'pauseMail');
      return false;
    }
  }

  /**
   * @returns {Promise<ICalendarCampaignActions>}
   * @description get campaign & actions by date
   */
  async getCalendarActions(payload: ICalendarActionsPayload): Promise<ICalendarCampaignActions[]> {
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/calendar/list`, payload);
      const response = data as ICalendarCampaignActions[];

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCampaignActionsByDate');
      return [] as ICalendarCampaignActions[];
    }
  }

  /**
   * @returns {Promise<boolean>}
   * @description search campaigns or actions by name or id
   */
  async globalSearchCampaigns(query: ISearchCampaignsGlobal): Promise<IListSearchCampaigns[]> {
    try {
      const { data } = await this.api.post(`${this.pathCampaigns}/actions/global/search`, query);
      const responseData = data as IListSearchCampaigns[];
      return responseData;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'globalSearchCampaigns');
      return {} as IListSearchCampaigns[];
    }
  }

  /**
   * @returns {Promise<IEmailPreview>}
   * @description returns the preview of an email based on a test contact's email ID
   */
  async getPreviewWithEmailID(actionID: TActionID, emailID: number): Promise<IEmailPreview | null> {
    try {
      const { data } = await this.api.get(
        `${this.pathCampaigns}/summary_preview_email/${actionID}/${emailID}`
      );
      return data as IEmailPreview;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getPreviewWithEmailId');
      return null;
    }
  }

  /**
   * @returns {Promise<IDuplicateCampaignActionsResponse>}
   * @description duplicate actions
   */
  async duplicateActions(
    payload: IDuplicateCampaignActions
  ): Promise<IDuplicateCampaignActionsResponse> {
    try {
      const { actionName, campaignActionId, actionType } = payload;
      const { data } = await this.api.post<{
        result: { campaignActionId: number; status: string };
      }>(`${this.pathCampaigns}/actions/duplicate`, {
        campaignActionId,
        actionName,
        actionType,
      });
      const response = data.result;
      return { statusOK: response.status === 'OK', actionIdDuplicate: response.campaignActionId };
    } catch (error) {
      console.error(`❌ EM_Error duplicateActions:`, error);
      return { statusOK: false, actionIdDuplicate: 0 };
    }
  }

  /**
   * @returns {Promise<IDuplicateCampaignsResponse>}
   * @description duplicate campaigns
   */
  async duplicateCampaigns(payload: IDuplicateCampaigns): Promise<IDuplicateCampaignsResponse> {
    try {
      const { data, status } = await this.api.post<{
        result: { campaignId: number; status: number; hasLegacyActions: boolean };
      }>(`${this.pathCampaigns}/duplicate`, payload);
      const response = data.result;
      return {
        statusOK: status === 200,
        campaignId: response.campaignId,
        hasLegacyActions: response.hasLegacyActions,
      };
    } catch (error) {
      console.error(`❌ EM_Error duplicateCampaigns:`, error);
      return { statusOK: false, campaignId: 0, hasLegacyActions: false };
    }
  }
}

export const CampaignsServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  CampaignsService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof CampaignsService);
