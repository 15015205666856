import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import { Card, CardSection, If, Text } from '@/lib/v2/components';

import noInvoice from '@/src/assets/lottiefiles/no-invoices.json';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { numberToCurrency } from '@/src/utils/currency';
import { convertDate } from '@/src/utils/Date/dateUtils';

import { atomContractDetail } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';

const BillingMonth = () => {
  const { t } = useTranslation();
  const [upcomingBill, , upcomingBillIsLoading] = useEmblue(ServiceMethods.getUpcomingBill);
  const contractDetail = useAtomValue(atomContractDetail);
  const [startMonthDiscount, endMonthDiscount] =
    contractDetail?.currentMonthWithDiscount?.split('/') ?? [];

  const noShowDiscounts = +startMonthDiscount + 1 > +endMonthDiscount;
  const isMonthlyPlan = upcomingBill?.plan.period === 'monthly';

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noInvoice,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const NoInvoices = () => {
    return (
      <div className="flex flex-col">
        <Lottie options={defaultOptions} width={150} />
        <div className="w-full pb-8">
          <Text alignment="center">{t('MY_PLAN.BILLING.noInvoice')}</Text>
        </div>
      </div>
    );
  };

  const BillInformation = () => {
    return (
      <>
        <div className="flex w-full justify-between">
          {upcomingBill?.plan.planName ? (
            <div className="flex flex-col">
              <Text
                {...(upcomingBill?.plan.period === 'annually' && { color: 'gray' })}
                fontWeight="medium"
                variant="sub-headline-2"
              >
                {t('MY_PLAN.BILLING.planLabel', {
                  planName: upcomingBill?.plan.planName,
                  billingPeriod: t(`MY_PLAN.BILLING.billingPeriod.${upcomingBill?.plan.period}`),
                })}
              </Text>
              <If
                condition={
                  contractDetail?.currentMonthWithDiscount && isMonthlyPlan && !noShowDiscounts
                }
              >
                <Text variant="text">{contractDetail?.contractDiscount}% OFF</Text>
              </If>
              <If condition={upcomingBill?.plan.period === 'annually'}>
                <Text color="gray" variant="text">
                  <If condition={contractDetail?.currentMonthWithDiscount !== undefined}>
                    {contractDetail?.contractDiscount}% OFF -{' '}
                  </If>
                  {t('MY_PLAN.BILLING.nextPayment', {
                    date: convertDate(upcomingBill.plan.billingDate, true),
                  })}
                </Text>
              </If>
            </div>
          ) : (
            <div className="my-1 h-4 w-48 animate-pulse rounded-full bg-gray-200"></div>
          )}
          {upcomingBill?.plan.price !== undefined ? (
            <div className="flex flex-col">
              <Text fontWeight="medium" variant="sub-headline-2">{`${
                upcomingBill?.currency ?? ''
              } ${numberToCurrency(upcomingBill?.plan.price ?? 0, false)}`}</Text>
              <If
                condition={
                  contractDetail?.currentMonthWithDiscount &&
                  contractDetail.currentMonthWithDiscount !== 'unlimited' &&
                  isMonthlyPlan &&
                  !noShowDiscounts
                }
              >
                <Text alignment="right" variant="text">
                  {+startMonthDiscount + 1}/{endMonthDiscount}
                </Text>
              </If>
            </div>
          ) : (
            <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
          )}
        </div>
        <Text color="gray" variant="text">
          {t('MY_PLAN.BILLING.Surpluses')}
        </Text>
        <div className="flex justify-between">
          <Text>{t('MY_PLAN.BILLING.emailConsumption')}</Text>
          <If condition={upcomingBill?.volumeExc.email !== undefined}>
            <Text>
              <If condition={!!upcomingBill?.currency}>{`${upcomingBill?.currency ?? ''} `}</If>
              {numberToCurrency(upcomingBill?.volumeExc.email ?? 0, false, 2) ?? '0'}
            </Text>
          </If>
          <If condition={upcomingBill?.volumeExc.email === undefined}>
            <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
          </If>
        </div>
        <div className="flex justify-between">
          <Text>{t('MY_PLAN.BILLING.smsConsumption')}</Text>
          <If condition={upcomingBill?.volumeExc.sms !== undefined}>
            <Text>
              <If condition={!!upcomingBill?.currency}>{`${upcomingBill?.currency ?? ''} `}</If>
              {numberToCurrency(upcomingBill?.volumeExc.sms ?? 0, false, 2) ?? '0'}
            </Text>
          </If>
          <If condition={upcomingBill?.volumeExc.sms === undefined}>
            <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
          </If>
        </div>
        <div className="flex justify-between">
          <Text>{t('MY_PLAN.BILLING.onsiteConsumption')}</Text>
          <If condition={upcomingBill?.volumeExc.onsite !== undefined}>
            <Text>
              <If condition={!!upcomingBill?.currency}>{`${upcomingBill?.currency ?? ''} `}</If>
              {numberToCurrency(upcomingBill?.volumeExc.onsite ?? 0, false, 2) ?? '0'}
            </Text>
          </If>
          <If condition={upcomingBill?.volumeExc.onsite === undefined}>
            <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
          </If>
        </div>
      </>
    );
  };

  const noInvoices = upcomingBill && !upcomingBill.plan.planName;

  return (
    <Card>
      <CardSection noPadding className="flex h-full flex-col justify-between">
        <div className="flex h-full flex-col">
          <CardSection className="border-b" paddingX="medium">
            <div className="flex w-full justify-between">
              <Text fontWeight="medium">{t('MY_PLAN.BILLING.upcomingBill')}</Text>
              <If condition={!upcomingBillIsLoading}>
                <Text variant="text">
                  {upcomingBill?.nextBillingDate && convertDate(upcomingBill.nextBillingDate, true)}
                </Text>
              </If>
            </div>
          </CardSection>
          <CardSection className="h-full !pt-8" paddingX="medium">
            <div className="flex size-full flex-col gap-6">
              {noInvoices ? <NoInvoices /> : <BillInformation />}
            </div>
          </CardSection>
        </div>
        <If condition={!noInvoices}>
          <CardSection className="border-t" paddingX="medium">
            <div className="flex w-full items-center justify-between">
              <Text fontWeight="medium" variant="sub-headline-2">
                {t('MY_PLAN.BILLING.totalPayAmount')}
              </Text>
              <If condition={upcomingBill?.totalBilling !== undefined}>
                <div className="flex gap-2">
                  <Text fontWeight="medium" variant="sub-headline-2">
                    <If condition={!!upcomingBill?.currency}>{`${
                      upcomingBill?.currency ?? ''
                    } `}</If>
                    {`${numberToCurrency(upcomingBill?.totalBilling ?? 0, false, 2) ?? '0'}/${t(
                      'MY_PLAN.BILLING.billingPeriod.month'
                    )}`}
                  </Text>
                </div>
              </If>
              <If condition={upcomingBill?.totalBilling === undefined}>
                <div className="my-1 h-4 w-28 animate-pulse rounded-full bg-gray-200"></div>
              </If>
            </div>
          </CardSection>
        </If>
      </CardSection>
    </Card>
  );
};

export default memo(BillingMonth);
