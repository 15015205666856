export const InfoIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <mask height="24" id="mask0_870_17278" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
        <rect fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_870_17278)">
        <path
          d="M12 16C12.2267 16 12.4167 15.9233 12.57 15.77C12.7233 15.6167 12.8 15.4267 12.8 15.2V12C12.8 11.7733 12.7233 11.5833 12.57 11.43C12.4167 11.2767 12.2267 11.2 12 11.2C11.7733 11.2 11.5833 11.2767 11.43 11.43C11.2767 11.5833 11.2 11.7733 11.2 12V15.2C11.2 15.4267 11.2767 15.6167 11.43 15.77C11.5833 15.9233 11.7733 16 12 16ZM12 9.6C12.2267 9.6 12.4167 9.52333 12.57 9.37C12.7233 9.21667 12.8 9.02667 12.8 8.8C12.8 8.57333 12.7233 8.38333 12.57 8.23C12.4167 8.07667 12.2267 8 12 8C11.7733 8 11.5833 8.07667 11.43 8.23C11.2767 8.38333 11.2 8.57333 11.2 8.8C11.2 9.02667 11.2767 9.21667 11.43 9.37C11.5833 9.52333 11.7733 9.6 12 9.6ZM12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z"
          fill="#364365"
        />
      </g>
    </svg>
  );
};
