import { Option } from '@/lib/v2/components/Select/Select';

import { IGroup } from '@/src/ContactsModule/interfaces/Interfaces';

export interface SegmentRowData {
  name: string;
  phone: number;
  tags: string;
  origin: string;
  state: string;
  creationDate: string;
  lastActivity: string;
}

export enum EmblueRecipesEnum {
  lastTag1Day = 1,
  abandonedContact,
  newWithoutInteraction,
  contactsToWakeUp,
  webVisitorsWithoutInteraction,
  promoters,
  passives,
  detractors,
  noEngagement,
  abandonedNewContacts,
  contactsWithBouncesLastDays,
  losingEngagement,
}

export enum ECommerceSegmentsEnum {
  rfmHigh = 1,
  rfmMedium,
  rfmLow,
  clients,
  purchasedAtLeast1Time,
  purchasedOnlyAMonthAgo,
  tenDaysSinceLastPurchase,
  neverPurchased,
}

// cspell:disable
export type ECommerceSegmentsV1Name =
  | 'RFM HIGH'
  | 'RFM MED'
  | 'RFM LOW'
  | 'Clientes'
  | 'Compraron al menos una vez'
  | 'Compraron en el último mes'
  | 'Compraron en los últimos 10 días'
  | 'Nunca Compraron'
  | 'Compraron';
// cspell:enable

export type ModalSegmentsCardsProps = {
  segmentNumber: EmblueRecipesEnum | ECommerceSegmentsEnum | undefined;
  filters: ISegmentFilter[];
  created: boolean;
};

export type SegmentFilterField = ContactField & { source: SegmentFilterCondition['source'] };

export type SegmentFilterSystemContactField =
  | 'name'
  | 'lastName'
  | 'contactId'
  | 'gender'
  | 'phone1'
  | 'phone2'
  | 'web_url'
  | 'linkedin'
  | 'facebook'
  | 'twitter'
  | 'address'
  | 'city'
  | 'country'
  | 'birthday'
  | 'company'
  | 'position'
  | 'secondaryEmail'
  | 'idTrigger'
  | 'recency'
  | 'frequency'
  | 'amount'
  | 'creationDate'
  | 'lastOpening'
  | 'lastClick'
  | 'lastViral'
  | 'lastActivity'
  | 'lastWebsiteVisit'
  | 'lastWebTag'
  | 'lastWebItem'
  | 'lastPushSubscription'
  | 'lastPushSend'
  | 'lastSendSMS'
  | 'nps'
  | 'eCommerceClassification';
export type SegmentFilterActivityType = 'clicks' | 'sendings' | 'opens' | 'bounces';
export type SegmentFilterRelationType =
  | 'groups'
  | 'discardedGroup'
  | 'tags'
  | 'ecommerceGroups'
  | 'items'
  | 'geoLocation'
  | 'rank'
  | 'devices'
  | 'socialNetworks'
  | 'email'
  | 'channels';

export type SegmentFilterCondition =
  | {
      source: 'relation';
      name: SegmentFilterRelationType;
      id?: number;
    }
  | {
      source: 'activity';
      name: SegmentFilterActivityType;
      id?: number;
    };

export type FieldDataType = ISegmentFilter['dataType'];

export type ISegment = {
  id: number;
  name: string;
  filters: ISegmentFilter[];
};

export type ISegmentFilterDate = {
  dataType: 'DATE';
  condition: 'eq' | 'neq' | 'gte' | 'lte' | 'bt' | 'last-days';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterNumeric = {
  dataType: 'NUMERIC';
  condition: 'eq' | 'neq' | 'gte' | 'lte' | 'bt';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterEmail = {
  dataType: 'EMAIL';
  condition: 'contains' | 'not-contains' | 'exactly';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterString = {
  dataType: 'STRING';
  /** 'or' by default; 'and' is only for CF users optionType 'combo' */
  operator: 'or' | 'and';
  condition: 'contains' | 'not-contains' | 'exactly';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterGender = {
  dataType: 'GENDER';
  condition: 'equal' | 'distinct';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterCountry = {
  dataType: 'COUNTRY';
  condition: 'equal' | 'distinct';
  field: ContactField;
  values: string[];
};
export type ISegmentFilterID = {
  dataType: 'ID';
  condition: 'equal' | 'distinct';
  operator: 'or' | 'and';
  field: SegmentFilterCondition;
  values: string[];
  daysOld?: number;
  allOptions?: boolean;
};
export type ISegmentFilterActivity = {
  dataType: 'ACTIVITY';
  condition: 'with' | 'without';
  field: SegmentFilterCondition;
  lastDays: number;
};

export type ISegmentFilter =
  | ISegmentFilterDate
  | ISegmentFilterNumeric
  | ISegmentFilterEmail
  | ISegmentFilterString
  | ISegmentFilterGender
  | ISegmentFilterID
  | ISegmentFilterActivity
  | ISegmentFilterCountry;

export type FiltersType = Partial<ISegmentFilter & { key?: number | string }>[];

export type FilterType = Partial<ISegmentFilter & { key?: number | string }>;

export type ISegmentInfo = {
  id: number;
  name: string;
  contacts: number;
};

export type SegmentInfo = {
  id: number;
  name: string;
  contacts: number;
  segmentType: SegmentTypeEnum;
  segmentNumber: EmblueRecipesEnum | ECommerceSegmentsEnum | null;
};

export type EmblueSegmentInfo = SegmentInfo & {
  segmentNumber: EmblueRecipesEnum | ECommerceSegmentsEnum;
};

export enum SegmentTypeEnum {
  custom,
  emblueRecipe,
  eCommerce,
}

export enum FieldType {
  TEXT_MULTIPLE = 'text_multiple',
  SELECT = 'select',
  SELECT_MULTIPLE = 'select_multiple',
  DATE = 'date',
}

export interface IOptionFilter extends Option {
  dataType: FieldDataType;
  fieldType: FieldType;
  groupValue: SegmentFilterCondition['source'];
  value: SegmentFilterCondition['name'];
  andSwitch?: boolean;
  daysSelect?: boolean;
  optionsValues?: Option[];
  field?: ContactField;
}

export interface ConditionsOption extends Option {
  value: ISegmentFilter['condition'];
}

export type ConditionalOptionsByDataType = Record<string, ConditionsOption[]>;

export interface ValuesSegmentsFiltersContactsResponse {
  userGroups: Array<string[]>;
  tags: Tag[];
  channels: Channel[];
  notAddressees: Array<string[]>;
  listProfiles: ListProfile[];
  ecommerceGroups: IGroup[];
}

export interface Tag {
  quantityActivity: number;
  amountFrequency: number;
  quantityUsers: number;
  layerId: number;
  categoryName: null;
  color: null;
  containerId: null;
  content: null;
  contentType: null;
  name: string;
  orderDefault: null;
  tagCategoryId: null;
  tagId: number;
  lastUse: string;
}

export interface Channel {
  channelTypeId: number;
  description: string;
}

export interface ListProfile {
  Key: string;
  Value: Array<{
    Key: string;
    Value: string;
  }>;
}

export interface CreateSegmentResponse {
  segmentId: number;
  errorByName?: boolean;
}
export interface CreateGroupResponse {
  groupId: number;
  errorByName?: boolean;
  errorByLimit?: boolean;
}

/** Segments fields */
export interface FieldsResponse {
  defaultFields: Field[];
  customFields: Field[];
  activityFields: ActivityField[];
}

export interface Field extends ContactField {
  value: string;
}

export interface ContactField {
  id: number;
  name: string;
  type: 'date' | 'string' | 'phone' | 'country' | 'gender' | 'number' | 'email' | 'url';
  metadata: Metadata;
}

export interface ActivityField {
  name: string;
  metadata: Pick<Metadata, 'internalColumn'>;
  type: string;
  value: string;
}

export interface Metadata {
  internalColumn: string;
  internalId?: number;
  internalNumber?: number;
  optionType: 'radio' | 'combo' | null;
  defaultValues?: string[];
  defaultField?: boolean;
  fieldType?: 'normal' | 'extended';
}

export interface DataResponse<T> {
  success: boolean;
  data: T;
}
