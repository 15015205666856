import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { If, Iframe, Spinner } from '@/lib/v2/components';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomOpenMessageModal } from '@/modules/CampaignsModule/atoms/actionsAtom';
import ErrorAlert from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/components/MessageDisplay/ErrorAlert/ErrorAlert';
import { EmailPreviewButtons } from '@/modules/CampaignsModule/components/EmailPreviewButtons';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { useHtmlProcessor } from '@/modules/CampaignsModule/hooks/useHtmlProcessor';
import { IContent } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { EActionStatusAvailable } from '@/modules/CampaignsModule/interfaces/Campaigns';
import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

interface IMessageDisplay {
  html?: IContent;
  isLoading?: boolean;
  disabled?: boolean;
}

const MessageDisplay = ({ html, isLoading, disabled = false }: IMessageDisplay) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setOpenModal = useSetAtom(atomOpenMessageModal);
  const setNewRulePath = useSetAtom(atomNewRulePath);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const processedMessage = useHtmlProcessor(html?.message ?? '');
  const { getEmailInfo, setActionState, deleteEmailTemplate } = useActionEmailData(
    Number(actionId)
  );

  const dragAndDrop = actionInfoEmail ? actionInfoEmail.content.editorVersion === 'beefree' : '';
  const editorRoute = dragAndDrop ? 'editor' : 'html-editor';
  const isAutomation = pathname.includes('automation');

  const redirectLocation = useMemo(() => {
    return `${CAMPAIGNS_PATH}/${Number(campaignId)}/${actionType}/${Number(actionId)}`;
  }, [campaignId, actionId, actionType]);

  const hasErrors = useMemo(() => {
    return html?.errors ? html?.errors?.length > 0 : false;
  }, [html?.errors]);

  const handleEditClick = useCallback(() => {
    setNewRulePath(true);
    if (isAutomation) {
      const isRulesPath = pathname.includes(
        `${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`
      );
      navigate(
        isRulesPath
          ? `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${String(campaignId)}/action/${String(
              actionId
            )}/rules/editor`
          : `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${String(campaignId)}/action/${String(
              actionId
            )}/${String(actionType)}/editor`
      );
      return;
    }

    navigate(`${redirectLocation}/${editorRoute}`);
  }, [
    setNewRulePath,
    isAutomation,
    navigate,
    redirectLocation,
    editorRoute,
    pathname,
    campaignId,
    actionId,
    actionType,
  ]);

  const handleDeleteMessage = useCallback(async () => {
    setIsLoadingDelete(true);
    await deleteEmailTemplate(Number(actionId));
    await getEmailInfo();
    await setActionState({ actionId: Number(actionId), statusId: EActionStatusAvailable.DRAFT });

    setIsLoadingDelete(false);
    setOpenModal(false);
  }, [actionId, deleteEmailTemplate, setActionState, setOpenModal, getEmailInfo]);

  const handlePreviewMessage = useCallback(() => {
    if (isAutomation) {
      navigate(
        `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${String(campaignId)}/action/${String(
          actionId
        )}/${String(actionType)}/${AUTOMATION_PATHS.PREVIEW}`
      );
      return;
    }
    navigate(`${redirectLocation}/preview`);
  }, [actionId, actionType, campaignId, isAutomation, redirectLocation, navigate]);

  return (
    <>
      <If condition={!isLoading && Boolean(html)} fallback={<Spinner withoutOverlay />}>
        <div className="size-full">
          <If condition={hasErrors}>
            <div className="flex flex-col gap-2 px-10 py-2">
              <ErrorAlert errors={html?.errors || []} />
            </div>
          </If>
          <div className="flex justify-end gap-2 px-10 pb-6 pt-5">
            <EmailPreviewButtons
              disabled={disabled}
              loading={isLoading || isLoadingDelete}
              sourceEditor={actionInfoEmail?.content.editorVersion}
              onDelete={handleDeleteMessage}
              onEdit={handleEditClick}
              onPreview={handlePreviewMessage}
            />
          </div>
          <div
            className={`relative grid ${
              hasErrors ? 'min-h-[calc(100vh-245px)]' : 'min-h-[calc(100vh-200px)]'
            } place-items-center px-10 ${hasErrors ? 'pb-6' : 'pb-6'}`}
          >
            <div className="relative size-full">
              <Iframe html={processedMessage} id="message-display-iframe" title="html" />
            </div>
          </div>
        </div>
      </If>
    </>
  );
};

export default MessageDisplay;
