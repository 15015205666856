import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';

import CardContacts from '@/src/modules/InsightsModule/components/CardContacts';

import {
  ICardContacts,
  IContactsMetric,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

interface IContactsAdditional {
  metrics: IContactsMetric;
}

const ContactsAdditional = ({ metrics }: IContactsAdditional) => {
  const { statistics } = metrics;
  const { t } = useTranslation(['insights']);

  const cardsAdditional: ICardContacts[] = useMemo(
    () => [
      {
        id: 'insights-contacts-groups',
        datadogKey: 'insights.contacts.groups',
        linkTo: '/v2/contacts/groups',
        total: statistics?.groups ? (
          `${statistics?.groups.count}/${statistics?.groups.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.groups.title'),
          tooltip: t('contacts.additional.groups.tooltip'),
        },
      },
      {
        id: 'insights-contacts-segments',
        datadogKey: 'insights.contacts.segments',
        linkTo: '/v2/contacts/segments',
        total: statistics?.segments ? (
          `${statistics?.segments.count}/${statistics?.segments.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.segments.title'),
          tooltip: t('contacts.additional.segments.tooltip'),
        },
      },
      {
        id: 'insights-contacts-tags',
        datadogKey: 'insights.contacts.tags',
        linkTo: '/v2/contacts/tags',
        total: statistics?.tags ? (
          `${statistics?.tags.count}/${statistics?.tags.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.tags.title'),
          tooltip: t('contacts.additional.tags.tooltip'),
        },
      },
      {
        id: 'insights-contacts-discarded',
        datadogKey: 'insights.contacts.discarded',
        linkTo: '/v2/contacts/discarded',
        total:
          typeof statistics?.contacts?.discarded?.percentage === 'number' ? (
            `${statistics?.contacts?.discarded?.percentage}%`
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.additional.discarded.title'),
          tooltip: t('contacts.additional.discarded.tooltip'),
        },
      },
    ],
    [t, statistics]
  );

  return (
    <div className="grid w-full grid-cols-4 gap-4">
      {cardsAdditional.map((item: ICardContacts, key: number) => (
        <CardContacts
          key={key}
          className="p-4"
          datadogKey={item.datadogKey}
          id={item.id}
          linkTo={item.linkTo}
          total={item.total}
          truncate={true}
          wordings={item.wordings}
        />
      ))}
    </div>
  );
};

export default ContactsAdditional;
