export const EditPencilIcon = ({ color = '#004dbc' }) => {
  return (
    <svg
      data-name="Group 7527"
      height="20"
      id="Group_7527"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect data-name="Rectangle 3179" fill="none" height="20" id="Rectangle_3179" width="20" />
      <g
        data-name="Icon feather-edit-3"
        id="Icon_feather-edit-3"
        transform="translate(-1.99 2.139)"
      >
        <path
          d="M18,30h7.49"
          data-name="Layout 3464"
          fill="none"
          id="Layout_3464"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(-6.01 -15.752)"
        />
        <path
          d="M15.735,4.835a1.765,1.765,0,1,1,2.5,2.5l-10.4,10.4L4.5,18.566l.832-3.329Z"
          data-name="Layout 3465"
          fill="none"
          id="Layout_3465"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(0 -4.318)"
        />
      </g>
    </svg>
  );
};
