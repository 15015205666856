import { Alert } from '@/lib/v3/components/Alert';
import { ScheduledAlert } from '@/lib/v3/components/ScheduledAlert';

import { useDeprecationOldEditors } from './hooks/useScheduledNotifications';

const DeprecationOldEditors = () => {
  const { alertInfoData, isVisible } = useDeprecationOldEditors();

  if (!alertInfoData) return null;

  return (
    <ScheduledAlert key={alertInfoData.id} enabled={isVisible}>
      <Alert
        textButton={alertInfoData.textButton}
        title={alertInfoData.title}
        type={alertInfoData.type}
        onClickButton={alertInfoData.onClickButton}
        onClose={alertInfoData.onClose}
      >
        {alertInfoData.text}
      </Alert>
    </ScheduledAlert>
  );
};

export default DeprecationOldEditors;
