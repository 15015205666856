import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Container } from '@/lib/v2/components';

import IframeTransport from '@/src/compat/iframeTransport';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModuleNew/constants';
import { useGetActionRecurringMessage } from '@/src/modules/AutomationModuleNew/hooks/RecurringMessageDetail';

const RulesReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { actionName, actionIsLoading } = useGetActionRecurringMessage();

  const rulesPaths = useMemo(() => {
    const paths = [
      {
        id: 'automation-home-path',
        name: t('BREADCRUMB.automation'),
        to: { path: `/v2/${AUTOMATION_PATHS.MODULE_PATH}` },
      },
      {
        id: 'rules-home-path',
        name: t('BREADCRUMB.rules'),
        to: { path: `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}` },
      },
      {
        id: 'rules-detail-path',
        name: `${t('RulesReport.report')} - ${actionName}`,
        to: {
          path: '',
        },
      },
    ];

    return paths;
  }, [actionName, t]);

  const handleOnBackClick = useCallback(() => {
    navigate(`/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RULES_PATH}`);
  }, [navigate]);

  return (
    <div className="flex h-auto w-full flex-col">
      <Breadcrumb
        withoutMargin
        customPaths={rulesPaths}
        handleOnBackClick={handleOnBackClick}
        isLoading={actionIsLoading}
      />
      <Container
        fullHeight
        background="gray"
        className="min-h-[calc(100vh_-_72px_-_48px)]"
        fluid="screen"
      >
        <IframeTransport loading={actionIsLoading} />
      </Container>
    </div>
  );
};

export default RulesReport;
