import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option, Select, Text } from '@/lib/v2/components';

import { atomDateExecutionValue } from '@/modules/RulesModule/atoms/rules';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import {
  TRANSLATE_RULES_PLACEHOLDERS,
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
} from '@/modules/RulesModule/constants';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IExecutionTimeSelectProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

export const ExecutionTimeSelect = ({
  form,
  disabled = false,
  rules,
}: IExecutionTimeSelectProps) => {
  const { t } = useTranslation('rules');

  const setDateExecutionValue = useSetAtom(atomDateExecutionValue);

  const { executionOptions } = useRuleDefaultOptions();

  const handleChangeExecution = useCallback(
    (value: Option | Option[]) => {
      const execution = value as Option;
      setDateExecutionValue(execution.value as string);
    },
    [setDateExecutionValue]
  );

  return (
    <div className="mb-5 flex items-center">
      <Text className="mr-2 whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.executionTime`)}
      </Text>
      <div className="min-w-40">
        <Select
          isRequired
          control={form.control}
          disabled={disabled}
          name="execution"
          options={executionOptions}
          placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.executionTime`)}
          rules={rules.execution}
          onChange={handleChangeExecution}
        />
      </div>
    </div>
  );
};
