import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, If, Text } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS,
  TRANSLATE_RULES_TRIGGER_TYPES,
} from '@/modules/RulesModule/constants';
import { RulesFormIcon } from '@/modules/RulesModule/images/icons';

const NodeContentWebForm = () => {
  const { t } = useTranslation('rules');

  const { trigger, repeatEmails, groupContacts } = useAtomValue(atomRuleData);

  const repeatedEmails = useMemo(() => {
    return repeatEmails?.id === 1
      ? t(`${TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS}.always`)
      : t(`${TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS}.once`);
  }, [repeatEmails, t]);

  const hasGroupContacts = useMemo(
    () =>
      (groupContacts: OptionExternal | undefined): groupContacts is OptionExternal => {
        return !!groupContacts?.name;
      },
    []
  );

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-2 flex items-center">
        <IconSvg fillColor="primary" height="24px" svgComponent={<RulesFormIcon />} width="24px" />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_TRIGGER_TYPES}.${trigger?.id ?? ''}`)}
        </Text>
      </div>
      <hr className="w-full min-w-64" />
      <div className="flex flex-col justify-center pb-4 pt-2">
        <Text fontWeight="medium" variant="text">
          {`${t(`${TRANSLATE_RULES_LABELS}.repeatedEmails.title`)}:`}
          <Text fontWeight="normal" variant="text">
            {`${repeatedEmails}`}
          </Text>
        </Text>
        <If condition={hasGroupContacts(groupContacts)}>
          <Text fontWeight="medium" variant="text">
            {`${t(`${TRANSLATE_RULES_LABELS}.groupContacts`)}:`}
            <Text truncate fontWeight="normal" variant="text">
              {groupContacts?.name}
            </Text>
          </Text>
        </If>
      </div>
    </div>
  );
};

export default NodeContentWebForm;
