import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTOMATION_PATHS, BACK_URL_KEY } from '@/src/modules/AutomationModuleNew/constants';

export const useNavigateAndBack = () => {
  const navigate = useNavigate();

  const navigateAndSavePathname = useCallback(
    (to: string) => {
      sessionStorage.setItem(BACK_URL_KEY, window.location.pathname);
      navigate(to);
    },
    [navigate]
  );

  const getBackUrlAndRemoveSS = useCallback(() => {
    const backUrl = sessionStorage.getItem(BACK_URL_KEY) || `/v2/${AUTOMATION_PATHS.MODULE_PATH}`;
    sessionStorage.removeItem(BACK_URL_KEY);
    return backUrl;
  }, []);

  return { navigateAndSavePathname, getBackUrlAndRemoveSS };
};
