import { ActionRuleType, FieldDTO } from '@/src/modules/RulesModule/interfaces';

export const RULES_PATHS = {
  RULES_PATH: ':rulesId',
};

export const ACTION_RULE_TYPE: ActionRuleType = 'email-trigger';

export const TRANSLATE_RULES_COLUMNS = 'RULES.COLUMNS';
export const TRANSLATE_RULES_TRIGGER_TYPES = 'RULES.TRIGGER_TYPES_TITLE';
export const TRANSLATE_RULES_ACTION_TYPES = 'RULES.ACTION_TYPES';
export const TRANSLATE_RULES_ACTION_FIELD_TRIGGER = 'RULES.ACTION_FIELD_TRIGGER';

export const TRANSLATE_RULES_CALL_TO_ACTION = 'RULES.CALL_TO_ACTION';
export const TRANSLATE_RULES_CREATE_RULES = 'RULES.CREATE_RULES';

export const TRANSLATE_RULES_SEQUENCE_SETTINGS = 'RULES.SEQUENCE_SETTINGS';
export const TRANSLATE_RULES_LABELS = 'RULES.LABELS';
export const TRANSLATE_RULES_PLACEHOLDERS = 'RULES.PLACEHOLDERS';

export const TRANSLATE_RULES_TRIGGER_OPTIONS = 'RULES.TRIGGER_OPTIONS';
export const TRANSLATE_RULES_FREQUENCY_OPTIONS = 'RULES.FREQUENCY_OPTIONS';
export const TRANSLATE_RULES_EXECUTION_OPTIONS = 'RULES.EXECUTION_OPTIONS';
export const TRANSLATE_RULES_PERIOD_OPTIONS = 'RULES.PERIOD_OPTIONS';
export const TRANSLATE_RULES_PRESENCE_OPTIONS = 'RULES.PRESENCE_OPTIONS';
export const TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS = 'RULES.REPEATED_EMAIL_OPTIONS';
export const TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS = 'RULES.REDIRECT_PAGE_OPTIONS';
export const TRANSLATE_RULES_TAG_TYPE_OPTIONS = 'RULES.TAG_TYPE_OPTIONS';
export const TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS = 'RULES.TAG_FREQUENCY_OPTIONS';
export const TRANSLATE_RULES_CONTACTS_GRAPH = 'RULES.CONTACTS_GRAPH';

export const TRANSLATE_RULES_RENAME = 'RULES.RENAME';
export const TRANSLATE_RULES_MODAL_BACK_TO_RULES = 'RULES.MODAL_BACK_TO_RULES';

export const NODE_WIDTH = 288;
export const VERTICAL_SPACING = 200;

//cspell:disable
export const RULES_TYPES_ALLOW = [
  'fechadesencadenante',
  'tagasociado',
  'apiintegrador',
  'flows',
  'llamadaexternaaunaurl',
  'formulario',
];
//cspell:enable

//cspell:disable
export const DEFAULT_HTML_FORM = `
<!DOCTYPE html>
<html lang="es">
<head>
    <meta name="robots" content="noindex,nofollow">
    <meta charset="UTF-8">
    <link rel="icon" type="image/png" href="/Imagenes/favicon.ico">
    <title>Formulario vía emBluemail</title>
    <style>
        html{
        overflow-x: hidden;
        }
        body, input {
            font-family: Helvetica, sans-serif;
            font-size: 12px;
            font-weight: lighter;
            color: #777;
            margin: 0;
            padding: 0;
        }
        .cabecera {
            width: auto;
            height: 50px;
            background-color: #004DBC;
            margin: 20px auto 0 auto;
            text-align: center;
            font-size: 16px;
            line-height: 50px;
            color: #ffffff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .cuerpo {
            border: 1px solid #e5e5e5;
            background-color: #f5f5f5;
            padding: 25px 0 30px 0;
            border-radius: 0 0 5px 5px;
            width: 100%;
            box-sizing: border-box;
        }
        .marco {
            padding: 0 50px;
        }
        .campo {
            margin-bottom: 24px;
        }
        .campo input {
            margin-top: 4px;
        }
        .formulario {
            width: 100%;
        }
        .formulario tr.campo td {
            padding: 6px 0;
        }
        .formulario td p {
            text-align: right;
            line-height: 12px;
            font-size: 12px !important;
            font-weight: lighter !important;
        }
        .formulario input, select {
            border: 1px solid #999;
            min-height: 22px;
            min-width: 200px;
            line-height: 22px;
            padding: 0 5px;
            color: #333;
            font-size: 12px;
            border-radius: 5px;
            background-color: #ffffff;
            margin-left: 5px;
        }
        input[type="submit"] {
            display: inline-block;
            border: 1px solid #1c83ea;
            min-height: 34px;
            line-height: 34px;
            padding: 0 20px;
            background-color: #004DBC;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 200;
            border-radius: 5px;
            margin-top: 20px;
        }
        .leyenda {
            padding-top: 15px;
            text-align: center;
            font-size: 11px;
        }
        .leyenda a {
            color: #3366cc;
            text-decoration: none;
        }
        .txt-cap {
            text-transform: capitalize;
        }
        .error-row {
             height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    visibility: hidden;
        }
        .error-row .error:not(:empty) {
            visibility: visible;
    height: auto;
    line-height: normal;
    font-size: 11px;
    margin-top: 4px;
        }
        .error {
          color: #8A0808;
          font-size: 11px;
          margin-right: 40px;
        }
    </style>
</head>
<body>
    <div style="width: auto; margin: 0 auto;">
        <div class="cabecera">{{FORM_TITLE}}</div>
        <div class="cuerpo">
            <div class="marco">
              <!-- Config here -->
                <table class="formulario campos">
                        <tbody id="dynamic-fields">
                        <!-- Campo Email -->

                        <tr class="campo">
                          <td colspan="2">
                            <label class="block text-sm font-medium text-gray-700"
                                  style="margin-left: 8px; margin-bottom: 4px;">
                              Email *
                            </label>
                            <input
                              id="email"
                              type="email"
                              name="email"
                              placeholder="you@example.com"
                              required
                              data-error="Campo requerido|Formato inválido"
                              class="eb-input eb-input--input w-full"
                              style="border: 1px solid #cbd5e1; border-radius: 0.375rem;
                                    padding: 0.5rem 0.75rem; box-sizing: border-box; width: 90%;"
                            />
                          </td>
                        </tr>
                        <tr class="error-row error-email">
                          <td colspan="2" style="text-align: center;">
                            <span class="error"></span>
                          </td>
                        </tr>

                          <!-- FormFields here -->
                        </tbody>
                    </table>
                    <table class="formulario">
                        <tbody>
                            <tr>
                                <td colspan="2" align="center">
                                    <input type="submit" value="{{SUBMIT_BUTTON_TEXT}}">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
        <div class="leyenda">
            {{FOOTER_TEXT}}
            <a href="http://www.embluemail.com" target="_blank">emBluemail</a>.
        </div>
    </div>
    <script type="text/javascript" src="https://app-staging-ar.embluemail.com/emblue/form_validation.js"></script>
</body>
</html>
`;
//cspell:enable
export const TAG_TYPE_FIELD_OPTIONS = {
  ANY_TAG: 'anyTag',
  GROUP_TAGS: 'groupTags',
};

export const TAG_FREQUENCY_MAP: { [key: string]: string } = {
  '1': 'day',
  '7': 'week',
  '30': 'month',
};

/* cspell:disable */
export const DEFAULT_PAYLOAD_RULE = {
  acumuladaCantidad: null,
  campoDTO: {} as FieldDTO,
  camposPersonalizadosDTO: [],
  elementosDTO: [],
  elementosHnosCantidad: 0,
  enviarCadaAnio: null,
  envioRecurrente: false,
  tag_id: [],
  tipo: 'Clicks',
  urlDestino: null,
  urlDestinoError: null,
};

export const RULES_TYPES_DICTIONARY = {
  'Fecha desencadenante': {
    id: 1,
    name: 'Fecha desencadenante',
  },
  'Actividad específica': {
    id: 2,
    name: 'Actividad específica',
  },
  'Actividad acumulada': {
    id: 3,
    name: 'Actividad acumulada',
  },
  'Llamada externa a una URL': {
    id: 4,
    name: 'Llamada externa a una URL',
  },
  'Api Integrador': {
    id: 5,
    name: 'Api Integrador',
  },
  Formulario: {
    id: 6,
    name: 'Formulario',
  },
  'Tag asociado': {
    id: 7,
    name: 'Tag asociado',
  },
  Flows: {
    id: 8,
    name: 'Flows',
  },
};
/* cspell:enable */
