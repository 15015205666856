import { t } from 'i18next';

export const formatLocalizedDate = (date: Date | string) => {
  date = typeof date === 'string' ? new Date(date) : date;
  return date.toLocaleDateString(t('TIMEZONE.locale'), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

export const formatNormalizeLocalizedDate = (
  date: Date | string,
  separator = '-',
  locale = 'en'
) => {
  date = typeof date === 'string' ? new Date(date) : date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  if (locale === 'en') {
    return `${year}${separator}${month}${separator}${day}`;
  }
  return `${day}${separator}${month}${separator}${year}`;
};
