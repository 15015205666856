import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Card,
  CardSection,
  Container,
  Flex,
  IconSvg,
} from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import {
  atomFiltersAction,
  atomHistorySearchPagerActions,
  atomPagerAction,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaign } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomCreateActionsModalIsOpen,
  atomCreateRules,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
  atomToggleEditCreateCampaignModal,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CreateActionsModal } from '@/src/modules/CampaignsModule/components/CampaignActions/CreateActionsModal';
import { HeaderBannerActions } from '@/src/modules/CampaignsModule/components/CampaignActions/HeaderBannerActions';
import { TableContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { TotalCountDisplay } from '@/src/modules/CampaignsModule/components/TotalCountDisplay';
import { defaultPager, WHATSAPP_PREFIX } from '@/src/modules/CampaignsModule/constants';
import { useActionsData } from '@/src/modules/CampaignsModule/hooks/useActionsData';
import { useActionsFilters } from '@/src/modules/CampaignsModule/hooks/useActionsFilters';
import { useCampaignLastPath } from '@/src/modules/CampaignsModule/hooks/useCampaignLastPath';
import { useStrategies } from '@/src/modules/CampaignsModule/hooks/useStrategies';
import {
  EActionTypes,
  ERulesActionType,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { ModalType, ScreenType } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { CreateRuleModal } from '@/modules/RulesModule/components/CreateRuleModal';

const CampaignActionsMain = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [detailCampaign] = useAtom(atomDetailCampaign);
  const [actionCreationsModalIsOpen, setActionCreationsModalIsOpen] = useAtom(
    atomCreateActionsModalIsOpen
  );
  const filters = useAtomValue(atomFiltersAction);
  const pager = useAtomValue(atomPagerAction);
  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);
  const setCreateRules = useSetAtom(atomCreateRules);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setScreenTypeProcess = useSetAtom(atomScreenTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);
  const { actionsList, loadingProcess, totalsActionsList, totalApplyFilters } = useActionsData(
    EActionTypes.DETAIL
  );

  const { resetUnmount } = useActionsFilters();
  const { getStrategies, strategyList } = useStrategies();
  const { campaignLastPath } = useCampaignLastPath();

  useEffect(() => {
    strategyList.length === 0 && void getStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => resetUnmount();
  }, [resetUnmount]);

  const handleCreateCampaignAction = () => {
    setCreateRules(ERulesActionType.ALL);
    setActionCreationsModalIsOpen(true);

    setHistorySearchPagerAction({
      filters: { ...filters },
      pager: {
        page: pager?.page || defaultPager.page,
        limit: pager?.limit || defaultPager.limit,
      },
    });
  };

  const handleClickEditCampaign = useCallback(() => {
    setModalTypeProcess(ModalType.EDIT);
    setScreenTypeProcess(ScreenType.DETAIL);
    setToggleEditCategoryModal(true);
  }, [setModalTypeProcess, setToggleEditCategoryModal, setScreenTypeProcess]);

  const handleBack = useCallback(() => {
    navigate(campaignLastPath.campaign);
  }, [campaignLastPath, navigate]);

  const handleCloseActionCreationsModal = useCallback(() => {
    setActionCreationsModalIsOpen(false);
  }, [setActionCreationsModalIsOpen]);

  const campaignActionsMainPaths = useMemo(() => {
    const paths = [
      {
        id: 'campaigns-path',
        name: t('BREADCRUMB.campaigns'),
        to: { path: campaignLastPath.campaign },
      },
      {
        id: 'campaigns-name-path',
        name: detailCampaign?.name || '',
      },
    ];

    paths.forEach((path) => {
      if (path.name.toLowerCase().startsWith(WHATSAPP_PREFIX)) {
        path.name = path.name.slice(9);
        path.name = path.name.charAt(0).toUpperCase() + path.name.slice(1);
      }
    });

    return paths;
  }, [detailCampaign?.name, campaignLastPath, t]);

  return (
    <>
      <Breadcrumb
        isEditable
        customPaths={campaignActionsMainPaths}
        handleOnBackClick={handleBack}
        onEditableClick={handleClickEditCampaign}
      />
      <Container>
        <div className="mb-4">
          <HeaderBannerActions />
        </div>
        <Card>
          <CardSection noPadding>
            <Flex column>
              <SectionHeader
                actionsButtons={
                  <Button
                    iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                    id="create-campaign-actions-button"
                    size="small"
                    onClick={handleCreateCampaignAction}
                  >
                    {t('CAMPAIGN_ACTIONS_MAIN.button')}
                  </Button>
                }
                subtitle={
                  <SectionHeaderSubtitle
                    countFilters={totalApplyFilters}
                    countRecordsPartial={totalsActionsList.partial}
                    dataList={actionsList}
                    i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                    loading={loadingProcess}
                  />
                }
                title={t('CAMPAIGN_ACTIONS_MAIN.title')}
                titleDetail={
                  <TotalCountDisplay
                    hasItems={totalsActionsList.total > 0}
                    isLoading={loadingProcess}
                    total={totalsActionsList.total}
                  />
                }
              />
              <Flex column withGap gapSize="medium">
                <TableContainer />
              </Flex>
            </Flex>
          </CardSection>
        </Card>
      </Container>
      <CreateActionsModal
        isOpen={actionCreationsModalIsOpen}
        onClose={handleCloseActionCreationsModal}
      />
      <CreateRuleModal />
    </>
  );
};

export default memo(CampaignActionsMain);
