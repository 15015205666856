const SkeletonNode = () => {
  return (
    <div className="pointer-events-none mb-2 flex w-full min-w-64 animate-pulse flex-col gap-2 py-2">
      <div className="h-3 w-20 rounded-full bg-gray-200 py-1"></div>
      <hr />
      <div className="h-3 w-20 rounded-full bg-gray-200"></div>
      <div className="h-3 w-36 rounded-full bg-gray-200"></div>
    </div>
  );
};

export default SkeletonNode;
