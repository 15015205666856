import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActionEmailData } from '@/src/modules/CampaignsModule/hooks/useActionEmailData';

import { ModalUpload } from './components/ModalUpload/ModalUpload';
import EditPage from './pages/EditPage';
import HeaderSection from './HeaderSection';

import { useHiddenV1Container } from '@/modules/AutomationModuleNew/hooks/useHiddenV1Container';

interface IHtmlEditorProps {
  isAutomationModule?: boolean;
}

const HtmlEditor = memo(({ isAutomationModule = false }: IHtmlEditorProps) => {
  useHiddenV1Container();
  const { actionId } = useParams();

  const { getEmailInfo } = useActionEmailData(Number(actionId));
  useEffect(() => void getEmailInfo(), [getEmailInfo]);

  return (
    <div className="min-h-[calc(100vh_-_64px)] bg-emblue-white">
      <HeaderSection isAutomationModule={isAutomationModule} />
      <div className="p-10">
        <EditPage />
      </div>
      <ModalUpload />
    </div>
  );
});

export default HtmlEditor;
