import { useTranslation } from 'react-i18next';

import { Alert } from '@/lib/v2/components';

import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/modules/CampaignsModule/constants';

const WarningVideo = () => {
  const { t } = useTranslation('inbox');
  return (
    <Alert
      className="mt-1"
      description={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.warning_video`)}
      type="info"
    />
  );
};

export default WarningVideo;
