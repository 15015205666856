import classNames from 'classnames';
import { FC } from 'react';

import { CardCompositionProps } from './types';

/**
 * CardContent component - Used as the main content section in a Card
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to render in the main section
 * @param {boolean} [props.hasHeader=false] - Whether a header is present (affects padding)
 * @param {boolean} [props.hasFooter=false] - Whether a footer is present (affects padding)
 * @param {boolean} [props.withoutPadding=false] - When true, removes horizontal padding
 *
 * @example
 * // Basic usage
 * <Card.Content>
 *   <p>Main content goes here</p>
 * </Card.Content>
 *
 * @example
 * // Without horizontal padding
 * <Card.Content withoutPadding>
 *   <p>Content without side padding</p>
 * </Card.Content>
 *
 * @returns {JSX.Element} Content section for the Card
 */
const CardContent: FC<CardCompositionProps> = ({
  children,
  hasHeader = false,
  hasFooter = false,
  withoutPadding = false,
}) => {
  const contentClass = classNames({
    'px-6': !withoutPadding,
    'py-4': hasHeader && hasFooter,
    'pt-4 pb-6': hasHeader && !hasFooter,
    'pt-6 pb-4': !hasHeader && hasFooter,
    'py-6': !hasHeader && !hasFooter,
  });

  return <div className={contentClass}>{children}</div>;
};

export default CardContent;
