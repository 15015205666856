import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { TRANSLATE_INSIGHTS_CONTACTS } from '@/modules/InsightsModule/constants';

const ContactsGraphTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation(['insights']);

  const tooltipData = useMemo(() => {
    if (!payload?.length) return null;
    const [{ value: contacts = 0 }, { value: contacted = 0 } = {}] = payload as { value: number }[];
    const noContacted = contacts - contacted;
    const rawPercentage = (noContacted / contacts) * 100;
    const formattedPercentage = rawPercentage.toFixed(1);

    return {
      contacts,
      contacted,
      percentageNoContacted: parseFloat(formattedPercentage).toString(),
    };
  }, [payload]);

  if (!active || !tooltipData) return null;
  if (tooltipData.contacts === 0) return null;

  return (
    <div className="inline-flex flex-col items-start gap-1 rounded-md border border-emblue-subheadings bg-emblue-subheadings p-2">
      <p className="text-12 font-medium leading-tight text-white">
        {t(`${TRANSLATE_INSIGHTS_CONTACTS}.contacts`)}: {quantifier(tooltipData.contacts)}
      </p>
      <p className="text-12 font-medium leading-tight text-white">
        {t(`${TRANSLATE_INSIGHTS_CONTACTS}.contacted`)}: {quantifier(tooltipData.contacted)}
      </p>
      <p className="text-12 font-medium leading-tight text-white">
        {quantifier(tooltipData.percentageNoContacted)}
        {t(`${TRANSLATE_INSIGHTS_CONTACTS}.labelPercentageContacted`)}
      </p>
    </div>
  );
};

export default memo(ContactsGraphTooltip);
