/* eslint-disable no-constant-binary-expression */
import { memo, ReactNode, useMemo } from 'react';

export interface IAvatar {
  email?: string;
  name?: string;
  lastName?: string;
  sizeXl?: boolean;
  sizeL?: boolean;
  sizeM?: boolean;
  sizeS?: boolean;
  status?: 'inactive' | 'discarded' | 'asleep' | 'new' | 'frequent' | '';
  icon?: JSX.Element | ReactNode | null;
}

const Avatar = ({ name, lastName, sizeXl, email, status, icon, sizeL, sizeM, sizeS }: IAvatar) => {
  const getInitials = useMemo(() => {
    let nInitial = '';
    if (name && name.trim() !== '') {
      nInitial = name?.trim()?.[0]?.toUpperCase();
    } else if (email) {
      nInitial = email?.trim()?.[0]?.toUpperCase();
    }

    const lnInitial =
      lastName && lastName.trim() !== '' ? lastName?.trim()?.[0]?.toUpperCase() : '';

    return nInitial + lnInitial;
  }, [name, lastName, email]);

  const currentStatusColor = useMemo(() => {
    return {
      new: 'bg-emblueStatus-newStatus text-emblueStatus-newStatus',
      frequent: 'bg-emblueStatus-frequentStatus text-emblueStatus-frequentStatus',
      asleep: 'bg-emblueStatus-asleepStatus text-emblueStatus-asleepStatus',
      inactive: 'bg-emblueStatus-inactiveStatus text-emblueStatus-inactiveStatus',
      discarded: 'bg-emblueStatus-discardedStatus text-emblueStatus-discardedStatus',
      vtex: 'bg-ecommerce-vtex',
    };
  }, []);

  return (!status?.length || !status) && !icon ? (
    <span
      className={`${sizeXl ? 'p-11' : ''} ${
        sizeS ? 'size-6' : 'size-8'
      } inline-flex items-center justify-center rounded-full bg-emblueStatus-newStatus ${
        !sizeS && 'md:size-10'
      }`}
    >
      <span
        className={`${
          sizeXl ? 'text-24' : sizeS ? 'text-[12px]' : 'xl:text-16 2xl:text-20'
        } font-medium leading-none text-emblueStatus-newStatus`}
        data-testid="initials"
      >
        {' '}
        {getInitials}{' '}
      </span>
    </span>
  ) : (
    <span
      className={`${sizeXl ? 'p-11' : ''} ${sizeL ? 'p-7 ' : ''} ${sizeM ? 'p-3' : ''} ${
        sizeS ? 'size-6' : 'size-8'
      } inline-flex items-center justify-center rounded-full ${!sizeS && 'md:size-10'} ${
        currentStatusColor[status as keyof typeof currentStatusColor]
      }`}
    >
      {icon && <span>{icon}</span>}
      <span
        className={`${
          sizeXl ? 'text-24' : sizeS ? 'text-[12px]' : 'xl:text-16 2xl:text-20'
        } font-medium leading-none`}
        data-testid="initials"
      >
        {' '}
        {getInitials}{' '}
      </span>
    </span>
  );
};

export default memo(Avatar);
