import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox, If, Text } from '@/lib/v2/components';

import EmptyScreen from './EmptyScreen';
import OrderColumn from './OrderColumn';
import Pagination from './Pagination';
import RowItem from './RowItem';
import SkeletonColumn from './SkeletonColumn';
import { ValuePagination } from './usePagination';

export { createColumnHelper } from '@tanstack/react-table';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    widthClassName?: string;
    enableOrderColumn?: boolean;
  }
}

export type OrderColumnState = {
  orderByColumn: string;
  orderByDirection: 'asc' | 'desc';
};

export const TRANSLATIONS_TABLE = {
  showRows: 'PAGINATION.displayRows',
};
export interface TableProps<T> {
  /** Data to show in the table */
  data: T[];
  /** create columns importing the helper method createColumnHelper */
  columns: ColumnDef<T, any>[];
  /** show skeleton for each column */
  isLoading?: boolean;
  /** use if you want create Table with pagination */
  withPagination?: {
    /** it's a prop to replace text "Show rows", you can export and pass TRANSLATIONS_TABLE.showRows */
    label?: string;
    /** total number of rows without filters and with filters */
    rowCount: number;
    /** value pagination state */
    valuePagination: ValuePagination;
    /** callback function to update state pagination controlled and recall fetch */
    onPaginationChange: (currentPage: number, pageSize: number) => void;
  };
  emptyState?: {
    /** set true if has filters applied */
    hasFiltersApplied: boolean;
    /** show component indicating that there is no data  */
    noDataResultComponent: ReactNode;
    /** show component indicating that the filter result does not contain data */
    noFilterResultComponent: ReactNode;
  };
  /** callback function and get row data when the user do click in the row */
  onRowClick?: (rowData: T) => void;
  /** value state Rows Selection */
  valueRowsSelection?: RowSelectionState;
  /** show checkbox rows for multi selections */
  onRowSelectionCondition?: (row: Row<T>) => boolean;
  /** callback function to get rows selected */
  onRowsSelectionChange?: OnChangeFn<RowSelectionState>;
  /** unControlled: active for expand subRows , remember that each row must have subRows prop*/
  enableExpandSubRows?: boolean;
  /** table expand row with user clicking a row */
  enableExpandSubRowsWithOnClick?: boolean;
  /** function to indicate what is the subRow prop */
  getSubRows?: (originalRow: T, index: number) => T[] | undefined;
  /** enable sticky header in table */
  enableStickyHeader?: boolean;
  /** enable Scroll Y*/
  enableScrollY?: boolean;
  /** enable scroll X */
  enableScrollX?: boolean;
  /** align header text columns */
  alignment?: 'left' | 'center' | 'right';
  /** Order column change  */
  onOrderColumnChange?: (value: OrderColumnState) => void;
  /** value state for orderColumn controller state */
  valueOrderColumn?: OrderColumnState;
  /** hide header */
  withoutHeader?: boolean;
}

const Table = <T,>({
  data,
  columns,
  withPagination,
  isLoading,
  emptyState,
  onRowClick,
  onRowSelectionCondition,
  valueRowsSelection,
  onRowsSelectionChange,
  enableExpandSubRows,
  enableExpandSubRowsWithOnClick,
  getSubRows,
  enableStickyHeader,
  enableScrollY,
  enableScrollX,
  alignment = 'left',
  onOrderColumnChange,
  valueOrderColumn,
  withoutHeader = false,
}: TableProps<T>) => {
  if (withPagination !== undefined) {
    if (!withPagination.valuePagination) {
      throw new Error('`valuePagination` is required when `withPagination` is used.');
    }
    if (!withPagination.onPaginationChange) {
      throw new Error('`onPaginationChange` is required when `withPagination` is used.');
    }
    if (withPagination.rowCount === undefined) {
      throw new Error('`rowCount` is required when `withPagination` is used.');
    }
  }

  if (enableExpandSubRows) {
    if (!getSubRows) {
      throw new Error('`getSubRows` is required when `enableRowExpand` is true.');
    }
  }

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const defaultHeightByScrollY = 'h-[500px]';

  const containerTableClasses = classNames('w-full', {
    [`overflow-y-scroll ${defaultHeightByScrollY}`]: enableScrollY,
    [`overflow-x-scroll`]: enableScrollX,
  });

  const theadClasses = classNames('bg-emblueLightGray shadow', {
    'sticky z-10': enableStickyHeader,
    'top-0': (enableStickyHeader && !withPagination) || enableScrollY,
    'top-[54px]': enableStickyHeader && withPagination && !enableScrollY,
  });

  const tbodyClasses = classNames('divide-y divide-gray-200');

  const paginationContainerClasses = classNames('flex w-full justify-end py-2', {
    'sticky top-0 bg-white z-[11]': enableStickyHeader && !enableScrollY,
  });

  const tableClasses = classNames('min-h-full', {
    'min-w-full': !enableScrollX,
    'min-w-max': enableScrollX,
  });

  const enhancedColumns = useMemo<ColumnDef<T, any>[]>(() => {
    if (valueRowsSelection || enableExpandSubRows) {
      const checkboxColumn: ColumnDef<T, any> = {
        id: 'checkbox',
        header: ({ table }) => {
          return (
            <div className="flex h-full items-center justify-center gap-2">
              {valueRowsSelection && (
                <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  id={`checkbox-all`}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
              {enableExpandSubRows && (
                <div className="flex h-full items-center justify-center">
                  <button onClick={table.getToggleAllRowsExpandedHandler()}>
                    {table.getIsAllRowsExpanded() ? (
                      <ChevronUpIcon className="size-5 fill-emblue-gray" />
                    ) : (
                      <ChevronDownIcon className="size-5 fill-emblue-gray" />
                    )}
                  </button>
                </div>
              )}
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex h-full items-center justify-center gap-2">
              {valueRowsSelection && (
                <div className="flex justify-center">
                  <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    id={`checkbox-${row.id}`}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {enableExpandSubRows && (
                <div className="flex h-full items-center justify-center">
                  {row.getCanExpand() && (
                    <button
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        row.getToggleExpandedHandler()();
                      }}
                    >
                      {row.getIsExpanded() ? (
                        <ChevronUpIcon className="size-5 fill-emblue-gray" />
                      ) : (
                        <ChevronDownIcon className="size-5 fill-emblue-gray" />
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        },
      };
      return [checkboxColumn, ...columns];
    }
    return columns;
  }, [columns, enableExpandSubRows, valueRowsSelection]);

  const enableRowSelection = useMemo(() => {
    if (valueRowsSelection && onRowSelectionCondition) {
      return onRowSelectionCondition;
    }
    if (valueRowsSelection) return true;
    return false;
  }, [onRowSelectionCondition, valueRowsSelection]);

  const valuePagination = useMemo<PaginationState | undefined>(() => {
    if (withPagination?.valuePagination) {
      return {
        pageIndex: withPagination.valuePagination.currentPage
          ? withPagination.valuePagination.currentPage - 1
          : 0,
        pageSize: withPagination.valuePagination.pageSize,
      };
    }
  }, [withPagination?.valuePagination]);

  const table = useReactTable<T>({
    data,
    columns: enhancedColumns,
    state: {
      rowSelection: valueRowsSelection,
      pagination: valuePagination,
      expanded,
    },
    rowCount: withPagination?.rowCount,
    enableRowSelection,
    getSubRows,
    onPaginationChange: handlePaginationChange,
    onRowSelectionChange: onRowsSelectionChange,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: withPagination ? true : false,
  });

  function handlePaginationChange(updater: Updater<PaginationState>): void {
    if (withPagination === undefined) return;
    const newPaginationState: PaginationState =
      typeof updater === 'function' && valuePagination
        ? updater(valuePagination)
        : (updater as PaginationState);

    withPagination?.onPaginationChange(
      newPaginationState.pageIndex + 1,
      newPaginationState.pageSize
    );
  }

  const handleOrderColumnsChange = useCallback(
    (value: OrderColumnState) => {
      onOrderColumnChange?.(value);
    },
    [onOrderColumnChange]
  );

  const showNoDataComponent = emptyState !== undefined && !isLoading && data.length === 0;
  const showNoFilterComponent = showNoDataComponent && emptyState.hasFiltersApplied;

  // remove this useEffect when expand subRows is controlled in the future
  useEffect(() => {
    if (valuePagination?.pageIndex || valuePagination?.pageSize) {
      setExpanded({});
    }
  }, [valuePagination?.pageIndex, valuePagination?.pageSize]);

  return (
    <div className="flex size-full flex-col">
      {!!withPagination && (
        <div className={paginationContainerClasses}>
          <Pagination
            currentPage={
              valuePagination?.pageIndex !== undefined ? valuePagination.pageIndex + 1 : 0
            }
            isDisabled={isLoading || showNoDataComponent || showNoFilterComponent}
            label={withPagination?.label}
            nextPage={table.nextPage}
            pageSize={
              withPagination?.valuePagination.pageSize
                ? withPagination.valuePagination.pageSize
                : 10
            }
            previousPage={table.previousPage}
            setPagination={table.setPagination}
            totalPages={table.getPageCount()}
          />
        </div>
      )}
      {isLoading && (
        <div className="flex w-full justify-between">
          {columns.map((col) => {
            return <SkeletonColumn key={col.header?.toString()} />;
          })}
        </div>
      )}
      {!isLoading && (
        <div className={containerTableClasses}>
          <table className={tableClasses}>
            {!withoutHeader && (
              <thead className={theadClasses}>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={classNames(
                            'p-4',
                            {
                              'w-[52px]':
                                header.id === 'checkbox' &&
                                enableRowSelection !== enableExpandSubRows,
                              'w-[80px]':
                                header.id === 'checkbox' &&
                                enableRowSelection &&
                                enableExpandSubRows,
                            },
                            header.column.columnDef.meta?.widthClassName
                          )}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              className={classNames('flex gap-2', {
                                'justify-start': alignment === 'left',
                                'justify-center': alignment === 'center',
                                'justify-end': alignment === 'right',
                              })}
                            >
                              <If condition={header.column.id === 'checkbox'}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                              </If>
                              <If condition={header.column.id !== 'checkbox'}>
                                <Text fontWeight="medium" variant="text">
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                </Text>
                              </If>
                              {header.column.columnDef.meta?.enableOrderColumn && (
                                <OrderColumn
                                  columnId={header.column.id}
                                  columnIdSelected={valueOrderColumn?.orderByColumn}
                                  isAsc={
                                    valueOrderColumn?.orderByDirection === 'asc' &&
                                    valueOrderColumn?.orderByColumn === header.column.id
                                  }
                                  onClick={handleOrderColumnsChange}
                                />
                              )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  );
                })}
              </thead>
            )}
            <tbody className={tbodyClasses}>
              {showNoDataComponent && !showNoFilterComponent && (
                <EmptyScreen
                  colSpan={enhancedColumns.length}
                  component={emptyState.noDataResultComponent}
                />
              )}
              {showNoFilterComponent && emptyState?.noFilterResultComponent && (
                <EmptyScreen
                  colSpan={enhancedColumns.length}
                  component={emptyState.noFilterResultComponent}
                />
              )}
              {(!showNoDataComponent || !showNoFilterComponent) &&
                table.getRowModel().rows.map((row) => {
                  return (
                    <RowItem
                      key={row.id}
                      alignment={alignment}
                      row={row}
                      {...(onRowClick &&
                        !enableExpandSubRowsWithOnClick && { onClick: onRowClick })}
                      {...(enableExpandSubRowsWithOnClick &&
                        row.getCanExpand() && {
                          onClick: row.getToggleExpandedHandler(),
                        })}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default memo(Table) as typeof Table;
